import React, { useState, useContext } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { GenerateBlob } from './generateBlob';
import { ContainerPdf, DownloadButton } from './style';
import BaseLayoutContext from 'contexts/base-layout';

export default function PdfViewer({ file, name, downloadAction = true }) {
  const [numOfPages, setNumOfPages] = useState(0);
  const { universityColor } = useContext(BaseLayoutContext);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumOfPages(numPages);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const generatePages = () => {
    return Array.from({ length: numOfPages }, (_, index) => (
      <Page pageNumber={index + 1} />
    ));
  };

  const handleClickDownload = async () => {
    const link = await GenerateBlob(file);
    const downloadLink = document.createElement('a');
    downloadLink.href = link;
    downloadLink.download = `${name}.pdf`;
    downloadLink.click();
  };

  return (
    <ContainerPdf>
      {downloadAction && (
        <DownloadButton
          universityColor={universityColor}
          onClick={handleClickDownload}
        >
          FAZER DOWNLOAD
        </DownloadButton>
      )}

      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {generatePages()}
      </Document>
    </ContainerPdf>
  );
}
