import styled from 'styled-components';

export const BackgroundModal = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background-color: #00000040;

  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const Modal = styled.div`
  width: 680px;
  height: auto;
  background-color: #fff;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  border-radius: 12px;

  position: absolute;
  top: 0;
`;

export const HeaderModal = styled.div`
  width: 100%;
  height: 85px;
  background-color: #009291;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 40px;

  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`;

export const BodyModal = styled.div`
  padding: 50px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const TitleModal = styled.h2`
  margin: 0;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
`;

export const ButtonClose = styled.button`
  width: 44px;
  height: 44px;

  border: none;
  border-radius: 50%;

  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const ContainerColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ButtonAddMaterial = styled.button`
  background-color: transparent;
  color: #009291;
  border: 1px solid #009291;
  border-radius: 2px;
  height: 40px;
  padding: 10px;

  cursor: pointer;
`;

export const Paragraph = styled.p`
  text-align: start;
  margin: 0;
  color: ${(props) => (props.data ? '#949494' : '#606062')};
  font-size: ${(props) => (props.data ? '14px' : '16px')};

  width: 350px;
`;
export const Link = styled.a`
  color: #005096;
  text-align: start;
  margin: 0;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  &:hover {
    text-decoration: underline;
  }
`;

export const ContainerDoc = styled.div`
  background-color: #eef2f4;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  flex-wrap: wrap;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-width: 100px;
  flex-shrink: 0;
`;

export const ContainerIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

export const ContainerImageName = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  flex: 1 1 auto;
  max-width: 80%;
`;
export const ButtonActionsDoc = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;

  cursor: pointer;
`;

export const ButtonImageDoc = styled.div``;
