import React, { useContext, useEffect, useRef, useState } from 'react';

import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import {
  ButtonFeedback,
  ButtonX,
  Container,
  Description,
  DivButtonsFooter,
  DivDescription,
  GridSide,
  ContentHeader,
  Title,
  UnpublishButton,
  WrapperContent,
  WrapperInformartions,
  WrapperMoreInfo,
  WrapperTitles,
  TitleHeader,
  BubbleText,
  TextBubble,
  EditButtonModal,
  ListButton,
  ContentButton,
  ParagraphCreated,
} from './styled';

import { RiCloseFill } from 'react-icons/ri';

import { RiTimeLine } from 'react-icons/ri';
import { RiGroupLine } from 'react-icons/ri';
import { GrPersonalComputer } from 'react-icons/gr';
import { BiCalendar } from 'react-icons/bi';
import { GrLocation } from 'react-icons/gr';
import { RiUser3Line } from 'react-icons/ri';
import { HiOutlineAcademicCap } from 'react-icons/hi2';
import { RiFocus2Line } from 'react-icons/ri';

import ModalUnpublishProject from '../ModalUnpublishProject';
import ModalViewFeedback from '../ModalViewFeedback';
import ModalTargetAudienceExtensionProjects from '../ModalTargetAudienceExtensionProjects';
import ImpactedPeopleModal from '../ImpactedPeopleModal';
import ModalUnpublishNoFeedback from '../ModalUnpublishProject/ModalUnpublishNoFeedback';
import { Divider } from '../ModalStylingPattern/styles';
import ModalSupportMaterial from '../ModalSupportMaterial';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import { getFeedback } from 'views/StaffPages/ExtensionProject/services';
import ModalInformation from 'components/informationModal';
import { configModalStudentsList } from 'views/StaffPages/ExtensionProject/utils/texts';
import {
  ContentFormInput,
  ParagraphFormInput,
} from 'views/StaffPages/ExtensionProject/styled';
import SimpleInput from 'components/UnformFields/SimpleInput';
import { Form } from '@unform/web';
import { schemaValidationEmail } from 'views/StaffPages/ExtensionProject/utils/validations';
import {
  downloadSpreadsheetStudents,
  getSupportMaterials,
} from 'views/StaffPages/ExtensionProject/services/urls';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import CreateExtensionProvider from '../../CreateModal/CreateExtensionContext';
import StepsEngine from '../../CreateModal/components/StepsEngine';
import { ModalsContext } from 'components/modals/contexts/modalContext';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { InfoRow } from './infoRow';

export default function ModalMoreInformations(props) {
  const {
    isOpen,
    setModalOpen,
    details,
    children,
    finishedProject,
    unpublishCard,
    waitingForStart,
    inProgress,
  } = props;

  const {
    setExtensionProjectId,
    extracurriculum,
    metadata,
    idAttraction,
    setOpenModalStudents,
    setStatus,
    setIdAttraction,
    kind,
    getCountProjects,
    appliedFilters,
    setFutureExtension,
    setPaginationTwoCurrentPage,
    setResetAllFutureListing,
    setInProgressExtension,
    setPaginationThreeCurrentPage,
    setResetInProgressListing,
  } = useContext(ExtensionProjectsContext);
  const { openModalWithContent } = useContext(ModalsContext);

  const modalContainer = useRef();
  const [openModalUnpublish, setOpenModalUnpublish] = useState(false);
  const [openModalFeedback, setOpenModalFeedback] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [openModalTargetAudience, setOpenModalTargetAudience] = useState(false);
  const [openImpactedPeopleModal, setOpenImpactedPeopleModal] = useState(false);
  const [openModalSupport, setOpenModalSupport] = useState(false);
  const [openModalStudentsList, setOpenModalStudentsList] = useState(false);
  const [dataSupportMaterials, setDataSupportMaterials] = useState(null);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const formatWorkload = (workload) => {
    if (workload) {
      const split = workload?.split('.');
      if (split[1] === '00') {
        return `${split[0].padStart(2, '0')}h`;
      }
      return `${split[0].padStart(2, '0')}h${split[1]}`;
    }
  };

  const startOffer = moment(details.start_at).format('DD/MM/YYYY');
  const endOffer = moment(details.end_at).format('DD/MM/YYYY');
  const createdAt = moment(details.created_at).format('DD/MM/YYYY');

  const formatDate = () => {
    return `${startOffer} a ${endOffer}`;
  };

  const dateNow = new Date();

  const compareDate = (dateNow, startAt) => {
    const dateNowFormat = moment(dateNow).format('DD/MM/YYYY');
    const startAtFormat = moment(startAt).format('DD/MM/YYYY');

    return startAtFormat >= dateNowFormat;
  };

  const isValidToUnpublish = compareDate(
    moment(dateNow).format('DD/MM/YYYY'),
    details.start_at
  );

  const closeModal = () => {
    setModalOpen(false);
    setExtensionProjectId(null);
  };

  const closeModalClickOutside = (e) => {
    if (modalContainer.current === e.target) {
      setModalOpen(false);
      setExtensionProjectId(null);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setOpenModalTargetAudience(false);
      setOpenImpactedPeopleModal(false);
      setOpenModalSupport(false);
    }
  }, [isOpen]);

  const handleModalFeedback = async (details) => {
    let feedbackId = details.extension_project.feedback_id;
    let projectId = details.id;
    setOpenModalFeedback(true);

    const data = await getFeedback(projectId, feedbackId);
    setFeedback(data);
  };

  const handleOpenStudentsList = () => {
    setOpenModalStudentsList(true);
  };

  const handleCloseStudentsList = () => {
    setOpenModalStudentsList(false);
  };

  const handleOpenEnrolledModal = (details) => {
    setIdAttraction(details.id);
    setExtensionProjectId(details.extension_project.id);
    setStatus(details.is_published);
    setOpenModalStudents(true);
  };

  const Address = ({ address }) => {
    const { street, number, complement, zip_code, city, state } = address;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '5px',
          width: '526px',
        }}
      >
        <p style={{ margin: 0 }}>
          {street && `${street},`} {number && ` ${number},`}{' '}
          {complement && ` ${complement},`} {zip_code && ` ${zip_code}`}
        </p>
        <p style={{ margin: 0 }}>
          {city && `${city} -`} {state && ` ${state}`}
        </p>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <ContentFormInput>
        <Form ref={formRef}>
          <SimpleInput
            name="email"
            defaultValue={metadata.email}
            placeholder="Digite aqui..."
          />
        </Form>

        <ParagraphFormInput>
          Caso precise, altere o e-mail no campo acima.
        </ParagraphFormInput>
      </ContentFormInput>
    );
  };

  const handleSubmitModal = async () => {
    const data = formRef.current.getData();
    try {
      setLoading(true);
      await schemaValidationEmail.validate(data, {
        abortEarly: false,
      });

      await downloadSpreadsheetStudents(idAttraction, data);
      setOpenModalStudentsList(false);
      customSnackbar('Lista enviada com sucesso!', 'confirmation');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          formRef.current.setFieldError(error.path, error.message);
        });
      }
      customSnackbar(
        'Erro ao enviar lista, tente novamente mais tarde.',
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const closeModalFeedback = () => {
    setOpenModalUnpublish(false);
    setModalOpen(false);
    
    // Reset future Listing
    setFutureExtension(null);
    setPaginationTwoCurrentPage(0);
    setResetAllFutureListing((prev) => !prev);
    
    getCountProjects(true);
  };

  const handleClickEdit = async (item) => {
    const modal = openModalWithContent({
      modalContent: mergeContextsHelper(
        <CreateExtensionProvider
          kind={kind}
          getCountProjects={getCountProjects}
          appliedFilters={appliedFilters}
          getModalInstance={() => modal}
          setFutureExtension={setFutureExtension}
          setPaginationTwoCurrentPage={setPaginationTwoCurrentPage}
          setResetAllFutureListing={setResetAllFutureListing}
          setInProgressExtension={setInProgressExtension}
          setPaginationThreeCurrentPage={setPaginationThreeCurrentPage}
          setResetInProgressListing={setResetInProgressListing}
        >
          <StepsEngine
            idProject={item.extension_project.id}
            uuidProject={item.id}
          />
        </CreateExtensionProvider>
      ),
      isNewModalDialog: true,
    });
  };

  return isOpen ? (
    <Container isOpen={isOpen}>
      <div
        className="box"
        ref={modalContainer}
        onClick={closeModalClickOutside}
      >
        {openModalStudentsList && (
          <ModalInformation
            config={{
              ...configModalStudentsList(metadata.email),
              loading: loading,
            }}
            closeModalFunction={handleCloseStudentsList}
            modalOpenState={openModalStudentsList}
            buttonConfirmFunction={handleSubmitModal}
            buttonCancelFunction={handleCloseStudentsList}
            formRef={formRef}
          >
            {renderInput()}
          </ModalInformation>
        )}
        <WrapperMoreInfo isOpen={isOpen}>
          <ContentHeader>
            <TitleHeader>Detalhes</TitleHeader>

            <ButtonX onClick={closeModal}>
              <RiCloseFill size={25} />
            </ButtonX>
          </ContentHeader>

          <WrapperContent>
            {openModalFeedback && (
              <ModalViewFeedback
                isOpen={openModalFeedback}
                setOpenModalFeedback={setOpenModalFeedback}
                feedback={feedback}
              />
            )}

            {openModalTargetAudience && (
              <ModalTargetAudienceExtensionProjects
                item={details}
                setIsOpenModal={setOpenModalTargetAudience}
              />
            )}

            {openImpactedPeopleModal && (
              <ImpactedPeopleModal
                item={details}
                setIsOpenModal={setOpenImpactedPeopleModal}
              />
            )}

            {openModalUnpublish &&
              details.extension_project.students_registered > 0 && (
                <ModalUnpublishProject
                  isOpen={openModalUnpublish}
                  setOpenModalUnpublish={setOpenModalUnpublish}
                  closeModalFeedback={closeModalFeedback}
                  projectId={details.extension_project.id}
                />
              )}

            {openModalSupport && (
              <ModalSupportMaterial
                idAttraction={details.extension_project.id}
                setOpenModalSupport={setOpenModalSupport}
                finishedProject={finishedProject}
                unpublishCard={unpublishCard}
              />
            )}

            {openModalUnpublish &&
              details.extension_project.students_registered < 1 && (
                <ModalUnpublishNoFeedback
                  isOpen={openModalUnpublish}
                  closeTheParentModal={setModalOpen}
                  setOpenModalUnpublish={setOpenModalUnpublish}
                  projectId={details.extension_project.id}
                />
              )}
            <WrapperTitles>
              <BubbleText>
                <TextBubble>
                  {extracurriculum
                    ? 'EXTENSÃO EXTRACURRICULAR'
                    : 'EXTENSÃO CURRICULAR'}
                </TextBubble>
              </BubbleText>
              <Title>{details.name}</Title>
              {details.description !== '' || details.description !== null ? (
                <DivDescription>
                  <Description>
                    {ReactHtmlParser(details.description)}
                  </Description>
                </DivDescription>
              ) : null}

              <ParagraphCreated>
                Postado em {createdAt} por {details.creator_name}
              </ParagraphCreated>
            </WrapperTitles>

            <WrapperInformartions>
              <div style={{ display: 'flex' }}>
                <GridSide>
                  <InfoRow
                    icon={<BiCalendar size={28} color="#009291" />}
                    label="Data"
                    values={
                      details.start_at && details.end_at
                        ? formatDate()
                        : 'Não informado'
                    }
                  />
                  <InfoRow
                    icon={<RiTimeLine size={28} color="#009291" />}
                    label="Carga horária"
                    values={formatWorkload(details.workload)}
                  />

                  {!unpublishCard &&
                    !finishedProject &&
                    details.extension_project.students_registered > 0 && (
                      <InfoRow
                        icon={<RiGroupLine size={28} color="#009291" />}
                        label={
                          extracurriculum
                            ? 'Inscrições'
                            : 'Quantidade de inscritos'
                        }
                        values={
                          <ContentButton>
                            <p
                              style={{
                                width: 'fit-content',
                                marginRight: '8px',
                              }}
                            >
                              <p>
                                {details.extension_project.students_registered}
                                {details.extension_project
                                  .students_registered === 1
                                  ? ' estudante'
                                  : ' estudantes'}
                              </p>
                            </p>
                            <button
                              onClick={() => handleOpenEnrolledModal(details)}
                            >
                              ver
                            </button>
                          </ContentButton>
                        }
                      />
                    )}

                  <InfoRow
                    icon={<GrPersonalComputer size={28} color="#009291" />}
                    label=" Material de apoio "
                    values={
                      <button onClick={() => setOpenModalSupport(true)}>
                        Clique para ver
                      </button>
                    }
                  />
                  <InfoRow
                    icon={<GrLocation size={28} color="#009291" />}
                    label="Local do projeto"
                    values={
                      details.address && details.address.zip_code ? (
                        <Address address={details.address} />
                      ) : (
                        <p>Não informado</p>
                      )
                    }
                  />
                </GridSide>

                <GridSide
                  finishedProject={finishedProject}
                  style={{ marginLeft: '20px' }}
                >
                  <InfoRow
                    icon={<RiUser3Line size={28} color="#009291" />}
                    label="Professor responsável"
                    values={
                      details.extension_project.responsible_teacher ||
                      'Não informado'
                    }
                  />
                  {!extracurriculum &&
                    details.extension_project.discipline_name !== '' && (
                      <InfoRow
                        icon={
                          <HiOutlineAcademicCap size={28} color="#009291" />
                        }
                        label={
                          details.extension_project.discipline_count > 1
                            ? 'Disciplinas do projeto'
                            : 'Disciplina do projeto'
                        }
                        values={
                          <>
                            <span>
                              {details.extension_project.discipline_name}
                            </span>
                            {details.extension_project.discipline_count > 1 && (
                              <button
                                style={{
                                  marginLeft: '6px',
                                  width: '10px',
                                }}
                                onClick={() => setOpenModalTargetAudience(true)}
                              >
                                +
                                {details.extension_project.discipline_count - 1}
                              </button>
                            )}
                          </>
                        }
                      />
                    )}
                  <InfoRow
                    icon={<RiFocus2Line size={28} color="#009291" />}
                    label="Público alvo"
                    values={
                      <button onClick={() => setOpenModalTargetAudience(true)}>
                        Clique para ver
                      </button>
                    }
                  />
                </GridSide>
              </div>
            </WrapperInformartions>

            <Divider />

            <DivButtonsFooter>
              {waitingForStart || inProgress ? (
                <EditButtonModal onClick={() => handleClickEdit(details)}>
                  Editar projeto
                </EditButtonModal>
              ) : null}

              {waitingForStart ? (
                <UnpublishButton onClick={() => setOpenModalUnpublish(true)}>
                  Despublicar projeto
                </UnpublishButton>
              ) : null}

              {details.students_registered >= 1 ? (
                <ListButton onClick={() => handleOpenStudentsList()}>
                  Baixar lista de inscritos
                </ListButton>
              ) : null}

              {details.is_published === false &&
              details.extension_project.feedback_id !== null &&
              finishedProject === true ? (
                <ButtonFeedback onClick={() => handleModalFeedback(details)}>
                  VISUALIZAR FEEDBACK
                </ButtonFeedback>
              ) : null}
            </DivButtonsFooter>
          </WrapperContent>
          {children && children}
        </WrapperMoreInfo>
      </div>
    </Container>
  ) : null;
}
