import {
  ButtonDetails,
  ContainerButtons,
  ContainerCard,
  Content,
  DataContent,
  Image,
  Title,
  ButtonEvidence,
} from './styled';
import moment from 'moment';
import { useContext, useState, useEffect } from 'react';
import { ViewStudents } from '../../PublishedProjects/Card/styled';
import Tooltip from 'components/NewTooltip';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import { getDetailsExtensionProject } from 'views/StaffPages/ExtensionProject/services';
import Loading from '../../../../components/Loading';
import { BiUserCircle } from 'react-icons/bi';
import { RiBook3Line } from 'react-icons/ri';
import {
  MdCalendarMonth,
  MdOutlineSchool,
  MdOutlineHideImage,
} from 'react-icons/md';
import { getLimitedText } from 'views/StaffPages/ExtensionProject/utils/limitedText';

export function FinishedProjectsCard({
  ref,
  item,
  setDetails,
  setIsOpen,
  finishedProject,
  setItemId,
  loadEvidences,
  setShowEvidences,
  unpublishCard,
}) {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingEvidence, setLoadingEvidence] = useState(false);
  const [imageError, setImageError] = useState(false);

  const {
    setExtensionProjectId,
    setStudentScore,
    setStatus,
    setOpenModalStudents,
    extracurriculum,
  } = useContext(ExtensionProjectsContext);

  useEffect(() => {
    if (finishedProject === true) {
      setStudentScore(true);
    }
  }, []);

  const handleMoreInfo = async (item) => {
    setExtensionProjectId(item.extension_project.id);
    setLoadingDetails(true);
    const data = await getDetailsExtensionProject(item.id);
    setDetails(data);
    setIsOpen(true);
    setLoadingDetails(false);
  };

  const handleClickEvidence = () => {
    setLoadingEvidence(true);
    loadEvidences(item.extension_project.id, 'limit=5&offset=0');
    setItemId(item.extension_project.id);
    setTimeout(() => {
      setShowEvidences(true);
    }, 300);
    setLoadingEvidence(false);
  };

  const strReduced = (str) => {
    if (str.length > 20) {
      return `${str.substr(0, 20)}...`;
    }
    return str;
  };

  const formattedDisciplineName =
    item.extension_project.discipline_name.charAt(0).toUpperCase() +
    item.extension_project.discipline_name.slice(1).toLowerCase();
  const limitedDisciplineName = getLimitedText(formattedDisciplineName, 32);

  return (
    <ContainerCard ref={ref}>
      <Image>
        {item.attraction_image.logo ? (
          <MdOutlineHideImage size={64} color="#a3a2a2" />
        ) : (
          <img
            src={item.attraction_image.image}
            alt="Imagem do projeto"
            onError={() => setImageError(true)}
          />
        )}
      </Image>

      <DataContent>
        <p className="projectCode" style={{ margin: '0', textAlign: 'start' }}>
          CÓDIGO: {item.extension_project.id}
        </p>
        <Title title={'teste'}>{item.name}</Title>

        <Content>
          {!unpublishCard && (
            <div className="wrapper-content">
              <MdCalendarMonth size={24} color="#009291" />
              <p>
                De {''}
                {moment(item.start_at).format('DD/MM/YYYY')} {''}a {''}
                {moment(item.end_at).format('DD/MM/YYYY')} {''}
              </p>
            </div>
          )}

          <div className="wrapper-content">
            <BiUserCircle size={24} color="#009291" />
            {item.extension_project.responsible_teacher ? (
              <Tooltip text={item.extension_project.responsible_teacher}>
                <p>
                  Prof.{' '}
                  {getLimitedText(
                    item.extension_project.responsible_teacher,
                    25
                  )}
                </p>
              </Tooltip>
            ) : (
              <p>Não informado</p>
            )}
          </div>
          {!unpublishCard && (
            <div className="wrapper-content">
              <MdOutlineSchool size={24} color="#009291" />
              <p>
                {(item.extension_project.students_registered > 1 ||
                  item.extension_project.students_registered === 0) &&
                  `${item.extension_project.students_registered} estudantes inscritos`}
                {item.extension_project.students_registered === 1 &&
                  `${item.extension_project.students_registered} estudante inscrito`}
                {item.extension_project.students_registered > 0 && (
                  <ViewStudents onClick={handleClickEvidence}>ver</ViewStudents>
                )}
              </p>
            </div>
          )}
          {!extracurriculum &&
            item.extension_project.discipline_name !== '' && (
              <div className="wrapper-content">
                <RiBook3Line size={24} color="#009291" />
                <p>
                  Disciplina:{' '}
                  <>
                    {limitedDisciplineName}
                    {item.extension_project.discipline_count > 1 && (
                      <button
                        onClick={() => handleMoreInfo(item)}
                        className="button-discipline"
                      >
                        +{item.extension_project.discipline_count - 1}
                      </button>
                    )}
                  </>
                </p>
              </div>
            )}
        </Content>
        <ContainerButtons>
          {!unpublishCard && (
            <ButtonEvidence onClick={handleClickEvidence}>
              {loadingEvidence ? <Loading color={'#009291'} /> : 'Avaliações'}
            </ButtonEvidence>
          )}
          <ButtonDetails onClick={() => handleMoreInfo(item)}>
            {loadingDetails ? <Loading color={'#fff'} /> : 'Detalhes'}
          </ButtonDetails>
        </ContainerButtons>
      </DataContent>
    </ContainerCard>
  );
}
