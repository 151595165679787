import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  countExtensionProjects,
  getFutureExtensionProject,
  getInProgressExtensionProject,
  getListFinishedExtensionProject,
  getListToFinishExtensionProject,
  getListUnpublishExtensionProject,
  getOpenedExtensionProject,
} from '../services';
import { getListWaitingStartExtension } from '../services/urls';
import BaseLayoutContext from 'contexts/base-layout';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

export const ExtensionProjectsContext = createContext();

export default function ExtensionProjectsProvider({
  children,
  activeTab,
  setActiveTab,
  metadata,
  kind,
}) {
  const { openModalWithContent, closeModal } = useContext(BaseLayoutContext);
  const [openModalUnpublish, setOpenModalUnpublish] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});

  const [futureExtension, setFutureExtension] = useState(null);
  const [inProgressExtension, setInProgressExtension] = useState(null);
  const [openForSubscriptionsExtension, setOpenForSubscriptionsExtension] =
    useState(null);

  const [paginationOneCount, setPaginationOneCount] = useState(0);
  const [paginationTwoCount, setPaginationTwoCount] = useState(0);
  const [paginationThreeCount, setPaginationThreeCount] = useState(0);

  const [paginationOneCurrentPage, setPaginationOneCurrentPage] =
    useState(null);
  const [paginationTwoCurrentPage, setPaginationTwoCurrentPage] =
    useState(null);
  const [paginationThreeCurrentPage, setPaginationThreeCurrentPage] =
    useState(0);

  const [unpublishProjects, setUnpublishProjects] = useState(null);
  const [finishedProjects, setFinishedProjects] = useState(null);

  const [currentPageFinishedProject, setCurrentPageFinishedProject] =
    useState(null);
  const [currentPageUnpublishProject, setCurrentPageUnpublishProject] =
    useState(null);
  const [currentPageToFinishProject, setCurrentPageToFinishProject] =
    useState(null);
  const [toFinishLoading, setToFinishLoading] = useState(false);
  const [finishedProjectCount, setFinishedProjectCount] = useState(0);
  const [unpublishProjectCount, setUnpublishProjectCount] = useState(0);
  const [toFinishProjectCount, setToFinishProjectCount] = useState(0);
  const [imageChanged, setImageChanged] = useState(false);
  const [usingFormUpload, setUsingFormUpload] = useState(false);
  const [extensionProjectId, setExtensionProjectId] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [modalFeedbackStudent, setModalFeedbackStudent] = useState(false);
  const [modalEnrolledStudents, setModalEnrolledStudents] = useState(false);
  const [modalFinishProject, setModalFinishProject] = useState(false);
  const [modalFeedbackUnpublish, setModalFeedbackUnpublish] = useState(false);
  const [activeTabExtensionProject, setActiveTabExtensionProject] = useState(0);
  const [toFinishProjects, setToFinishProjects] = useState(null);
  const [evidenceFiles, setEvidenceFiles] = useState([]);
  const [evidenceLinks, setEvidenceLinks] = useState([]);
  const [informationItem, setInformationItem] = useState(null);
  const [queryFilter, setQueryFilter] = useState(null);

  const [idPostEvidence, setIdPostEvidence] = useState(0);

  const refModalExtensionProject = useRef(null);

  const [formDataFinishProject, setFormDataFinishProject] = useState({});

  const [studentScore, setStudentScore] = useState(null);

  const [countOpenedProjects, setCountOpenedProjects] = useState(0);
  const [countPublishedSum, setCountPublishedSum] = useState(0);
  const [countFinishedhedSum, setCountFinishedhedSum] = useState(0);
  const [openModalStudents, setOpenModalStudents] = useState(false);
  const [status, setStatus] = useState(null);

  const [allProjectsSum, setAllProjectsSum] = useState(0);
  const [finishedProject, setFinishedProject] = useState(0);
  const [finishedProjectsSum, setFinishedProjectsSum] = useState(0);
  const [inProgressProjects, setInProgressProjects] = useState(0);
  const [publishedProjectsSum, setPublishedProjectsSum] = useState(0);
  const [toFinishProjectsSum, settoFinishProjectsSum] = useState(0);
  const [unpublishedProjects, setUnpublishedProjects] = useState(0);
  const [waitingStartProjects, setWaitingStartProjects] = useState(0);

  const [idProjectBeeingProcessed, setIdProjectBeeingProcessed] =
    useState(null);

  const [idAttraction, setIdAttraction] = useState(null);
  const [extracurriculum, setExtracurriculum] = useState(false);

  const [selectItems, setSelectItems] = useState([]);

  const [currentPagePagination, setCurrentPagePagination] = useState(1);
  const [newModalFinish, setNewModalFinish] = useState(false);

  const [courses, setCourses] = useState();
  const [periods, setPeriods] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [zIndex, setZIndex] = useState(2000);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [resetInProgressListing, setResetInProgressListing] = useState(false);
  const [resetAllFutureListing, setResetAllFutureListing] = useState(false);

  async function changePage(data, page) {
    setSelectItems([]);
    setCurrentPagePagination(() => page);

    if (typeof data === 'function') {
      await data({ page });
    } else {
      await data;
    }
  }

  useEffect(() => {
    if (activeTab === 1) {
      setExtracurriculum(true);
    } else {
      setExtracurriculum(false);
    }
  }, [activeTab]);

  const getCountProjects = async () => {
    let queryParams = `?kind=${kind}`;
    const data = await countExtensionProjects(queryParams);
    const {
      finished,
      in_progress,
      unpublished,
      waiting_start,
      finished_sum,
      to_finish_sum,
      published_sum,
      all_projects_sum,
    } = data;

    setFinishedProject(finished);
    setInProgressProjects(in_progress);
    setUnpublishedProjects(unpublished);
    setWaitingStartProjects(waiting_start);
    setFinishedProjectsSum(finished_sum);
    settoFinishProjectsSum(to_finish_sum);
    setPublishedProjectsSum(published_sum);
    setAllProjectsSum(all_projects_sum);
  };

  const setFormValues = (values) => {
    setFormDataFinishProject((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  useEffect(() => {
    if (activeTabExtensionProject === 0 && activeTab !== null) {
      getAllFutureProjects();
    } else {
      setFutureExtension(null);
    }
  }, [
    paginationTwoCurrentPage,
    activeTab,
    activeTabExtensionProject,
    resetAllFutureListing,
  ]);

  useEffect(() => {
    if (activeTabExtensionProject === 0 && activeTab !== null) {
      getAllInProgressProjects();
    } else {
      setInProgressExtension(null);
    }
  }, [
    paginationThreeCurrentPage,
    activeTab,
    activeTabExtensionProject,
    resetInProgressListing,
  ]);

  useEffect(() => {
    if (activeTab !== 3) {
      setFutureExtension(null);
      setInProgressExtension(null);
      setOpenForSubscriptionsExtension(null);
      setPaginationOneCurrentPage(0);
      setPaginationTwoCurrentPage(0);
      setPaginationThreeCurrentPage(0);
      setModalEnrolledStudents(false);
      setExtensionProjectId(null);
    }
    // setActiveTabExtensionProject(0);
  }, [activeTab]);

  useEffect(() => {
    if (activeTabExtensionProject !== 3) {
      setUnpublishProjects(null);
      setFinishedProjects(null);
      setCurrentPageUnpublishProject(0);
      setCurrentPageFinishedProject(0);
      setCurrentPageToFinishProject(0);
    }
  }, [activeTabExtensionProject]);

  /* Published Projects */

  async function getAllFutureProjects(appliedFilters, reset = false) {
    if (reset) {
      setWaitingStartProjects(0);
      setPaginationTwoCount(0);
      setFutureExtension(null);
      setPaginationTwoCurrentPage(0);
    }

    let queryParams = `?kind=${kind}&limit=8&offset=${
      reset ? 0 : paginationTwoCurrentPage
    }`;

    if (appliedFilters) {
      queryParams += `&${queryParamsFromFilterObject(appliedFilters)}`;
    }

    try {
      const response = await getListWaitingStartExtension(queryParams);

      if (response) {
        const { results, count } = response;
        if (results.length <= 8) {
          setWaitingStartProjects(count);
          setPaginationTwoCount(count);

          setFutureExtension((prevProjects) =>
            prevProjects ? [...prevProjects, ...results] : results
          );
        } else {
          setFutureExtension(null);
        }
      }
    } catch (error) {
      console.error('Error fetching future projects:', error);
      setFutureExtension(null);
    }
  }

  async function getAllOpenForSubscription(appliedFilters, reset = false) {
    let queryParams = `?kind=${kind}&limit=8&offset=${
      reset ? 0 : paginationOneCurrentPage
    }`;
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }

    const response = await getOpenedExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response;
      setCountOpenedProjects(count);
      if (reset) {
        setOpenForSubscriptionsExtension(results);
      } else {
        setOpenForSubscriptionsExtension((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setPaginationOneCount(count);
    } else {
      setOpenForSubscriptionsExtension(null);
    }
  }
  async function getAllInProgressProjects(appliedFilters, reset = false) {
    if (reset) {
      setInProgressProjects(0);
      setPaginationThreeCount(0);
      setInProgressExtension(null);
      setPaginationThreeCurrentPage(0);
    }

    let queryParams = `?kind=${kind}&limit=8&offset=${
      reset ? 0 : paginationThreeCurrentPage
    }`;

    if (appliedFilters) {
      queryParams += `&${queryParamsFromFilterObject(appliedFilters)}`;
    }

    try {
      const response = await getInProgressExtensionProject(queryParams);
      if (response.status === 200) {
        const { results, count } = response.data;

        if (results.length <= 8) {
          setInProgressProjects(count);
          setPaginationThreeCount(count);

          setInProgressExtension((prevProjects) =>
            prevProjects ? [...prevProjects, ...results] : results
          );
        } else {
          setInProgressExtension(null);
        }
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      setInProgressExtension(null);
    }
  }

  /* Finished Projects */

  useEffect(() => {
    if (activeTabExtensionProject === 2) {
      getAllUnpublishedProjects();
    } else {
      setUnpublishProjects(null);
    }
  }, [currentPageUnpublishProject, activeTabExtensionProject]);

  useEffect(() => {
    if (activeTabExtensionProject === 2) {
      getAllFinishedProjects();
    } else {
      setFinishedProjects(null);
    }
  }, [currentPageFinishedProject, activeTabExtensionProject]);

  async function getAllUnpublishedProjects(appliedFilters, reset = false) {
    let queryParams = `?kind=${kind}&limit=8&offset=${
      reset ? 0 : currentPageUnpublishProject
    }`;
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }
    const response = await getListUnpublishExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response.data;
      setUnpublishedProjects(count);
      if (reset) {
        setUnpublishProjects(results);
      } else {
        setUnpublishProjects((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setUnpublishProjectCount(count);
    } else {
      setUnpublishProjects(null);
    }
  }

  async function getAllFinishedProjects(appliedFilters, reset = false) {
    let queryParams = `?kind=${kind}&limit=8&offset=${
      reset ? 0 : currentPageFinishedProject
    }`;
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }
    const response = await getListFinishedExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response.data;
      setFinishedProject(count);
      if (reset) {
        setFinishedProjects(results);
      } else {
        setFinishedProjects((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setFinishedProjectCount(count);
    } else {
      setFinishedProjects(null);
    }
  }

  /* To Finish Projects */

  useEffect(() => {
    if (activeTabExtensionProject === 1) {
      getAllToFinishProjects();
    } else {
      setToFinishProjects(null);
    }
  }, [currentPageToFinishProject, activeTabExtensionProject]);

  useEffect(() => {
    settoFinishProjectsSum(toFinishProjectsSum);
  }, [toFinishProjectsSum]);

  async function getAllToFinishProjects(appliedFilters, reset = false) {
    let queryParams;
    if (currentPageToFinishProject > 0 && reset) {
      queryParams = `?kind=${kind}&?limit=${
        currentPageToFinishProject + 10
      }&offset=${reset ? 0 : currentPageToFinishProject}`;
    } else {
      queryParams = `?kind=${kind}&?limit=10&offset=${
        reset ? 0 : currentPageToFinishProject
      }`;
    }
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }
    setToFinishLoading(true);
    const response = await getListToFinishExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response.data;
      settoFinishProjectsSum(count);
      if (reset) {
        setToFinishProjects(results);
      } else {
        setToFinishProjects((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setToFinishProjectCount(count);
    } else {
      setToFinishProjects(null);
    }
    setToFinishLoading(false);
    setIdProjectBeeingProcessed(null);
  }
  const filterData = (dataArray, search) => {
    const lowercaseSearch = (search || '').toLowerCase();

    return dataArray.filter((item) => {
      const lowercaseItemName = (item.student_name || '').toLowerCase();
      const itemId = item.id.toString();
      return (
        lowercaseSearch === '' ||
        lowercaseItemName.includes(lowercaseSearch) ||
        itemId.includes(lowercaseSearch)
      );
    });
  };
  return (
    <ExtensionProjectsContext.Provider
      value={{
        activeTab,
        refModalExtensionProject,

        futureExtension,
        setFutureExtension,

        inProgressExtension,
        setInProgressExtension,

        unpublishProjects,
        setUnpublishProjects,

        finishedProjects,
        setFinishedProjects,

        openForSubscriptionsExtension,
        setOpenForSubscriptionsExtension,

        paginationOneCurrentPage,
        setPaginationOneCurrentPage,

        paginationTwoCurrentPage,
        setPaginationTwoCurrentPage,

        paginationThreeCurrentPage,
        setPaginationThreeCurrentPage,

        paginationOneCount,
        setPaginationOneCount,

        paginationTwoCount,
        setPaginationTwoCount,

        paginationThreeCount,
        setPaginationThreeCount,

        modalEnrolledStudents,
        setModalEnrolledStudents,

        modalFeedbackUnpublish,
        setModalFeedbackUnpublish,

        modalFeedbackStudent,
        setModalFeedbackStudent,

        extensionProjectId,
        setExtensionProjectId,

        isSuccessModalOpen,
        setIsSuccessModalOpen,

        setActiveTab,
        getAllFutureProjects,
        getAllOpenForSubscription,
        getAllInProgressProjects,

        imageChanged,
        setImageChanged,

        usingFormUpload,
        setUsingFormUpload,

        activeTabExtensionProject,
        setActiveTabExtensionProject,

        getAllUnpublishedProjects,
        getAllFinishedProjects,

        currentPageUnpublishProject,
        setCurrentPageUnpublishProject,

        currentPageFinishedProject,
        setCurrentPageFinishedProject,

        unpublishProjectCount,
        setUnpublishProjectCount,

        finishedProjectCount,
        setFinishedProjectCount,

        toFinishProjectCount,
        setToFinishProjectCount,
        getAllToFinishProjects,
        toFinishProjects,
        setToFinishProjects,
        setCurrentPageToFinishProject,
        toFinishLoading,

        modalFinishProject,
        setModalFinishProject,
        idPostEvidence,
        setIdPostEvidence,
        evidenceFiles,
        setEvidenceFiles,
        evidenceLinks,
        setEvidenceLinks,

        formDataFinishProject,
        setFormDataFinishProject,
        setFormValues,

        studentScore,
        setStudentScore,

        informationItem,
        setInformationItem,

        queryFilter,
        setQueryFilter,

        countOpenedProjects,
        setCountOpenedProjects,

        countPublishedSum,
        setCountPublishedSum,

        countFinishedhedSum,
        setCountFinishedhedSum,

        getCountProjects,

        idProjectBeeingProcessed,
        setIdProjectBeeingProcessed,

        openModalWithContent,
        closeModal,

        openModalUnpublish,
        setOpenModalUnpublish,

        filterData,
        openModalStudents,
        setOpenModalStudents,
        status,
        setStatus,

        idAttraction,
        setIdAttraction,

        extracurriculum,
        metadata,

        allProjectsSum,
        finishedProject,
        finishedProjectsSum,
        inProgressProjects,
        publishedProjectsSum,
        toFinishProjectsSum,
        unpublishedProjects,
        waitingStartProjects,

        currentPagePagination,
        setCurrentPagePagination,

        changePage,
        selectItems,
        newModalFinish,
        setNewModalFinish,

        courses,
        setCourses,
        periods,
        setPeriods,
        modalIsOpen,
        setModalIsOpen,
        kind,

        zIndex,
        setZIndex,
        openCreateModal,
        setOpenCreateModal,
        setInProgressProjects,
        setWaitingStartProjects,
        appliedFilters,
        setAppliedFilters,
        setResetAllFutureListing,
        setResetInProgressListing,
      }}
    >
      {children}
    </ExtensionProjectsContext.Provider>
  );
}
