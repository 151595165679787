import React, { useEffect, useState } from 'react';
import SearchableSelect from 'react-select';
import { shape, func, bool } from 'prop-types';
import classNames from 'classnames';

import { Formik } from 'formik';

import withStyles from '@material-ui/core/styles/withStyles';

import ProfessionalStatusReportStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalStatusReportStyles';

import {
  FormControl,
  InputLabel,
  List,
  ListItem,
  Typography,
  Button,
} from '@material-ui/core';

import { getStudentProfessionalReportOptions } from 'services/student/studentsList';

const ProfessionalStatusReportFormFilters = ({
  classes,
  handleSubmitFilters,
  saveActiveFilters,
  isSocioeconomic,
}) => {
  const [state, setState] = useState({});
  const [valueState, setValueState] = useState({});
  const [macroAreas, setMacroAreas] = useState([]);
  const [courses, setCourses] = useState([]);
  const [actuationField, setActuationField] = useState(false);
  const [loadInfo, setLoadingInfo] = useState(false);
  const handleLoadFormOptions = async () => {
    const studentListFilterOptions = await getStudentProfessionalReportOptions();
    setState({
      professionalStatusFilters: studentListFilterOptions.professional_status,
      coursesFilters: studentListFilterOptions.courses,
      courseLevelFilters: studentListFilterOptions.course_levels,
      ageFilters: studentListFilterOptions.ages,
      periodFilters: studentListFilterOptions.semesters,
      modeFilters: studentListFilterOptions.modes,
      jobTypeFilters: studentListFilterOptions.job_type,
      oportunityDesiredFilters: studentListFilterOptions.professional_offers,
      macroareaFilters: studentListFilterOptions.macroareas,
      genderFilters: studentListFilterOptions.gender,
      ufFilters: studentListFilterOptions.uf,
      cityFilters: studentListFilterOptions.city,
      unityFilters: studentListFilterOptions.unity,
      studentStatusFilters: studentListFilterOptions.student_statuses,
      workingInAreaFilters: studentListFilterOptions.working_in_formation_area,
    });
    setMacroAreas(studentListFilterOptions.macroareas);
    setCourses(studentListFilterOptions.courses);
  };

  const handleMacroarea = (courseLevel) => {
    if (courseLevel.length > 0 && courseLevel[0].label !== 'Indiferente') {
      const matchMacroareas = macroAreas.filter((macroarea) =>
        courseLevel.some((level) =>
          macroarea.course_levels.find((obj) => obj === level.value)
        )
      );
      setState((prevStates) => ({
        ...prevStates,
        macroareaFilters: matchMacroareas,
      }));
    } else {
      setState((prevStates) => ({
        ...prevStates,
        macroareaFilters: macroAreas,
      }));
    }
  };

  const handleCourses = (macroarea) => {
    if (macroarea.length > 0 && macroarea[0].label !== 'Indiferente') {
      const matchCourses = courses.filter((course) =>
        macroarea.some((macro) => macro.value === course.macroarea)
      );
      setState((prevStates) => ({
        ...prevStates,
        coursesFilters: matchCourses,
      }));
    } else {
      setState((prevStates) => ({
        ...prevStates,
        coursesFilters: courses,
      }));
    }
  };

  const fieldsData = [
    {
      label: 'Unidade',
      name: 'unity',
      options: state.unityFilters,
      isMulti: false,
    },
    {
      label: 'Status do Estudante',
      name: 'studentStatus',
      options: state.studentStatusFilters,
      isMulti: true,
    },
    {
      label: 'Nível do Curso',
      name: 'courseLevel',
      options: state.courseLevelFilters,
      isMulti: true,
    },

    {
      label: 'Modalidade',
      name: 'mode',
      options: state.modeFilters,
      isMulti: true,
    },

    {
      label: 'Macroáreas',
      name: 'macroarea',
      options: state.macroareaFilters,
      isMulti: true,
    },

    {
      label: 'Curso',
      name: 'course',
      options: state.coursesFilters,
      isMulti: true,
    },

    {
      label: 'Período',
      name: 'semester',
      options: state.periodFilters,
      isMulti: true,
    },

    {
      label: 'Idade',
      name: 'age',
      options: state.ageFilters,
      isMulti: true,
    },

    {
      label: 'Gênero',
      name: 'gender',
      options: state.genderFilters,
      isMulti: false,
    },

    {
      label: 'Tipo de Oportunidade Desejada',
      name: 'oportunityDesired',
      options: state.oportunityDesiredFilters,
      isMulti: true,
    },
  ];

  useEffect(() => {
    if (!isSocioeconomic) {
      saveActiveFilters(
        fieldsData.map((field) => ({
          label: field.label,
          value: field.value,
        }))
      );
    }
    handleLoadFormOptions();
  }, []);

  const handleReset = () => {
    Object.keys(valueState).forEach((key) => {
      setValueState((prevValues) => ({ ...prevValues, [key]: [] }));
    });
  };

  function verificateArray(values, name) {
    if (Array.isArray(values[name]) && values[name].length > 0) {
      return values[name].map((value) => value.label);
    } else if (values[name].label) {
      return (
        values[name].label.charAt(0).toUpperCase() + values[name].label.slice(1)
      );
    } else {
      return 'Indiferente';
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        course: { label: null, value: undefined },
        courseLevel: { label: null, value: undefined },
        macroarea: { label: null, value: undefined },
        age: { label: null, value: undefined },
        semester: { label: null, value: undefined },
        mode: { label: null, value: undefined },
        oportunityDesired: { label: null, value: undefined },
        gender: { label: null, value: undefined },
        unity: { label: null, value: undefined },
        studentStatus: { label: null, value: undefined },
      }}
      onSubmit={async (values, actions) => {
        if (loadInfo) return;

        setLoadingInfo(true);
        if (!isSocioeconomic)
          saveActiveFilters(
            Object.keys(values).map((name) => ({
              label: fieldsData.find((f) => f.name === name).label,
              value: verificateArray(values, name),
            }))
          );
        const {
          course,
          courseLevel,
          age,
          semester,
          mode,
          oportunityDesired,
          macroarea,
          gender,
          unity,
          studentStatus,
        } = values;
        await handleSubmitFilters({
          course,
          course_levels: courseLevel,
          age,
          semesters: semester,
          mode,
          oportunity_desired: oportunityDesired,
          macroarea,
          gender,
          unity,
          student_status: studentStatus,
        });
        actions.setSubmitting(false);
        setTimeout(() => setLoadingInfo(false), 1000);
      }}
      render={({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        submitForm,
        isLoading = !Object.values(state).length,
      }) => (
        <form>
          <List className={classes.list} style={{ marginTop: -20 }}>
            {isLoading ? (
              <Typography
                align="center"
                style={{
                  marginTop: 40,
                  marginBottom: 18,
                  color: 'rgb(117 117 117)',
                  fontSize: 19,
                }}
              >
                Carregando filtros...
              </Typography>
            ) : (
              fieldsData.map((item) =>
                item.name === 'actuationStatus' && !actuationField ? null : (
                  <ListItem
                    className={classes.listItem}
                    style={{ paddingLeft: 15, paddingRight: 15 }}
                  >
                    <FormControl variant="filled" fullWidth>
                      <InputLabel
                        htmlFor={item.name}
                        shrink
                        error={errors[item.name] && touched[item.name]}
                      >
                        {item.label}
                      </InputLabel>
                      <SearchableSelect
                        name={item.name}
                        id={item.name}
                        isClearable
                        InputLabel={{
                          shrink: true,
                        }}
                        isMulti={item.isMulti}
                        value={valueState[item.name]}
                        placeholder="Selecione"
                        menuPlacement="bottom"
                        isSearchable
                        classNamePrefix="react-select"
                        className={
                          item.isMulti
                            ? classNames([
                                classes.selectSection,
                                classes.selectSectionAutoHeight,
                              ])
                            : classes.selectSection
                        }
                        onBlur={(event) =>
                          setFieldTouched(item.name, event.label)
                        }
                        onChange={async (event) => {
                          if (
                            event !== null &&
                            item.name === 'professionalStatus' &&
                            event.value === 'working'
                          ) {
                            setActuationField(true);
                          } else if (
                            item.name === 'professionalStatus' &&
                            (event === null || event.value === 'not_working')
                          ) {
                            setActuationField(false);
                          } else if (item.name === 'courseLevel') {
                            handleMacroarea(event);
                          } else if (item.name === 'macroarea') {
                            handleCourses(event);
                          }
                          if (event === null) {
                            await setFieldValue(item.name, {
                              label: event,
                              value: undefined,
                            });
                          } else {
                            await setFieldValue(item.name, event);
                          }
                          setValueState((prevValues) => ({
                            ...prevValues,
                            [item.name]: event,
                          }));
                        }}
                        options={item.options || []}
                      />
                    </FormControl>
                  </ListItem>
                )
              )
            )}
          </List>
          <ListItem className={classes.itemListFlexEnd}>
            <button
              type="button"
              onClick={() => {
                if (isLoading) return;
                submitForm();
              }}
              className={classes.submitButton}
              disabled={loadInfo}
            >
              Filtrar dados
            </button>
            <Button
              type="button"
              className={classes.resetButton}
              onClick={() => {
                fieldsData.forEach((field) => {
                  setFieldValue(field.name, { label: null, value: undefined });
                  saveActiveFilters({
                    label: field.label,
                    value: field.value,
                  });
                  handleReset();
                });
                handleSubmitFilters({});
              }}
            >
              Limpar
            </Button>
          </ListItem>
        </form>
      )}
    />
  );
};

ProfessionalStatusReportFormFilters.propTypes = {
  classes: shape.isRequired,
  handleSubmitFilters: func.isRequired,
  saveActiveFilters: func.isRequired,
  isSocioeconomic: bool.isRequired,
};

export default withStyles(ProfessionalStatusReportStyles)(
  ProfessionalStatusReportFormFilters
);
