import React from 'react';
import { ItemWrapper } from './styled';

export const InfoRow = ({ icon, label, values }) => {
  return (
    <ItemWrapper>
      <div className="container">
        <i>{icon}</i>
        <div className="text-wrapper">
          <strong>{label}</strong>
          <div>{values}</div>
        </div>
      </div>
    </ItemWrapper>
  );
};
