import React, { Fragment, useRef } from 'react';

import {
  ModalBackground,
  DivModal,
  Information,
  Subtitle,
  BackToHome,
  ContentModal,
  HeaderModal,
  TitleModal,
} from './styles';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import {
  ButtonX,
  DivX,
} from '../ModalMoreInformation/styled';
import { RiCloseFill } from 'react-icons/ri';

export default function ModalTargetAudienceExtensionProjects({
  item,
  setIsOpenModal,
}) {
  const setState = () => {
    setIsOpenModal(false);
  };

  const returnCourses = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].courses &&
        item.target_audiences[0].courses.length
      ) {
        const result = item.target_audiences[0].courses.map((item) => item);
        return result.join(' | ');
      }
    }
    return 'Todos os cursos';
  };

  const returnMacroAreas = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].macro_areas &&
        item.target_audiences[0].macro_areas.length
      ) {
        const result = item.target_audiences[0].macro_areas.map((item) => item);
        return result.join(', ');
      }
    }
    return 'Todas as áreas de atuação';
  };

  const returnDisciplines = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].disciplines &&
        item.target_audiences[0].disciplines.length
      ) {
        const result = item.target_audiences[0].disciplines.map((item) => item);
        return result.join(', ');
      }
    }
    return 'Todas as disciplinas';
  };

  const returnPeriods = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].semesters &&
        item.target_audiences[0].semesters.length
      ) {
        let result = item.target_audiences[0].semesters.map((item) => item);
        if (result.length <= 1) {
          return (result = result + 'º Semestre');
        }
        const arrayLength = result.length;
        result[arrayLength - 1] = `${result[arrayLength - 1] + 'º Semestre'}`;

        return result.join('º Semestre, ');
      }
    }
    return 'Todos os períodos';
  };

  const returnUnities = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].unities &&
        item.target_audiences[0].unities.length
      ) {
        const result = item.target_audiences[0].unities.map((item) => item);
        return result.join(', ');
      }
    }
    return 'Todas as Unidades';
  };

  const modalTargetAudience = useRef();

  const closeModalClickOutside = (e) => {
    if (modalTargetAudience.current === e.target) {
      setIsOpenModal(false);
    }
  };

  const closeModalX = () => {
    setIsOpenModal(false);
  };

  return (
    <Fragment>
      <ModalBackground
        ref={modalTargetAudience}
        onClick={closeModalClickOutside}
      >
        <DivModal>
          <div className="container-wrapper ">
            <HeaderModal>
              <TitleModal>Público alvo</TitleModal>

              <DivX>
                <ButtonX onClick={closeModalX}>
                  <RiCloseFill size={25} />
                </ButtonX>
              </DivX>
            </HeaderModal>
            <div className="align-arrow">
              <BackToHome onClick={setState}>
                <HiOutlineArrowNarrowLeft size={32} color="#009291" />
              </BackToHome>
            </div>
            <ContentModal>
              <Subtitle>Áreas de atuação</Subtitle>
              <Information> {returnMacroAreas(item)} </Information>

              <Subtitle> Cursos relacionados </Subtitle>
              <Information> {returnCourses(item)} </Information>

              <Subtitle>
                {item.extension_project.discipline_count > 1
                  ? 'Disciplinas'
                  : 'Disciplina'}
              </Subtitle>
              <Information> {returnDisciplines(item)} </Information>

              <Subtitle> Unidades/Polo </Subtitle>
              <Information> {returnUnities(item)} </Information>
            </ContentModal>
          </div>
        </DivModal>
      </ModalBackground>
    </Fragment>
  );
}
