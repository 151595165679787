import React, { useContext, useEffect } from 'react';
import ListExtensionProject from './ListExtensionProject';

import {
  Container,
  Content,
  SeeMoreButton,
  SubTitle,
  Title,
  Wrapper,
} from './styled';
import Loading from '../../../components/Loading';
import { formatNumber } from 'utils/formatNumber';
import GenericMessage from 'components/GenericMessage';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';

export default function ToFinishProjects() {
  const {
    futureExtension,
    inProgressExtension,
    toFinishProjects,
    setPaginationOneCurrentPage,
    setPaginationTwoCurrentPage,
    setPaginationThreeCurrentPage,
    activeTabExtensionProject,
    setCurrentPageToFinishProject,
    toFinishProjectCount,
    toFinishLoading,
    toFinishProjectsSum,
  } = useContext(ExtensionProjectsContext);

  useEffect(() => {
    setPaginationOneCurrentPage(0);
    setPaginationTwoCurrentPage(0);
    setPaginationThreeCurrentPage(0);
  }, [activeTabExtensionProject]);

  function handleNextPage() {
    setCurrentPageToFinishProject((currentPage) => currentPage + 10);
  }

  return (
    <Container>
      <Content>
        {inProgressExtension !== null &&
        inProgressExtension.length === 0 &&
        toFinishProjects !== null &&
        toFinishProjects.length === 0 &&
        futureExtension !== null &&
        futureExtension.length === 0 ? (
          <GenericMessage title="Não identificamos nenhum Projeto de Extensão a finalizar!" />
        ) : (
          <Wrapper>
            <Title marginTop="14px">
              A finalizar ({formatNumber(toFinishProjectsSum)})
            </Title>
            <SubTitle>
              Aqui você pode ver seus Projetos de Extensão que já passaram da
              data de realização e aguardam sua finalização.
            </SubTitle>
            {toFinishProjects !== null && toFinishProjects.length === 0 ? (
              <GenericMessage title="Não identificamos nenhum Projeto de Extensão a finalizar!" />
            ) : (
              <ListExtensionProject array={toFinishProjects} />
            )}
            {toFinishProjects?.length < toFinishProjectCount && (
              <SeeMoreButton
                onClick={handleNextPage}
                disabled={toFinishLoading}
              >
                {toFinishLoading ? <Loading color={'#FFF'} /> : 'Ver mais'}
              </SeeMoreButton>
            )}
          </Wrapper>
        )}
      </Content>
    </Container>
  );
}
