import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import { number, object, func, string, bool } from 'prop-types';
import CardComposition from 'components/CardComposition/CardComposition';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import CancelApplicationDialog from '../../views/JobOffers/components/CancelApplicationDialog';
import ConfirmApplicationDialog from 'views/JobOffers/components/ConfirmApplicationDialog';

import { triggerEvent } from '../../utils/analytics';
import { Mixpanel } from 'utils/mixpanel';
import {
  getStudentJobDetails,
  getStudentApplicationDetails,
} from 'services/student/jobOffers';

import BaseLayoutContext from 'contexts/base-layout';

import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import jobsPositionDetailsStyles from 'assets/jss/material-dashboard-pro-react/views/components/jobsPositionDetailsStyles';
import {
  jobDetailsAdapter,
  jobDetailsFormAdapter,
} from 'adapters/job/jobDetailsAdapter';
import moment from 'moment';
import {
  DivHeader,
  ButtonJobs,
  Title,
  DivFooter,
  Paragraph,
  AppliedRoundedButton,
  SimpleButton,
  ButtonCancelApplication,
  DivButtonsHeader,
  ContainerButton,
  TitleJobDetails,
} from './infoJobStyles';
import WallNotFound from '../ValNotFound/wallNotFound';

function JobDetails(props) {
  const {
    classes,
    jobId,
    jobDetailPreview,
    handleIsLoadingState,
    role,
    getJobDetails,
    applicationDetailId,
    applicationJob,
    returnAllJobs,
    application,
    applyJobOffer,
    history,
    openSnackbar,
    cancelApplication,
    studentContracted,
    externalJob,
    isPublished,
    candidateJobOffer,
    isConfirmApplicationDialogOpen,
    setClose,
    deleteAplication,
    setApplication,
    finishStatus,
    id,
    appliedJob,
    configTextModal,
  } = props;
  const [jobDetails, setJobDetails] = useState(null);

  const [jobNotFound, setJobNotFound] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [
    cancelApplicationDialogIsOpen,
    setCancelApplicationDialogIsOpen,
  ] = useState(false);

  const { universityColor } = useContext(BaseLayoutContext);

  function openOrApplyExternalJob() {
    if (appliedJob) {
      jobDetails.divulgationLink
        ? window.open(jobDetails.divulgationLink, '_blank')
        : (window.location.href = `mailto:${jobDetails.jobEmail}`);
    } else {
      configTextModal();
    }
  }

  function returnButton() {
    if (jobDetails.divulgationLink || jobDetails.jobEmail) {
      return {
        label: 'Acessar Vaga',
        onClick: () => {
          openOrApplyExternalJob();
        },
      };
    }
    if (cancelApplication) {
      return {
        label: 'Cancelar Candidatura',
        onClick: () => {
          setCancelApplicationDialogIsOpen(true);
        },
      };
    }
    return {
      label: 'Candidatar-se',
      onClick: () => {
        applyJobOffer(jobId);
      },
    };
  }

  // todo temporary solution - hidden button for external link
  const showApplyButton = useMemo(() => {
    const href = window.location.href;
    const splitUrl = href.split('/');
    return splitUrl.every((item) => {
      return isNaN(item) || item === '';
    });
  }, []);

  function setCloseModal() {
    setCancelApplicationDialogIsOpen(false);
  }

  const contracted = async (jobId) => {
    const response = await studentContracted(jobId);
    if (!response.message) {
      setApplication &&
        setApplication({
          ...applicationJob,
          jobOffer: {
            ...applicationJob.jobOffer,
            is_candidate: response.is_candidate,
          },
        });
      openSnackbar(
        'Parabéns! O informe de contratação foi realizado com sucesso.'
      );
      triggerEvent('student/vagas/contratado');
      Mixpanel.track('Menu Vagas: Clicou - Fui Contratado');
      setTimeout(() => {
        const searchParams =
          window.location.href.includes('?') &&
          window.location.href.split('?')[1];
        history.push({
          pathname: '/',
          search: searchParams,
          state: { addExperience: true },
        });
      }, 1000);
      setShowDialog(false);
    } else {
      openSnackbar('Erro ao informar contratação!', true);
    }
  };

  const setContractedDialog = () => {
    setShowDialog(true);
  };

  useEffect(() => {
    if (applicationDetailId) {
      const getStudentDetail = async () => {
        handleIsLoadingState(true);
        const appDetail = await getStudentApplicationDetails(
          applicationDetailId
        );
        setJobDetails(jobDetailsAdapter(appDetail.job_offer));
        handleIsLoadingState(false);
      };
      getStudentDetail();
    } else if (!jobDetailPreview) {
      handleIsLoadingState(true);
      const fetchJobsDetails = async () => {
        const job =
          role === 'student'
            ? await getStudentJobDetails(jobId)
            : await getJobDetails(jobId);
        if (job.message && externalJob) {
          setJobNotFound(true);
          handleIsLoadingState(false);
        }
        if (!job.message) {
          setJobDetails(jobDetailsAdapter(job));

          setTimeout(() => {
            handleIsLoadingState(false);
          }, 300);
        }
      };
      fetchJobsDetails();
    } else {
      setJobDetails(jobDetailsFormAdapter(jobDetailPreview));
    }
  }, []);

  const formatDateValue = (date) => {
    if (date.includes('/')) {
      return date;
    }
    return moment(date).format('DD/MM/YYYY');
  };

  return (
    <div style={{ margin: '0 auto', maxWidth: 860 }}>
      {candidateJobOffer && (
        <ConfirmApplicationDialog
          id={jobId}
          setClose={setClose}
          isOpen={isConfirmApplicationDialogOpen}
        />
      )}

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        disableBackdropClick
        PaperProps={{
          style: {
            borderRadius: 10,
          },
          classes: {
            root: classes.modalPaper,
          },
        }}
        fullWidth
      >
        <DialogContent>
          <Grid container justify="center" spacing={2}>
            <h5
              style={{ paddingRight: 0, fontSize: 23 }}
              className={classes.confirmDialogVacancy}
            >
              Tem certeza que deseja confirmar a sua contratação para a vaga{' '}
              <b>{jobDetails && jobDetails.profession}</b> da empresa{' '}
              <b>{jobDetails && jobDetails.company.label}</b>?
            </h5>
            <div
              style={{
                display: 'inline-flex',
                flexWrap: 'wrap',
                marginBottom: '7px',
              }}
            >
              <Grid item xs={6} style={{ padding: '3px 19px 9px' }}>
                <AppliedRoundedButton
                  universityColor={universityColor}
                  size="small"
                  variant="outlined"
                  onClick={() => contracted(application.jobOffer.id)}
                  style={{
                    height: '43px',
                    width: '202px',
                    justifyContent: 'center',
                  }}
                >
                  Sim
                </AppliedRoundedButton>
              </Grid>
              <Grid item xs={6} style={{ padding: '3px 19px 9px' }}>
                <SimpleButton
                  size="small"
                  variant="outlined"
                  style={{
                    height: '43px',
                    width: '202px',
                    borderRadius: '5px !important',
                    fontWeight: 'bold',
                  }}
                  onClick={() => setShowDialog(false)}
                >
                  Não
                </SimpleButton>
              </Grid>
            </div>
          </Grid>
        </DialogContent>
      </Dialog>

      {cancelApplication && (
        <CancelApplicationDialog
          deleteAplication={deleteAplication}
          id={id}
          setClose={setCloseModal}
          isOpen={cancelApplicationDialogIsOpen}
        />
      )}
      {jobNotFound ? (
        <WallNotFound
          text="A vaga que você está procurando não está alinhada ao seu perfil ou não existe mais."
          title="Oops!"
          titleButton="Acessar outras vagas"
          actionButton={returnAllJobs && returnAllJobs}
        />
      ) : jobDetails ? (
        <Fragment>
          {role === 'student' && (
            <TitleJobDetails universityColor={universityColor}>
              {`Detalhes da vaga: ${jobDetails.id} - ${jobDetails.name}`}
            </TitleJobDetails>
          )}
          {isPublished &&
          !finishStatus &&
          applicationJob &&
          applicationJob.isAnswered &&
          applicationJob.answer.is_interesting &&
          !applicationJob.jobOffer.is_candidate ? (
            <DivHeader>
              <DivButtonsHeader>
                <ButtonCancelApplication
                  universityColor={universityColor}
                  onClick={() => setCancelApplicationDialogIsOpen(true)}
                >
                  Cancelar Candidatura
                </ButtonCancelApplication>
                <ButtonJobs
                  universityColor={universityColor}
                  onClick={() => setContractedDialog()}
                >
                  Fui Contratado(a)
                </ButtonJobs>
              </DivButtonsHeader>
              <Title universityColor={universityColor}>Dados Básicos</Title>
            </DivHeader>
          ) : (
            <DivHeader>
              <ContainerButton>
                {showApplyButton && !finishStatus && (
                  <ButtonJobs
                    universityColor={universityColor}
                    onClick={() => {
                      returnButton().onClick();
                    }}
                  >
                    {returnButton().label}
                  </ButtonJobs>
                )}
              </ContainerButton>
              <Title universityColor={universityColor}>Dados Básicos</Title>
            </DivHeader>
          )}
          <CardComposition
            CardContent={() => (
              <Fragment>
                {jobDetails.profession && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Vaga Disponível</strong>
                    <br />
                    {jobDetails.profession}
                  </Paragraph>
                )}
                {jobDetails.company && jobDetails.company.label && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Empresa onde a vaga está disponível</strong>
                    <br />
                    {jobDetails.company.label}
                  </Paragraph>
                )}
                {jobDetails.thirdPartyOffer && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Empresa responsável pela vaga</strong>
                    <br />
                    {jobDetails.jobCreatorName}
                  </Paragraph>
                )}
                {jobDetails.jobsOpenings && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Quantidade de vagas disponíveis</strong>
                    <br />
                    {jobDetails.jobsOpenings}
                  </Paragraph>
                )}
                {jobDetails.description && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Atividades desenvolvidas pelo profissional</strong>
                    <br />
                    <div
                      className={classes.descriptionHtml}
                      dangerouslySetInnerHTML={{
                        __html: jobDetails.description
                          ? jobDetails.description
                          : 'Não definido',
                      }}
                    />
                  </Paragraph>
                )}
                {jobDetails.selectedJobsType && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Tipo de vaga</strong>
                    <br />
                    <span className={classes.capitalize}>
                      {jobDetails.selectedJobsType
                        .map((item) => item.label)
                        .join(', ')}
                    </span>
                  </Paragraph>
                )}
                {role === 'company' && jobDetails.enableJustForUniversities && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Universidades</strong>
                    <br />
                    <span>
                      {jobDetails.enableJustForUniversities.length > 0
                        ? jobDetails.enableJustForUniversities
                            .map((element) => element.label)
                            .join(', ')
                        : 'Disponível para todas as universidades'}
                    </span>
                  </Paragraph>
                )}
                {role !== 'student' && jobDetails.macroAreas && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Macroáreas</strong>
                    <br />
                    <span>
                      {jobDetails.macroAreas && jobDetails.macroAreas.length > 0
                        ? jobDetails.macroAreas
                            .map((element) => element.label)
                            .join(', ')
                        : 'Todas as macroáreas são compatíveis à vaga'}
                    </span>
                  </Paragraph>
                )}
                {jobDetails.courses && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Cursos</strong>
                    <br />
                    <span>
                      {jobDetails.courses && jobDetails.courses.length > 0
                        ? jobDetails.courses
                            .map((element) => element.label)
                            .join(', ')
                        : 'Todos os cursos são compatíveis à vaga'}
                    </span>
                  </Paragraph>
                )}
                {jobDetails.endTime && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Horário de Trabalho</strong>
                    <br />
                    <span className={classes.capitalize}>
                      <div>
                        <span>Horário de inicio: {jobDetails.startTime}</span>
                        <br />
                        <span>Horário de término: {jobDetails.endTime}</span>
                      </div>
                    </span>
                  </Paragraph>
                )}
                {jobDetails.selectedDisability &&
                  jobDetails.selectedDisability.length > 0 && (
                    <Paragraph universityColor={universityColor}>
                      <strong>Pessoa com deficiência</strong>
                      <br />
                      <span className={classes.capitalize}>
                        {jobDetails.selectedDisability
                          .map((item) => item.label)
                          .join(', ')}
                      </span>
                    </Paragraph>
                  )}
                {jobDetails.salaryRange.value && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Faixa salarial</strong>
                    <br />
                    {jobDetails.salaryRange.label}
                  </Paragraph>
                )}
                {jobDetails.salary && jobDetails.salary !== '0' && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Salário</strong>
                    <br />
                    {jobDetails.divulgationLink
                      ? new Intl.NumberFormat('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(jobDetails.salary)
                      : jobDetails.salary}
                  </Paragraph>
                )}
                {!jobDetails.divulgationLink && (
                  <Paragraph
                    universityColor={universityColor}
                    style={{ overflowWrap: 'anywhere' }}
                  >
                    <strong style={{ color: '##009291' }}>Benefícios</strong>
                    <br />
                    <span
                      style={{ display: 'grid', color: '#4b4b4d' }}
                      className={classes.capitalize}
                    >
                      {jobDetails.selectedBenefits &&
                      jobDetails.selectedBenefits.length >= 1
                        ? jobDetails.selectedBenefits
                            .filter((benefit) => benefit !== 'Outros')
                            .map((benefit, index) => {
                              return (
                                <span key={index}>
                                  {benefit.value && benefit.label}
                                </span>
                              );
                            })
                        : 'Não existe benefícios para essa vaga.'}
                    </span>
                    <div
                      style={{ marginTop: 3 }}
                      className={classes.descriptionHtml}
                      dangerouslySetInnerHTML={{
                        __html: jobDetails.anotherBenefit,
                      }}
                    />
                  </Paragraph>
                )}
                {jobDetails.selectedCities.length > 0 && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Localização</strong>
                    <br />
                    {jobDetails.selectedCities
                      .map((item) => item.label)
                      .join(' - ')}
                  </Paragraph>
                )}
                {jobDetails.selectedCities.length < 1 && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Localização</strong>
                    <br />
                    <p>O trabalho poderá ser efetuado remotamente</p>
                  </Paragraph>
                )}{' '}
                {jobDetails.startedAt && (
                  <Paragraph universityColor={universityColor}>
                    <strong>Período de divulgação</strong>
                    <br />
                    {`De ${
                      !jobDetails.startedAt
                        ? new Date(
                            jobDetails.startedAt
                          ).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
                        : formatDateValue(jobDetails.startedAt)
                    } ${
                      jobDetails.endAt
                        ? `até ${formatDateValue(jobDetails.endAt)}`
                        : ''
                    }`}
                  </Paragraph>
                )}
                {jobDetails.jobContact && (
                  <Paragraph universityColor={universityColor}>
                    <strong>
                      E-mail de recebimento de divulgação das vagas
                    </strong>
                    <br />
                    <a href={`mailto:${jobDetails.jobContact}`} target="_blank">
                      {jobDetails.jobContact}
                    </a>
                  </Paragraph>
                )}
              </Fragment>
            )}
          />
          {jobDetails.selectedBehaviors.length > 0 && (
            <Fragment>
              <Title
                universityColor={universityColor}
                style={{ marginBottom: '30px' }}
              >
                Requisitos Comportamentais
              </Title>
              <CardComposition
                CardContent={() => (
                  <Fragment>
                    <Paragraph universityColor={universityColor}>
                      <strong>Competências</strong>
                      <br />
                      <span className={classes.capitalize}>
                        <ul className={classes.behaviorList}>
                          {jobDetails.selectedBehaviors.map(
                            (behavior, index) => (
                              <li key={index}>{behavior.label}</li>
                            )
                          )}
                        </ul>
                      </span>
                    </Paragraph>
                  </Fragment>
                )}
              />
            </Fragment>
          )}
          {((jobDetails &&
            jobDetails.selectedAcademicRequirements &&
            jobDetails.selectedAcademicRequirements.courses &&
            jobDetails.selectedAcademicRequirements.courses.length > 0) ||
            (jobDetails.enableJustForUniversities &&
              jobDetails.enableJustForUniversities.length > 0) ||
            (jobDetails.relatedProfession &&
              jobDetails.relatedProfession.length > 0) ||
            jobDetails.academicLevelRequired.label ||
            jobDetails.academicPerformance.label) && (
            <Fragment>
              <Title
                universityColor={universityColor}
                style={{ marginBottom: '30px' }}
              >
                Requisitos Acadêmicos
              </Title>
              <CardComposition
                CardContent={() => (
                  <Fragment>
                    {jobDetails.enableJustForUniversities &&
                      jobDetails.enableJustForUniversities.length > 0 && (
                        <Paragraph universityColor={universityColor}>
                          <strong>Universidades Parceiras</strong>
                          <br />
                          <span className={classes.capitalize}>
                            {jobDetails.enableJustForUniversities
                              .map((item) => item.label)
                              .join(', ')}
                          </span>
                        </Paragraph>
                      )}
                    {jobDetails.selectedAcademicRequirements && (
                      <div className={classes.paragraph}>
                        <Paragraph universityColor={universityColor}>
                          <strong>Formação acadêmica</strong>
                          <br />
                          {jobDetails &&
                            jobDetails.selectedAcademicRequirements &&
                            jobDetails.selectedAcademicRequirements.courses &&
                            jobDetails.selectedAcademicRequirements.courses.map(
                              (course) => <div>{course.label}</div>
                            )}
                        </Paragraph>
                      </div>
                    )}
                    {!jobDetails.selectedAcademicRequirements &&
                      jobDetails.academicLevelRequired.label && (
                        <Paragraph universityColor={universityColor}>
                          <strong>Estágio de formação mínimo desejável:</strong>
                          <br />
                          {jobDetails.academicPerformance.label}
                        </Paragraph>
                      )}
                  </Fragment>
                )}
              />
            </Fragment>
          )}
          {jobDetails.selectedProfessionalRequirements.length > 0 ||
          jobDetails.selectedSectors.length > 0 ||
          jobDetails.selectedExtraCourses.length > 0 ||
          jobDetails.experienceTimeRequired.hasOwnProperty('value') ? (
            <Fragment>
              <Title
                universityColor={universityColor}
                style={{ marginBottom: '30px' }}
              >
                Requisitos Profissionais
              </Title>
              <CardComposition
                CardContent={() => (
                  <Fragment>
                    {jobDetails.selectedExtraCourses &&
                      jobDetails.selectedExtraCourses.length > 0 && (
                        <Paragraph universityColor={universityColor}>
                          <strong>Competências Técnicas</strong>
                          <br />
                          {jobDetails.selectedExtraCourses
                            .map((course) => course.label)
                            .join(', ')}
                          .
                        </Paragraph>
                      )}
                    {jobDetails.selectedProfessionalRequirements &&
                      jobDetails.selectedProfessionalRequirements.length >
                        0 && (
                        <Fragment>
                          <Divider height={1} className={classes.divider} />
                          <Paragraph universityColor={universityColor}>
                            <strong>Experiência Profissional</strong>
                            <br />
                            <span className={classes.capitalize}>
                              {jobDetails.selectedProfessionalRequirements
                                .map((experience) => experience.label)
                                .join(', ')}
                            </span>
                          </Paragraph>
                        </Fragment>
                      )}
                    {jobDetails.experienceTimeRequired.hasOwnProperty(
                      'value'
                    ) && (
                      <Paragraph universityColor={universityColor}>
                        <strong>Tempo de experiência:</strong>
                        <br />
                        {jobDetails.experienceTimeRequired.label}
                      </Paragraph>
                    )}
                    {jobDetails.selectedSectors &&
                      jobDetails.selectedSectors.length > 0 && (
                        <Fragment>
                          <Divider height={1} className={classes.divider} />
                          <Paragraph universityColor={universityColor}>
                            <strong>Segmento de Empresas</strong>
                            <br />
                            {jobDetails.selectedSectors
                              .map((sector) => sector.label)
                              .join(', ')}
                          </Paragraph>
                        </Fragment>
                      )}
                  </Fragment>
                )}
              />
            </Fragment>
          ) : null}
          {isPublished &&
          applicationJob &&
          !finishStatus &&
          applicationJob.isAnswered &&
          applicationJob.answer.is_interesting &&
          !applicationJob.jobOffer.is_candidate ? (
            <DivFooter isApplicationJob>
              <ButtonCancelApplication
                isFooter
                universityColor={universityColor}
                onClick={() => setCancelApplicationDialogIsOpen(true)}
              >
                Cancelar Candidatura
              </ButtonCancelApplication>
              <AppliedRoundedButton
                universityColor={universityColor}
                round
                isFooter
                variant="outlined"
                onClick={() => setContractedDialog()}
              >
                Fui Contratado(a)
              </AppliedRoundedButton>
            </DivFooter>
          ) : (
            <DivFooter>
              {showApplyButton && !finishStatus && (
                <ButtonJobs
                  isFooter
                  universityColor={universityColor}
                  onClick={() => {
                    returnButton().onClick();
                  }}
                >
                  {returnButton().label}
                </ButtonJobs>
              )}
            </DivFooter>
          )}
        </Fragment>
      ) : (
        <CardComposition
          CardContent={() => (
            <div>Não há detalhes cadastrados para esta vaga.</div>
          )}
        />
      )}
    </div>
  );
}

JobDetails.propTypes = {
  classes: object,
  applicationDetailId: number,
  jobId: number,
  jobDetailPreview: object,
  handleIsLoadingState: func,
  role: string,
  getJobDetails: func,
  externalJob: bool,
  deleteAplication: func,
  cancelApplication: func,
  openModalWithContent: func,
};
export default withStyles(jobsPositionDetailsStyles)(JobDetails);
