import styled from 'styled-components';

export const BackgroundModal = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(100vw - 260px);
  height: 100%;
  overflow-y: scroll;
  left: 16rem;
  background-color: #fff;
  padding: 2rem 2rem;

  @media screen and (max-width: 963px) {
    left: 0;
    width: 100vw;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 942px;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
`;
export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0 18px;
`;

export const ContentTexts = styled.div`
  display: flex;
  align-items: self-start;
  flex-direction: column;
`;

export const Title = styled.h3`
  color: #009291;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
`;

export const TextHeader = styled.p`
  color: #606062;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
`;

export const ButtonList = styled.button`
  padding: 10px 15px;
  background-color: #009291;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;

  cursor: pointer;
`;

export const ContentModal = styled.div`
  margin-top: 50px;
  width: 100%;
`;

export const ContentCloseModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const ButtonCloseModal = styled.button`
  width: 40px;
  background-color: #efeeec;
  border: none;
  border-radius: 50%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    justify-content: center;

    @media (max-width: 1080px) {
      justify-content: flex-start;
    }
  }
`;
