import styled from 'styled-components';

const getColor = ({ color }) => {
  return color || '#009291';
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  margin: 1rem 2rem;
  font-family: Roboto;

  h1 {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    color: ${getColor};
  }

  p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #606062;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 1rem;

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.875rem;
    }
  }
`;

export const WhiteCard = styled.div`
  background: white;
  border-radius: 6px;
  box-shadow: 0 0 34px 0 #0000000d;
  padding: 2rem;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: ${getColor};
  margin-bottom: 2rem;
`;

export const ProjectListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem;

  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;
