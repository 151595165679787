import styled from 'styled-components';
import { device } from '../utils/devicesQuery';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f8f8;
  margin-top: 10px;
  height: 40px;
  padding: 26px 20px;
  border-radius: 8px;

  @media ${device.mobileL} {
    height: 95px;
    padding: 26px 10px;
  }
`;

export const ContainerTypeName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > img {
    width: 24px;
  }

  > span {
    font-size: 13px;
    display: inline-block;
    width: 335px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div {
    .nameEvidence {
      display: flex;
      align-items: baseline;
      text-align: center;
      margin: 0px;
      padding: 0px;
      margin-bottom: -10px;

      @media ${device.mobileL} {
        flex-direction: column;
        align-items: unset;
        margin-bottom: 0px;

        > p {
          order: 2;
          overflow: hidden;
          max-width: 130px;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0;
        }

        span {
          order: 1;
          background-color: transparent;
          padding: 0;
          margin-left: 0px;
        }
      }
    }
    .date {
      font-size: 12px;
      display: flex;
      margin: 0;
      gap: 6px;

      @media ${device.mobileL} {
        gap: 4px;
      }
    }
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  gap: 10px;

  > img {
    width: 20px;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }

  > button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    padding: 0;
  }
`;

export const PopUp = styled.div`
  > img {
    width: 20px;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }

  a{
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    font-weight: 400;
    text-decoration: underline;
  }

  position: relative;
  width: fit-content;

  &:hover {
    &:before {
      display: block;
    }
    &:after {
      display: block;
    }
  }

  &:before {
    content: '${(props) => props.label}';
    width: 56px;
    background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
    position: absolute;
    top: -37px;
    right: -20px;
    padding: 5px 7px;
    text-align: center;
    display: block;
    color: #ffff;
    border-radius: 4px;
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'}
      transparent;
    border-width: 10px 10px 0;
    bottom: 30px;
    left: 50%;
    margin-left: -10px;
    display: none;
  }

  > button {
    border: 1px solid #009291;
    color: #009291;
    font-weight: 500;
    padding: 5px 12px;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }
`;

export const Status = styled.span`
  background-color: ${(props) => props.background};
  font-weight: bold;
  color: ${(props) => props.color};
  padding: 5px;
  margin-left: 10px;
  font-size: 10px;
  height: 15px;
  display: flex;
  align-items: center;
`;
