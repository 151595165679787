import React, { Fragment, useState, useContext, useEffect } from 'react';
import SkeletonCardProjectExtension from '../../../../components/SkeletonExtensionProjects';
import { PublishedCard } from '../Card';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import ModalMoreInformations from '../../../../components/Modal/ModalMoreInformation';
import ModalUnpublishProject from '../../../../components/Modal/ModalUnpublishProject';
import ModalUnpublishNoFeedback from 'views/StaffPages/ExtensionProject/components/Modal/ModalUnpublishProject/ModalUnpublishNoFeedback';
import GenericMessage from 'components/GenericMessage';

export default function PublishedCarousel({
  array,
  waitingForStart,
  ref,
  handleOpenStudentsList,
  genericMessage,
  inProgress,
}) {
  const [details, setDetails] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [openModalUnpublish, setOpenModalUnpublish] = useState(false);
  const [projectIdToUnpublish, setProjectIdToUnpublish] = useState(null);

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const {
    setModalEnrolledStudents,
    setExtensionProjectId,
    setStatus,
    getAllFutureProjects,
    getCountProjects,
    appliedFilters,
  } = useContext(ExtensionProjectsContext);

  const handleEnrolledStudents = (item) => {
    setModalEnrolledStudents(true);
    setExtensionProjectId(item.extension_project.id);
    setStatus(item.is_published);
  };

  const handleClickUnpublish = (item) => {
    setOpenModalUnpublish(true);
    setProjectIdToUnpublish(item.extension_project.id);
  };

  const closeModalFeedback = () => {
    setOpenModalUnpublish(false);
    getAllFutureProjects(appliedFilters, true);
    getCountProjects(true);
  };

  const mapProject = (array) => {
    if (array === null) {
      return (
        <SkeletonCardProjectExtension quantity={randomIntFromInterval(3, 4)} />
      );
    }

    if (array.length === 0) {
      return <GenericMessage title={genericMessage} />;
    }

    return (
      array &&
      array.map((item) => (
        <PublishedCard
          ref={ref}
          item={item}
          waitingForStart={waitingForStart}
          setDetails={setDetails}
          setIsOpen={setIsOpen}
          handleOpenStudentsList={handleOpenStudentsList}
          setOpenModalUnpublish={setOpenModalUnpublish}
          handleClickUnpublish={() => handleClickUnpublish(item)}
        />
      ))
    );
  };

  const selectedProject = array?.find(
    (project) => project.extension_project.id === projectIdToUnpublish
  );

  return (
    <Fragment>
      {mapProject(array)}
      {details && Object.keys(details).length > 0 ? (
        <ModalMoreInformations
          details={details}
          isOpen={isOpen}
          setModalOpen={setIsOpen}
          handleEnrolledStudents={handleEnrolledStudents}
          waitingForStart={waitingForStart}
          inProgress={inProgress}
        />
      ) : null}

      {openModalUnpublish &&
        selectedProject &&
        (selectedProject.extension_project.students_registered < 1 ? (
          <ModalUnpublishNoFeedback
            isOpen={openModalUnpublish}
            setOpenModalUnpublish={setOpenModalUnpublish}
            projectId={projectIdToUnpublish}
          />
        ) : (
          <ModalUnpublishProject
            isOpen={openModalUnpublish}
            setOpenModalUnpublish={setOpenModalUnpublish}
            closeModalFeedback={closeModalFeedback}
            projectId={projectIdToUnpublish}
          />
        ))}
    </Fragment>
  );
}
