import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  getFairAndEventsFinishedCount,
  getFairAndEventsListingPagination,
  getFairAndEventsListings,
  getFairAndEventsListingsCount,
  getFairAndEventDetailsAndStatus,
} from 'views/Student/Learning/Services';

const VisualizationContext = createContext();

export const VisualizationProvider = ({ children }) => {
  const [myRegistrations, setMyRegistrations] = useState([]);
  const [availables, setAvailables] = useState([]);
  const [finished, setFinished] = useState([]);

  const [modalTicketOpen, setModalTicketOpen] = useState(false);
  const [modalTicketData, setModalTicketData] = useState({});

  const [currentTab, setCurrentTab] = useState(0);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const [detailsObject, setDetailsObject] = useState({});

  const [getFavoritedAvaibles, setGetFavoritedAvaiables] = useState(false);

  const [counts, setCounts] = useState({});

  const [livestreamModalOpen, setLivestreamModalOpen] = useState(false);

  const [typeFinish, setTypeFinish] = useState('finished');

  const [queryFilterMounted, setQueryFilterMounted] = useState('');
  const [renderingByRequest, setRenderingByRequest] = useState(false);

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  const paginationObject = {
    0: {
      next: myRegistrations?.value?.next,
      setter: setMyRegistrations,
      type: 'inscriptions',
    },
    1: {
      next: availables?.value?.next,
      setter: setAvailables,
      type: 'opened',
    },
    2: {
      next: finished?.value?.next,
      setter: setFinished,
      type: 'finish',
    },
  };

  const hasNextPagination = paginationObject[currentTab].next;

  const handleClickLoadMore = async () => {
    const currentPagination = paginationObject[currentTab];

    if (hasNextPagination) {
      const queryParams = new URLSearchParams(
        currentPagination.next.split('?')[1]
      );

      const uniqueParams = Array.from(queryParams.keys()).reduce((acc, key) => {
        acc.set(key, queryParams.getAll(key).pop());
        return acc;
      }, new Map());

      const query = Array.from(
        uniqueParams,
        ([key, value]) => `${key}=${value}`
      ).join('&');

      const response = await getFairAndEventsListingPagination(
        currentPagination.type,
        query,
        typeFinish
      );

      currentPagination.setter((prevs) => ({
        value: {
          ...response,
          results: [...(prevs.value?.results || []), ...response.results],
        },
      }));
    }
  };

  const getListings = async () => {
    const [
      countsListings,
      myRegistrations,
      availables,
      finished,
    ] = await Promise.allSettled([
      getFairAndEventsListingsCount(),
      getFairAndEventsListings('inscriptions'),
      getFairAndEventsListings('opened'),
      getFairAndEventsListings('finish'),
    ]);
    setCounts({ ...countsListings.value });

    setMyRegistrations(myRegistrations);
    setAvailables(availables);
    setFinished(finished);
  };

  const updateCount = async () => {
    const [countsListings, countsFinished] = await Promise.allSettled([
      getFairAndEventsListingsCount(),
      getFairAndEventsFinishedCount(),
    ]);

    setCounts({ ...countsListings.value, ...countsFinished.value });
  };

  const getEventDetailsAndStatus = async (eventId) => {
    const tabIndexes = {
      'canceled': null,
      'inscriptions' : 0,
      'opened' : 1,
      'finished' : 2,
    };

    const resp = await getFairAndEventDetailsAndStatus(eventId);
    if (!resp || resp?.message) {
      return null;
    }

    const tab = tabIndexes[resp.external_access_type];

    if (Number.isInteger(tab)) {
      return {
        tabIndex: tab,
        eventObject: resp,
      };
    } else {
      return null;
    }
  };

  useEffect(() => {
    getListings();
  }, []);

  return (
    <VisualizationContext.Provider
      value={{
        updateCount,
        handleTabChange,
        currentTab,
        detailsModalOpen,
        setDetailsModalOpen,
        detailsObject,
        setDetailsObject,
        myRegistrations,
        availables,
        finished,
        setModalTicketOpen,
        setModalTicketData,
        modalTicketData,
        modalTicketOpen,
        getListings,
        handleClickLoadMore,
        hasNextPagination,
        paginationObject,
        setGetFavoritedAvaiables,
        getFavoritedAvaibles,
        setAvailables,
        counts,
        livestreamModalOpen,
        setLivestreamModalOpen,
        typeFinish,
        setTypeFinish,
        queryFilterMounted,
        setQueryFilterMounted,
        setCurrentTab,
        getEventDetailsAndStatus,
        renderingByRequest,
        setRenderingByRequest,
      }}
    >
      {children}
    </VisualizationContext.Provider>
  );
};

export const useVisualizationContext = () => {
  const context = useContext(VisualizationContext);
  if (!context) {
    throw new Error(
      'VisualizationContext must be used within a VisualizationProvider'
    );
  }
  return context;
};
