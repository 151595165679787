import React from 'react';
import { IconWrapper, InfoLink, ItemWrapper } from './styled';

export const InfoRowWithLink = ({ icon, label, value, onClick, color }) => {
  return (
    <ItemWrapper>
      <div className="container">
        <IconWrapper>{icon}</IconWrapper>
        <div className="text-wrapper">
          <strong>{label}</strong>
          <InfoLink onClick={onClick} color={color}>
            {value}
          </InfoLink>
        </div>
      </div>
    </ItemWrapper>
  );
};
