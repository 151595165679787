import React, { useEffect, useRef, useState } from 'react';
import {
  BackgroundCheckbox,
  CheckboxContainer,
  DropDown,
  DropDownOption,
  HiddenCheckbox,
  StyledCheckbox,
} from './styled';
import { IoIosArrowDown } from 'react-icons/io';

const Icon = () => (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1H13" stroke="white" strokeWidth="2" />
  </svg>
);

const CustomCheckbox = ({
  className,
  onChange,
  options,
  onClearOption,
  unCheck = false,
}) => {
  const [checked, setChecked] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropDownRef = useRef(null);

  const lastOptionSelected = useRef('');

  const hasOptions = options && options.length > 0;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (unCheck) {
      setChecked(false);
    }
  }, [unCheck]);

  const handleCheckboxChange = () => {
    if (!hasOptions) {
      setChecked(!checked);
      onChange && onChange(!checked);
    }

    if (hasOptions && checked) {
      setChecked(false);
      onClearOption && onClearOption();
      toggleMenu();
      lastOptionSelected.current = null;
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOption = (option) => {
    toggleMenu();

    if (lastOptionSelected.current === option.type && checked) {
      setChecked(false);
      return onClearOption && onClearOption();
    }

    option.onClick && option.onClick(!checked, option.type);
    setChecked(true);

    lastOptionSelected.current = option.type;
  };

  const mapOptions = () => {
    if (hasOptions && menuOpen) {
      return (
        <DropDown ref={dropDownRef}>
          {options.map((option) => (
            <DropDownOption onClick={() => handleClickOption(option)}>
              {option.label}
            </DropDownOption>
          ))}
        </DropDown>
      );
    }
  };

  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} onChange={handleCheckboxChange} />
      <BackgroundCheckbox
        checked={checked}
        onClick={toggleMenu}
        menuOpened={menuOpen}
      >
        <StyledCheckbox checked={checked} onClick={handleCheckboxChange}>
          <Icon />
        </StyledCheckbox>
        {hasOptions && <IoIosArrowDown />}
        {mapOptions()}
      </BackgroundCheckbox>
    </CheckboxContainer>
  );
};

export default CustomCheckbox;
