import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { projectExtensionApi } from '../services/api';
import { CurriculumContext } from 'contexts/CurriculumContext';
import {
  customSnackbar,
  SnackbarType as SnackBarType,
} from 'components/CustomSnackBar/customSnackbar';

const ProjectExtensionContext = createContext();

export const ContextProvider = ({ children }) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [supportMaterialModalOpen, setSupportMaterialModalOpen] =
    useState(false);
  const [finalReportOpen, setFinalReportOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [tabsCount, setTabsCount] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [activeInternalTab, setActiveInternalTab] = useState(0);
  const [materialSupport, setMaterialSupport] = useState({});
  const [enrolledProjects, setEnrolledProjects] = useState(null);

  const fetchTabsCount = async () => {
    try {
      const responseData = await projectExtensionApi.getProjectsCount();
      if (responseData) {
        setTabsCount(responseData);
      }
    } catch (_) {
      customSnackbar('Erro ao buscar contagem de projetos', SnackBarType.ERROR);
    }
  };

  useEffect(() => {
    fetchTabsCount();
  }, [activeTab, activeInternalTab]);

  const curriculumContext = useContext(CurriculumContext);

  const studentFirstName = useMemo(() => {
    return (
      curriculumContext?.profile?.academic_profile?.name?.split(' ')[0] ?? ''
    );
  }, [curriculumContext]);

  const openDetailsModal = async (data) => {
    const [projectDetailsResponse, supportMaterialResponse] = await Promise.all(
      [
        projectExtensionApi.getDetails({
          id: data.id,
        }),
        projectExtensionApi.getSupportMaterials({
          projectId: data.extension_project_id,
        }),
      ]
    );
    projectDetailsResponse.supportMaterial = supportMaterialResponse;
    setModalData(projectDetailsResponse);
    setMaterialSupport(supportMaterialResponse);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
    setModalData({});
  };

  const closeSupportMaterialModal = () => {
    setSupportMaterialModalOpen(false);
  };

  return (
    <ProjectExtensionContext.Provider
      value={{
        detailsModalOpen,
        setDetailsModalOpen,
        modalData,
        setModalData,
        openDetailsModal,
        closeDetailsModal,
        setSupportMaterialModalOpen,
        supportMaterialModalOpen,
        closeSupportMaterialModal,
        studentFirstName,
        finalReportOpen,
        setFinalReportOpen,
        tabsCount,
        fetchTabsCount,
        activeTab,
        setActiveTab,
        activeInternalTab,
        setActiveInternalTab,
        materialSupport,
        setMaterialSupport,
        enrolledProjects,
        setEnrolledProjects,
      }}
    >
      {children}
    </ProjectExtensionContext.Provider>
  );
};

export function useProjectExtensionContext() {
  return useContext(ProjectExtensionContext);
}
