import {
  UploadTile,
  UploadTextColumn,
  UploadActionsWrapper,
} from 'views/Student/ExtensionProject/components/FinalReportModal/styled';
import { RiFileCopyLine } from "react-icons/ri";
import { PopUp } from 'components/ModalShowEvidence/ListFiles/style';
import { typeIcons } from 'components/GenericModalUpload/utils/enums';
import { ActionButton } from 'views/Student/ExtensionProject/components/SupportMaterialModal/styled';
import { BiTrash } from 'react-icons/bi';
import moment from 'moment';
import { handleCopyUrl } from 'components/GenericModalUpload/utils/resource';
import Tooltip from 'components/NewTooltip';

export function ListLinks({ link, removeItem }) {

  function handleOpenUrl(value) {
    window.open(value, '_blank');
  }

  return (
    <UploadTile>
      {typeIcons['url']}
      <UploadTextColumn>
        <a onClick={() => handleOpenUrl(link)}>
          {link}
        </a>
        <span>Adicionada em {moment(new Date()).format('DD/MM/YYYY')}</span>
      </UploadTextColumn>
      <UploadActionsWrapper>
        <Tooltip text="Remover" direction="top">
          <ActionButton
            type={'button'} 
            onClick={() => removeItem(link)}
          >
            <BiTrash size={24} color={'#009291'} alt="delete" />
          </ActionButton>
        </Tooltip>
        <Tooltip text="Copiar" direction="top">
          <ActionButton
            onClick={() => {
              handleCopyUrl(link);
            }}
            type={'button'}
          >
            <RiFileCopyLine size={24} color={'#009291'} alt="eye" />
          </ActionButton>
        </Tooltip>
      </UploadActionsWrapper>
    </UploadTile>
  );
}
