import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  ButtonCancel,
  ButtonThree,
  WrapperButtons,
  SpanInformationList,
  ContainerInformationList,
  ButtonX,
  ParagraphInfo,
  ContentInfo,
} from './style';
import React, { Fragment, useMemo } from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import { InformationIcon } from 'assets/backoffice/information';
import Tooltip from 'components/NewTooltip';

import { IoClose } from 'react-icons/io5';

export default function ModalInformation({
  config,
  closeModalFunction,
  modalOpenState,
  buttonConfirmFunction,
  buttonCancelFunction,
  buttonThreeFunction,
  universityColor,
  children,
}) {
  const memorizedModal = useMemo(
    () =>
      modalOpenState ? (
        <Wrapper visible={modalOpenState} zIndex={config.zIndex}>
          <Box marginLeft={config?.style?.marginLeft}>
            <Modal width={config.modalWidth} height={config.modalHeight}>
              {config?.buttonCloseX && (
                <ButtonX
                  colorBg={config?.style?.colorBgButtonX}
                  onClick={closeModalFunction}
                >
                  <IoClose size={25} color="#4B4B4D" />
                </ButtonX>
              )}
              <WrapperTitle alignIcon={config?.style?.alignIcon}>
                <IconPersonStyled
                  universityColor={universityColor}
                  sizeIconPerson={config?.style?.sizeIconPerson}
                  src={config.icon || IconPerson}
                />
                <BubbleDialog universityColor={universityColor}>
                  <H4Styled {...config?.bubble}>{config.bubbleText}</H4Styled>
                </BubbleDialog>
              </WrapperTitle>
              <ParagraphStyled
                textAlign={config?.style?.textAlign}
                fontWeight={config?.style?.fontWeight}
                marginTop={config?.style?.marginTop}
                marginBottom={config?.style?.marginBottom}
                fontSize={config?.style?.fontSize}
                lineHeight={config?.style?.lineHeight}
                gapParagraph={config?.style?.gapParagraph}
                maxWidth={config?.style?.maxWidth}
              >
                <p test-id="paragraph-text">
                  {ReactHtmlParser(config.ParagraphText)}
                </p>
                <p>{ReactHtmlParser(config.TwoParagraphText)}</p>
              </ParagraphStyled>

              {children}

              {config.showInfo && (
                <ContentInfo>
                  <ParagraphInfo>{config.ParagraphInformation}</ParagraphInfo>
                </ContentInfo>
              )}

              <ContainerInformationList>
                {config.informationList?.map((item) => (
                  <li>
                    <SpanInformationList>
                      {item.text}
                      {item.information && (
                        <Tooltip
                          direction={'top'}
                          isMultiLine={true}
                          minWidth={item.toolTipWidth}
                          text={item.information}
                        >
                          <InformationIcon color={'#000'} size={15} />
                        </Tooltip>
                      )}
                    </SpanInformationList>
                  </li>
                ))}
              </ContainerInformationList>

              <WrapperButtons
                marginButtons={config?.style?.marginButtons}
                config={config}
              >
                <Button
                  type="button"
                  universityColor={universityColor}
                  width={config.buttonConfirmWidth}
                  height={config.buttonConfirmHeight}
                  onClick={() =>
                    buttonConfirmFunction && buttonConfirmFunction()
                  }
                  disabled={config.loading}
                >
                  {config.loading ? (
                    <FilterLoading />
                  ) : (
                    config.buttonConfirmText
                  )}
                </Button>

                {config.showButtonCancel && (
                  <ButtonCancel
                    universityColor={universityColor}
                    width={config.buttonCancelWidth}
                    height={config.buttonCancelHeight}
                    type={'button'}
                    onClick={() =>
                      buttonCancelFunction && buttonCancelFunction()
                    }
                    disabled={config.loading}
                  >
                    {config.buttonCancelText}
                  </ButtonCancel>
                )}

                {config.showButtonThree && (
                  <ButtonThree
                    universityColor={universityColor}
                    width={config.buttonThreeWidth}
                    height={config.buttonThreeHeight}
                    onClick={() => buttonThreeFunction && buttonThreeFunction()}
                    disabled={config.loading}
                  >
                    {config.buttonThreeText}
                  </ButtonThree>
                )}
              </WrapperButtons>
            </Modal>
          </Box>
        </Wrapper>
      ) : null,
    [modalOpenState, config]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}

ModalInformation.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 418,
    iconWidth: 95,
    iconHeight: 95,
    titleWidth: 100,
    bubbleText: '...',
    ParagraphText: 'Adicione sua Descrição',
    TwoParagraphText: 'Adicione uma segunda frase a descrição',
    buttonWidth: 290,
    buttonHeight: 50,
    buttonFuction: () => {},
    buttonText: 'Botão',
    visible: true,
    loading: false,
    icon: IconPerson,
    showButtonCancel: false,
    buttonCancelText: 'CANCELAR',
    showButtonThree: false,
  },
};
