import React from 'react';
import {
  BackgroundModal,
  ContentCloseModal,
  ButtonCloseModal,
  Container,
  Content,
} from './styled';
import { IoClose } from 'react-icons/io5';

export default function GenericModal({ onClose, children }) {
  return (
    <BackgroundModal>
      <Container>
        <Content>
          <ContentCloseModal>
            <ButtonCloseModal onClick={onClose}>
              <IoClose size={22} color="#606062" />
            </ButtonCloseModal>
          </ContentCloseModal>
          {children}
        </Content>
      </Container>
    </BackgroundModal>
  );
}
