import React, { useContext } from 'react';
import {
  ContainerDoc,
  ContainerImageName,
  ContainerColumn,
  Link,
  Paragraph,
  ContainerButtons,
  ButtonActionsDoc,
} from '../ModalSupportMaterial/styled';
import {
  customSnackbar,
  SnackbarType,
} from 'components/CustomSnackBar/customSnackbar';
import {
  JPGIcon,
  MP4Icon,
  PDFIcon,
  PNGIcon,
  RARIcon,
  ZIPIcon,
} from 'assets/ComponentIcons/evidencesIcon';
import { UploadType } from 'views/Student/ExtensionProject/enums/upload_type';
import PdfViewer from 'components/UploadEvidences/components/PdfViwer';
import BaseLayoutContext from 'contexts/base-layout';
import { RiEyeLine, RiLink } from 'react-icons/ri';
import Tooltip from 'components/NewTooltip';
import moment from 'moment';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';

const EvidenceList = ({ evidences, setSelectedImage }) => {
  const { openModalWithContent } = useContext(BaseLayoutContext);
  const {setZIndex} = useContext(ExtensionProjectsContext)

  const materialTypesIcons = {
    '.pdf': <PDFIcon size={40} />,
    '.png': <PNGIcon size={40} />,
    '.jpg': <JPGIcon size={40} />,
    '.jpeg': <JPGIcon size={40} />,
    '.mp4': <MP4Icon size={40} />,
    '.zip': <ZIPIcon size={40} />,
    '.rar': <RARIcon size={40} />,
    '.url': <RiLink color={'#009291'} size={40} />,
  };

  const getMaterialTypeIcon = (material) => {
    switch (material.type) {
      case UploadType.LINK:
        return materialTypesIcons['.url'];
      case UploadType.IMAGE:
        return materialTypesIcons[material.image.suffix];
      case UploadType.DOCUMENT:
        return materialTypesIcons[material.file.suffix];
      default:
        return null;
    }
  };

  const handleCopyUrl = (url) => {
    navigator.clipboard.writeText(url).then(
      () => {
        customSnackbar(
          'URL copiada para a área de transferência com sucesso!',
          SnackbarType.CONFIRMATION
        );
      },
      () => {
        customSnackbar(
          'Erro ao copiar o URL para a área de transferência: ',
          SnackbarType.ERROR
        );
      }
    );
  };

  const handleOpenUrl = (value) => {
    window.open(value, '_blank');
  };

  const handleClickViewPdf = (file) => {
    setZIndex(0);
    openModalWithContent(
      <PdfViewer file={file} name={file.name} />,
      null,
      null,
      () => setZIndex(20000000)
    );
  };
  return evidences.map((evidence) => (
    <ContainerDoc key={evidence.id} style={{ margin: '15px 0' }}>
      <ContainerImageName>
        {getMaterialTypeIcon(evidence)}
        <ContainerColumn>
          {evidence.type === 'LINK' ? (
            <Link onClick={() => handleOpenUrl(evidence.file)}>
              {evidence.file?.name}
            </Link>
          ) : (
            <Paragraph>{evidence.file?.name}</Paragraph>
          )}
          <Paragraph data>
            Adicionada em {moment(evidence?.created_at).format('DD/MM/YYYY')}
          </Paragraph>
        </ContainerColumn>
      </ContainerImageName>
      <ContainerButtons>
        <Tooltip direction="top" colorSchema="black" text="Visualizar">
          <ButtonActionsDoc
            onClick={() => {
              switch (evidence.type) {
                case UploadType.LINK:
                  handleCopyUrl(evidence.link);
                  break;
                case UploadType.IMAGE:
                  setSelectedImage(evidence.image.file);
                  break;
                case UploadType.DOCUMENT:
                  handleClickViewPdf(evidence.file.file);
                  break;
              }
            }}
          >
            <RiEyeLine size={20} color="#009291" />
          </ButtonActionsDoc>
        </Tooltip>
      </ContainerButtons>
    </ContainerDoc>
  ));
};

export default EvidenceList;
