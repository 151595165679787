import BaseLogin from 'layouts/BaseLogin';
import NewPassword from 'views/RecoverPassword/NewPassword';
import LoginStudent from 'views/LoginPage/Login/Screens/LoginStudent';
import LoginUniversity from 'views/LoginPage/Login/Screens/LoginUniversity';
import LoginCompany from 'views/LoginPage/Login/Screens/LoginCompany';
import ChooseLogin from 'views/LoginPage/Login/Screens/ChooseLogin';
import LoginPage from '../views/LoginPage/LoginPage';
import subdomainService from 'services/subdomain.service';

const subdomainContext = subdomainService.getContextLoginRoutes();
const subdomain = subdomainService.getIesName();

const loginRoutes = {
  student: [
    {
      path: '/login',
      name: 'login estudantes',
      type: 'enrolled',
      hide: true,
      showRecoverPassword: true,
      isStudentLogin: true,
      university: subdomain,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/login/matriculados',
      name: 'login estudantes',
      type: 'enrolled',
      hide: true,
      showRecoverPassword: true,
      isStudentLogin: true,
      university: subdomain,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/login/egressos',
      name: 'login estudantes',
      type: 'egress',
      hide: true,
      showRecoverPassword: true,
      isStudentLogin: true,
      university: subdomain,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      redirect: true,
      path: '*',
      pathTo: '/login',
    },
  ],
  university: [
    {
      path: '/vaga/:universidade/:id',
      name: 'login vaga',
      hide: true,
      type: 'enrolled',
      showRecoverPassword: true,
      isStudentLogin: true,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/feiraseeventos/:universidade/:fairEventId',
      name: 'Login feiras e eventos',
      hide: true,
      type: 'enrolled',
      showRecoverPassword: true,
      isStudentLogin: true,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/aprendizagem/:universidade/compartilhar/evento/:eventId/:registerId?',
      name: 'login compartilhar evento',
      hide: true,
      showRecoverPassword: true,
      isStudentLogin: true,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },

    {
      path: '/feiras-e-eventos/:id/:uuid',
      name: 'Login feiras e eventos',
      hide: true,
      showRecoverPassword: true,
      baseComponent: BaseLogin,
      component: LoginUniversity,
    },
    {
      path: '/login',
      name: 'login universidade',
      hide: true,
      baseComponent: BaseLogin,
      component: LoginUniversity,
    },
    {
      path: '/trabalhabilidade',
      name: 'login universidade',
      hide: true,
      baseComponent: BaseLogin,
      component: LoginUniversity,
    },
    {
      path: '/login/recuperar-senha',
      name: 'Recuperar Senha',
      hide: true,
      isRecovery: true,
      baseComponent: BaseLogin,
      component: LoginUniversity,
    },
    {
      redirect: true,
      path: '*',
      pathTo: '/login',
    },
  ],
  company: [
    {
      path: '/login',
      name: 'login empresa',
      hide: true,
      showRegistrationForm: true,
      showRecoverPassword: true,
      baseComponent: BaseLogin,
      component: LoginCompany,
    },
    {
      path: '/login/:universidade',
      name: 'login empresa',
      hide: true,
      showRegistrationForm: true,
      showRecoverPassword: true,
      baseComponent: BaseLogin,
      component: LoginCompany,
    },
    {
      redirect: true,
      path: '*',
      pathTo: '/login',
    },
  ],
  default: [
    // TODO: Add base route with loginPage
    {
      path: '/vaga/:universidade/:id',
      name: 'login vaga',
      hide: true,
      showRecoverPassword: true,
      isStudentLogin: true,
      type: 'enrolled',
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/feiraseeventos/:universidade/:fairEventId',
      name: 'Login feiras e eventos',
      hide: true,
      showRecoverPassword: true,
      isStudentLogin: true,
      type: 'enrolled',
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/aprendizagem/:universidade/compartilhar/evento/:eventId/:registerId?',
      name: 'Compartilhar Evento',

      isStudentLogin: true,
      type: 'enrolled',
      baseComponent: BaseLogin,
      showRecoverPassword: true,
      component: LoginStudent,
      hide: true,
    },
    {
      path: '/trabalhabilidade',
      name: 'login universidade',
      hide: true,
      baseComponent: BaseLogin,
      component: LoginUniversity,
    },
    {
      path: '/login/:universidade/aluno',
      name: 'login estudantes',
      type: 'enrolled',
      hide: true,
      showRecoverPassword: true,
      isStudentLogin: true,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/feiras-e-eventos/:id/:uuid',
      name: 'Login feiras e eventos',
      hide: true,
      showRecoverPassword: true,
      baseComponent: BaseLogin,
      component: LoginUniversity,
    },
    {
      path: '/login/:universidade/matriculados',
      name: 'login estudantes',
      type: 'enrolled',
      hide: true,
      showRecoverPassword: true,
      isStudentLogin: true,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/login/:universidade/egressos',
      name: 'login estudantes',
      type: 'egress',
      hide: true,
      showRecoverPassword: true,
      isStudentLogin: true,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/login/universidade',
      name: 'login universidade',
      hide: true,
      baseComponent: BaseLogin,
      component: LoginUniversity,
    },
    {
      path: '/login/recuperar-senha-empresa',
      name: 'Recuperar Senha Empresa',
      hide: true,
      isRecovery: true,
      baseComponent: BaseLogin,
      component: LoginCompany,
    },
    {
      path: '/login/recuperar-senha-universidade',
      name: 'Recuperar Senha Universidade',
      hide: true,
      isRecovery: true,
      baseComponent: BaseLogin,
      component: LoginUniversity,
    },
    {
      path: '/login/recuperar-senha-aluno',
      name: 'Recuperar Senha Aluno',
      hide: true,
      isRecovery: true,
      baseComponent: BaseLogin,
      component: LoginStudent,
    },
    {
      path: '/login/recuperar-senha',
      name: 'Recuperar Senha',
      hide: true,
      isRecovery: true,
      baseComponent: BaseLogin,
      component: LoginUniversity,
    },
    {
      path: '/login/empresa',
      name: 'login empresa',
      hide: true,
      showRegistrationForm: true,
      showRecoverPassword: true,
      baseComponent: BaseLogin,
      component: LoginCompany,
    },
    {
      path: '/login/empresa/:universidade',
      name: 'login empresa',
      hide: true,
      showRegistrationForm: true,
      showRecoverPassword: true,
      baseComponent: BaseLogin,
      component: LoginCompany,
    },
    {
      path: '/login/backoffice',
      name: 'login backoffice',
      hide: true,
      baseComponent: BaseLogin,
      component: LoginPage,
    },
    {
      path: '/login',
      name: 'login',
      hide: true,
      baseComponent: BaseLogin,
      component: ChooseLogin,
    },
    {
      path: '/login/recuperar-senha',
      name: 'Recuperar Senha',
      hide: true,
      baseComponent: BaseLogin,
      component: NewPassword,
    },
    {
      redirect: true,
      path: '*',
      pathTo: '/login',
    },
  ],
};

export default loginRoutes[subdomainContext];
