import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
`;

export const WrapperInformations = styled.div`
  width: 610px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.31px;
  color: #606062;
  padding-bottom: 6px;

  div {
    font-family: Roboto;
    font-size: 18px;

    p {
      font-weight: 400;
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 6px;
    }
  }
`;

export const DividerInfos = styled.div`
  height: 18px;
  width: 2px;
  background-color: #c4c4c4;
`;