import styled from 'styled-components';

export const StudentItem = styled.div`
  & + & {
    margin-top: 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .mb-20 {
    margin-bottom: 1.25rem;
  }

  .infos {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    color: #606062;
    h3.name {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 24px;
      margin-bottom: 2px;
      margin-top: 0px;
    }
    p.info {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 0.125rem;
    }
    p.infoHighlight {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: auto;
      color: #009291;
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      background: transparent;
      padding: 10px;
      border: #009291 1px solid;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 1rem;
      color: #009291;
      line-height: 18px;
      outline: none;
      cursor: pointer;
      transition: all 0.3s;
      white-space: nowrap;
      width: fit-content;

      &:hover {
        background: #009291;
        color: #fff;
      }
    }

    span {
      font-family: Roboto;
      font-weight: 400;
      font-size: 1rem;
      line-height: 18.56px;
      letter-spacing: 0px;
      text-align: center;
      color: #949494;
      width: 160px;
      margin-top: 10px;
    }
  }

  & + & {
    .line {
      height: 1px;
      background-color: #e5e5e5;
    }
  }
`;

export const ProfileImage = styled.img`
  width: 76px;
  height: 75px;
  background-image: url(${(props) => props.image});
  border-radius: 40px;
  background-position: center;
  background-size: cover;
  margin-right: 0.5rem;
`;
