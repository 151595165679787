import React, { useContext, useEffect, useState } from 'react';

import { Container, ContainerCards, DivButton } from './styled';
import { Card } from '../../UiComponents/Card';
import { LuBuilding2, LuCalendarClock, LuClock3 } from 'react-icons/lu';
import Button from '../../UiComponents/Button';
import { IoIosLaptop } from 'react-icons/io';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import { FaRegCalendar } from 'react-icons/fa';
import BaseLayoutContext from 'contexts/base-layout';
import { ContainerButtonLoadMore } from '../Availables/styled';
import FilterHandlers from '../../Handlers/FilterHandlers';
import InsideFilter from '../../UiComponents/InsideFilter';
import Search from '../../UiComponents/Search';
import { VisualizationHandler } from '../../Handlers/VisualizationHandler';
import FeedbackModal from '../../UiComponents/FeedbackModal';
import { DateHelper } from '../../Helpers/DateHelper';

import CertificateModal from '../../UiComponents/CertificateModal';
import GenericMessage from 'components/GenericMessage';
import { CardFormatHelper } from '../../Helpers/CardFormatHelper';
import useMediaQuery from 'hooks/useMediaQuery';
import { certificateHandlers } from '../../Handlers/CertificateHandlers';

function Finished() {
  const visualizationContextInstance = useVisualizationContext();

  const { finished, hasNextPagination, handleClickLoadMore, typeFinish } =
    visualizationContextInstance;
  const { universityColor } = useContext(BaseLayoutContext);

  const filterHandlersFactory = FilterHandlers(visualizationContextInstance);

  const [selectedStudent, setSelectedStudent] = useState({});
  const [openModalFeedback, setOpenModalFeedback] = useState(false);
  const [certificateModalOpen, setCertificateModalOpen] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [searchText, setSearchText] = useState('');

  const [blockButton, setBlockButton] = useState(false);

  const loadMore = async () => {
    setBlockButton(true);
    await handleClickLoadMore();
    setBlockButton(false);
  };

  const handleClickSeeFeedback = (event) => {
    setSelectedStudent(event);
    setOpenModalFeedback(true);
  };

  const closeModalFeedback = () => {
    setOpenModalFeedback(false);
  };

  const handleClickOpenCertificate = (event) => {
    setSelectedStudent(event);
    setCertificateModalOpen(true);
  };

  const closeCertificateModal = () => {
    setCertificateModalOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    filterHandlersFactory.handleChangeSearchParam(event.target.value);
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  const download = async (event) => {
    setDownloadLoading(true);
    const certificateHandlersFactory = certificateHandlers(event);
    await certificateHandlersFactory.downloadCertificate();
    setDownloadLoading(false);
  };

  const createCardButton = (event) => {
    if (typeFinish === 'finished') {
      return (
        <>
          {event.certification &&
            event.registration &&
            event.event_status !== 'presence_not_confirmed' &&
            event.event_status !== 'awaiting_presence_confirmation' && (
              <Button
                theme="light"
                width="100%"
                onClick={() =>
                  isMobile ? download(event) : handleClickOpenCertificate(event)
                }
                disabled={downloadLoading}
              >
                {isMobile ? 'Baixar certificado' : 'Visualizar certificado'}
              </Button>
            )}
          {event.event_status === 'presence_not_confirmed' ? (
            <Button
              width="100%"
              onClick={() =>
                VisualizationHandler.openDetailsModal(
                  visualizationContextInstance,
                  event.id
                )
              }
            >
              Detalhes
            </Button>
          ) : (
            <Button
              width="100%"
              onClick={() =>
                VisualizationHandler.openDetailsModal(
                  visualizationContextInstance,
                  event.id
                )
              }
              theme={
                event.certification && event.registration ? 'flat' : 'light'
              }
            >
              Detalhes
            </Button>
          )}
        </>
      );
    }
    if (typeFinish === 'canceled') {
      return (
        <DivButton>
          {(event?.feedback || event?.registration?.feedback?.feedback) && (
            <Button
              theme="unfilled"
              width="100%"
              onClick={() => handleClickSeeFeedback(event)}
              className="feedBackButton"
            >
              Visualizar feedback
            </Button>
          )}
          <Button
            width="100%"
            onClick={() =>
              VisualizationHandler.openDetailsModal(
                visualizationContextInstance,
                event.id
              )
            }
            theme={event?.registration?.feedbaack ? 'flat' : 'light'}
          >
            Detalhes
          </Button>
        </DivButton>
      );
    }
  };

  const createCardListing = finished?.value?.results?.map((event) => {
    const modality =
      event.event_modality === 'presential' ? 'Presencial' : 'Online';
    const iconModality =
      event.event_modality === 'presential' ? (
        <LuBuilding2 color={universityColor} />
      ) : (
        <IoIosLaptop color={universityColor} />
      );

    return (
      <Card.Root isFinished>
        <Card.Status status={CardFormatHelper.formatFinishedStatus(event)} />
        <Card.Image
          src={event.event_cover.file}
          onClick={() =>
            VisualizationHandler.openDetailsModal(
              visualizationContextInstance,
              event.id
            )
          }
        />
        {event.certification && (
          <Card.CertificateStatus status="Possui certificado" />
        )}
        <Card.Title id={event.id} title={event.name} />
        <Card.ContainerInformation isFinished>
          <Card.Information icon={iconModality} text={modality} />
          <Card.Information
            icon={<FaRegCalendar color={universityColor} />}
            text={DateHelper.formatRange(
              event.event_start_at,
              event.event_end_at
            )}
          />
          {event.complementary_activity && (
            <Card.Information
              icon={<LuClock3 color={universityColor} />}
              text={'Atividade complementar'}
            />
          )}
        </Card.ContainerInformation>
        <Card.ContainerButton>{createCardButton(event)}</Card.ContainerButton>
      </Card.Root>
    );
  });

  if (finished?.value?.results.length === 0 && searchText.length === 0) {
    return (
      <Container>
        <div className="filters">
          <InsideFilter
            optionsConfig={[
              {
                label: 'Finalizados',
                value: 'finished',
                borderLeftRadius: true,
                onClick: filterHandlersFactory.handleChangeFinishedInsideFilter,
              },
              {
                label: 'Cancelados',
                value: 'canceled',
                borderLeftRadius: false,
                onClick: filterHandlersFactory.handleChangeFinishedInsideFilter,
              },
            ]}
          />
        </div>
        <GenericMessage
          resetIndex={true}
          title="Nenhum evento encontrado"
          subtitle={`No momento não existem eventos ${
            typeFinish === 'finished' ? 'finalizados' : 'cancelados'
          }.`}
          subtitleResponsible={`No momento não existem eventos ${
            typeFinish === 'finished' ? 'finalizados' : 'cancelados'
          }.`}
        />
      </Container>
    );
  }

  if (finished?.value?.results.length === 0 && searchText.length > 0) {
    return (
      <Container>
        <div className="filters">
          <InsideFilter
            optionsConfig={[
              {
                label: 'Finalizados',
                value: 'finished',
                borderLeftRadius: true,
                onClick: filterHandlersFactory.handleChangeFinishedInsideFilter,
              },
              {
                label: 'Cancelados',
                value: 'canceled',
                borderLeftRadius: false,
                onClick: filterHandlersFactory.handleChangeFinishedInsideFilter,
              },
            ]}
          />
          <Search onChange={handleSearchChange} />
        </div>
        <GenericMessage
          resetIndex={true}
          title="Nenhum evento encontrado"
          subtitleResponsible="Não foram encontrados eventos para sua busca."
        />
      </Container>
    );
  }

  return (
    <Container>
      <CertificateModal
        open={certificateModalOpen}
        onClose={closeCertificateModal}
        event={selectedStudent}
      />
      <div className="filters">
        <InsideFilter
          optionsConfig={[
            {
              label: 'Finalizados',
              value: 'finished',
              borderLeftRadius: true,
              onClick: filterHandlersFactory.handleChangeFinishedInsideFilter,
            },
            {
              label: 'Cancelados',
              value: 'canceled',
              borderLeftRadius: false,
              onClick: filterHandlersFactory.handleChangeFinishedInsideFilter,
            },
          ]}
        />
        <Search onChange={handleSearchChange} />
      </div>

      <FeedbackModal
        open={openModalFeedback}
        onClose={closeModalFeedback}
        config={{
          feedback:
            selectedStudent?.registration?.feedback !== null
              ? selectedStudent?.registration?.feedback
              : selectedStudent?.feedback,
          ParagraphText:
            'Veja o feedback que sua Instituição de ensino te enviou',
          bubbleText: 'Visualizar feedback',
          sendBy: selectedStudent?.registration?.unregistrated_user,
          unregistratedDate: DateHelper.formatDateWithHour(
            selectedStudent?.registration?.unregistrated_at
          ),
          modalHeight: 'auto',
        }}
      />

      <ContainerCards>{createCardListing}</ContainerCards>
      {hasNextPagination && (
        <ContainerButtonLoadMore>
          <Button onClick={loadMore} disabled={blockButton}>
            Ver mais
          </Button>
        </ContainerButtonLoadMore>
      )}
    </Container>
  );
}

export default Finished;
