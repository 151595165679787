import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Content } from '../style';
import {
  ContainerSearchInput,
  ContentFormInput,
  SearchInput,
  StepFourContainer,
} from './style';
import ExtensionStudent from './components/Student';
import { CreateExtensionContext } from '../../../CreateExtensionContext';
import Pagination from 'components/CustomPagination';
import ModalInformation from 'components/informationModal';
import {
  configAddStudent,
  configFeedbackStudent,
} from 'views/StaffPages/ExtensionProject/utils/textModal';
import { Form } from '@unform/web';
import SimpleInput from 'components/UnformFields/SimpleInput';
import { extensionService } from '../../../services/extension.service';
import { useDebounce } from 'hooks/useDebounce';
import { IoSearchSharp } from 'react-icons/io5';
import { ModalAddStudents } from '../../ModalAddStudents';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import GenericMessage from 'components/GenericMessage';
import { StudentSkeleton } from '../../Skeletons/StudentSkeleton';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';

export default function StepFive() {
  const {
    getStudentsListing,
    formData,
    studentsListing,
    returnTitleModal,
    excludedStudent,
    extraStudent,
    coursesLabel,
    disciplinesLabel,
    controlModalFeedbackStudent,
    setControlModalFeedbackStudent,
    setIsFeedbackSent,
    setFormValues,
    setExcludedStudent,
    setExtraStudent,
    setHasStudents,
  } = useContext(CreateExtensionContext);

  const [countStudents, setCountStudents] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(24);
  const [offset, setOffset] = useState(0);

  const [searchStudents, setSearchStudents] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [controlModalAddStudent, setControlModalAddStudent] = useState(false);

  const [controlModalListing, setControlModalListing] = useState(false);
  const [studentSearchResult, setStudentSearchResult] = useState(null);
  const [countStudentSearch, setCountStudentSearch] = useState(0);

  const debouncedSearchStudent = useDebounce(searchStudents, 600);
  const formRef = useRef(null);
  const [dataLoaded, setDataLoaded] = useState(false);


  const buildBodyParams = useCallback(() => {
    const { disciplines, unities, courses } = formData;

    return {
      discipline: disciplines,
      courses,
      unities: unities || [],
      exclude: excludedStudent,
      extra: extraStudent,
    };
  }, [formData, excludedStudent, extraStudent]);

  const buildObjectParams = (additionalParams = {}) => ({
    limit,
    offset,
    ...additionalParams,
  });

  const fetchStudentListing = async (additionalParams = {}) => {
    try {
      const bodyParams = buildBodyParams();
      const queryParams = buildObjectParams(additionalParams);
      const { count } = await getStudentsListing(bodyParams, queryParams);
    } catch (err) {
      customSnackbar('Ocorreu um erro ao carregar os dados.', 'error');
    }
  };

  useEffect(() => {
    const { removed_students, extra_students } = formData;

    setExcludedStudent(removed_students);
    setExtraStudent(extra_students);
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    if (dataLoaded) {
      fetchStudentListing(true);
    }
  }, [currentPage, dataLoaded, excludedStudent, extraStudent]);

  useEffect(() => {
    if (debouncedSearchStudent) {
      fetchStudentListing({ name_or_code: searchStudents });
    }
  }, [debouncedSearchStudent, searchStudents,]);

  const onPageChange = (page) => {
    setCurrentPage(page);
    const mathOffset = (page - 1) * limit || 0;
    setOffset(mathOffset);
  };

  useEffect(() => {
    if (studentsListing) {
      setCountStudents(studentsListing?.count);
    }

    if (studentsListing?.count > 0) {
      setHasStudents(true);
    } else {
      setHasStudents(false);
    }
  }, [studentsListing]);

  const handleCloseModal = () => {
    setControlModalAddStudent(false);
    setControlModalFeedbackStudent(false);
  };

  const fetchFindStudents = async (bodyParams, queryParams) => {
    const { results, count } = await extensionService.findStudents(
      bodyParams,
      queryParams
    );
    if (results) {
      setStudentSearchResult(results);
      setCountStudentSearch(count);
    }

    return { results, count };
  };

  const handleSubmitModal = async () => {
    const data = formRef.current.getData();
    if (!data.name_or_code || data.name_or_code.trim() === '') {
      formRef.current.setErrors({
        name_or_code: 'O campo é obrigatório.',
      });
      return;
    }
    setSearchValue(data.name_or_code);
    setControlModalListing(true);
    setControlModalAddStudent(false);
  };

  const handleSubmitModalFeedback = async () => {
    const data = formRef.current.getData();
    if (data.removed_feedback && data.removed_feedback.trim() !== '') {
      setFormValues({
        ...formData,
        removed_feedback: data.removed_feedback,
      });
      setIsFeedbackSent(true);
    } else {
      formRef.current.setErrors({
        removed_feedback: 'O campo é obrigatório.',
      });
      setIsFeedbackSent(false);
      return;
    }
    setControlModalFeedbackStudent(false);
    customSnackbar('Feedback enviado com sucesso!', 'confirmation');
  };

  const handleAddStudent = (e) => {
    e?.preventDefault();
    setControlModalAddStudent(true);
  };

  const renderInput = () => {
    return (
      <ContentFormInput>
        <Form ref={formRef}>
          <SimpleInput
            name="name_or_code"
            placeholder="Digite aqui..."
            label={'Nome/Código'}
          />
        </Form>
      </ContentFormInput>
    );
  };
  const renderInputFeedback = () => {
    return (
      <ContentFormInput>
        <p className="inputFeedback">Feedback*</p>
        <Form ref={formRef}>
          <DescriptionInput
            name="removed_feedback"
            marginLeft="-80px"
            charLimit={200}
            charCount="/200"
          />
        </Form>
      </ContentFormInput>
    );
  };

  const renderStudentListing = (studentArray) => {
    if (studentArray === null) {
      return [1, 2, 3].map(() => <StudentSkeleton matchView={true} />);
    }

    if (studentArray?.results?.length === 0) {
      return (
        <GenericMessage
          resetIndex={true}
          title="Nenhum estudante encontrado."
          subtitle="No momento não existem estudantes disponíveis"
        />
      );
    }

    if (studentArray?.results?.length > 0) {
      return studentArray?.results?.map((student) => (
        <ExtensionStudent student={student} countStudents={countStudents} />
      ));
    }
  };

  return (
    <Content>
      {controlModalAddStudent && (
        <ModalInformation
          config={configAddStudent}
          closeModalFunction={handleCloseModal}
          modalOpenState={controlModalAddStudent}
          buttonConfirmFunction={handleSubmitModal}
          formRef={formRef}
        >
          {renderInput()}
        </ModalInformation>
      )}
      {controlModalListing && (
        <ModalAddStudents
          setControlModalListing={setControlModalListing}
          students={studentSearchResult}
          count={countStudentSearch}
          search={searchValue}
          setStudents={setStudentSearchResult}
          fetchFindStudents={fetchFindStudents}
          buildBodyParams={buildBodyParams}
        />
      )}
      {controlModalFeedbackStudent && (
        <ModalInformation
          config={configFeedbackStudent}
          closeModalFunction={handleCloseModal}
          modalOpenState={controlModalFeedbackStudent}
          buttonConfirmFunction={handleSubmitModalFeedback}
          formRef={formRef}
        >
          {renderInputFeedback()}
        </ModalInformation>
      )}

      <h3 className="title">{returnTitleModal()}</h3>

      <StepFourContainer>
        <p className="highlight">
          <b>Conferência de estudantes ({countStudents})</b>
        </p>
        <p className="info">
          Foram adicionados ao projeto os {countStudents} estudantes do curso de{' '}
          {coursesLabel[0]} que estão cursando a disciplina de{' '}
          <b>{disciplinesLabel[0]}</b>. Caso queira, você pode remover ou
          incluir estudantes na listagem.
        </p>
        <ContainerSearchInput>
          <SearchInput
            placeholder="Buscar por nome/código"
            onChange={(e) => setSearchStudents(e.target.value)}
          />
          <div className="wrapper-search">
            <IoSearchSharp color="#fff" size={22} />
          </div>
        </ContainerSearchInput>
        <div>
          {renderStudentListing(studentsListing)}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={countStudents}
            onPageChange={onPageChange}
            setCurrentPage={setCurrentPage}
            setLimit={setLimit}
            limit={limit}
            showLimit={false}
          />
          <div className="notFound">
            <p className="info">
              Não encontrou quem procurava? Adicione o estudante <br />
              manualmente inserindo o nome ou código
            </p>
            <a onClick={handleAddStudent}>Adicionar manualmente </a>
          </div>
        </div>
      </StepFourContainer>
    </Content>
  );
}
