import { IconWrapper, Row } from './styled';

export const InfoRow = ({
  Icon,
  text,
  universityColor,
  iconSize = 18,
  textColor,
  isBold,
  truncate = true,
}) => (
  <Row truncate={truncate}>
    <IconWrapper>
      <Icon color={universityColor} size={iconSize} />
    </IconWrapper>
    <span
      style={{
        fontWeight: isBold ? 'bold' : 'normal',
        color: textColor || 'inherit',
      }}
    >
      {text}
    </span>
  </Row>
);
