import React from 'react';
import FormCoursesAndEvents from '../Components/Forms/FormCoursesAndEvents';
import FormContent from '../Components/Forms/FormContent';

export function coursesAndEvents(modal, setModalContent, setActiveTab) {
  modal(true);
  setModalContent(<FormCoursesAndEvents setActiveTab={setActiveTab} />);
}

export function Content(modal, setModalContent, setActiveTab) {
  modal(true);
  setModalContent(<FormContent setActiveTab={setActiveTab} />);
}
