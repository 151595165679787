import React from 'react';
import ModalInformation from 'components/informationModal';
import { configCloseProject } from './configModals';

export default function ModalClose({
  closeProject,
  setCloseProject,
  handleConfirmCloseModal,
}) {
  return (
    <ModalInformation
      modalOpenState={closeProject}
      config={configCloseProject}
      buttonConfirmFunction={handleConfirmCloseModal}
      buttonCancelFunction={() => setCloseProject(false)}
      closeModalFunction={() => setCloseProject(false)}
    />
  );
}
