import React, { useContext } from 'react';
import { Container, WhiteCard } from '../styled';
import BaseLayoutContext from 'contexts/base-layout';
import InternalTab from './screens';
import { useProjectExtensionContext } from '../../context';

export const MyProjectsTab = () => {
  const { universityColor } = useContext(BaseLayoutContext);
  const { studentFirstName } = useProjectExtensionContext();

  return (
    <Container color={universityColor}>
      <div>
        <h1>Meus projetos</h1>
        <p>
          <strong>{studentFirstName}</strong>, aqui você irá encontrar todos os
          projetos nos quais você se inscreveu ou foi adicionado(a)
        </p>
      </div>
      <WhiteCard>
        <InternalTab />
      </WhiteCard>
    </Container>
  );
};
