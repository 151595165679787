import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import BaseLayoutContext from '../../../contexts/base-layout';
import { verifyDiversityData } from '../services';

export const JobOfferContext = createContext();

export default function JobOfferProvider({ children }) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const StepExclusiveIESRef = useRef(null);
  const stepOneRef = useRef(null);
  const stepTwoRef = useRef(null);
  const stepThreeRef = useRef(null);

  const ExternalJobRef = useRef(null);

  const [data, setData] = useState({
    quantity: Number(1),
    start_offer_at: currentDate,
  });

  const [companyData, setCompanyData] = useState({});
  const [jobOfferStep, setjobOfferStep] = useState(0);
  const [registerSteps, setRegisterSteps] = useState(0);
  const [externalJobOffer, setExternalJobOffer] = useState(false);
  const [optionsProfession, setOptionsProfession] = useState({});
  const [functionToLogin, setFunctionToLogin] = useState(() => {});
  const [offSet, setOffset] = useState(0);
  const [listJobs, setListJobs] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedUniversity, setSelectedUniversity] = useState([]);
  const [justForSomeUniversities, setJustForSomeUniversities] = useState(null);
  const [hasDiversityData, setHasDiversityData] = useState(true);

  const [dataFunction, setDataFunction] = useState({});

  const { openModalWithContent, metadata } = useContext(BaseLayoutContext);

  const [cacheContext, setCacheContext] = useState([]);
  const [currentJobFilter, setCurrentJobFilter] = useState(null);
  const [zIndex, setZIndex] = useState(1164);

  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(
    false
  );

  const [
    showInformationModalMyProfile,
    setShowInformationModalMyProfile,
  ] = useState(false);

  const resetAllData = () => {
    setData({ quantity: Number(1), start_offer_at: new Date() });
    setjobOfferStep(0);
    setExternalJobOffer(false);
    setRegisterSteps(0);
    setOptionsProfession({});
    setSelectedUniversity(null);
    setJustForSomeUniversities(null);

    stepOneRef.current ? stepOneRef.current.reset() : null;
    stepTwoRef.current ? stepTwoRef.current.reset() : null;
    stepThreeRef.current ? stepThreeRef.current.reset() : null;
  };

  useEffect(() => {
    if (justForSomeUniversities === false) {
      setSelectedUniversity(null);
      setData({ ...data, enabled_just_for_universities: null });
    }
  }, [justForSomeUniversities]);

  useEffect(() => {
    if (selectedUniversity) verifyIeDiversityData();
  }, [selectedUniversity]);

  async function verifyIeDiversityData() {
    const hasDiversity = await verifyDiversityData(selectedUniversity);
    setHasDiversityData(hasDiversity);
  }

  const NextStepRegister = () => {
    setRegisterSteps((prevsStep) => prevsStep + 1);
  };

  const prevsStepRegister = () => {
    setRegisterSteps((prevsStep) => prevsStep - 1);
  };

  const nextJobOfferStep = () => {
    setjobOfferStep((prevsStep) => prevsStep + 1);
  };

  const prevsJobOfferStep = () => {
    setjobOfferStep((prevsStep) => prevsStep - 1);
  };

  const setDataFunctions = (values) => {
    setDataFunction((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  const setFormValues = (values) => {
    setData((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  const handleTabChange = (event, value) => {
    setActiveTab(value);
    setAppliedFilters({});
    setFilterState({});
    setCurrentJobFilter(null);
  };

  const setCompanyValues = (values) => {
    setCompanyData((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  return (
    <JobOfferContext.Provider
      value={{
        setFormValues,
        data,
        jobOfferStep,
        setjobOfferStep,
        registerSteps,
        setRegisterSteps,
        NextStepRegister,
        nextJobOfferStep,
        externalJobOffer,
        setExternalJobOffer,
        prevsStepRegister,
        setCompanyValues,
        functionToLogin,
        setFunctionToLogin,
        companyData,
        prevsJobOfferStep,
        cacheContext,
        setCacheContext,
        openModalWithContent,
        setData,
        resetAllData,
        stepOneRef,
        stepTwoRef,
        stepThreeRef,
        StepExclusiveIESRef,
        optionsProfession,
        setOptionsProfession,
        ExternalJobRef,
        setDataFunctions,
        dataFunction,
        setOffset,
        offSet,
        currentJobFilter,
        setCurrentJobFilter,
        zIndex,
        setZIndex,
        filterState,
        setFilterState,
        appliedFilters,
        setAppliedFilters,
        listJobs,
        setListJobs,
        activeTab,
        setActiveTab,
        handleTabChange,
        metadata,
        showCompleteProfileModal,
        setShowCompleteProfileModal,
        showInformationModalMyProfile,
        setShowInformationModalMyProfile,
        selectedUniversity,
        setSelectedUniversity,
        justForSomeUniversities,
        setJustForSomeUniversities,
        hasDiversityData,
      }}
    >
      {children}
    </JobOfferContext.Provider>
  );
}
