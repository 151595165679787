import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const HelpText = styled.span`
  font-size: 16px;
  color: #606062;
  text-align: start;
`;

export const Question = styled(HelpText)`
  padding-top: 36px;
  font-weight: 700;
  font-size: 18px;

  span {
    font-weight: 400;
  }
`;

export const HelpTextQuestion = styled(HelpText)`
  font-size: 16px;
  max-width: unset;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #009291;
  align-items: start;
  display: flex;
`;

export const TextArea = styled.textarea`
  width: 100%;
  max-width: 732px;
  height: 120px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;

  font-size: 13px;
  font-family: 'Roboto', sans-serif;

  padding: 10px;

  ::-webkit-input-placeholder {
    color: #b8b8b8;
  }
  input:-moz-placeholder {
    color: #b8b8b8;
  }
  textarea:-moz-placeholder {
    color: #b8b8b8;
  }
`;

export const ViewEvidence = styled.button`
  width: auto;
  height: 34px;

  background-color: #fff;
  border: 1px solid #009291;
  border-radius: 3px;
  cursor: pointer;

  color: #009291;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  padding: 8px 10px;

  margin-top: 12px;
`;

export const AddEvidence = styled.button`
  width: auto;
  height: 34px;

  background-color: #009291;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  padding: 8px 10px;

  margin-top: 12px;
`;

export const ContentButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;
