import React, { useContext, useState } from 'react';
import {
  CancelSubscriptionButton,
  Container,
  Divider,
  FillReportButton,
  Flag,
  Footer,
  GridList,
  GridSide,
  Title,
  WrapperInformations,
} from './styled';
import BaseLayoutContext from 'contexts/base-layout';
import GenericModal from 'views/Student/Mentorship/components/GenericModal';
import { RiGraduationCapLine } from 'react-icons/ri';
import InfoRow from './InfoRow';
import moment from 'moment';
import { getAddressDetails } from '../../utils/addressAdapter';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { TbClockHour4 } from 'react-icons/tb';
import { FiUser } from 'react-icons/fi';
import { GrLocation, GrTarget } from 'react-icons/gr';
import { RxLaptop } from 'react-icons/rx';
import { InfoRowWithLink } from './InfoRowWithLink';
import { useProjectExtensionContext } from '../../context';
import { InfoRowStringList } from './InfoRowStringList';
import ModalInformation from 'components/informationModal';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { unsubscribeProject } from 'views/Student/Extracurricular/services';

export function DetailsModal({
  item,
  title,
  onClose,
  fetchEnrolledProjects,
  isEnrolledTab,
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const {
    detailsModalOpen,
    setDetailsModalOpen,
    setSupportMaterialModalOpen,
    setFinalReportOpen,
    activeInternalTab,
    fetchTabsCount,
  } = useProjectExtensionContext();
  const [unsubscribeModalOpen, setUnsubscribeModalOpen] = useState(false);
  const isProjectStarted = (startAt) => {
    const projectStartDate = new Date(startAt);
    const currentDate = new Date();
    return projectStartDate <= currentDate;
  };
  const projectHasStarted = isProjectStarted(item?.start_at);

  const handleUnsubscribe = async () => {
    try {
      await unsubscribeProject(item.project_id);
      setDetailsModalOpen(false);
      setUnsubscribeModalOpen(false);
      fetchEnrolledProjects();
      fetchTabsCount();
      customSnackbar('Inscrição cancelada com sucesso!', 'confirmation');
    } catch (error) {
      customSnackbar('Erro ao cancelar a inscrição.', 'error');
    }
  };

  const configModal = {
    bubbleText: 'Atenção!',
    modalWidth: 370,
    modalHeight: 384,
    ParagraphText: `Tem certeza que deseja cancelar a sua inscrição no projeto de extensão?`,
    buttonConfirmText: 'NÃO CANCELAR',
    buttonCancelText: 'SIM, CANCELAR INSCRIÇÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px !important',
      marginButtons: '-36px',
      marginTop: '38px',
    },
  };

  const isNextActivitiesTab = activeInternalTab === 0;

  const isExtraCurricularExtension = () =>
    item?.extension_kind === 'extracurricular';

  const startFormattedDate = moment(item?.start_at).format('DD/MM/YY');
  const endFormattedDate = moment(item?.end_at).format('DD/MM/YY');
  const workloadFormatted = item?.workload
    ? item.workload.replace('.00', 'h')
    : 'Não informado';
  const projectAddress = getAddressDetails(item) || 'Não informado';

  const handleOpenSupportMaterial = () => {
    setSupportMaterialModalOpen(true);
  };

  function handleCancelSubscription() {
    setUnsubscribeModalOpen(true);
  }

  function handleFillReport() {
    setFinalReportOpen(true);
  }

  return (
    <GenericModal
      isOpen={detailsModalOpen}
      onClose={onClose}
      title={title}
      maxWidthToMarginLeft={'1024px'}
    >
      <Container>
        <Flag color={universityColor}>
          {item?.extension_kind === 'curricular'
            ? 'EXTENSÃO CURRICULAR'
            : 'EXTENSÃO EXTRACURRICULAR'}
        </Flag>
        <Title>
          <h2>{item?.name}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: item?.description || '',
            }}
          />
        </Title>
        <WrapperInformations>
          <GridSide style={{ marginTop: '30px' }}>
            <InfoRow
              icon={<FaRegCalendarAlt size="40px" color={universityColor} />}
              label="Data"
              value={`${startFormattedDate} a ${endFormattedDate}`}
            />
            <InfoRow
              icon={<FiUser size="40px" color={universityColor} />}
              label="Professor responsável"
              value={item?.responsible_teacher || 'Não informado'}
            />
            <InfoRow
              icon={<TbClockHour4 size="40px" color={universityColor} />}
              label="Carga horária"
              value={workloadFormatted}
            />
            {!isExtraCurricularExtension && (
              <InfoRow
                icon={
                  <RiGraduationCapLine size="40px" color={universityColor} />
                }
                label="Disciplina do projeto"
                value={item?.discipline || 'Não informado'}
              />
            )}
            {item?.has_support_materials && (
              <InfoRowWithLink
                icon={<RxLaptop size="40px" color={universityColor} />}
                label="Material de apoio"
                value="Clique para ver"
                color={universityColor}
                onClick={handleOpenSupportMaterial}
              />
            )}
          </GridSide>

          <GridList style={{ marginTop: '5px' }}>
            <InfoRow
              icon={<GrLocation size="40px" color={universityColor} />}
              label="Local do projeto"
              value={projectAddress}
            />

            <InfoRowStringList
              icon={<GrTarget size="40px" color={universityColor} />}
              label="Objetivos de Desenvolvimento Sustentável:"
              values={item?.development_objective}
            />
          </GridList>
        </WrapperInformations>
        <Divider />
        {isEnrolledTab && (
          <Footer>
            {item?.extension_kind === 'extracurricular' && (
              <CancelSubscriptionButton
                color={universityColor}
                onClick={handleCancelSubscription}
              >
                Cancelar inscrição
              </CancelSubscriptionButton>
            )}
            {!item?.report_sent && projectHasStarted && (
              <FillReportButton
                color={universityColor}
                onClick={handleFillReport}
              >
                Preencher relatório
              </FillReportButton>
            )}
          </Footer>
        )}
      </Container>
      <ModalInformation
        modalOpenState={unsubscribeModalOpen}
        buttonConfirmFunction={() => setUnsubscribeModalOpen(false)}
        buttonCancelFunction={handleUnsubscribe}
        config={configModal}
        universityColor={universityColor}
      />
    </GenericModal>
  );
}

export default DetailsModal;
