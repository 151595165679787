import React, { useContext, useEffect, useState } from 'react';
import StepIndicator from 'components/StepIndicator';
import { CreateExtensionContext } from '../../CreateExtensionContext';

import { Header as HeaderContainer } from './style';
export default function Header({ isEdit }) {
  const { currentStep, toStep, kind, validateSteps } = useContext(
    CreateExtensionContext
  );

  return (
    <HeaderContainer>
      <StepIndicator
        count={kind === 'curricular' ? 5 : 4}
        currentStep={currentStep}
        toStepFunction={toStep}
        validation={validateSteps}
        clarifyPastSteps
        disabled={isEdit}
      />
    </HeaderContainer>
  );
}
