import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Container,
  DropContent,
  DropOverlayContent,
  DropRow,
  ErrorMessage,
  IconCircularWrapper,
} from './styled';
import { useField } from '@unform/core';
import { TbUpload } from 'react-icons/tb';
import BaseLayoutContext from 'contexts/base-layout';
import { UploadType } from '../../enums/upload_type';

export function FilesInput({
  name,
  formRef,
  fileSizeMBLimit,
  onChange,
  defaultValue,
  filesLimit,
  type,
  ...rest
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const componentRef = useRef(null);
  const inputRef = useRef(null);
  let [dragOverlay, setDragOverlay] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    defaultValue ? defaultValue : null
  );
  const [multipleFileError, setMultipleFileError] = useState(false);
  const { fieldName, registerField, error } = useField(name);

  const allowedFileFormats =
    type === UploadType.DOCUMENT
      ? ['application/pdf']
      : ['image/jpeg', 'image/png', 'image/jpg'];

  const handleDrag = (e) => {
    preventBrowserDefaults(e);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: componentRef.current,
      getValue: () => {
        return selectedFile;
      },
      setValue: (ref, value) => setSelectedFile(value),
      clearValue: () => setSelectedFile(null),
    });
  }, [fieldName, registerField, selectedFile]);

  useEffect(() => {
    onChange && onChange(selectedFile, type);
  }, [selectedFile]);

  const preventBrowserDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    preventBrowserDefaults(e);
    const mouseIsOver = componentRef.current.contains(e.relatedTarget);
    const fileAmount = e.dataTransfer.items.length;
    if (fileAmount > 0 && mouseIsOver) {
      setDragOverlay(true);
      if (fileAmount > 1) {
        setMultipleFileError(true);
      } else {
        setMultipleFileError(false);
      }
    }
  };

  const handleDragOut = (e) => {
    preventBrowserDefaults(e);
    if (!componentRef.current.contains(e.relatedTarget)) {
      setMultipleFileError(false);
      setDragOverlay(false);
    }
  };

  const validateFiles = async ({
    files,
    allowedFileFormats,
    fileSizeMBLimit,
    filesLimit,
  }) => {
    const { length } = files;
    let result = {
      isValid: true,
      err: null,
    };

    function validateFile(file) {
      const { size, type, name } = file;
      if (!allowedFileFormats.includes(type)) {
        return 'Formato de arquivo não permitido.';
      } else if (size / 1024 / 1024 > fileSizeMBLimit) {
        return `São permitidos arquivos de no máximo ${fileSizeMBLimit}MB.`;
      } else if (name.length > 100) {
        return 'O nome do arquivo não pode superar 100 caracteres.';
      }
    }

    if (length === 0) {
      return result;
    } else if (filesLimit != null && length > filesLimit) {
      result.err = `É permitido o carregamento de apenas ${filesLimit} arquivo${
        filesLimit > 1 ? 's' : ''
      }.`;
      result.isValid = false;
    } else {
      files.forEach((file) => {
        const err = validateFile(file);
        if (err) {
          result.err = err;
          result.isValid = false;
          return result;
        }
      });
    }
    return result;
  };

  const handleDrop = async (e, dropped = false) => {
    preventBrowserDefaults(e);
    setMultipleFileError(false);
    let newFiles;
    if (dropped) {
      newFiles = e.dataTransfer.files;
    } else {
      newFiles = e.target.files;
    }
    setDragOverlay(false);
    const newErrors = formRef.current.getErrors();
    delete newErrors[name];
    formRef.current.setErrors(newErrors);

    const { err } = await validateFiles({
      files: [...newFiles],
      allowedFileFormats,
      fileSizeMBLimit,
      filesLimit,
    });
    if (err) {
      const newErrors = formRef.current.getErrors();
      newErrors[name] = err;
      return false;
    } else {
      onChange && onChange(newFiles[0], type);
    }
  };

  const acceptExtensions =
    type === UploadType.DOCUMENT ? '.pdf' : '.jpg,.jpeg,.png';
  const acceptExtensionDescription =
    type === UploadType.DOCUMENT ? 'PDF' : 'JPG, JPEG ou PNG';

  return (
    <Container
      ref={componentRef}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={(e) => {
        handleDrop(e, true);
      }}
      {...rest}
    >
      <input
        ref={inputRef}
        type="file"
        name="evidence"
        id="upload"
        accept={acceptExtensions}
        onChange={handleDrop}
      />
      <DropContent
        className={`${dragOverlay && 'drop-overlay'}
           ${error || (multipleFileError && 'invalid-dropped-file')}`}
        onClick={() => inputRef.current.click()}
      >
        {dragOverlay && (
          <DropOverlayContent>
            <p>
              {multipleFileError
                ? 'Apenas um arquivo é permitido'
                : 'Solte a imagem para adicionar'}
            </p>
          </DropOverlayContent>
        )}
        {!dragOverlay && (
          <DropRow>
            <IconCircularWrapper color={universityColor}>
              <TbUpload color="white" size={34}></TbUpload>
            </IconCircularWrapper>
            <p>
              <strong>Clique aqui</strong> para selecionar um arquivo ou{' '}
              <strong>arraste-o</strong> para esta área. (Apenas arquivos{' '}
              <strong>{acceptExtensionDescription}</strong> serão aceitos.)
            </p>
            <span>Tamanho suportado: 5MB</span>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </DropRow>
        )}
      </DropContent>
    </Container>
  );
}
