import React, { Fragment } from 'react';
import {
  StepItem,
  StepNumber,
  StepperContainer,
  StepSeparator,
  StepTitle,
} from './styled';

export default function StepIndicatorWithText({
  count,
  currentStep,
  onStepChange,
  titles,
  toStepFunction = null,
  ...rest
}) {
  return (
    <StepperContainer>
      {Array.from({ length: count }, (_, index) => (
        <Fragment key={index}>
          <StepItem>
            <StepNumber
              active={index + 1 === currentStep}
              completed={index + 1 < currentStep}
            >
              {index + 1}
            </StepNumber>
            <StepTitle
              active={index + 1 === currentStep}
              completed={index + 1 < currentStep}
            >
              {titles[index]}
            </StepTitle>
          </StepItem>
          {index < count - 1 && <StepSeparator />}
        </Fragment>
      ))}
    </StepperContainer>
  );
}
