import * as Yup from 'yup';

export const schemaValidationEmail = Yup.object().shape({
  email: Yup.string()
    .email('O e-mail deve ser válido')
    .required('Insira um e-mail válido'),
});

export const schemaValidationFeedbackStudent = Yup.object().shape({
  feedback: Yup.string().required('O Feedback é obrigatório'),
});
