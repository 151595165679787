import * as Yup from 'yup';
import { existProfession } from '../../../../../../services/backoffice/professions';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schemaValidationStepOne = Yup.object()
  .shape({
    name: Yup.string().required('Campo obrigatório'),
    profession: Yup.string().required('Campo obrigatório'),
  })
  .when((values, schema) => {
    if (values.id === 'new' && values.profession) {
      return schema.shape({
        profession: Yup.string().test(
          'professionUnique',
          'Esta atuação já existe',
          async function validate(value) {
            const response = await existProfession(value);
            return !response.exists;
          }
        ),
      });
    }
    return schema;
  });

export const schemaValidationStepTwoWithUF = Yup.object().shape({
  application_type: Yup.string().ensure().required('Campo obrigatório'),
  quantity: Yup.number()
    .typeError('É necessário informar um número valido')
    .min(1, 'A quantidade minima deve ser superior ou igual a 1')
    .required('Campo obrigatório'),
  modality_type: Yup.string().ensure().required('Campo obrigatório'),
  zip_code: Yup.string()
    .ensure()
    .required('Campo obrigatório')
    .test('is-not-empty-or-whitespace', 'Este campo é obrigatório', (value) => {
      return value?.trim() !== '';
    }),
  
});

export const schemaValidationStepTwo = Yup.object().shape({
  application_type: Yup.string().ensure().required('Campo obrigatório'),
  shift: Yup.string().ensure().required('Campo obrigatório'),
  quantity: Yup.number()
    .typeError('É necessário informar um número valido')
    .min(1, 'A quantidade minima deve ser superior ou igual a 1')
    .required('Campo obrigatório'),
  modality_type: Yup.string().ensure().required('Campo obrigatório'),
});

export const schemaValidationStepThree = Yup.object()
  .shape({
    start_offer_at: Yup.date()
      .typeError('Informe a data de início')
      .required('Campo obrigatório'),
    end_offer_at: Yup.date()
      .required('Campo obrigatório')
      .typeError('Informe a data de término')
      .min(
        Yup.ref('start_offer_at'),
        'A data final precisa ser maior do que a data inicial'
      ),
    contact_email: Yup.string().email('Informe um e-mail válido'),
  })
  .when((values, schema) => {
    if (values.administrative_selection_process) {
      const validateAffirmativeProcess =
        values.disability.length !== 0 ||
        values.color_or_race.length !== 0 ||
        values.gender_identity.length !== 0;
      return schema.shape({
        gender_identity: Yup.string().test(
          'has_at_least_one_affirmative_process',
          'Para seguir em frente, selecione pelo menos um tipo de inclusão',
          (_) => {
            return validateAffirmativeProcess;
          }
        ),
      });
    }
    return schema;
  });
