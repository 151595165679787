import React, { useContext, useRef, useState, useEffect } from 'react';
import GenericModalComponent from './components/GenericComponent';
import { Form } from '@unform/web';
import {
  ButtonUpload,
  ContentButton,
  UploadWrapper,
} from './styles/BaseComponent';
import LinkInput from 'views/Student/ExtensionProject/components/LinkInput';
import { UploadType } from 'views/Student/ExtensionProject/enums/upload_type';
import { FieldLabelLarge } from 'views/Student/ExtensionProject/components/FinalReportModal/styled';
import { ImageViewer } from 'views/Student/ExtensionProject/components/ImageViewer';
import {
  actionsGenericUpload,
  useGenericUpload,
} from 'components/GenericModalUpload/store/genericUpload.store';
import { ListFile } from './components/Listing/ListFile';
import { FilesInput } from 'views/Student/ExtensionProject/components/FilesInput';
import { SelectField } from 'views/StaffPages/Mentorship/components/SelectField';
import { ListLinks } from './components/Listing/ListLinks';
import { typeOptions } from './utils/resource';
import { useId } from 'react';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export default function GenericModalUpload({
  onSave,
  removeItem,
  onRefresh,
  fullSizeModal = false,
  setOpenModalUpload,
  limit,
}) {
  const [formData, setFormData] = useState({
    type: '',
    file: null,
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const idUpload = useId();
  const { updateListing } = actionsGenericUpload;
  const formRef = useRef();
  const { listingItems } = useGenericUpload((state) => state.listingItems);

  const handleChange = (selectedOption) => {
    setFormData({
      ...formData,
      type: selectedOption.value,
      uploadedContent: null,
    });
  };

  const closeModal = () => {
    setOpenModalUpload(false);
  };

  async function handleSubmit() {
    const response = await onSave(formData);

    if (response) {
      const dataResponseUpdate = {
        file: response.data.file,
        id: response.data.id,
        type: response.data.type,
        image: response.data.image,
        link: response.data.link,
      };
      updateListing(dataResponseUpdate);
    } else {
      const fileExtension = formData?.uploadedContent?.name?.split('.').pop();
      let dataUpdate;

      if (formData.type === 'link') {
        dataUpdate = {
          id: idUpload,
          type: formData.type,
          link: formData.uploadedContent,
        };
      } else {
        dataUpdate = {
          file: formData.uploadedContent,
          id: idUpload,
          type: formData.type,
          suffix: fileExtension,
        };
      }
      updateListing(dataUpdate);
      closeModal();
    }

    if (response) {
      closeModal();
      onRefresh && onRefresh();
    }
  }

  const hasReachedLimit = (typeMaterial) => {
    const countByType = listingItems?.filter(
      ({ type }) => type === typeMaterial
    ).length;
    return countByType >= (limit[typeMaterial] || 1);
  };

  const handleAddMaterial = (material, type) => {
    if (hasReachedLimit(type)) {
      customSnackbar(
        'Limite máximo para este tipo de material atingido.',
        'error'
      );
      return;
    }
    setFormData({
      type,
      uploadedContent: material,
    });
  };

  const renderTypeInput = () => {
    if (formData?.type && !formData.uploadedContent) {
      if (formData.type === UploadType.LINK) {
        return <LinkInput name={'url'} onAddEvidence={handleAddMaterial} />;
      }

      if (
        formData.type === UploadType.DOCUMENT ||
        formData.type === UploadType.IMAGE
      ) {
        return (
          <FilesInput
            name={'file'}
            formRef={formRef}
            fileSizeMBLimit={5}
            type={formData?.type}
            filesLimit={1}
            onChange={handleAddMaterial}
          />
        );
      }
    }
  };

  const removeListing = (file) => {
    setFormData({
      type: '',
      uploadedContent: [],
    });
    formRef.current.setFieldValue('type', '');
  };

  const renderTypeListing = () => {
    if (formData.uploadedContent) {
      if (formData.type === UploadType.LINK) {
        return (
          <ListLinks
            link={formData.uploadedContent}
            removeItem={removeListing}
          />
        );
      }

      if (
        formData.type === UploadType.DOCUMENT ||
        formData.type === UploadType.IMAGE
      ) {
        return (
          <ListFile
            file={formData}
            setSelectedImage={setSelectedImage}
            removeItem={removeListing}
          />
        );
      }
    }
  };

  return (
    <GenericModalComponent
      fullSize={fullSizeModal}
      closeModal={closeModal}
      title="Adicionar documentos"
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FieldLabelLarge>
          Selecione o tipo de material de apoio*
        </FieldLabelLarge>
        <SelectField
          name={'type'}
          placeholder={'Selecione'}
          options={typeOptions}
          onChange={handleChange}
        />
        {renderTypeInput()}
        <UploadWrapper>{renderTypeListing()}</UploadWrapper>
        {formData?.type && (
          <ContentButton>
            <ButtonUpload type="submit" disabled={!formData.uploadedContent}>
              Salvar
            </ButtonUpload>
          </ContentButton>
        )}
      </Form>
      <ImageViewer
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
        widthModal="100%"
      />
    </GenericModalComponent>
  );
}
