import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import {
  createExtensionProject,
  updateExtensionProject,
} from 'views/StaffPages/ExtensionProject/services';

export function buildQueryParams(params) {
  if (params) {
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([, value]) => value !== null && value !== undefined && value !== ''
      )
    );
    const queryString = new URLSearchParams(filteredParams).toString();

    return queryString && `?${queryString}`;
  }
}

export const returnImgFromb64 = async (image, setBlobValue, setImageValue) => {
  const byteCharacters = atob(image.value);
  const byteNumbers = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const blob = new Blob([byteNumbers], { type: 'image/png' });
  const file = new File([blob], `${image.label}.png`, {
    type: 'image/png',
  });

  const imageUrl = URL.createObjectURL(blob);

  setBlobValue(imageUrl);
  setImageValue(file);
};

export const handleEditProject = async (data, projectId) => {
  try {
    return await updateExtensionProject(data, projectId);
  } catch (error) {
    customSnackbar('Erro ao editar o projeto.', 'error');
    return null;
  }
};

export const handleCreateProject = async (data) => {
  try {
    return await createExtensionProject(data);
  } catch (error) {
    customSnackbar('Erro ao criar o projeto.', 'error');
    return null;
  }
};


export const strReduced = (str) => {
  if (str.length > 20) {
    return `${str.substr(0, 20)}...`;
  }
  return str;
};