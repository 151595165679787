import styled from 'styled-components';
import { positions, colorSchemas } from './utils';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: fit-content;

  :hover {
    span {
      visibility: visible;
      opacity: 1;
      color: ${(props) => props.textColor};
    }
  }
`;

export const Span = styled.span`
  visibility: hidden;
  opacity: 0;

  position: absolute;
  padding: 8px;
  font-size: 14px !important;
  line-height: 1.5;
  border-radius: 4px;
  font-weight: 500;
  ${(props) =>
    !props.isMultiLine ? 'white-space: nowrap;' : 'white-space: normal;'}
  max-width: ${(props) => props.maxWidth} !important;
  min-width: ${(props) => props.minWidth} !important;
  z-index: 2000;
  text-align: ${(props) => props.textAlign};

  ${(props) => positions[props.direction]};
  ${(props) => colorSchemas[props.colorSchema].span};

  @media screen and (max-width: 640px) {
    max-width: 90vw;
    min-width: 70vw;
    width: auto;
    text-wrap: pretty;
    margin-left: 2vw;
  }
`;
