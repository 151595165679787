import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;

  position: fixed;
  left: 0;
  top: 0;

  z-index: 1300;

  flex-direction: column;
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 33px;
  top: 33px;

  cursor: pointer;
  background-color: #efeeec;
  border: none;
`;

export const HeaderModal = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #f8fafc;
  margin-top: 82px;
  margin-bottom: 12px;

  width: 80%;
  height: fit-content;

  padding: 17px 21px;

  p {
    font-size: 16px;
    color: #949494;
    font-weight: 400;
    margin: 0;
    padding: 3px 0px;
  }

  h3 {
    font-size: 22px;
    font-weight: 700;
    color: #606062;
    margin: 0;
  }
`;

export const WrapperStudents = styled.div`
  width: 80%;
  padding-bottom: 40px;
`