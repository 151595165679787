import styled, { keyframes } from 'styled-components';
import { Menu as MenuInner } from '@szhsin/react-menu';
import {
  menuSelector,
  menuItemSelector,
  menuDividerSelector,
} from '@szhsin/react-menu/style-utils';
import rightArrow from './chevron-right-solid.svg';

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;
const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

export const Menu = styled(MenuInner)`
  ${menuSelector.name} {
    box-sizing: border-box;
    z-index: 100;
    list-style: none;
    user-select: none;
    color: #606062;
    font-size: 0.925rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background-color: #fff;
    min-width: ${(props) => (props.MinWidth ? props.MinWidth : '10rem')};
    margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0')};
  }

  ${menuSelector.name}:focus,${menuItemSelector.name}:focus {
    outline: none;
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuItemSelector.name} {
    cursor: pointer;
    border-radius: 6px;
    padding: 0.375rem 0.625rem;
  }

  ${menuItemSelector.hover} {
    background-color: #0092912e;
  }

  ${menuItemSelector.disabled} {
    cursor: default;
    color: #aaa;
  }

  ${menuItemSelector.submenu} {
    position: relative;
    &::after {
      content: url(${rightArrow});
      position: absolute;
      width: 7px;
      right: 0.625rem;
    }
  }

  ${menuDividerSelector.name} {
    height: 1px;
    margin: 0.5rem 0.625rem;
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

export const Button = styled.button`
  width: ${(props) => (props.buttonWidth ? props.buttonWidth : '100%')};
  height: ${(props) => (props.buttonHeight ? props.buttonHeight : '29px')};
  border: none;
  background-color: ${(props) => (props.transparentButton ? 'transparent' : '#009291')};
  color: ${(props) => (props.transparentButton ? '#606062' : '#fff')};
  border: ${(props) => (props.transparentButton ? '1px solid #C4C4C4' : 'none')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px;')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
