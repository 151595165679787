export function getAddressDetails(item) {
  const { street, number, complement, district, city, state, zip_code } =
    item?.address || {};

  const addressDetails = [
    street,
    number,
    complement,
    district,
    city,
    state,
    zip_code,
  ];

  return addressDetails.filter((detail) => detail).join(', ');
}
