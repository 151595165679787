import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem 2rem 2rem;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

export const ModalDescription = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #606062;
  padding: 2rem 0 1rem 0;

  > b {
    font-weight: 700;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 40px;

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;

const backgroundColor = (color) => {
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, 0.1)`;
};

export const MaterialTile = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 2rem;
  background-color: ${({ color }) => backgroundColor(color)};
  border-radius: 3px;
  gap: 2rem;
  align-items: center;

  @media (max-width: 600px) {
    gap: 1rem;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 auto 0 0;

  > p,
  span,
  a {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: fit-content;
  }

  a {
    color: ${({ color }) => color || '#009291'};
    text-decoration: underline;
  }

  > p {
    color: #606062;
    font-weight: 500;
  }

  > span {
    color: #949494;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

export const MaterialListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0;
  margin-left: auto;
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent !important;
  border: none !important;
`;
