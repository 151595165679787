import { CloseButton, ImageWrapper, Overlay } from './styled';
import { IconCloseModal } from 'assets/img/iconsCurriculum/iconCloseModal';
import React, { useRef } from 'react';

export const ImageViewer = ({ image, onClose, widthModal }) => {
  const imageRef = useRef(null);
  const imageUrl = image
    ? typeof image === 'string'
      ? image
      : URL.createObjectURL(image)
    : null;


  const handleOverlayClick = (e) => {
    if (imageRef.current && !imageRef.current.contains(e.target)) {
      onClose();
    }
  };

  return (
    <Overlay show={!!image} onClick={handleOverlayClick} widthModal={widthModal}>
      <ImageWrapper>
        <CloseButton onClick={onClose}>
          <IconCloseModal color={'#4B4B4D'} />
        </CloseButton>
        <img ref={imageRef} src={imageUrl} alt="Imagem" />
      </ImageWrapper>
    </Overlay>
  );
};
