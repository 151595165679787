import { useContext, useState, useEffect } from 'react';
import { EnrolledStudentsList } from '../../../ModalTrackEnrollments/components/EnrolledStudentsList';
import { Container, ContentFilters } from './styled';
import ButtonWithMenu from 'components/ModalShowEvidence/ButtonWithMenu';
import { GenericInputSearch } from 'components/GenericInputSearch';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import { getReports } from 'views/StaffPages/ExtensionProject/services';
import { useDebounce } from 'hooks/useDebounce';
import { PaginationContainer } from '../../../ModalTrackEnrollments/styled';
import Pagination from 'components/CustomPagination';
export function StepTwo({ setHasRegister, setAllStudentsEvaluated }) {
  const [limitPerPage, setLimitPerPage] = useState(20);
  const [students, setStudents] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [studentCount, setStudentCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [ordering, setOrdering] = useState('');
  const {
    currentPagePagination,
    setCurrentPagePagination,
    extensionProjectId,
  } = useContext(ExtensionProjectsContext);

  const debouncedSearch = useDebounce(searchValue, 500);

  useEffect(() => {
    if (students.length > 0) {
      setHasRegister(true);
    }
  }, [students]);

  async function getStudents(page = 1) {
    setRequestInProgress(true);
    const offset = (page - 1) * limitPerPage;
    const data = await getReports(
      extensionProjectId,
      limitPerPage,
      offset,
      ordering,
      searchValue
    );

    const { results, count } = data;
    setStudentCount(count);
    setStudents(results);
    setRequestInProgress(false);
  }

  async function changePage(page) {
    setStudents([]);
    setCurrentPagePagination(() => page);
    await getStudents(page);
  }

  const menusButtons = [
    {
      name: 'Avaliação pendente',
      onChange: () => setOrdering('pending'),
    },
    {
      name: 'Estudantes aprovados',
      onChange: () => setOrdering('approved'),
    },
    {
      name: 'Estudantes reprovados',
      onChange: () => setOrdering('disapproved'),
    },
    {
      name: 'Nome (A-Z)',
      onChange: () => setOrdering('student__name'),
    },
    {
      name: 'Nome (Z-A)',
      onChange: () => setOrdering('-student__name'),
    },
  ];

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (debouncedSearch !== null) {
      getStudents({
        limitPerPage,
        offset: (page - 1) * limitPerPage,
        ordering,
        searchValue: debouncedSearch,
      });
    }
  }, [debouncedSearch, ordering, page, limitPerPage]);

  return (
    <Container>
      <h1>Avaliar estudantes</h1>
      <p>
        Agora, avalie os estudantes inscritos que preencheram o relatório de
        finalização do projeto (se aplicável), aprovando ou reprovando conforme
        necessário.
      </p>

      <ContentFilters>
        <GenericInputSearch
          placeholder="Buscar por nome ou código"
          value={searchValue}
          onChange={handleSearchChange}
        />
        <ButtonWithMenu
          buttonHeight="50px"
          buttonWidth="125px"
          insideMenus={menusButtons}
          menuLabel="Ordenar por"
          className="custom-width"
          MinWidth={'6rem'}
          transparentButton={true}
        />
      </ContentFilters>

      <EnrolledStudentsList
        ordering={ordering}
        status={true}
        limitPerPage={limitPerPage}
        page={page}
        getStudents={getStudents}
        students={students}
        requestInProgress={requestInProgress}
        showStatus={false}
        finalization={true}
        search={debouncedSearch}
        setAllStudentsEvaluated={setAllStudentsEvaluated}
      />

      <PaginationContainer>
        <Pagination
          className="pagination-bar"
          currentPage={currentPagePagination}
          totalCount={studentCount}
          onPageChange={changePage}
          limit={limitPerPage}
          setLimit={setLimitPerPage}
          setCurrentPage={setCurrentPagePagination}
          showLimit={false}
        />
      </PaginationContainer>
    </Container>
  );
}
