import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import { Form } from '@unform/web';
import {
  Container,
  ActionButtons,
  GridSide,
  WrapperInformations,
  Divider,
  StudentNotes,
  WrapperLink,
  WrapperForm,
  RatingContainer,
  StarIcon,
  EditButton,
  TypeLabel,
  GridModality,
  RejectButton,
  RescheduleButton,
  ConfirmButton,
  ChangeButton,
  FileTile,
  LabelContent,
  ActionsWrapper,
  ActionButton,
} from './styled';
import GenericModal from '../GenericModal';
import AuthContext from 'contexts/authentication';
import { getAddressDetails } from '../../utils/addressAdapter';
import StatusFlag from '../../utils/StatusFlag';
import DetailsItem from './detailsItem';
import { StudentDocuments } from '../StudentDocuments';
import { mentorshipApi } from '../../services/api';
import { formatDateTime } from 'utils/formatDateTime';
import Icons from '../../utils/mentorshipIcons';
import DescriptionInput from '../DescriptionInput';
import * as Yup from 'yup';
import { schema } from './schema';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import CepAddressInput from 'components/CepAddressInput';
import Input from 'components/UnformFields/inputField';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import PDFIcon from 'assets/ComponentIcons/PDFIcon';
import eye from 'assets/JobOffer/eye.svg';
import DisplayPdf from 'components/DisplayDocument/DisplayDocument';
import BaseLayoutContext from 'contexts/base-layout';

const DetailsModal = ({
  item,
  onClose,
  showMentorName,
  showmodality,
  showmodalityInfo,
  showStudentAssessment,
  showReasonCancellation,
  showTypeLabel,
  showDescription,
  showEditStudentNotes,
  openMentoringDoneModal,
  title,
  isOpen,
  studentName,
  openCancelModal,
  openRescheduleModal,
  isActiveExpiredTab,
  showCountDays,
  showStudentNotes,
  showAccessForm,
  showRejectButton,
  showChangeButton,
  showFullDateFormat,
  showSimpleDateFormat,
  fetchMentorships,
  setPage,
}) => {
  const formRef = useRef();
  const [studentProfile, setStudentProfile] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const { openModalWithContent } = useContext(BaseLayoutContext);

  const { metadata } = useContext(AuthContext);
  const staffRole = metadata?.staff_role;
  const shouldRenderForStaff = !['teacher', 'course_coordinator'].includes(
    staffRole
  );
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  useEffect(() => {
    async function fetchStudentProfile(id) {
      const response = await mentorshipApi.getStudentProfile(id);
      setStudentProfile(response);
    }

    if (item?.id) {
      fetchStudentProfile(item.id);
    }
  }, [item?.id]);

  const handleSubmit = async (data) => {
    try {
      setLoadingConfirm(true);
      await schema.validate(
        { ...data, mentorship_modality: item?.mentorship_modality },
        { abortEarly: false }
      );
      const addressData = {
        address: data.address,
        address_number: data.address_number,
        address_complement: data.address_complement,
        district: data.district,
        city: data.city,
        state: data.state,
        zip_code: data.zip_code,
        access_link: data.access_link,
      };
      await mentorshipApi.confirmMentorship(item.id, addressData);
      onClose();
      customSnackbar('Mentoria confirmada!', 'confirmation');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      customSnackbar('Error ao confirmar mentoria', 'error');
    }
    setPage(1);
    fetchMentorships(true);
    setLoadingConfirm(false);
  };

  const handleRejectButtonClick = () => {
    openCancelModal(item);
  };

  const handleRescheduleButtonClick = () => {
    item.mentor_id = item.mentor?.id;
    item.mentorship_modality = item.mentorship_modality?.value;
    openRescheduleModal(item);
  };

  useEffect(() => {
    if (item?.id) {
      fetchStudentProfile(item.id);
    }
  }, [item?.id]);

  const fetchStudentProfile = async (id) => {
    try {
      const response = await mentorshipApi.getStudentProfile(id);
      setStudentProfile(response);
    } catch (error) {
      console.error('Error fetching student profile:', error);
    }
  };

  const handleEditStudentNotesClick = () => {
    openMentoringDoneModal(item);
  };

  const {
    fullFormattedDate,
    formattedStartHour,
    formattedEndHour,
    formattedPreviousDate,
    formattedPreviousStartHour,
    formattedPreviousEndHour,
    simpleFormattedDate,
  } = formatDateTime(
    item?.date,
    item?.start_hour,
    item?.end_hour,
    item?.previous_date,
    item?.previous_start_hour,
    item?.previous_end_hour
  );

  const modality = useMemo(() => item?.mentorship_modality || null, [item]);

  const isStaffLoggedIn = (item) => item?.is_for_logged_staff === true;

  const isReScheduled = (item) => item?.status === 'reschedule_request';

  const isRescheduledByStudent = (item) =>
    isReScheduled(item) && item?.rescheduled_by === 'student';

  const shouldShowStatusFlag = isActiveExpiredTab
    ? item?.status === 'accepted' ||
      item?.status === 'requested' ||
      item?.status === 'rescheduled' ||
      item?.status === 'scheduled'
    : !(
        item?.status === 'accepted' ||
        item?.status === 'requested' ||
        item?.status === 'rescheduled' ||
        item?.status === 'scheduled'
      );

  const renderCountDays = () =>
    showCountDays ? (
      <p>
        Solicitada{' '}
        {item?.count_days === 0
          ? 'hoje'
          : item?.count_days === 1
          ? 'há 1 dia'
          : `há ${item?.count_days} dias`}
      </p>
    ) : null;

  const renderTypeLabel = () => {
    return (
      showTypeLabel &&
      ({
        reschedule_request: (
          <TypeLabel>
            {item?.rescheduled_by === 'student'
              ? 'Reagendada por você'
              : 'Reagendada pela Instituição'}
          </TypeLabel>
        ),
        canceled: (
          <TypeLabel>
            {item?.canceled_by === 'student'
              ? 'Cancelada pelo estudante'
              : `Cancelada por ${item.canceled_by}`}
          </TypeLabel>
        ),
        not_realized: (
          <TypeLabel>
            {item?.finished_by === 'student'
              ? 'Marcada como não realizada pelo estudante'
              : `Marcada como não realizada por ${item.finished_by}`}
          </TypeLabel>
        ),
      }[item?.status] ||
        null)
    );
  };

  const renderInformations = () => (
    <>
      {showMentorName && shouldRenderForStaff && (
        <DetailsItem
          icon={<Icons.UserIcon size={'30px'} />}
          label="Mentor"
          value={item?.mentor?.label || 'Mentor não identificado'}
        />
      )}
      {showFullDateFormat && (
        <DetailsItem
          icon={<Icons.CalendarIcon size={'30px'} />}
          label="Data"
          value={
            item?.status === 'reschedule_request'
              ? formattedPreviousDate
              : fullFormattedDate
          }
          valueNew={
            item?.status === 'reschedule_request'
              ? simpleFormattedDate
              : undefined
          }
        />
      )}
      {showSimpleDateFormat && (
        <DetailsItem
          icon={<Icons.CalendarIcon size={'30px'} />}
          label="Data"
          value={simpleFormattedDate}
        />
      )}

      <DetailsItem
        icon={<Icons.ClockIcon size={'30px'} />}
        label="Horário"
        value={
          item?.status === 'reschedule_request'
            ? `Das ${formattedPreviousStartHour} às ${formattedPreviousEndHour}`
            : `Das ${formattedStartHour} às ${formattedEndHour}`
        }
        valueNew={
          item?.status === 'reschedule_request'
            ? `Das ${formattedStartHour} às ${formattedEndHour}`
            : undefined
        }
      />

      <DetailsItem
        icon={<Icons.LaptopIcon size={'30px'} />}
        label="Curso"
        value={studentProfile?.courses[0]?.name}
      />

      {showmodality && modality && (
        <DetailsItem
          icon={<Icons.HouseLaptopIcon size={'30px'} />}
          label="Modalidade"
          value={modality?.label}
        />
      )}

      <GridModality>{renderModalityDetails()}</GridModality>
    </>
  );

  const renderModalityDetails = () =>
    showmodalityInfo &&
    modality && (
      <>
        {modality?.value === 'in_person' && getAddressDetails(item) && (
          <DetailsItem
            icon={<Icons.HouseLaptopIcon size={'30px'} />}
            label="Presencial"
            value={getAddressDetails(item)}
          />
        )}
        {modality?.value === 'online' && item?.access_link && (
          <DetailsItem
            icon={<Icons.HouseLaptopIcon size={'30px'} />}
            label="Online"
            value={item?.access_link}
          />
        )}
        {showmodality && modality && (
          <DetailsItem
            icon={<Icons.HouseLaptopIcon size={'30px'} />}
            label="Modalidade"
            value={modality?.label}
          />
        )}
      </>
    );

  const renderRescheduleRequestNotes = () =>
    item?.status === 'reschedule_request' ? (
      <StudentNotes>
        <strong>Motivo do reagendamento:</strong>
        <p
          dangerouslySetInnerHTML={{
            __html: item?.rescheduling_reason,
          }}
        />
      </StudentNotes>
    ) : null;

  const renderExpectedByStudent = () =>
    showStudentNotes && item?.expected_by_student ? (
      <>
        <Divider />
        <StudentNotes>
          <strong>Observações do estudante:</strong>
          <p dangerouslySetInnerHTML={{ __html: item.expected_by_student }} />
        </StudentNotes>
      </>
    ) : null;

  const renderStudentNotes = () =>
    showEditStudentNotes &&
    item?.feedback && (
      <>
        <Divider />
        <StudentNotes>
          <div>
            <strong>Comentários sobre a mentoria:</strong>
            <EditButton type={'button'} onClick={handleEditStudentNotesClick}>
              <Icons.EditIcon color={'#009291'} size={'23px'} />
            </EditButton>
          </div>
          <p dangerouslySetInnerHTML={{ __html: item?.feedback }}></p>
        </StudentNotes>
      </>
    );

  const renderAccessForm = () => {
    const staffLoggedIn = isStaffLoggedIn(item);
    const rescheduledByStudent = isRescheduledByStudent(item);
    const shouldShowForm =
      showAccessForm &&
      staffLoggedIn &&
      (rescheduledByStudent || !isReScheduled(item));

    return (
      <Form ref={formRef} onSubmit={handleSubmit}>
        {shouldShowForm &&
          (modality?.value === 'online' ? (
            <WrapperLink>
              <p>Link para realizar a mentoria:</p>
              <Input name="access_link" placeholder="Digite aqui..." />
            </WrapperLink>
          ) : (
            <WrapperLink>
              <CepAddressInput
                formRef={formRef}
                fieldsProps={{
                  zip_code: {
                    label: 'CEP do local da mentoria*',
                  },
                }}
              />
            </WrapperLink>
          ))}

        <ActionButtons>
          {showAccessForm && (
            <RejectButton type="button" onClick={handleRejectButtonClick}>
              Recusar solicitação
            </RejectButton>
          )}

          <div style={{ display: 'flex', gap: '10px' }}>
            {showAccessForm && (
              <RescheduleButton
                type="button"
                onClick={handleRescheduleButtonClick}
              >
                Reagendar
              </RescheduleButton>
            )}
            {staffLoggedIn && shouldShowForm && (
              <ConfirmButton type="submit">
                {loadingConfirm ? <FilterLoading /> : 'Confirmar'}
              </ConfirmButton>
            )}
          </div>
        </ActionButtons>
      </Form>
    );
  };

  useEffect(() => {
    if (isOpen && item?.document?.file && item?.document?.name) {
      async function urlToFile(url, filename) {
        const mimeType = 'application/pdf';
        const res = await fetch(url);
        const buffer = await res.arrayBuffer();
        return new File([buffer], filename, { type: mimeType });
      }

      urlToFile(item?.document.file, item?.document.name).then(setDocumentFile);
    } else {
      setDocumentFile(null);
    }
  }, [item, isOpen]);

  const handleSeeDocument = () => {
    if (documentFile) {
      openModalWithContent(
        <DisplayPdf
          file={documentFile}
          style={{
            margin: '0 auto',
            width: '80%',
            height: '90vh',
          }}
        />,
        null,
        null,
        () => {},
        false,
        null,
        true
      );
    }
  };

  const renderFileInput = () =>
    documentFile ? (
      <FileTile>
        <LabelContent>
          <PDFIcon color={'#009291'} size={22} />
          <p>{documentFile.name}</p>
        </LabelContent>
        <ActionsWrapper>
          <ActionButton onClick={handleSeeDocument} type={'button'}>
            <img src={eye} alt="Ver documento" />
          </ActionButton>
        </ActionsWrapper>
      </FileTile>
    ) : null;

  const renderDescriptionInput = () =>
    showDescription && (
      <WrapperForm>
        <p>Explique o motivo do reagendamento da mentoria:</p>
        <DescriptionInput name="description" placeholder="Digite aqui..." />
      </WrapperForm>
    );

  const renderStudentAssessment = () =>
    showStudentAssessment &&
    item.mentor_rating > 0 && (
      <RatingContainer>
        <span>Avaliação do estudante:</span>
        {[...Array(5)].map((_, index) => (
          <StarIcon key={index} selected={index < item.mentor_rating} />
        ))}
      </RatingContainer>
    );

  const renderReasonCancellation = () =>
    showReasonCancellation &&
    (item?.cancel_reason || item?.not_realize_reason) && (
      <StudentNotes>
        <strong>
          {item?.cancel_reason
            ? 'Motivo do cancelamento:'
            : 'Motivo pelo qual a mentoria não foi realizada:'}
        </strong>
        <p
          dangerouslySetInnerHTML={{
            __html: item?.cancel_reason || item?.not_realize_reason,
          }}
        />
      </StudentNotes>
    );

  const renderActionButtons = () => (
    <ActionButtons>
      {showRejectButton && (
        <RejectButton onClick={handleRejectButtonClick}>
          Cancelar mentoria
        </RejectButton>
      )}
      {showChangeButton && (
        <ChangeButton onClick={handleRescheduleButtonClick}>
          Reagendar mentoria
        </ChangeButton>
      )}
    </ActionButtons>
  );

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} title={title}>
      <Container>
        <ActionButtons>
          <StudentDocuments studentProfile={studentProfile} />
        </ActionButtons>
        {shouldShowStatusFlag && (
          <StatusFlag
            mentoringStatus={item?.status}
            isActiveExpiredTab={isActiveExpiredTab}
          />
        )}
        <WrapperInformations marginBottom="20px">
          {renderCountDays()}
          Sessão de mentoria com<span>{studentName}</span>
        </WrapperInformations>

        <WrapperInformations>
          <GridSide>{renderInformations()} </GridSide>
        </WrapperInformations>
        {renderRescheduleRequestNotes()}
        {renderExpectedByStudent()}
        {renderStudentNotes()}
        {renderAccessForm()}
        {renderFileInput()}
        {renderDescriptionInput()}
        {renderStudentAssessment()}
        {renderTypeLabel()}
        {renderReasonCancellation()}
        {renderActionButtons()}
      </Container>
    </GenericModal>
  );
};

export default DetailsModal;
