import { dnaColor } from '../../../material-dashboard-pro-react';

const styles = () => ({
  selectSection: {
    width: '100%',
    margin: '0 auto',
    fontSize: 17,
    '& > div:nth-child(1)': {
      height: 56,
      border: 0,
      borderRadius: 10,
      '& > div': {
        marginLeft: 4,
        marginTop: 15,
      },
    },
    '& > div:nth-child(2)': {
      height: 56,
      border: 0,
      borderRadius: 10,
      '& > div': {
        marginLeft: 4,
        marginTop: 15,
      },
    },
    '& .react-select__menu': {
      zIndex: 999,
    },
  },
  selectSectionAutoHeight: {
    '& > div:nth-child(1)': {
      paddingTop: 10,
      height: 'auto',
    },
    '& > div:nth-child(2)': {
      paddingTop: 10,
      height: 'auto',
    },
    '& > div:last-child': {
      display: 'none',
    },
  },
  itemListFlexEnd: {
    justifyContent: 'flex-end',
  },
  resetButton: {
    color: `${dnaColor}`,
    padding: 0,
    fontSize: '1rem',
  },
  submitButton: {
    padding: '8px 10px',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Roboto, sans-serif',
    textTransform: 'none',
    marginRight: 24,
    outline: 'none',
    backgroundColor: '#009291',
    borderRadius: 4,
    outline: 'none',
    border: 'none',
    color: '#FFF',
    transition: 'all 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#009291',
      filter: 'brightness(1.05);',
    },
    '&:disabled': {
      opacity: '0.65',
      cursor: 'not-allowed',
    },
  },
});

export default styles;
