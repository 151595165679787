import React, { useRef, useContext } from 'react';
import {
  Box,
  CloseButton,
  Container,
  Content,
  WrapperMessage,
  WrapperTitle,
  BubbleDialog,
  AreaMessage,
  Information,
  Subtitle,
} from '../ModalStylingPattern/styles';
import Wall from 'assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import { GrFormClose } from 'react-icons/gr';
import { Circle } from '../../../screens/Tabs/PublishedProjects/Card/styled';
import { HiUser } from 'react-icons/hi';
import { BsCalendar4Week } from 'react-icons/bs';
import BaseLayoutContext from 'contexts/base-layout';

export default function ModalFeedbackExtensionProject(props) {
  const { isOpen, setOpenModalFeedback, feedback, customMessage } = props;

  const { universityColor } = useContext(BaseLayoutContext);
  const modalFeedback = useRef();

  const closeModalFeedback = () => {
    setOpenModalFeedback(false);
  };

  const closeModalClickOutside = (e) => {
    if (modalFeedback.current === e.target) {
      setOpenModalFeedback(false);
    }
  };

  return (
    <Container isOpen={isOpen}>
      <Box ref={modalFeedback} onClick={closeModalClickOutside}>
        <Content>
          <CloseButton onClick={closeModalFeedback}>
            <GrFormClose size={18} />
          </CloseButton>

          <WrapperTitle universityColor={universityColor}>
            <div className="border-image">
              <img src={Wall} alt="Icone Wall" />
            </div>
            <BubbleDialog
              universityColor={universityColor}
              widthBubble="201px"
              heightBubble="54px"
            >
              <span> Visualizar feedback </span>
            </BubbleDialog>
          </WrapperTitle>

          <WrapperMessage
            fontMessage="18px"
            lineHeigthMessage="1.6rem"
            positionMessage="start"
          >
            {customMessage ||
              'Veja o feedback que foi enviado ao seu estudante:'}
          </WrapperMessage>
          <Subtitle>Feedback</Subtitle>
          <AreaMessage>
            {ReactHtmlParser(
              (feedback && feedback.feedback) || 'Não informado'
            )}
          </AreaMessage>

          <Information>
            <Circle bgColor={universityColor}>
              <HiUser size={16} color="#ffffff" />
            </Circle>
            <p>
              Enviado por{' '}
              {(feedback && feedback?.created_by) ||
                feedback?.feedback_created_by}
            </p>
          </Information>

          <Information>
            <BsCalendar4Week size={17} color={universityColor ?? '#009291'} />
            <p>
              {feedback && moment(feedback.created_at).format('DD/MM/YYYY')} às{' '}
              {feedback && moment(feedback.created_at).format('H:mm ')}
            </p>
          </Information>
        </Content>
      </Box>
    </Container>
  );
}
