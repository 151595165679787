import React, { useRef } from 'react';
import { BackgroundModal, BodyModal, Modal } from '../styles/BaseComponent';
import GenericHeaderModal from './HeaderModal';

export default function GenericModalComponent({ children, closeModal, title, fullSize }) {
  const modalRef = useRef();

  return (
    <BackgroundModal fullSize={fullSize} ref={modalRef}>
      <Modal>
        <GenericHeaderModal title={title} closeModal={closeModal} />
        <BodyModal>{children}</BodyModal>
      </Modal>
    </BackgroundModal>
  );
}
