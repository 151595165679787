import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  Container,
  Title,
  WrapperContent,
  ContainerSpan,
  ContainerFilterAndAddProject,
  FilterContainer,
  ContainerHeader,
  ContentFormInput,
  WrapperHeader,
  ParagraphFormInput,
} from './styled';
import InternalTab from './components/InternalTab';
import PublishedProjects from './screens/Tabs/PublishedProjects';
import ToFinishProjects from './screens/Tabs/ToFinishProjects';
import FinishedProjects from './screens/Tabs/FinishedProjects';
import { FilterPublishedExtensionProjects } from './components/Filter/FilterPublishedExtensionProjects';
import { FilterToFinishedExtensionProjects } from './components/Filter/FilterToFinishedExtensionProjects';
import { FilterFinishedExtensionProjects } from './components/Filter/FilterFinishedExtensionProjects';
import ModalInformation from 'components/informationModal';
import { Form } from '@unform/web';

import {
  deleteEvidence,
  postCreateFileEvidence,
  postCreateLinkEvidence,
  putEditLinkEvidence,
} from './services';
import { formatNumber } from 'utils/formatNumber';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import BaseLayoutContext from 'contexts/base-layout';
import { cancelRequests } from './services';
import ModalUploadEvidences from 'components/UploadEvidences';
import { ExtensionProjectsContext } from './context/ExtensionProjectsContext';
import NewModalStudents from './components/Modal/ModalTrackEnrollments/components/NewModalStudents';
import SimpleInput from 'components/UnformFields/SimpleInput';
import * as Yup from 'yup';

import { configModalStudentsList } from './utils/texts';
import { downloadSpreadsheetStudents } from './services/urls';
import { schemaValidationEmail } from './utils/validations';
import CreateExtensionModalButton from './components/CreateModal/Index';

export default function ExtensionProject({ metadata }) {
  const {
    usingFormUpload,
    activeTabExtensionProject,
    setActiveTabExtensionProject,
    setUsingFormUpload,
    idPostEvidence,
    evidenceFiles,
    evidenceLinks,
    getAllToFinishProjects,
    queryFilter,
    setQueryFilter,
    getCountProjects,
    extensionProjectId,
    openModalStudents,
    setOpenModalStudents,
    status,
    extracurriculum,

    finishedProjectsSum,
    publishedProjectsSum,
    toFinishProjectsSum,
    appliedFilters,
    setAppliedFilters,
    idAttraction,

    getAllFutureProjects,
    getAllInProgressProjects,
    setInProgressProjects,
    setInProgressExtension,
    setFutureExtension,
    setPaginationThreeCurrentPage,
    setResetInProgressListing,
    setResetAllFutureListing,
    setPaginationTwoCurrentPage,
    setIdAttraction,
  } = useContext(ExtensionProjectsContext);

  const { openSnackbar } = useContext(BaseLayoutContext);
  const formRef = useRef(null);

  const [filterState, setFilterState] = useState({});
  const [filters, setFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [openModalStudentsList, setOpenModalStudentsList] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCountProjects();
  }, [activeTabExtensionProject]);

  useEffect(() => {
    setQueryFilter(null);
  }, [activeTabExtensionProject]);

  useEffect(() => {
    setFilterState({});
  }, [activeTabExtensionProject]);

  const handleCloseEnrolledModal = () => {
    setOpenModalStudents(false);

    // Reset future Listing
    setFutureExtension(null);
    setPaginationTwoCurrentPage(0);
    setResetAllFutureListing((prev) => !prev);

    // Reset In Progress Listing
    setInProgressExtension(null);
    setPaginationThreeCurrentPage(0);
    setResetInProgressListing((prev) => !prev);
  };

  const handleOpenStudentsList = (id) => {
    setIdAttraction(id);
    setOpenModalStudentsList(true);
    setOpenModalStudents && setOpenModalStudents(false);
  };

  const handleCloseStudentsList = () => {
    setOpenModalStudentsList(false);
  };

  const handleSubmitModal = async () => {
    const data = formRef.current.getData();
    try {
      setLoading(true);
      await schemaValidationEmail.validate(data, {
        abortEarly: false,
      });

      await downloadSpreadsheetStudents(idAttraction, data);
      setOpenModalStudentsList(false);
      customSnackbar('Lista enviada com sucesso!', 'confirmation');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          formRef.current.setFieldError(error.path, error.message);
        });
      }
      customSnackbar(
        'Erro ao enviar lista, tente novamente mais tarde.',
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <PublishedProjects
          activeTab={activeTabExtensionProject}
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
          handleOpenStudentsList={handleOpenStudentsList}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <ToFinishProjects
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
      ),
    },
    {
      activeTab: 2,
      component: (
        <FinishedProjects
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
      ),
    },
  ];

  const Filters = [
    {
      activeTab: 0,
      component: (
        <FilterPublishedExtensionProjects
          filterState={filterState}
          setFilterState={setFilterState}
          appliedFilters={appliedFilters}
          filters={filters}
          setFilters={setFilters}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          setAppliedFilters={setAppliedFilters}
          activeTab={activeTabExtensionProject}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <FilterToFinishedExtensionProjects
          filterState={filterState}
          setFilterState={setFilterState}
          appliedFilters={appliedFilters}
          filters={filters}
          setFilters={setFilters}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          setAppliedFilters={setAppliedFilters}
          activeTab={activeTabExtensionProject}
        />
      ),
    },
    {
      activeTab: 2,
      component: (
        <FilterFinishedExtensionProjects
          filterState={filterState}
          setFilterState={setFilterState}
          appliedFilters={appliedFilters}
          filters={filters}
          setFilters={setFilters}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          setAppliedFilters={setAppliedFilters}
          activeTab={activeTabExtensionProject}
        />
      ),
    },
  ];

  const handleTabChange = (_, value) => {
    cancelRequests();
    setActiveTabExtensionProject(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);

    return returnTabs.component;
  };

  const renderFilters = (step) => {
    const returnFilters = Filters.find((tab) => tab.activeTab === step);

    return returnFilters.component;
  };

  const functionCloseUpdateModal = () => {
    setUsingFormUpload(false);
    getAllToFinishProjects(appliedFilters, true);
  };

  const addProject = () => {
    handleCreateExtensionProject(setModalIsOpen, setModalContent);
  };

  const configSuccessModal = {
    modalWidth: 400,
    bubbleText: `Projeto publicado com sucesso!`,
    ParagraphText: `Acompanhe o projeto cadastrado na aba projetos publicados.`,
    buttonConfirmText: 'OK, ENTENDI',
    showButtonCancel: false,
    buttonCloseX: false,
    bubble: {
      style: {
        textAlign: 'left',
      },
    },
    style: {
      textAlign: 'center',
      fontSize: '18px',
      lineHeight: '24px',
      maxWidth: '92%',
    },
  };

  const addEvidence = async (id, body, type) => {
    const response = await postCreateFileEvidence(id, body, type);

    if (response?.file || response?.image) {
      openSnackbar('Evidência adicionada com sucesso!', false);
    }

    return response;
  };

  const addLink = async (id, body, uniqueLink) => {
    const response = await postCreateLinkEvidence(id, body, uniqueLink);

    if (response?.id) {
      openSnackbar('Evidência adicionada com sucesso!', false);
    }

    return response;
  };

  const removeEvidence = async (id, evidenceId) => {
    const response = await deleteEvidence(id, evidenceId);

    if (!response) {
      openSnackbar('Evidência removida com sucesso!', false);
    }

    return response;
  };

  const editLink = async (id, idEvidence, body) => {
    const response = await putEditLinkEvidence(id, idEvidence, body);

    if (response?.id) {
      openSnackbar('Evidência editada com sucesso!', false);
    }

    return response;
  };

  const renderInput = () => {
    return (
      <ContentFormInput>
        <Form ref={formRef}>
          <SimpleInput
            name="email"
            defaultValue={metadata.email}
            placeholder="Digite aqui..."
          />
        </Form>
        <ParagraphFormInput>
          Caso precise, altere o e-mail no campo acima.
        </ParagraphFormInput>
      </ContentFormInput>
    );
  };

  return (
    <Fragment>
      {openModalStudents && (
        <NewModalStudents
          onClose={handleCloseEnrolledModal}
          status={status}
          extensionProjectId={extensionProjectId}
          openListModal={handleOpenStudentsList}
          extracurriculum={extracurriculum}
          setOpenModalStudents={setOpenModalStudents}
        />
      )}

      {openModalStudentsList && (
        <ModalInformation
          config={{
            ...configModalStudentsList(metadata.email),
            loading: loading,
          }}
          closeModalFunction={handleCloseStudentsList}
          modalOpenState={openModalStudentsList}
          buttonConfirmFunction={handleSubmitModal}
          buttonCancelFunction={handleCloseStudentsList}
          formRef={formRef}
        >
          {renderInput()}
        </ModalInformation>
      )}

      <Container>
        <ModalUploadEvidences
          open={usingFormUpload}
          evidenceFiles={evidenceFiles}
          evidenceLinks={evidenceLinks}
          acceptFormats={['file', 'link']}
          headerTitle={'Upload de evidências'}
          acceptFileTypes={['PNG', 'JPEG', 'PDF']}
          functionCloseAndUpdate={functionCloseUpdateModal}
          idPost={idPostEvidence}
          functionPostFile={addEvidence}
          functionDeleteEvidence={removeEvidence}
          functionPostLink={addLink}
          functionPutLink={editLink}
          buttonLabel={'Salvar Evidência(s)'}
        />
        <ContainerHeader>
          <ContainerSpan>
            <Title>
              {extracurriculum === true
                ? 'Projetos de Extensão extracurricular'
                : 'Projetos de Extensão curricular'}
            </Title>
            <span>
              Aqui você pode acompanhar seus projetos de extensão cadastrados e
              encerrados.
            </span>
          </ContainerSpan>
          <ContainerFilterAndAddProject>
            <CreateExtensionModalButton />
          </ContainerFilterAndAddProject>
        </ContainerHeader>
        <WrapperContent>
          <WrapperHeader>
            <InternalTab
              activeTab={activeTabExtensionProject}
              handleTabChange={handleTabChange}
              tabs={[
                `Publicados (${formatNumber(publishedProjectsSum)})`,
                `A finalizar (${formatNumber(toFinishProjectsSum)})`,
                `Finalizados (${formatNumber(finishedProjectsSum)})`,
              ]}
            />
            <FilterContainer>
              {renderFilters(activeTabExtensionProject)}
            </FilterContainer>
          </WrapperHeader>

          {renderPrincipalTabs(activeTabExtensionProject)}
        </WrapperContent>
      </Container>
    </Fragment>
  );
}
