import { PopUp } from 'components/ModalShowEvidence/ListFiles/style';
import { ActionButton } from 'views/Student/ExtensionProject/components/SupportMaterialModal/styled';
import {
  UploadTile,
  UploadTextColumn,
  UploadActionsWrapper,
} from 'views/Student/ExtensionProject/components/FinalReportModal/styled';
import { DefaultEyeIcon } from 'assets/ComponentIcons/DefaultEye';
import {
  typeIcons,
  UploadType,
} from 'components/GenericModalUpload/utils/enums';
import moment from 'moment';
import { useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { handleClickViewPdf } from 'components/GenericModalUpload/utils/resource';
import Tooltip from 'components/NewTooltip';
import { BiTrash } from 'react-icons/bi';

export function ListFile({ file, setSelectedImage, removeItem }) {
  const { openModalWithContent } = useContext(BaseLayoutContext);
  const fileExtension = file?.uploadedContent?.name.split('.').pop();

  return (
    <UploadTile>
      {typeIcons[fileExtension]}
      <UploadTextColumn>
        <p>{file.uploadedContent.name || file.name}</p>
        <span>
          Adicionada em {moment(file.lastModifiedDate).format('DD/MM/YYYY')}
        </span>
      </UploadTextColumn>
      <UploadActionsWrapper>
        <Tooltip text="Remover" direction="top">
          <ActionButton type={'button'} onClick={() => removeItem(file)}>
            <BiTrash size={24} color={'#009291'} alt="eye" />
          </ActionButton>
        </Tooltip>
        <Tooltip text="Visualizar" direction="top">
          <ActionButton
            onClick={() => {
              if (file.type === UploadType.IMAGE) {
                setSelectedImage(file.uploadedContent || file);
              } else {
                handleClickViewPdf(
                  file.uploadedContent || file,
                  openModalWithContent,
                  false
                );
              }
            }}
            type={'button'}
          >
            <DefaultEyeIcon size={24} color={'#009291'} alt="eye" />
          </ActionButton>
        </Tooltip>
      </UploadActionsWrapper>
    </UploadTile>
  );
}
