import { updateVisibleCurriculum } from 'services/student/curriculumData';
import { actionsProfileStore } from 'stores/profile.store';
import updateStateOfObjects from 'utils/updateStateObjects';
import { customSnackbar } from '../../../components/CustomSnackBar/customSnackbar';
/**
 * A custom hook for managing student curriculum-related data.
 * @param {function} setUserData - Function to update user data.
 * @returns {object} An object containing the handleVisibleItem function.
 */

export const useDataManagement = (setUserData, setDisabled) => {
  /**
   * Function to update the student's curriculum.
   * @param {object} data - The curriculum data to be updated.
   * @returns {Promise} A promise that resolves with the update response.
   */

  const { updateCard } = actionsProfileStore;

  const handleUpdateCurriculum = async (data) => {
    if (setDisabled) {
      setDisabled(true);
      const response = await updateVisibleCurriculum(data);
      setDisabled(false);

      return response;
    }
    const response = await updateVisibleCurriculum(data);

    return response;
  };

  /**
   * Function to update a specific item in a data list.
   * @param {object} newItem - The new item to be added or updated in the list.
   * @param {boolean} isUpdate - Indicates whether it's an update or an addition.
   * @param {object} cardData - The data of the session where the change was made.
   * @param {string} dataKey - The key to access the data list in cardData.
   * @param {string} reqKey - The key to update user data.
   */

  const updateItem = (newItem, isUpdate, cardData, dataKey, updateKey) => {
    let itemList = cardData[dataKey];

    if (isUpdate) {
      updateStateOfObjects(itemList, newItem);
    }
    if (!isUpdate) {
      itemList = itemList.filter((item) => item.id !== newItem.id);
    }
    const updatedUserData = { [updateKey]: itemList };
    setUserData(updatedUserData);
    updateCard(dataKey, itemList);
  };

  /**
   * Function to handle the visibility of an item and update the curriculum.
   * @param {array} items - The list of items to be displayed.
   * @param {object} item - The specific item to be updated (can be null).
   * @param {string} reqKey - The key to update user data.
   * @param {boolean} isUpdate - Indicates whether it's an update or an addition.
   * @param {object} cardData - The data of the session where the change was made.
   * @param {string} dataKey - The key to access the data list in cardData.
   * @param {function} setData - Function to update section data.
   * @param {string} updateKey - The key to update user data related to visibility.
   * @param {function} setVisibleItems - Function to set visible items.
   */

  const handleVisibleItem = ({
    items,
    item = null,
    reqKey,
    isUpdate,
    cardData,
    dataKey,
    setData,
    updateKey,
    setVisibleItems,
  }) => {
    setVisibleItems(items);
    if (item) {
      handleUpdateCurriculum({ [reqKey]: items }).then((updatedData) => {
        if (updatedData?.status === 200) {
          let newItem = { ...item, visible: !item.visible };
          updateItem(newItem, isUpdate, cardData, dataKey, updateKey);
        }
        else{
            customSnackbar(
            'Erro ao atualizar. Tente novamente mais tarde.', 'error', 5000,
          );
        }
      });
    }
  };

  return { handleVisibleItem };
};
