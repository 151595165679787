import styled, { keyframes } from 'styled-components';
import { Container as ContainerFromCard } from '../Card/styled';

export const Container = styled(ContainerFromCard)`
  cursor: unset;
  transition: none;
  border: 1px solid #e5e5e5;

  &:hover {
    transform: none;
  }
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const ActionButtonsSkeleton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  width: 280px;
  padding: 0 1rem;
  margin-top: 10px;
`;

const Skeleton = styled.div`
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;

export const SkeletonBox = styled(Skeleton)`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  margin: 0 !important;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.5')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
`;
