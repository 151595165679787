const FinishProjectAdapterForm = ({
  externalPublic,
  impactedPublic,
  score,
}) => ({
  external_public: externalPublic,
  impacted_public: impactedPublic,

});

export { FinishProjectAdapterForm };
