import ModalShowEvidence from 'components/ModalShowEvidence';
import React, { Fragment, useState, useContext } from 'react';
import ModalMoreInformations from '../../../../components/Modal/ModalMoreInformation';
import SkeletonCardProjectExtension from '../../../../components/SkeletonExtensionProjects';
import { FinishedProjectsCard } from '../Card';
import { getEvidences } from 'views/StaffPages/ExtensionProject/services';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import EvidenceFinishedModal from 'views/StaffPages/ExtensionProject/components/Modal/ModalTrackEnrollments/components/EvidenceFinishedModal';

export default function FinishedProjectsCarousel({
  unpublishCard,
  array,
  ref,
  finishedProject,
}) {
  const [details, setDetails] = useState({});
  const [showEvidences, setShowEvidences] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [evidencesCount, setEvidencesCount] = useState(0);
  const [arrayLinks, setArrayLinks] = useState([]);
  const [arrayFiles, setArrayFiles] = useState([]);
  const [itemId, setItemId] = useState(0);

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const {
    setModalEnrolledStudents,
    setExtensionProjectId,
    setStatus,
    setIdAttraction,
    extracurriculum,
  } = useContext(ExtensionProjectsContext);

  const loadEvidences = async (id, query) => {
    const { results, count } = await getEvidences(id, query);
    setEvidencesCount(count);
    const files = results.filter(
      (item) => item.file !== null || item.image !== null
    );
    const links = results.filter(
      (item) => item.name !== '' && item.link !== ''
    );

    setArrayFiles(files);
    setArrayLinks(links);
  };

  const handleEnrolledStudents = (item) => {
    setIdAttraction(item.id);
    setModalEnrolledStudents(true);
    setExtensionProjectId(item.extension_project.id);
    setStatus(item.is_published);
  };

  const handleCloseEvidenceModal = () => {
    setShowEvidences(false);
  };

  const mapProject = (array) => {
    if (array === null) {
      return (
        <SkeletonCardProjectExtension quantity={randomIntFromInterval(3, 4)} />
      );
    }
    return (
      array &&
      array.map((item) => (
        <FinishedProjectsCard
          ref={ref}
          setItemId={setItemId}
          setShowEvidences={setShowEvidences}
          item={item}
          setDetails={setDetails}
          loadEvidences={loadEvidences}
          setIsOpen={setIsOpen}
          unpublishCard={unpublishCard}
          finishedProject={finishedProject}
          extracurriculum={extracurriculum}
        />
      ))
    );
  };

  return (
    <Fragment>
      {mapProject(array)}
      {showEvidences && (
        <EvidenceFinishedModal
          id={itemId}
          open={showEvidences}
          onClose={handleCloseEvidenceModal}
        />
      )}
      {details && Object.keys(details).length > 0 ? (
        <ModalMoreInformations
          details={details}
          isOpen={isOpen}
          setModalOpen={setIsOpen}
          finishedProject={finishedProject}
          handleEnrolledStudents={handleEnrolledStudents}
          extracurriculum={extracurriculum}
          unpublishCard={unpublishCard}
        />
      ) : null}
    </Fragment>
  );
}
