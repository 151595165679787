import React, { useContext, useState } from 'react';
import {
  Container,
  CounterBadge,
  Flag,
  FullEnrollmentMessage,
  ImageWrapper,
  RowWrapper,
} from './styled';
import BaseLayoutContext from 'contexts/base-layout';
import { RxCalendar } from 'react-icons/rx';
import { HiOutlineClock } from 'react-icons/hi';
import { MdLocationOn, MdOutlineHideImage } from 'react-icons/md';
import { RiGraduationCapLine } from 'react-icons/ri';
import { GoShareAndroid } from 'react-icons/go';
import { getAddressDetails } from '../../utils/addressAdapter';
import { InfoRow } from './infoRow';
import moment from 'moment';
import ActionButtons from './actionButtons';
import { getStatusDetails } from '../../utils/statusUtils';
import { FaRegStar } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

export default function ProjectCard({
  item,
  buttons,
  dropdownMenuItems,
  hideStatus,
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const startFormattedDate = moment(item.start_at).format('DD/MM/YY');
  const endFormattedDate = moment(item.end_at).format('DD/MM/YY');
  const workloadFormatted = item.workload.replace('.00', 'h');
  const projectAddress = getAddressDetails(item) || 'Local não informado';
  const [imageError, setImageError] = useState(false);
  const { icon, backgroundColor, textColor, text } = getStatusDetails(
    item?.status
  );

  return (
    <Container>
      <ImageWrapper>
        {imageError || item.image.logo ? (
          <MdOutlineHideImage
            size={64}
            color="#a3a2a2"
            style={{
              marginTop: item.status ? '37px' : '0px',
            }}
          />
        ) : (
          <img
            src={item.image.image}
            alt="Imagem do projeto"
            onError={() => setImageError(true)}
          />
        )}
      </ImageWrapper>
      {item.enrolled_students_quantity && (
        <CounterBadge color={universityColor}>
          {item.enrolled_students_quantity} vagas preenchidas
        </CounterBadge>
      )}
      {!hideStatus && item?.status && (
        <Flag style={{ backgroundColor, color: textColor }}>
          {icon}
          <span>{text}</span>
        </Flag>
      )}

      <RowWrapper>
        <span className="project-code">
          CÓDIGO: {item?.extension_project_id}
        </span>

        <span className="project-name">{item?.name}</span>
        {item.extension_kind && (
          <InfoRow
            Icon={
              item.extension_kind === 'curricular'
                ? RiGraduationCapLine
                : GoShareAndroid
            }
            text={
              item.extension_kind === 'curricular'
                ? 'Extensão curricular'
                : 'Extensão extracurricular'
            }
            universityColor={universityColor}
            textColor={
              item.extension_kind === 'extracurricular'
                ? '#606062'
                : universityColor
            }
            isBold={true}
          />
        )}

        {item?.status === 'approved' && (
          <InfoRow
            Icon={FaRegStar}
            text={`Você foi aprovado no projeto!`}
            universityColor={universityColor}
          />
        )}

        <InfoRow
          Icon={RxCalendar}
          text={`${startFormattedDate} a ${endFormattedDate}`}
          universityColor={universityColor}
        />
        {item?.status !== 'disapproved' && (
          <InfoRow
            Icon={HiOutlineClock}
            text={`Carga horária de ${workloadFormatted}`}
            universityColor={universityColor}
          />
        )}
        {item?.status !== 'approved' && item?.status !== 'disapproved' && (
          <InfoRow
            Icon={MdLocationOn}
            text={projectAddress}
            universityColor={universityColor}
          />
        )}
        {item?.status === 'disapproved' && (
          <InfoRow
            Icon={IoMdClose}
            text={`Você não foi aprovado. Veja o motivo em 'feedback' ou contate o professor responsável.`}
            universityColor={universityColor}
            truncate={false}
          />
        )}
      </RowWrapper>

      <ActionButtons
        item={item}
        universityColor={universityColor}
        buttons={buttons}
        dropdownMenuItems={dropdownMenuItems}
      />
      {item.enrolled_students_percentual &&
        item.enrolled_students_percentual === '100%' && (
          <FullEnrollmentMessage>
            No momento as vagas estão todas preenchidas. Caso haja alguma
            desistência, o projeto ficará disponível novamente para inscrição.
          </FullEnrollmentMessage>
        )}
    </Container>
  );
}
