import axios from 'axios';

export const GenerateBlobImage = (url) => {
  const blobResponse = axios
    .get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'image/png, image/jpeg, image/jpg',
      },
    })
    .then((response) => {
      return window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers['content-type'] })
      );
    })
    .catch((error) => error);

  return blobResponse;
};
