import React, { useContext, useEffect } from 'react';
import StepOne from '../steps/StepOne';
import StepTwo from '../steps/StepTwo';
import StepThree from '../steps/StepThree';
import StepFour from '../steps/StepFour';
import StepFive from '../steps/StepFive/index';
import { CreateExtensionContext } from '../../CreateExtensionContext';
import Footer from '../Footer';
import Header from '../Header';

import { Container } from './style';
import { actionsGenericUpload } from 'components/GenericModalUpload/store/genericUpload.store';
import { extensionDataAdapter } from '../../validators/extensionProjectAdapter';
import { getEditExtensionProjectValues } from 'views/StaffPages/ExtensionProject/services';

export default function StepsEngine({ idProject, uuidProject }) {
  const { currentStep, kind, setFormValues, setIdEditing } = useContext(
    CreateExtensionContext
  );
  const { changeItems } = actionsGenericUpload;

  useEffect(() => {
    changeItems(null);
  }, []);

  const fetchGetEditValues = async (id) => {
    const response = await getEditExtensionProjectValues(id);
    const dataAdapted = extensionDataAdapter(response);
    setFormValues(dataAdapted);
  };

  useEffect(() => {
    if (idProject) {
      fetchGetEditValues(uuidProject);
      setIdEditing(uuidProject);
    } else {
      setIdEditing(null);
    }
  }, [idProject]);

  const curricularSteps = {
    1: <StepOne extensionKind={kind} isEdit={idProject} />,
    2: <StepTwo extensionKind={kind} isEdit={idProject} />,
    3: <StepThree />,
    4: <StepFour idProjectEdit={idProject} uuidProject={uuidProject} />,
    5: <StepFive />,
  };

  const extracurricularSteps = {
    1: <StepTwo extensionKind={kind} idProject={idProject} />,
    2: <StepOne extensionKind={kind} isEdit={idProject} />,
    3: <StepThree />,
    4: <StepFour idProjectEdit={idProject} uuidProject={uuidProject} />,
  };

  const returnOrderForm = (extensionKind) => {
    if (extensionKind === 'curricular') {
      return curricularSteps[currentStep];
    }
    if (extensionKind === 'extracurricular') {
      return extracurricularSteps[currentStep];
    }
  };

  return (
    <Container>
      <Header isEdit={idProject} />
      {returnOrderForm(kind)}
      <Footer isEdit={idProject} />
    </Container>
  );
}
