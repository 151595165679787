import React, { createContext, useState, useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { getStudentGamification } from 'services/student/profile';

export const JobOffersContext = createContext();

export default function JobOffersProvider({
  children,
  setGamificationData,
  setUserData,
}) {
  const {
    profile_id,
    academic_profile,
    closeModal,
    openSnackbar,
    handleIsLoadingState,
    openModalWithContent,
    setGlobalState,
  } = useContext(BaseLayoutContext);
  const ProfileId = profile_id;
  const academicProfile = academic_profile;

  const [errorDate, setErrorDate] = useState(false);
  const [countJobs, setCountJobs] = useState(null);

  const [listJobOffers, setListJobOffers] = useState([]);
  const [listFavoriteJobOffers, setListFavoriteJobOffers] = useState([]);

  const [listExternalJobOffers, setListExternalJobOffers] = useState([]);
  const [listExternalOtherJobs, setListExternalOtherJobs] = useState([]);

  const [listApplicationJobs, setListApplicationJobs] = useState(0);
  const [listFinishedJobs, setListFinishedJobs] = useState(0);

  const [listFavoriteJobs, setListFavoriteJobs] = useState(0);

  const [visibleItems, setVisibleItems] = useState(20);
  const [search, setSearch] = useState('');

  const [openCoverLetterModal, setOpenCoverLetterModal] = useState(false);
  const [hasCoverLetter, setHasCoverLetter] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  // count states
  const [favoriteCompaniesJobsCount, setFavoriteCompaniesJobsCount] =
    useState(0);
  const [otherJobsCount, setOtherJobsCount] = useState(0);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [favoriteJobsCount, setFavoriteJobsCount] = useState(0);

  // loading states
  const [isLoadingFavoriteJobs, setIsLoadingFavoriteJobs] = useState(false);
  const [isLoadingOtherJobs, setIsLoadingOtherJobs] = useState(false);
  const [isLoadingExternalJobs, setIsLoadingExternalJobs] = useState(false);
  const [isLoadingExternalOtherJobs, setIsLoadingExternalOtherJobs] =
    useState(false);
  const [isLoadingApplications, setIsLoadingApplications] = useState(false);
  const [isLoadingFavorites, setIsLoadingFavorites] = useState(false);

  const [activeSubTab, setActiveSubTab] = useState(0);

  const [
    openGamificationLevelOneIncompleteModal,
    setOpenGamificationLevelOneIncompleteModal,
  ] = useState(false);

  const [avatar, setAvatar] = useState(null);
  const [showDisability, setShowDisability] = useState(false);
  const [showGenderIdentity, setShowGenderIdentity] = useState(false);
  const [showColorOrRace, setShowColorOrRace] = useState(false);
  const [statusIcon, setStatusIcon] = useState(null);
  const [studentProfileState, setStudentProfileState] = useState({});
  const [profileData, setProfileData] = useState(academic_profile);

  const cityStudent = academic_profile.city;
  const stateStudent = academic_profile.state;
  const stateAndCityStudent = `${cityStudent}/${stateStudent}`;
  const zipCodeStudent = academic_profile.zip_code;
  const nameStudent = academic_profile.name;
  const firstName = nameStudent.split(' ')[0];

  const handleShowSpecialNeeds = (value) => {
    setShowDisability(value);
    setShowGenderIdentity(value);
    setShowColorOrRace(value);
  };

  const updateAvatarState = (newAvatar) => {
    setAvatar(newAvatar.avatar);
  };

  const updateProfileState = (newProfile, profileUpdated) => {
    setStudentProfileState(newProfile);
    setUserData(profileUpdated);

    if (newProfile) {
      const personalData = [
        newProfile.zipCode,
        newProfile.mobilePhone,
        newProfile.name,
        newProfile.genderIdentity,
        newProfile.colorOrRace,
        newProfile.birth_date,
        newProfile.email,
      ];

      const requiredFields = personalData.every((field) => !!field);
      setStatusIcon(requiredFields);
    }
  };

  const fetchGamificationData = async () => {
    const id = profile_id;
    const data = await getStudentGamification(id);
    setGamificationData && setGamificationData(data);
  };

  const filterData = (dataArray, search) => {
    if (!dataArray) return [];

    const lowercaseSearch = search.toLowerCase();

    return dataArray.filter((item) => {
      const lowercaseItemName = item.name.toLowerCase();
      const itemId = item.id.toString();
      return lowercaseSearch === ''
        ? true
        : lowercaseItemName.includes(lowercaseSearch) ||
            itemId.includes(lowercaseSearch);
    });
  };

  const handleMoreItems = (data, fetchFunction) => {
    if (data.next) {
      const query = data.next.split('?');
      fetchFunction(query[1], true);
    }
  };

  const handleExclusiveJobsCount = async (
    countJobs,
    otherJobs = 0,
    favCompanies = 0
  ) => {
    if (otherJobs) {
      setOtherJobsCount(otherJobs);
    }
    if (favCompanies) {
      setFavoriteCompaniesJobsCount(favCompanies);
    }
    countJobs['exclusive_offers'] = otherJobsCount + favoriteCompaniesJobsCount;
    return countJobs;
  };

  return (
    <JobOffersContext.Provider
      value={{
        ProfileId,
        academicProfile,

        errorDate,
        setErrorDate,

        listJobOffers,
        setListJobOffers,
        listFavoriteJobOffers,
        setListFavoriteJobOffers,

        listExternalJobOffers,
        setListExternalJobOffers,
        listExternalOtherJobs,
        setListExternalOtherJobs,

        listApplicationJobs,
        setListApplicationJobs,
        listFinishedJobs,
        setListFinishedJobs,

        listFavoriteJobs,
        setListFavoriteJobs,

        isLoadingFavoriteJobs,
        setIsLoadingFavoriteJobs,
        isLoadingOtherJobs,
        setIsLoadingOtherJobs,
        isLoadingApplications,
        setIsLoadingApplications,
        isLoadingFavorites,
        setIsLoadingFavorites,
        isLoadingExternalJobs,
        setIsLoadingExternalJobs,
        isLoadingExternalOtherJobs,
        setIsLoadingExternalOtherJobs,

        handleExclusiveJobsCount,

        search,
        setSearch,

        visibleItems,
        setVisibleItems,
        filterData,

        handleMoreItems,

        openGamificationLevelOneIncompleteModal,
        setOpenGamificationLevelOneIncompleteModal,

        closeModal,
        openSnackbar,
        handleIsLoadingState,
        openModalWithContent,
        setGlobalState,

        openCoverLetterModal,
        setOpenCoverLetterModal,
        hasCoverLetter,
        setHasCoverLetter,

        countJobs,
        setCountJobs,

        activeSubTab,
        setActiveSubTab,

        showDisability,
        setShowDisability,
        showGenderIdentity,
        setShowGenderIdentity,
        showColorOrRace,
        setShowColorOrRace,

        handleShowSpecialNeeds,
        updateAvatarState,
        fetchGamificationData,
        profileData,
        setProfileData,
        updateProfileState,

        stateAndCityStudent,
        zipCodeStudent,
        firstName,
        openDetailsModal,
        setOpenDetailsModal,
      }}
    >
      {children}
    </JobOffersContext.Provider>
  );
}
