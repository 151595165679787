import React, { useState, useContext, useEffect } from 'react';
import {
  Container,
  Name,
  BoxImage,
  Divider,
  ContentInfos,
  DividerInfos,
  ButtonFeedback,
  BoxInformation,
  AcademicInformation,
  ContainerInformation,
  RemoveCandidateButton,
} from './styled';
import placeholderImage from 'assets/img/placeholder.jpg';
import ModalViewFeedback from '../../../ModalViewFeedback';
import ModalShowEvidence from 'components/ModalShowEvidence';
import ModalSendYourFeedback from '../../../ModalFeedbackStudent';
import { getFeedbackStudent } from 'views/StaffPages/ExtensionProject/services';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import { configUnpublishProjects } from 'views/StaffPages/ExtensionProject/utils/texts';
import ModalInformation from 'components/informationModal';
import ModalUnpublishProject from '../../../ModalUnpublishProject';

export default function EnrolledStudents({
  registeredStudent,
  getStudents,
  limitPerPage,
  offset,
  setScore,
  finalization,
  extracurriculum,
  status,
  students,
  setOpenModalStudents,
}) {
  const [openModalFeedbackStudent, setOpenModalFeedbackStudent] =
    useState(false);
  const [openModalViewFeedback, setOpenModalViewFeedback] = useState(false);
  const [feedbackStudent, setFeedbackStudent] = useState({});
  const {
    extensionProjectId,
    getAllToFinishProjects,
    appliedFilters,
    getCountProjects,
    getAllInProgressProjects,
    getAllFutureProjects,
  } = useContext(ExtensionProjectsContext);
  const [modalEvidencesOpen, setModalEvidencesOpen] = useState(false);
  const [modalRemoveAndUnpublish, setModalRemoveAndUnpublish] = useState(false);
  const [modalUnpublishProject, setModalUnpublishProject] = useState(false);

  const handleRemoveStudent = () => {
    const oneStudent =
      students.filter((student) => student.status === 'enrolled').length === 1;

    if (oneStudent && status === true) {
      setModalRemoveAndUnpublish(true);
    } else {
      setOpenModalFeedbackStudent(true);
    }
  };

  const closeModalUnpublish = () => {
    setModalRemoveAndUnpublish(false);
  };

  const handleOpenModalUnpublish = () => {
    setModalUnpublishProject(true);
    setModalRemoveAndUnpublish(false);
  };

  const handleViewFeedback = async (feedbackId) => {
    const data = await getFeedbackStudent(feedbackId);
    setFeedbackStudent(data);
    setOpenModalViewFeedback(true);
  };

  useEffect(() => {
    setScore && setScore(registeredStudent.score);
  }, []);

  const closeModalFeedback = () => {
    setModalUnpublishProject(false);
    setOpenModalStudents(false);
    getAllFutureProjects(appliedFilters, true);
    getAllToFinishProjects(appliedFilters, true);
    getAllInProgressProjects(appliedFilters, true);
    getCountProjects(true);
  };

  return (
    <Container>
      <ModalShowEvidence
        open={modalEvidencesOpen}
        setOpen={setModalEvidencesOpen}
        id={extensionProjectId}
        useTab={false}
        studentId={registeredStudent.id}
        uniqueStudent={true}
        seeAllEvidencesStudent={false}
      />
      <div className="wrapper">
        <BoxImage>
          <img
            src={registeredStudent.avatar}
            alt="avatar"
            onError={(event) => {
              event.target.src = placeholderImage;
              event.target.onerror = null;
            }}
          />
        </BoxImage>
        <ContainerInformation>
          <BoxInformation>
            <ContentInfos>
              <Name>{registeredStudent.name}</Name>

              <AcademicInformation>
                {registeredStudent &&
                  registeredStudent.courses.map((item) => (
                    <div className="wrapperFlex">
                      <p>
                        {item.course__name} - {item.semester}° semestre
                      </p>
                    </div>
                  ))}

                <div className="wrapperFlex">
                  <p>Código: {registeredStudent.id}</p>
                </div>
              </AcademicInformation>
            </ContentInfos>
          </BoxInformation>

          <div className="action-buttons">
            <RemoveCandidateButton
              onClick={() => handleRemoveStudent()}
              removed={registeredStudent.status === 'removed'}
            >
              {registeredStudent.status === 'removed'
                ? 'Estudante removido'
                : extracurriculum
                ? 'Remover estudante'
                : 'Remover do projeto'}
            </RemoveCandidateButton>

            {registeredStudent.feedback_id !== null && (
              <ButtonFeedback
                onClick={() =>
                  handleViewFeedback(registeredStudent.feedback_id)
                }
              >
                Visualizar Feedback
              </ButtonFeedback>
            )}
          </div>

          {openModalFeedbackStudent && (
            <ModalSendYourFeedback
              isOpen={openModalFeedbackStudent}
              closeModalFeedbackStudent={setOpenModalFeedbackStudent}
              student={registeredStudent}
              getStudents={getStudents}
              limitPerPage={limitPerPage}
              offset={offset}
              finalization={finalization}
            />
          )}

          {openModalViewFeedback && (
            <ModalViewFeedback
              isOpen={openModalViewFeedback}
              setOpenModalFeedback={setOpenModalViewFeedback}
              feedback={feedbackStudent}
            />
          )}

          {modalRemoveAndUnpublish && (
            <ModalInformation
              config={configUnpublishProjects}
              closeModalFunction={() => closeModalUnpublish()}
              modalOpenState={modalRemoveAndUnpublish}
              buttonCancelFunction={() => handleOpenModalUnpublish()}
              buttonConfirmFunction={() => closeModalUnpublish()}
            />
          )}

          {modalUnpublishProject && (
            <ModalUnpublishProject
              isOpen={modalUnpublishProject}
              closeTheParentModal={setModalRemoveAndUnpublish}
              setOpenModalUnpublish={setModalUnpublishProject}
              projectId={extensionProjectId}
              closeModalFeedback={closeModalFeedback}
            />
          )}
        </ContainerInformation>
      </div>
      {extracurriculum && <Divider />}
    </Container>
  );
}
