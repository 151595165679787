import axios from 'axios';
import httpRequest from 'services/university/HttpRequest';

const CancelToken = axios.CancelToken;

let cancelGetAllAvailable;
let cancelGetAllFavorites;
let cancelGetAllFinished;

const getLearnings = async (page) => {
  return await httpRequest.get(
    `/api/internal/students/me/attractions/list${page}`,
    {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetAllAvailable = c;
      }),
    }
  );
};

const getFavoriteLearnings = async (page) => {
  return await httpRequest.get(
    `/api/internal/students/me/attractions/list?is_recommendation=true&${page}`,
    {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetAllFavorites = c;
      }),
    }
  );
};

const getFinishedLearnings = async (page) => {
  return await httpRequest.get(
    `/api/internal/students/me/attractions/list?is_finished=true&${page}`,
    {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetAllFinished = c;
      }),
    }
  );
};

const cancelGetAllLearnings = () => {
  cancelGetAllAvailable && cancelGetAllAvailable();
  cancelGetAllFavorites && cancelGetAllFavorites();
  cancelGetAllFinished && cancelGetAllFinished();
};

const getDetails = async (uuid) => {
  return await httpRequest.get(
    `/api/internal/students/me/attractions/detail/${uuid}`
  );
};

const finishedLearning = async (uuid) => {
  return await httpRequest.post(
    `/api/internal/students/me/attractions/finished/${uuid}`
  );
};

const getAccess = async (uuid) => {
  return await httpRequest.get(
    `/api/internal/students/me/attractions/access/${uuid}`
  );
};

const GenerateBlob = async (url) => {
  try {
    const response = await axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    });
    return window.URL.createObjectURL(new Blob([response.data]));
  } catch (error) {
    return error;
  }

  return response;
};

const getLearningFilters = async (paramList) => {
  const param = paramList ? paramList : '';

  return await httpRequest.get(
    `api/internal/students/me/attractions/filter/options?${param}`
  );
};

const newReaction = async (uuid, reaction) => {
  return await httpRequest.post(
    `/api/internal/students/me/attractions/${uuid}/reaction`,
    reaction
  );
};

const updateReaction = async (uuid, reactionId, reaction) => {
  return await httpRequest.patch(
    `/api/internal/students/me/attractions/${uuid}/reaction/${reactionId}/edit`,
    reaction
  );
};

const deleteReaction = async (uuid, reactionId) => {
  return await httpRequest.delete(
    `/api/internal/students/me/attractions/${uuid}/reaction/${reactionId}/edit`
  );
};

const newRecommend = async (uuid) => {
  return await httpRequest.post(
    `/api/internal/students/me/attractions/${uuid}/recommendation`
  );
};

const cancelRecommendation = async (uuid) => {
  return await httpRequest.delete(
    `/api/internal/students/me/attractions/${uuid}/recommendation`
  );
};

const getCount = async () => {
  return await httpRequest.get(`/api/internal/students/me/attractions/count`);
};

const getFairAndEventsListingsCount = async () => {
  return await httpRequest.get(
    `/api/internal/students/academic-events/amounts`
  );
};

const getFairAndEventsListings = async (
  type = 'inscriptions',
  searchParam = '',
  favorited = false,
  typeFinish = 'finished',
  query
) => {
  let baseUrl = `/api/internal/students/academic-events`;

  const queryParams = new URLSearchParams();
  if (type === 'opened' || type === 'inscriptions') {
    queryParams.append('type', type);
    queryParams.append('search', searchParam);
    queryParams.append('favorited', favorited.toString());
  }

  if (type === 'finish') {
    baseUrl += '/finish';
    queryParams.append('type', typeFinish);
    queryParams.append('search', searchParam);
  }

  if (query) {
    baseUrl += `?${query}`;
  }

  const prefix = baseUrl.includes('?') ? '&' : '?';
  const fullUrl = `${baseUrl}${prefix}${queryParams.toString()}`;

  const response = await httpRequest.get(fullUrl);

  return response;
};

const getFairAndEventsListingPagination = async (
  type,
  query,
  complementaryType
) => {
  const url =
    type === 'finish'
      ? `/api/internal/students/academic-events/finish?type=${complementaryType}&${query}`
      : `/api/internal/students/academic-events?type=${type}&${query}`;

  const response = await httpRequest.get(url);

  return response;
};

const postFairAndEventsSubscribeStudent = async (id) => {
  const response = await httpRequest.post(
    `api/internal/students/academic-events/${id}/registration/`
  );

  return response;
};

const verifyFairAndEventsSubscribeStudent = async (id) => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/${id}/registration-period/`
  );

  return response;
};

const getFairAndEventsSubscribeDetails = async (id, uuid) => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/${id}/registration/${uuid}`
  );
  return response;
};

const getFairAndEventsDetails = async (id) => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/${id}`
  );

  return response;
};

const getFairAndEventDetailsAndStatus = async (id) => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/${id}/external-access`
  );

  return response;
};

const deleteFairAndEventsSubscribe = async (id, uuid) => {
  const response = await httpRequest.delete(
    `api/internal/students/academic-events/${id}/registration/${uuid}`
  );

  return response;
};

const postCreateReaction = async (id, type) => {
  const response = await httpRequest.post(
    `api/internal/students/academic-events/${id}/${type}/`,
    {}
  );
  return response;
};

const getFairAndEventCertificate = async (uuid, isPrint = false) => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/certificate/${uuid}?isprint=${isPrint}`,
    {
      responseType: isPrint ? 'application/json' : 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
};

const getFairAndEventsFilterOptions = async () => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/options`
  );
  return response;
};

const getFairAndEventsFilterOptionsFinished = async () => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/finished/options`
  );
  return response;
};

const getFairAndEventsAssociatedAreas = async (param) => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/options?associated_areas=true&label=${param}`
  );
  return response;
};

const getFairAndEventsFinishedCount = async () => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/finish/amounts`
  );
  return response;
};

const getUniversityLogo = async () => {
  const response = await httpRequest.get(
    `api/internal/students/academic-events/university/logo`
  );
  return response;
};

export {
  getLearnings,
  getFavoriteLearnings,
  getFinishedLearnings,
  getDetails,
  finishedLearning,
  getAccess,
  GenerateBlob,
  getLearningFilters,
  newReaction,
  updateReaction,
  deleteReaction,
  newRecommend,
  cancelRecommendation,
  getCount,
  cancelGetAllLearnings,
  getFairAndEventsListings,
  postFairAndEventsSubscribeStudent,
  getFairAndEventsSubscribeDetails,
  getFairAndEventsDetails,
  getFairAndEventDetailsAndStatus,
  deleteFairAndEventsSubscribe,
  getFairAndEventsListingPagination,
  postCreateReaction,
  getFairAndEventsListingsCount,
  getFairAndEventCertificate,
  getFairAndEventsFilterOptions,
  getFairAndEventsAssociatedAreas,
  getFairAndEventsFinishedCount,
  getUniversityLogo,
  getFairAndEventsFilterOptionsFinished,
  verifyFairAndEventsSubscribeStudent,
};
