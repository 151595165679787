import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { CircularProgressBar } from '../NewCards/CircularProgress';
import { CompanyDetail } from 'components/CompanyDetail/CompanyDetail';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import Tooltip from 'components/NewTooltip';

import { IoIosArrowBack } from 'react-icons/io';
import {
  ContainerInfo,
  Header,
  Container,
  BackButton,
  FragmentColor,
  ButtonDetailsModal,
  ModalEnrollButton,
  TextName,
  Box,
  ContainerContent,
  ReversedColumnOnMobileContainerContent,
  JobTagsContainer,
  TextParagraph,
  CustomTextParagraph,
  BoxModal,
  ContainerColumn,
  ContainerMatchTopBottom,
  ButtonTransparent,
  ContentIcon,
  ContainerCenter,
  ContainerLogo,
  ListParagraph,
  ContainerIconAndText,
} from './styled';
import LogoNetworkNotFound from 'assets/Network/logoCompanyNotFound';
import { SkeletonDetails } from './SkeletonDetails';
import { ModalUnderstendMatch } from '../Modals/ModalUnderstendMatch';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import { wrapperIconsJobOffers } from '../utils/wrapperIconsJobOffers';
import ModalFeedback from './ModalFeedback';
import ModalInformation from 'components/informationModal';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export function DetailsJobOffer({
  jobId,
  handleCloseModal,
  universityColor,
  match,
  onClickApplyToJobOffer,
  candidatureBeingProcessed,
  isApplication,
  isExclusive,
  haveCancelCandidature,
  setCancelCandidature,
  config,
  fetchListApplicationJobOffers,
  handleCloseModalExternal,
  isOtherExternal,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [isModalLoadingData, setIsModalLoadingData] = useState(false);
  const [isCompanyModalLoadingData, setIsCompanyModalLoadingData] =
    useState(false);
  const [detailsExclusiveJobs, setDetailsExclusiveJobs] = useState([]);
  const { IconSuitcase, IconLocation, IconHouse, IconPCD, IconCheck } =
    wrapperIconsJobOffers();

  const [detailsApplicationsJobs, setDetailsApplicationsJobs] = useState([]);
  const [companyDetails, setCompanyDetail] = useState(false);

  const [companyDetailId, setCompanyDetailId] = useState(null);
  const [companiesData, setCompaniesData] = useState([]);
  const [withVacanciesData, setWithVacanciesData] = useState([]);
  const [reloadIntern, setReloadIntern] = useState(false);
  const [openCanceledModal, setOpenCanceledModal] = useState(false);

  const openModalCancel = () => {
    setOpenCanceledModal(true);
  };

  const handleCloseModalCandidature = () => {
    setOpenCanceledModal(false);
  };

  const fetchDetails = async (jobId) => {
    if (isExclusive) {
      const data = await JobOffersApi.getDetailsExclusiveJobs(jobId);
      setDetailsExclusiveJobs && setDetailsExclusiveJobs(data);
    }

    if (isApplication) {
      const data = await JobOffersApi.getDetailsApplicationsJobs(jobId);
      setDetailsApplicationsJobs && setDetailsApplicationsJobs(data);
    }
  };

  useEffect(() => {
    fetchDetails(jobId);
  }, []);

  const detailsJob = isExclusive
    ? detailsExclusiveJobs?.data
    : detailsApplicationsJobs.data;

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str.slice(1);
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };

  const onClickCloseModal = () => {
    setOpenModal(false);
  };

  const onClickOpenFeedbackModal = () => {
    setOpenFeedbackModal(true);
  };

  const onClickCloseFeedbackModal = () => {
    setOpenFeedbackModal(false);
  };

  const onClickCompanyDetails = (id) => {
    setCompanyDetailId(id);
    setCompanyDetail(true);
  };

  const closeCompanyDetails = () => {
    setCompanyDetail(false);
  };

  const forceReloadIntern = () => {
    setReloadIntern(!reloadIntern);
  };

  const closeOtherExternalJobs = () => {
    if (isOtherExternal) {
      handleCloseModalExternal();
    } else {
      handleCloseModal();
    }
  };

  const closeModalCandidature = () => {
    if (!isOtherExternal) {
      handleCloseModal();
    }
  };

  const handleFavorite = async (data) => {
    const { id, isFavorite } = data;
    let companiesDataLocal = companiesData;
    let withVacanciesDataLocal = withVacanciesData;
    if (companiesData.filter((company) => company.id === id)) {
      companiesDataLocal.forEach((item) => {
        if (item.id === id) {
          item.isFavorite = !isFavorite;
        }
      });
      setCompaniesData(companiesDataLocal);
      forceReloadIntern();
    }
    if (withVacanciesData.filter((company) => company.id === id)) {
      withVacanciesDataLocal.forEach((item) => {
        if (item.id === id) {
          item.isFavorite = !isFavorite;
        }
      });
      setWithVacanciesData(withVacanciesDataLocal);
      forceReloadIntern();
    }
  };

  const fetchCancelJobOffer = async (id) => {
    try {
      const data = await JobOffersApi.fetchCancelJobOffer(id);
      if (data.status === 204) {
        setCancelCandidature(data);
        fetchListApplicationJobOffers &&
          (await fetchListApplicationJobOffers());
        customSnackbar('Candidatura cancelada com sucesso!', 'confirmation');
        setOpenCanceledModal(false);
        closeOtherExternalJobs();
      } else {
        customSnackbar(
          'Erro ao cancelar candidatura, tente novamente mais tarde.',
          'error'
        );
      }
    } catch (error) {
      customSnackbar(
        'Erro ao cancelar candidatura, tente novamente mais tarde.',
        'error'
      );
      return error;
    }
  };

  function pluralize(quantity, singular, plural) {
    return quantity === 1 ? `${quantity} ${singular}` : `${quantity} ${plural}`;
  }

  const quantity = detailsJob?.quantity;
  const returnQuantity =
    quantity !== null
      ? pluralize(quantity, 'vaga disponível', 'vagas disponíveis')
      : null;

  return (
    <>
      {openCanceledModal && (
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={openCanceledModal}
          config={config}
          buttonCancelFunction={handleCloseModalCandidature}
          buttonConfirmFunction={() => fetchCancelJobOffer(detailsJob.id)}
          closeModalFunction={handleCloseModalCandidature}
        />
      )}
      {companyDetails === true && companyDetailId !== null && (
        <CompanyDetail
          id={companyDetailId}
          closeModal={closeCompanyDetails}
          handleFavorite={handleFavorite}
          student
          setIsLoadingData={setIsCompanyModalLoadingData}
          closeJobDetails={closeOtherExternalJobs}
        />
      )}
      {openModal && (
        <ModalUnderstendMatch
          closeModal={onClickCloseModal}
          detailsJob={detailsJob}
          id={detailsJob.id}
          setIsLoadingData={setIsModalLoadingData}
        />
      )}
      {openFeedbackModal && (
        <ModalFeedback
          feedback={detailsJob.application_feedback}
          handleCloseModal={onClickCloseFeedbackModal}
          universityColor={universityColor}
        />
      )}
      {detailsJob === null || detailsJob === undefined ? (
        <SkeletonDetails
          handleCloseModal={(e) => closeOtherExternalJobs(e)}
          universityColor={universityColor}
          hasIcon={true}
        />
      ) : (
        <BoxModal>
          <Container>
            <ContainerInfo>
              <FragmentColor color={universityColor}>
                <BackButton
                  onClick={(e) => closeOtherExternalJobs(e)}
                  color={universityColor}
                >
                  <IoIosArrowBack />
                  <p>Voltar</p>
                </BackButton>
              </FragmentColor>
              <Header>
                <ContainerLogo>
                  {detailsJob.logo === null || detailsJob.logo === '' ? (
                    <LogoNetworkNotFound color={universityColor} />
                  ) : (
                    <img src={detailsJob.logo} alt="Logo da empresa" />
                  )}
                </ContainerLogo>
                {detailsJob.company_id !== null ? (
                  <ButtonDetailsModal
                    color={universityColor}
                    onClick={() => onClickCompanyDetails(detailsJob.company_id)}
                  >
                    {isCompanyModalLoadingData ? (
                      <FilterLoading width={'172.5px'} height={'16px'} />
                    ) : (
                      'Visualizar perfil da empresa'
                    )}
                  </ButtonDetailsModal>
                ) : null}
                <TextName>{detailsJob.company_name}</TextName>
              </Header>

              <Box>
                <ReversedColumnOnMobileContainerContent>
                  <ContainerColumn>
                    {isExclusive && (
                      <ModalEnrollButton
                        color={universityColor}
                        onClick={async () => {
                          await onClickApplyToJobOffer();
                        }}
                      >
                        {candidatureBeingProcessed ? (
                          <FilterLoading width={'88px'} height={'16px'} />
                        ) : (
                          'Candidatar-se'
                        )}
                      </ModalEnrollButton>
                    )}

                    {haveCancelCandidature && (
                      <ModalEnrollButton
                        color={universityColor}
                        onClick={() => openModalCancel()}
                      >
                        {candidatureBeingProcessed ? (
                          <FilterLoading width={'88px'} height={'16px'} />
                        ) : (
                          'Cancelar candidatura'
                        )}
                      </ModalEnrollButton>
                    )}

                    {detailsJob.application_feedback && (
                      <ModalEnrollButton
                        color={universityColor}
                        onClick={onClickOpenFeedbackModal}
                      >
                        Visualizar feedback
                      </ModalEnrollButton>
                    )}

                    <CustomTextParagraph
                      fontSize={'13px'}
                      fontWeight={'400'}
                      color={'#949494'}
                    >
                      CÓDIGO: {detailsJob.id}
                    </CustomTextParagraph>

                    <CustomTextParagraph fontSize={'24px'} fontWeight={'700'}>
                      {detailsJob.name}
                    </CustomTextParagraph>

                    <CustomTextParagraph
                      fontSize={'14px'}
                      fontWeight={'400'}
                      isItalicStyle
                      color={'#949494'}
                    >
                      Publicada em{' '}
                      {moment(detailsJob.created_at).format('DD/MM/YYYY')}
                    </CustomTextParagraph>

                    {detailsJob.end_offer_at && isExclusive && (
                      <CustomTextParagraph fontSize={'17px'} fontWeight={'400'}>
                        Inscreva-se até o dia{' '}
                        {moment(detailsJob.end_offer_at).format('DD/MM/YYYY')}
                      </CustomTextParagraph>
                    )}
                  </ContainerColumn>

                  {(match > 0 || match === 0) && (
                    <ContainerMatchTopBottom>
                      <Tooltip
                        text={`Seu perfil combina ${match}% com a vaga`}
                        colorSchema={'black'}
                        direction={'top'}
                        minWidth="135px"
                        isMultiLine={true}
                      >
                        <CircularProgressBar match={match} totalWidth={140} />
                      </Tooltip>

                      <div className="bottom">
                        <ButtonTransparent
                          onClick={onClickOpenModal}
                          color={universityColor}
                        >
                          {isModalLoadingData ? (
                            <FilterLoading color={universityColor} />
                          ) : (
                            'Detalhes do match'
                          )}
                        </ButtonTransparent>
                      </div>
                    </ContainerMatchTopBottom>
                  )}
                </ReversedColumnOnMobileContainerContent>

                <JobTagsContainer>
                  <ContainerIconAndText>
                    <ContentIcon universityColor={universityColor}>
                      <IconHouse color={universityColor} />
                    </ContentIcon>
                    <TextParagraph>{detailsJob.modality}</TextParagraph>
                  </ContainerIconAndText>

                  <ContainerIconAndText>
                    <ContentIcon universityColor={universityColor}>
                      <IconSuitcase color={universityColor} />
                    </ContentIcon>
                    <TextParagraph>{detailsJob.application_type}</TextParagraph>
                  </ContainerIconAndText>

                  <ContainerIconAndText>
                    <ContentIcon universityColor={universityColor}>
                      <IconLocation color={universityColor} />
                    </ContentIcon>
                    <TextParagraph>
                      {detailsJob.location
                        ? detailsJob.location
                        : 'Não informado'}
                    </TextParagraph>
                  </ContainerIconAndText>

                  {detailsJob.quantity !== null ? (
                    <ContainerIconAndText>
                      <ContentIcon universityColor={universityColor}>
                        <IconCheck color={universityColor} />
                      </ContentIcon>
                      <TextParagraph>{returnQuantity}</TextParagraph>
                    </ContainerIconAndText>
                  ) : null}

                  {detailsJob.disability && detailsJob.disability.length > 0 ? (
                    <ContainerIconAndText>
                      <ContentIcon universityColor={universityColor}>
                        <IconPCD color={universityColor} />
                      </ContentIcon>
                      <TextParagraph>Exclusiva para PCD</TextParagraph>
                    </ContainerIconAndText>
                  ) : null}
                </JobTagsContainer>

                {detailsJob.description ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Atividades desenvolvidas pelo profissional
                      </TextParagraph>
                      <CustomTextParagraph textAlign={'justify'}>
                        {ReactHtmlParser(detailsJob.description)}
                      </CustomTextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.required_academic_progress ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Requisitos acadêmicos
                      </TextParagraph>
                      <TextParagraph>
                        {detailsJob.required_academic_progress}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.course_progress ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Andamento do curso desejado
                      </TextParagraph>
                      <TextParagraph>
                        {detailsJob.course_progress}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}
                {detailsJob.modality === 'Presencial' ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>Endereço</TextParagraph>
                      <TextParagraph>
                        {detailsJob.address ||
                        detailsJob.address_number ||
                        detailsJob.district ||
                        detailsJob.zip_code ? (
                          <>
                            {detailsJob.address
                              ? `${detailsJob.address}, `
                              : ''}
                            {detailsJob.address_number
                              ? `${detailsJob.address_number} - `
                              : ''}
                            {detailsJob.district
                              ? `${detailsJob.district}. `
                              : ''}
                            {detailsJob.zip_code
                              ? `CEP ${detailsJob.zip_code}`
                              : ''}
                          </>
                        ) : (
                          'Não informado.'
                        )}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}
                {detailsJob?.required_behavioral_attributes &&
                detailsJob?.required_behavioral_attributes?.length ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Requisitos comportamentais
                      </TextParagraph>
                      <ul>
                        {detailsJob.required_behavioral_attributes.map(
                          (attribute, index) => (
                            <ListParagraph key={index}>
                              {capitalizeFirstLetter(attribute)}
                            </ListParagraph>
                          )
                        )}
                      </ul>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob?.required_professional_sectors &&
                detailsJob?.required_professional_sectors?.length > 0 ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Requisitos profissionais
                      </TextParagraph>
                      <TextParagraph>
                        {detailsJob.required_professional_sectors.map(
                          (sector, index) => (
                            <ListParagraph key={index}>
                              {capitalizeFirstLetter(sector)}
                            </ListParagraph>
                          )
                        )}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob?.required_attributes ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Pré-requisitos obrigatórios
                      </TextParagraph>
                      <TextParagraph>
                        {detailsJob?.required_attributes}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob?.shift ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>Turno</TextParagraph>
                      <TextParagraph>
                        {detailsJob.shift.length > 1
                          ? detailsJob.shift?.join('/')
                          : detailsJob.shift}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob?.perks_offered &&
                detailsJob?.perks_offered?.length > 0 ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>Benefícios</TextParagraph>
                      <ul>
                        {detailsJob.perks_offered.map((perks, index) => (
                          <ListParagraph key={index}>
                            {capitalizeFirstLetter(perks)}
                          </ListParagraph>
                        ))}
                      </ul>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.another_perks ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Detalhes dos benefícios
                      </TextParagraph>
                      <TextParagraph>
                        {ReactHtmlParser(detailsJob.another_perks)}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob?.disability &&
                detailsJob?.disability?.length > 0 ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>PCD</TextParagraph>
                      <ul>
                        {detailsJob.disability.map((disability, index) => (
                          <ListParagraph key={index}>
                            {capitalizeFirstLetter(disability)}
                          </ListParagraph>
                        ))}
                      </ul>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {detailsJob.divulgation_link === '' ||
                detailsJob.divulgation_link === null ? null : (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Link de acesso
                      </TextParagraph>
                      <TextParagraph isOtherExternal>
                        <a href={detailsJob.divulgation_link} target="_blank">
                          {detailsJob.divulgation_link}
                        </a>
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                )}

                {detailsJob.job_email === '' ||
                detailsJob.job_email === null ? null : (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        E-mail para contato
                      </TextParagraph>
                      <TextParagraph isOtherExternal>
                        {detailsJob.job_email}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                )}

                {detailsJob.salary !== '0.00' ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Faixa salarial
                      </TextParagraph>
                      <TextParagraph>
                        R${' '}
                        {new Intl.NumberFormat(`id`, {
                          minimumFractionDigits: 2,
                        }).format(detailsJob.salary)}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                {isExclusive && (
                  <ContainerCenter>
                    <ButtonDetailsModal
                      color={universityColor}
                      onClick={async () => {
                        await onClickApplyToJobOffer();
                        closeModalCandidature();
                      }}
                    >
                      {candidatureBeingProcessed ? (
                        <FilterLoading width={'88px'} height={'16px'} />
                      ) : (
                        'Candidatar-se'
                      )}
                    </ButtonDetailsModal>
                  </ContainerCenter>
                )}

                {haveCancelCandidature && (
                  <ButtonDetailsModal
                    color={universityColor}
                    onClick={() => openModalCancel()}
                  >
                    {candidatureBeingProcessed ? (
                      <FilterLoading width={'88px'} height={'16px'} />
                    ) : (
                      'Cancelar candidatura'
                    )}
                  </ButtonDetailsModal>
                )}
              </Box>
            </ContainerInfo>
          </Container>
        </BoxModal>
      )}
    </>
  );
}
