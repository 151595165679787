import {
  Title,
  Container,
  HelpText,
  Question,
  HelpTextQuestion,
  Wrapper,
} from './styled';
import { Form } from '@unform/web';
import { useContext, useEffect, useState } from 'react';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';
import ModalShowEvidence from 'components/ModalShowEvidence';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';

export function StepOne({ refStepOne }) {
  const [modalShowEvidencesOpen, setModalOpenEvidencesOpen] = useState(false);

  const { formDataFinishProject, extensionProjectId } = useContext(
    ExtensionProjectsContext
  );

  return (
    <Container>
      <ModalShowEvidence
        id={extensionProjectId}
        open={modalShowEvidencesOpen}
        setOpen={setModalOpenEvidencesOpen}
      />
      <Title> Dados do projeto </Title>
      <HelpText>
        Aqui você pode fornecer informações sobre as pessoas que foram
        impactadas e participaram da ação, além de inserir documentos de
        evidência.
      </HelpText>

      <Form ref={refStepOne} initialData={formDataFinishProject}>
        <Wrapper>
          <Question>
            Público externo <span>(Opcional)</span>
          </Question>
          <HelpTextQuestion>
            Descreva quais e quantos foram os agentes externos (segurança
            pública, prefeitura, secretarias etc) que participaram dessa ação.
          </HelpTextQuestion>
        </Wrapper>
        <DescriptionInput
          name="externalPublic"
          placeholder="Exemplo: 10 oficiais de segurança pública, 5 pessoas da prefeitura, 20 pessoas da secretaria da educação."
          charLimit={3000}
          charCount="/3000"
        />
        <Wrapper>
          <Question>
            Público impactado <span>(Opcional)</span>
          </Question>
          <HelpTextQuestion>
            Especifique os resultados da ação: quantas pessoas foram impactadas
            e/ou beneficiadas.
          </HelpTextQuestion>
        </Wrapper>

        <DescriptionInput
          name="impactedPublic"
          placeholder="Exemplo: 155 pessoas no total - 100 pessoas da comunidade,
          50 estudantes do curso de estética, 5 professores do curso de estética."
          charLimit={3000}
          charCount="/3000"
        />
      </Form>
    </Container>
  );
}
