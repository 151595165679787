import React, { useEffect, useRef, Fragment, useState } from 'react';
import { useField } from '@unform/core';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR'; // the locale you want
import { AiOutlineCloseCircle } from 'react-icons/ai';

import '../styles/reactDatePicker.css';

import { UnInputLabel } from '../styles/unformCompany';
import { ContainerLabelInput, ErrorWrapper } from '../styles/styleForm';

registerLocale('ptBR', ptBR);

export default function DatePickerCustom({
  placeholder,
  label,
  name,
  ...rest
}) {
  const datepickerRef = useRef(null);
  const [startDate, setstartDate] = useState(null);
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      setstartDate(new Date(defaultValue));
    }
  }, [defaultValue]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <DatePicker
        ref={datepickerRef}
        locale="ptBR"
        className={`react-datepicker__input-container input ${
          error ? 'input-error' : ''
        }`}
        error={error}
        isClearable
        onFocus={clearError}
        dateFormat="dd/MM/yyyy"
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1).toUpperCase()}
        selected={startDate}
        placeholderText={placeholder}
        minDate={new Date()}
        onChange={(date) => setstartDate(date)}
        {...rest}
      />
      <ErrorWrapper style={{ marginTop: '5px', marginBottom: '5px' }}>
        {error && (
          <Fragment>
            <AiOutlineCloseCircle size={18} color={'#f10909'} />
            {error}
          </Fragment>
        )}
      </ErrorWrapper>
    </ContainerLabelInput>
  );
}
