import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Container, ProjectListContainer, Title, WhiteCard } from './styled';
import BaseLayoutContext from 'contexts/base-layout';
import ProjectCard from '../../components/Card';
import { projectExtensionApi } from '../../services/api';
import GenericMessage from '../../../../../components/GenericMessage';
import { SeeMoreButton } from '../MyProjectsTab/screens/styled';
import { ProjectCardSkeleton } from '../../components/CardSkeleton';
import { useProjectExtensionContext } from '../../context';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import DetailsModal from '../../components/DetailsModal';

export const AvailableProjectsTab = () => {
  const { universityColor } = useContext(BaseLayoutContext);
  const [availableProjects, setAvailableProjects] = useState(null);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const {
    studentFirstName,
    openDetailsModal,
    fetchTabsCount,
    closeDetailsModal,
    modalData,
  } = useProjectExtensionContext();

  const fetchAvailableProjects = async () => {
    const [responseExtensions, responseImages] = await Promise.all([
      projectExtensionApi.getAvailableExtensions(),
      projectExtensionApi.getImagesAvailableExtensions(),
    ]);
    setIsLoadingList(false);
    const extensions = responseExtensions.results;
    setHasNextPage(!!responseExtensions.next);
    const images = responseImages.results;
    if (extensions != null) {
      if (images) {
        const extensionsWithImages = extensions.map((extension) => {
          const image = images.find((image) => image.id === extension.id);
          extension.image = image.attraction_image;
          return extension;
        });
        setAvailableProjects(extensionsWithImages);
        return;
      }
      setAvailableProjects(extensions);
    }
  };

  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (10 - 3 + 1) + 3);
  }, []);

  useEffect(() => {
    fetchAvailableProjects();
  }, []);

  const handleSubscribe = async (item) => {
    try {
      await projectExtensionApi.enrollInProject(item.extension_project_id);
      customSnackbar('Inscrição realizada com sucesso!', 'confirmation');
      fetchAvailableProjects();
      fetchTabsCount();
    } catch (error) {
      customSnackbar('Erro ao se inscrever no projeto.', 'error');
    }
  };

  const getButtons = (item) => {
    const isFullyEnrolled = item.enrolled_students_percentual === '100%';

    const btns = [
      {
        label: isFullyEnrolled ? 'Visualizar Detalhes' : 'Detalhes',
        isDetails: isFullyEnrolled ? false : true,
        onClick: openDetailsModal,
      },
    ];

    if (!isFullyEnrolled) {
      btns.push({
        label: 'Inscrever-se',
        onClick: () => handleSubscribe(item),
      });
    }

    return btns;
  };

  const handleNextPage = useCallback(async () => {
    setIsLoadingList(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 10;
    const [responseExtensions, responseImages] = await Promise.all([
      projectExtensionApi.getAvailableExtensions(`limit=10&offset=${offset}`),
      projectExtensionApi.getImagesAvailableExtensions(
        `limit=10&offset=${offset}`
      ),
    ]);
    setIsLoadingList(false);
    const extensions = responseExtensions.results;
    setHasNextPage(!!responseExtensions.next);
    const images = responseImages.results;
    if (extensions != null) {
      if (images) {
        const extensionsWithImages = extensions.map((extension) => {
          const image = images.find((image) => image.id === extension.id);
          extension.image = image.attraction_image;
          return extension;
        });
        setAvailableProjects([...availableProjects, ...extensionsWithImages]);
        return;
      }
      setAvailableProjects([...availableProjects, ...extensions]);
    }
  }, [availableProjects, page]);

  const countProjects = availableProjects?.length;

  return (
    <Container color={universityColor}>
      <div>
        <h1>Extensão extracurricular</h1>
        <p>
          <strong>{studentFirstName}</strong>, sua instituição de ensino
          destacou {countProjects}{' '}
          {countProjects === 1 ? 'projeto' : 'projetos'} de extensão
          extracurricular para o seu desenvolvimento profissional
        </p>
      </div>

      <WhiteCard>
        <Title color={universityColor}>
          Projetos de extensão extracurricular
        </Title>
        {availableProjects?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhum projeto de extensão encontrado"
            subtitle="Os projetos de extensão disponíveis aparecerão aqui."
          />
        ) : (
          <ProjectListContainer>
            {availableProjects?.map((item, index) => (
              <ProjectCard
                key={index}
                item={item}
                buttons={getButtons(item)}
                hideStatus={true}
              />
            ))}
            {isLoadingList &&
              Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
                <ProjectCardSkeleton key={index} />
              ))}
          </ProjectListContainer>
        )}
        {hasNextPage && !isLoadingList && (
          <SeeMoreButton onClick={handleNextPage} color={universityColor}>
            Ver mais
          </SeeMoreButton>
        )}
      </WhiteCard>
      <DetailsModal item={modalData} onClose={closeDetailsModal} />
    </Container>
  );
};
