import { MenuItem } from '@szhsin/react-menu';
import { Menu, Button } from './menuStyle';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import downArrow from './downArrow.svg';
import DownArrowSVG from './downArrow';

export default function ButtonWithMenu({
  insideMenus,
  menuLabel,
  offsetX,
  buttonWidth,
  item,
  marginLeft,
  MinWidth,
  buttonHeight,
  fontWeight,
  fontSize,
  transparentButton,
}) {
  const menus = insideMenus?.map((menu) => (
    <MenuItem
      disabled={menu.disabled}
      onClick={() => menu.onChange(item?.id, item)}
    >
      {menu.name}
    </MenuItem>
  ));

  const color = transparentButton ? '#606062' : '#fff'

  return (
    <Menu
      menuButton={
        <Button
          buttonWidth={buttonWidth}
          buttonHeight={buttonHeight}
          fontSize={fontSize}
          fontWeight={fontWeight}
          transparentButton={transparentButton}
        >
          {menuLabel} <DownArrowSVG color={color} /> {' '}
        </Button>
      }
      transition
      offsetX={offsetX}
      marginLeft={marginLeft}
      MinWidth={MinWidth}
    >
      {menus}
    </Menu>
  );
}
