import React, { useEffect, useRef, Fragment, useState, useMemo } from 'react';
import { useField } from '@unform/core';
import JoditEditor from 'jodit-react';
import './joditStyled.css';
import {
  ContainerLabelTextAreaJodit,
  ErrorWrapper,
  UnInputLabel,
} from '../Style/BaseInputs';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export function JoditTextArea({
  label,
  name,
  setError,
  handleChange,
  ...rest
}) {
  const inputRef = useRef(null);
  const [description, setDescription] = useState('');
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setDescription(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',

      setValue: (ref, value) => {
        setDescription(value);
      },
    });
  }, [fieldName, registerField]);

  const config = {
    theme: 'summer',
    style: {
      'color-text': 'red',
      colorBorder: 'black',
      'color-panel': 'blue',
    },
    placeholder: 'Digite aqui...',
    language: 'pt_br',
    readonly: false,
    height: '10em',
    buttons: ['bold', 'italic', 'underline'],
    buttonsMD: ['bold', 'italic', 'underline'],
    buttonsSM: ['bold', 'italic', 'underline'],
    buttonsXS: ['bold', 'italic', 'underline'],
    removeButtons: [
      'source',
      '|',
      '|',
      'ul',
      'ol',
      '|',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      '|',
      'left',
      'center',
      'right',
      'justify',
      '|',
      'undo',
      'redo',
      '|',
      'hr',
      'eraser',
      'fullsize',
    ],
    showXPathInStatusbar: false,
    showCharsCounter: true,
    showWordsCounter: false,
    toolbarAdaptive: true,
    allowResizeX: false,
    allowResizeY: false,
    toolbarSticky: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    tabIndex: 0,
    disablePlugins: ['powered-by-jodit'],
    defaultActionOnPaste: 'insert_only_text',
  };

  const handleUpdate = (event) => {
    setDescription(event);
    setIsFocused(false);
    clearError();
  };

  const joditEditorMemo = useMemo(
    () => (
      <ContainerLabelTextAreaJodit error={error} isFocused={isFocused}>
        {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
        <div className="EditJodit">
          <JoditEditor
            ref={inputRef}
            value={description}
            config={config}
            onBlur={handleUpdate}
            defaultValue={defaultValue}
            onChange={(newContent) => {
              if (handleChange) {
                handleChange(newContent);
                inputRef.current.focus();
              }
            }}
            onFocus={() => setIsFocused(true)}
            {...rest}
          />
        </div>
      </ContainerLabelTextAreaJodit>
    ),
    [description, error, isFocused]
  );

  return (
    <Fragment>
      {joditEditorMemo}
      <ErrorWrapper>
        {error && (
          <Fragment>
            <AiOutlineCloseCircle size={18} color={'#f10909'} />
            {error}
          </Fragment>
        )}
      </ErrorWrapper>
    </Fragment>
  );
}
