import styled from 'styled-components';
import CheckboxCheck from 'assets/JobOffer/CheckboxCheck.svg';
import error from 'assets/Matriz/error.svg';

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.order ? 'row' : 'column' || 'column')};
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: ${(props) => props.marginTop};

  gap: 4px;

  input[type='checkbox'] {
    background-color: #f5f5f5;
    accent-color: #009291;
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
    margin: 0;
  }

  input[type='checkbox']:disabled {
    cursor: default;
  }

  input[type='radio'] {
    background-color: #f5f5f5;
    accent-color: #009291;
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    margin: 0;

    margin-top: 2px;
  }

  input[type='radio']:disabled {
    cursor: default;
  }
`;

export const ContainerLabelTextAreaJodit = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;

  ${({ error, isFocused }) =>
    isFocused
      ? `
      border: 1px solid #007BFF; 
      background-color: #E7F3FF; 
      border-radius: 3px;
    `
      : error
      ? `
      border: 0px solid #FFCECE; 
      background-color: #FFEDED; 
      border-radius: 3px;
    `
      : `
      border: unset; 
      background-color: white; 
      border-radius: 3px;
    `}

  .EditJodit .jodit-workplace + .jodit-status-bar:not(:empty) {
    ${({ error, isFocused }) =>
      isFocused
        ? `background-color: #E7F3FF;`
        : error && `background-color: #FFEDED;`}
  }

`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: right;
  gap: 4px;
  color: #606062;
  font-size: 12px;
  margin: 5px 0;
`;

export const ContainerLabelInputTextArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  position: relative;
  gap: 4px;

  textarea {
    resize: none;
    border: ${(props) =>
      props.error ? '1px solid #FFCECE' : ' 1px solid #d0d0d0'};
    border-radius: 4px;
    font-family: Roboto;
    font-size: 14px;
    padding: 4px;
    padding: 10px;
    background-color: ${(props) => (props.error ? '#FFEDED' : '#FFFF')};
  }
`;

export const ContainerLabelSelect = styled(ContainerLabelInput)`
  flex-direction: column;
  margin-top: ${(props) => props.marginTop};

  width: ${(props) => props.maxWidth};
  margin-right: 0;
`;

export const ErrorMessage = styled.span`
  color: #f00;
  margin-top: ${(props) => props.marginTop || '10px'};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: 2px;
  font-size: 12px;
  text-align: start;

  order: ${(props) => (props.order ? 2 : 3 || 3)};
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  margin-left: 2px;
  text-align: start;

  order: ${(props) => (props.order ? 2 : 1 || 0)};
`;

export const UnSelectLabel = styled(UnInputLabel)`
  order: unset;
`;

export const UnInput = styled.input`
  border: ${(props) =>
    props.error ? '1px solid #FFCECE' : ' 1px solid #d0d0d0'};
  width: ${(props) => props.maxWidth || '100%'};
  height: ${(props) => props.maxHeightInput || 'auto'};
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 0px;

  background-color: ${(props) => (props.error ? '#FFEDED' : '#FFFF')};
  order: ${(props) => (props.order ? 1 : 2 || 2)};

  &:focus {
    border: 1px solid #2784ff;
  }
`;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  gap: 10px;
  margin-bottom: ${({ spacing }) => `${spacing}px`};
`;

export const CheckboxInput = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;

  &:checked {
    border: none;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    background-image: url(${CheckboxCheck});
    background-repeat: no-repeat;
    background-position: center;
  }

  &:focus {
    outline: none;
  }
`;

export const CheckboxGroupInput = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;

  &:checked {
    border: none;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    background-image: url(${CheckboxCheck});
    background-repeat: no-repeat;
    background-position: center;
  }

  &:focus {
    outline: none;
  }
`;

export const CheckboxText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #606062;
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;
  order: 2;
  font-size: 12px;
  &:before {
    content: ${(props) => props.error && `url(${error})`};
    width: 9px;
    height: 16px;
  }
`;
