import React, { Component } from 'react';
import { FastField, Formik } from 'formik';
import * as Yup from 'yup';
import SearchableSelect from 'react-select';
import classNames from 'classnames';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  TextField,
} from '@material-ui/core';
import {
  getOptions,
  getProjects,
  savePortfolioProjects,
  updatePortfolioProjects,
} from 'services/student/portfolioProjects';
import portfolioProjectsAdapter from 'adapters/student/portfolioProjectsAdapter';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles
  from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { arrayOf, bool, func, shape } from 'prop-types';
import DateTimePicker from 'components/DateTimePicker';
import { isValidDate } from 'utils/validation';
import { triggerHotjarEvent } from 'utils/hotjar';
import { Mixpanel } from 'utils/mixpanel';
import SaveButtonsTests from 'components/SaveButtonsTests';
import useDefineColor from 'constants/colors';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { actionsProfileStore } from 'stores/profile.store';

const PortfolioProjectsSchema = Yup.object().shape({
  title: Yup.string()
    .max(255, 'O campo aceita no máximo 255 caracteres')
    .required('Você deve informar o título do projeto.'),
  finishedAt: Yup.date()
    .required('Campo obrigatório.')
    .max(new Date(), 'A "data de conclusão" não pode ser maior que hoje.')
    .test(
      'isValidFinishedAt',
      'Preencha a data no formato dia/mês/ano',
      (value) => {
        if (value) return isValidDate('finishedAt');
        return false;
      }
    )
    .typeError('Preencha a data no formato dia/mês/ano'),
  hasRelationCompany: Yup.boolean(),
  companyChallenge: Yup.string()
    .when('hasRelationCompany', {
      is: true,
      then: Yup.string().required('Campo Obrigatório.'),
    })
    .nullable(),
  classification: Yup.string().required('Campo obrigatório').nullable(),

  dedicatedTime: Yup.number()
    .required('Campo obrigatório.')
    .min(1, 'Informe um número válido.')
    .test(
      'len',
      'Infome no máximo 5 dígitos.',
      (val) => val && val.toString().length <= 5
    ),
  link: Yup.string()
    .max(499, 'O campo Link aceita no máximo 499 caracteres')
    .test('link', 'Digite um link válido', () => {
      const regexpFullUrl =
        /^(?:(ftp|http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9])+([\/\w-.?=]*)/g;
      const linkValue = document.getElementById('link').value;

      if (regexpFullUrl.test(linkValue)) {
        return true;
      }
      return false;
    }),
  knowledge: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.number().nullable(),
      })
    )
    .required('Campo obrigatório')
    .max(5, 'Você só pode selecionar 5 (cinco) conhecimentos.')
    .nullable(),
});

class PortfolioProjectsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {},
      initialValues: {
        knowledge: null,
        title: '',
        link: '',
        hasRelationCompany: false,
        dedicatedTime: 0,
        classification: '',
        finishedAt: '',
        companyChallenge: '',
      },
    };
  }

  async componentDidMount() {
    const { handleIsLoadingState, projects, isEdit } = this.props;

    if (isEdit) {
      const {
        knowledge,
        id,
        title,
        link,
        section,
        dedicatedTime,
        classification,
        finishedAt,
        companyChallenge,
      } = projects;
      this.setState({
        initialValues: {
          knowledge,
          id,
          title,
          link,
          section: section || { label: 'Outros', value: null },
          dedicatedTime,
          classification,
          finishedAt,
          companyChallenge,
          hasRelationCompany: !!companyChallenge,
        },
      });
    }

    await this.fetchOptions();
    handleIsLoadingState(false);
    if (this.props.metadata.university_slug !== 'uniexemplo') {
      triggerHotjarEvent('project_portfolio');
    }
    Mixpanel.track('Abriu Formulário de Portfólio de Projetos');
  }

  fetchOptions = async () => {
    const options = await getOptions();
    this.setState({
      options,
    });
  };

  render() {
    const {
      classes,
      isEdit,
      closeModal,
      openSnackbar,
      handleIsLoadingState,
      setVisibleProjects,
      visibleProjects,
      universityColor,
      cardData,
      cardDataKey,
      handleVisibleItem,
      fetchGamificationData,
    } = this.props;

    const { initialValues, options } = this.state;
    const { updateCard } = actionsProfileStore;
    return (
      <Grid
        container
        className={classes.fullScreenGridContainer}
        alignItems="center"
        direction="column"
      >
        <Grid item xs md={12} className={classes.fullScreenGrid}>
          <h4>Cadastre seus melhores trabalhos acadêmicos.</h4>
        </Grid>
        <Grid item xs md={12} className={classes.fullScreenGrid}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={PortfolioProjectsSchema}
            onSubmit={async (values, actions) => {
              handleIsLoadingState(true);

              const newProject = isEdit
                ? await updatePortfolioProjects(
                    portfolioProjectsAdapter(values)
                  )
                : await savePortfolioProjects(portfolioProjectsAdapter(values));

              const response = await getProjects();

              if (!isEdit && visibleProjects?.length < 3) {
                const updateProjects = [...visibleProjects, newProject.id];

                const visibilityItemConfig = {
                  items: updateProjects,
                  item: newProject,
                  reqKey: 'portfolio',
                  isUpdate: true,
                  cardData,
                  dataKey: cardDataKey,
                  updateKey: 'portfolio',
                  setVisibleItems: setVisibleProjects,
                };

                handleVisibleItem(visibilityItemConfig);
              }

              if (newProject && newProject.message) {
                customSnackbar(
                  ` Erro ao gravar seus dados. Se o erro persistir,
                    entre em contato com o suporte avisando sobre o erro:
                    ${newProject.message}
                  `,
                  'error'
                );
              } else {
                cardDataKey && updateCard(cardDataKey, response);
                await fetchGamificationData();
              }

              actions.setSubmitting(false);
              handleIsLoadingState(false);
              closeModal();
              Mixpanel.track('Salvou Formulário de Portfólio de Projetos');
              customSnackbar('Projeto salvo com sucesso!', 'confirmation');
            }}
            render={({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className={classes.formContainer}>
                {isEdit && (
                  <FastField
                    type="hidden"
                    id="id"
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
                <List className={classes.list}>
                  {/* Project title */}
                  <ListItem className={classes.listItem}>
                    <FastField
                      autoFocus
                      className={classes.inputField}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        FormLabelClasses: {
                          focused: classes.inputField,
                        },
                      }}
                      name="title"
                      variant="filled"
                      id="title"
                      label="Título"
                      placeholder="Ex.: Sistemas Intel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.title && !!touched.title}
                      value={values.title}
                      component={TextField}
                      required
                    />
                    {errors.title && touched.title ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.title}
                      </FormHelperText>
                    ) : null}
                  </ListItem>

                  {/* Conhecimentos */}
                  <ListItem className={classes.listItem}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel
                        htmlFor="knowledge"
                        shrink
                        required
                        error={errors.knowledge && touched.knowledge}
                      >
                        Principais Conhecimentos/Especialidades
                      </InputLabel>
                      {((isEdit && values.knowledge) || !isEdit) && (
                        <SearchableSelect
                          name="knowledge"
                          id="knowledge"
                          isClearable={false}
                          InputLabel={{
                            shrink: true,
                          }}
                          menuPlacement="bottom"
                          placeholder="Selecione até 5"
                          isMulti
                          noOptionsMessage={() => 'Não existe nenhuma opção.'}
                          isSearchable
                          classNamePrefix="react-select"
                          className={classNames([
                            classes.selectSection,
                            classes.selectSectionAutoHeight,
                          ])}
                          onBlur={(event) =>
                            setFieldTouched('knowledge', event.label)
                          }
                          onInputChange={this.handleInputChange}
                          onChange={(event) => {
                            if (event) {
                              setFieldValue('knowledge', event);
                            }
                          }}
                          options={options.knowledge || []}
                          defaultValue={values.knowledge}
                        />
                      )}
                    </FormControl>
                    {!!errors.knowledge && !!touched.knowledge && (
                      <FormHelperText className={classes.helperText}>
                        {errors.knowledge}
                      </FormHelperText>
                    )}
                  </ListItem>

                  {/* Setores */}
                  {((isEdit && values.section) || !isEdit) && (
                    <ListItem className={classes.listItem}>
                      <FormControl variant="filled" fullWidth>
                        <InputLabel
                          htmlFor="section"
                          shrink
                          error={errors.section && touched.section}
                        >
                          Área
                        </InputLabel>
                        <SearchableSelect
                          name="section"
                          id="section"
                          InputLabel={{
                            shrink: true,
                          }}
                          menuPlacement="bottom"
                          placeholder="Selecione"
                          noOptionsMessage={() => 'Não existe nenhuma opção.'}
                          isSearchable
                          classNamePrefix="react-select"
                          className={classes.selectSection}
                          onBlur={(event) =>
                            setFieldTouched('section', event.label)
                          }
                          onInputChange={this.handleInputChange}
                          onChange={(event) => {
                            if (event) {
                              setFieldValue('section', event);
                            }
                          }}
                          options={options.sectors || []}
                          defaultValue={values.section}
                        />
                      </FormControl>
                      {!!errors.section && !!touched.section && (
                        <FormHelperText className={classes.helperText}>
                          {errors.section}
                        </FormHelperText>
                      )}
                    </ListItem>
                  )}
                  {/* Classificação */}
                  <ListItem className={classes.listItem}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel
                        htmlFor="classification"
                        shrink
                        required
                        error={errors.classification && touched.classification}
                      >
                        Classificação
                      </InputLabel>
                      <SearchableSelect
                        name="classification"
                        id="classification"
                        InputLabel={{
                          shrink: true,
                        }}
                        menuPlacement="bottom"
                        placeholder="Selecione"
                        noOptionsMessage={() => 'Não existe nenhuma opção.'}
                        isSearchable
                        classNamePrefix="react-select"
                        className={classes.selectSection}
                        onBlur={(event) =>
                          setFieldTouched('classification', event.label)
                        }
                        onInputChange={this.handleInputChange}
                        onChange={(event) => {
                          if (event) {
                            setFieldValue('classification', event);
                          }
                        }}
                        options={options.portfolio_classification || []}
                        value={values.classification}
                        defaultValue={values.classification}
                      />
                    </FormControl>
                    {!!errors.classification && !!touched.classification && (
                      <FormHelperText className={classes.helperText}>
                        {errors.classification}
                      </FormHelperText>
                    )}
                  </ListItem>
                  {/* Link */}
                  <ListItem className={classes.listItem}>
                    <FastField
                      className={classes.inputField}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        FormLabelClasses: {
                          focused: classes.inputField,
                        },
                      }}
                      name="link"
                      variant="filled"
                      id="link"
                      label="Link para o projeto"
                      placeholder="Ex.: http://meusite.com/meuportfolio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.link && touched.link}
                      value={values.link}
                      required
                      component={TextField}
                    />
                    {errors.link && touched.link ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.link}
                      </FormHelperText>
                    ) : null}
                  </ListItem>

                  {/* Horas Dedicadas */}
                  <ListItem className={classes.listItem}>
                    <TextField
                      className={classes.inputField}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        FormLabelClasses: {
                          focused: classes.inputField,
                        },
                      }}
                      name="dedicatedTime"
                      variant="filled"
                      id="dedicatedTime"
                      label="Tempo médio (horas) de dedicação"
                      placeholder="Tempo médio de dedicação a esse projeto."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.dedicatedTime && touched.dedicatedTime}
                      value={values.dedicatedTime || ''}
                      type="number"
                      required
                    />
                    {errors.dedicatedTime && touched.dedicatedTime ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.dedicatedTime}
                      </FormHelperText>
                    ) : null}
                  </ListItem>
                  {/* Data de conclusão */}
                  <ListItem className={classes.listItem}>
                    <DateTimePicker
                      className={
                        (classes.textFieldDatePicker, classes.inputField)
                      }
                      InputProps={{
                        className: [
                          classes.input,
                          classes.selectSection,

                          classes.selectSectionCard,
                        ],
                      }}
                      InputLabelProps={{
                        shrink: true,
                        FormLabelClasses: {
                          focused: classes.inputField,
                        },
                      }}
                      variant="filled"
                      name="finishedAt"
                      id="finishedAt"
                      type="date"
                      label="Projeto concluído em"
                      required
                      placeholder="Ex.: 17/11/2021"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.finishedAt}
                      error={errors.finishedAt && touched.finishedAt}
                    />
                    {errors.finishedAt && touched.finishedAt ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.finishedAt}
                      </FormHelperText>
                    ) : null}
                  </ListItem>
                  <ListItem
                    style={{ maxWidth: 584 }}
                    className={classNames([
                      classes.listItem,
                      classes.listItemPaddingTopZero,
                    ])}
                  >
                    <FormControlLabel
                      style={{ justifyContent: 'center !important' }}
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      labelPlacement="center"
                      label="Este projeto está relacionado aos desafios enfrentados por alguma empresa parceira da minha instituição de ensino."
                      control={
                        <Checkbox
                          id="hasRelationCompany"
                          name="hasRelationCompany"
                          checked={values.hasRelationCompany}
                          value={values.hasRelationCompany}
                          classes={{
                            checked:
                              classes[
                                `${useDefineColor(universityColor)}checkBox`
                              ],
                          }}
                          onChange={handleChange}
                          onClick={(event) => {
                            if (!event.target.checked) {
                              setFieldValue('companyChallenge', '');
                            }
                          }}
                          color="default"
                          tabIndex={-1}
                          disableRipple
                        />
                      }
                    />
                  </ListItem>
                  {/* Classificação */}
                  {values.hasRelationCompany && (
                    <ListItem className={classes.listItem}>
                      <FormControl variant="filled" fullWidth>
                        <InputLabel
                          htmlFor="companyPartner"
                          shrink
                          required
                          error={
                            errors.companyChallenge && touched.companyChallenge
                          }
                        >
                          Empresa Parceira
                        </InputLabel>
                        <SearchableSelect
                          name="companyChallenge"
                          id="companyChallenge"
                          InputLabel={{
                            shrink: true,
                          }}
                          menuPlacement="bottom"
                          placeholder="Selecione a empresa"
                          noOptionsMessage={() => 'Não existe nenhuma opção.'}
                          isSearchable
                          classNamePrefix="react-select"
                          className={classes.selectSection}
                          error={
                            !!errors.companyChallenge &&
                            !!touched.companyChallenge
                          }
                          onBlur={(event) =>
                            setFieldTouched('companyChallenge', event.label)
                          }
                          onInputChange={this.handleInputChange}
                          onChange={(event) => {
                            if (event) {
                              setFieldValue('companyChallenge', event);
                            }
                          }}
                          options={options.connected_companies || []}
                          defaultValue={values.companyChallenge}
                        />
                      </FormControl>
                      {!!errors.companyChallenge &&
                        !!touched.companyChallenge && (
                          <FormHelperText className={classes.helperText}>
                            {errors.companyChallenge}
                          </FormHelperText>
                        )}
                    </ListItem>
                  )}
                  {/* Save Button */}
                  <ListItem className={classes.listItem}>
                    <SaveButtonsTests
                      disabled={isSubmitting}
                      type="submit"
                      round
                    />
                  </ListItem>
                </List>
              </form>
            )}
          />
        </Grid>
      </Grid>
    );
  }
}

PortfolioProjectsForm.propTypes = {
  classes: shape.isRequired,
  closeModal: func.isRequired,
  updateProjectsState: func.isRequired,
  openSnackbar: func.isRequired,
  handleIsLoadingState: func.isRequired,
  isEdit: bool,
  projects: shape,
  visibleProjects: arrayOf(shape).isRequired,
  setVisibleProjects: func.isRequired,
};

PortfolioProjectsForm.defaultProps = {
  isEdit: false,
  projects: null,
};

export default withStyles(ProfessionalExperienceFormStyles)(
  PortfolioProjectsForm
);
