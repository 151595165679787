import React, { useContext, useEffect, useState } from 'react';

import {
  Container,
  ContainerButtonLoadMore,
  ContainerCards,
  InformationP,
} from './styled';
import { Card } from '../../UiComponents/Card';
import { LuBuilding2, LuCalendarClock, LuClock3 } from 'react-icons/lu';
import Button from '../../UiComponents/Button';
import { IoIosLaptop } from 'react-icons/io';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import { FaRegCalendar } from 'react-icons/fa';
import BaseLayoutContext from 'contexts/base-layout';
import { VisualizationHandler } from '../../Handlers/VisualizationHandler';
import Search from '../../UiComponents/Search';
import InsideFilter from '../../UiComponents/InsideFilter';
import { CardFormatHelper } from '../../Helpers/CardFormatHelper';
import FilterHandlers from '../../Handlers/FilterHandlers';
import GenericMessage from 'components/GenericMessage';
import { CurriculumContext } from 'contexts/CurriculumContext';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { CurriculumModal } from 'views/MyProfile/components/CurriculumModal/CurriculumModal';
import { profileAdapter } from 'adapters/student/profileAdapter';
import { checkCurriculumFilled } from 'services/student/profile';

function Availables({ history }) {
  const visualizationContextInstance = useVisualizationContext();

  const {
    profile,
    setIsOpenCurriculumModal,
    isOpenCurriculumModal,
    updateBehavioralState,
    updatePersonalityState,
    updateProfileState,
    updateAvatarState,
    updateInterestState,
    handleShowSpecialNeeds,
  } = useContext(CurriculumContext);

  const filterHandlersFactory = FilterHandlers(visualizationContextInstance);
  const [searchText, setSearchText] = useState('');
  const {
    availables,
    handleClickLoadMore,
    hasNextPagination,
    getFavoritedAvaibles,
    renderingByRequest,
    currentTab,
  } = visualizationContextInstance;
  const { universityColor } = useContext(BaseLayoutContext);

  const [blockButton, setBlockButton] = useState(false);

  const loadMore = async () => {
    setBlockButton(true);
    await handleClickLoadMore();
    setBlockButton(false);
  };

  const closeModalProfile = () => {
    setIsOpenCurriculumModal(false);

    if (renderingByRequest && history){
      history.push({
        pathname: '/cursos-e-eventos',
        state: {
          externalTab: 1,
          internalTab: currentTab,
        },
      });
    }

    return;
  };

  const widthButtons = `calc(50% - 7.5px)`;

  const createCardListing = availables?.value?.results.map((event) => {
    const modality =
      event.event_modality === 'presential' ? 'Presencial' : 'Online';
    const iconModality =
      event.event_modality === 'presential' ? (
        <LuBuilding2 color={universityColor} />
      ) : (
        <IoIosLaptop color={universityColor} />
      );

    const {
      canSubscribe,
      label: labelDate,
      subLabel,
    } = CardFormatHelper.formatLabelDate(event);

    const verifyCanSubscribe = async (event) => {
      const check = await checkCurriculumFilled();
      if (!check) {
        return setIsOpenCurriculumModal(true);
      }
      if (
        !(await VisualizationHandler.verifyFairAndEvents(
          visualizationContextInstance,
          event.id
        ))
      ) {
        closeModalProfile();
        return;
      }

      return VisualizationHandler.subscribeStudent(
        visualizationContextInstance,
        event.id
      );
    };
    const availableEvent = (event) => {
      if (
        CardFormatHelper.formatStatus(event).label ===
        'Aguardando início das inscrições'
      ) {
        return true;
      } else {
        return false;
      }
    };

    const detailsButtonWidth = (event) => {
      if (availableEvent(event)) {
        return '100%';
      }
      if (event.limit_students === 0) {
        return widthButtons;
      }

      if (event.registred_students < event.limit_students) {
        return widthButtons;
      }

      return '100%';
    };

    return (
      <Card.Root>
        <Card.Status status={CardFormatHelper.formatStatus(event, 0)} />
        <Card.Image
          src={event.event_cover.file}
          onClick={() =>
            VisualizationHandler.openDetailsModal(
              visualizationContextInstance,
              event.id
            )
          }
        />
        {event.certification && (
          <Card.CertificateStatus status="Possui certificado" />
        )}
        <Card.ImageStatus label={CardFormatHelper.imageStatus(event)} />
        <Card.Title id={event.id} title={event.name} />
        <Card.ContainerInformation>
          {labelDate && (
            <Card.Information
              icon={<LuCalendarClock color={universityColor} />}
              text={labelDate}
            />
          )}
          <Card.Information icon={iconModality} text={modality} />
          {subLabel && (
            <Card.Information
              icon={<FaRegCalendar color={universityColor} />}
              text={subLabel}
            />
          )}
          {event.complementary_activity && (
            <Card.Information
              icon={<LuClock3 color={universityColor} />}
              text={'Atividade complementar'}
            />
          )}
        </Card.ContainerInformation>
        <Card.ContainerButton hasReaction={true}>
          <Button
            width={detailsButtonWidth(event)}
            theme="unfilled"
            onClick={() =>
              VisualizationHandler.openDetailsModal(
                visualizationContextInstance,
                event.id
              )
            }
          >
            Detalhes
          </Button>

          {!availableEvent(event) && event.limit_students === 0 ? (
            <Button
              width={widthButtons}
              onClick={() => verifyCanSubscribe(event)}
            >
              Inscrever-se
            </Button>
          ) : (
            !availableEvent(event) &&
            event.registred_students < event.limit_students && (
              <Button
                width={widthButtons}
                onClick={() => verifyCanSubscribe(event)}
              >
                Inscrever-se
              </Button>
            )
          )}
        </Card.ContainerButton>
        <Card.Divider />
        {event.registred_students === event.limit_students &&
        event.limit_students !== 0 ? (
          <InformationP>
            No momento as vagas estão todas preenchidas. Caso haja alguma
            desistência, o evento ficará disponível novamente para inscrição.
          </InformationP>
        ) : (
          <Card.Reaction event={event} fromCard={true} />
        )}
      </Card.Root>
    );
  });

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    filterHandlersFactory.handleChangeSearchParam(event.target.value);
  };

  if (availables?.value?.results.length === 0 && searchText.length === 0) {
    return (
      <Container>
        {isOpenCurriculumModal &&
          mergeContextsHelper(
            <CurriculumModal
              personalityTestIsDone={profile?.quizzes?.personality.is_done}
              behavioralTestIsDone={profile?.quizzes?.behavioral.is_done}
              lifeInterestList={profile?.life_interests}
              interestList={profile?.interests}
              studentProfileState={profileAdapter(profile?.academic_profile)}
              updateBehavioralState={updateBehavioralState}
              updatePersonalityState={updatePersonalityState}
              updateProfileState={updateProfileState}
              updateInterestState={updateInterestState}
              setClose={closeModalProfile}
              isOpen={isOpenCurriculumModal}
              updateAvatarState={updateAvatarState}
              handleShowSpecialNeeds={handleShowSpecialNeeds}
            />
          )
        }
        <div className="filters">
          <InsideFilter
            optionsConfig={[
              {
                label: 'Todos os eventos',
                value: false,
                borderLeftRadius: true,
                style: {
                  textAlign: 'center',
                  lineHeight: '13px',
                },
                onClick:
                  filterHandlersFactory.handleChangeFavoritedInsideFilter,
              },
              {
                label: 'Favoritados',
                value: true,
                borderLeftRadius: false,
                countKey: 'favorited',
                onClick:
                  filterHandlersFactory.handleChangeFavoritedInsideFilter,
              },
            ]}
          />
        </div>
        <GenericMessage
          resetIndex={true}
          title="Nenhum evento encontrado"
          subtitle={`No momento não existem eventos ${
            getFavoritedAvaibles ? 'favoritados' : 'disponíveis'
          }.`}
          subtitleResponsible={`No momento não existem eventos ${
            getFavoritedAvaibles ? 'favoritados' : 'disponíveis'
          }.`}
        />
      </Container>
    );
  }

  if (availables?.value?.results.length === 0 && searchText.length > 0) {
    return (
      <Container>
        {isOpenCurriculumModal &&
          mergeContextsHelper(
            <CurriculumModal
              personalityTestIsDone={profile?.quizzes?.personality.is_done}
              behavioralTestIsDone={profile?.quizzes?.behavioral.is_done}
              lifeInterestList={profile?.life_interests}
              interestList={profile?.interests}
              studentProfileState={profileAdapter(profile?.academic_profile)}
              updateBehavioralState={updateBehavioralState}
              updatePersonalityState={updatePersonalityState}
              updateProfileState={updateProfileState}
              updateInterestState={updateInterestState}
              setClose={closeModalProfile}
              isOpen={isOpenCurriculumModal}
              updateAvatarState={updateAvatarState}
              handleShowSpecialNeeds={handleShowSpecialNeeds}
            />
          )
        }
        <div className="filters">
          <InsideFilter
            optionsConfig={[
              {
                label: 'Todos os eventos',
                value: false,
                borderLeftRadius: true,
                style: {
                  textAlign: 'center',
                  lineHeight: '13px',
                },
                onClick:
                  filterHandlersFactory.handleChangeFavoritedInsideFilter,
              },
              {
                label: 'Favoritados',
                value: true,
                borderLeftRadius: false,
                onClick:
                  filterHandlersFactory.handleChangeFavoritedInsideFilter,
              },
            ]}
          />
          <Search onChange={handleSearchChange} />
        </div>
        <GenericMessage
          resetIndex={true}
          title="Nenhum evento encontrado"
          subtitleResponsible="Não foram encontrados eventos para sua busca."
        />
      </Container>
    );
  }

  return (
    <Container>
      {isOpenCurriculumModal &&
        mergeContextsHelper(
          <CurriculumModal
            personalityTestIsDone={profile?.quizzes?.personality.is_done}
            behavioralTestIsDone={profile?.quizzes?.behavioral.is_done}
            lifeInterestList={profile?.life_interests}
            interestList={profile?.interests}
            studentProfileState={profileAdapter(profile?.academic_profile)}
            updateBehavioralState={updateBehavioralState}
            updatePersonalityState={updatePersonalityState}
            updateProfileState={updateProfileState}
            updateInterestState={updateInterestState}
            setClose={closeModalProfile}
            isOpen={isOpenCurriculumModal}
            updateAvatarState={updateAvatarState}
            handleShowSpecialNeeds={handleShowSpecialNeeds}
          />
        )}
      <div className="filters">
        <InsideFilter
          optionsConfig={[
            {
              label: 'Todos os eventos',
              value: false,
              borderLeftRadius: true,
              style: {
                textAlign: 'center',
                lineHeight: '13px',
              },
              onClick: filterHandlersFactory.handleChangeFavoritedInsideFilter,
            },
            {
              label: 'Favoritados',
              value: true,
              borderLeftRadius: false,
              onClick: filterHandlersFactory.handleChangeFavoritedInsideFilter,
            },
          ]}
        />
        <Search onChange={handleSearchChange} />
      </div>
      <ContainerCards>{createCardListing}</ContainerCards>
      {hasNextPagination && (
        <ContainerButtonLoadMore>
          <Button onClick={loadMore} disabled={blockButton}>
            Ver mais
          </Button>
        </ContainerButtonLoadMore>
      )}
    </Container>
  );
}

export default Availables;
