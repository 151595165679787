import { files } from 'jszip';
import moment from 'moment';

export const extensionDataPayload = ({
  address,
  address_number,
  complement,
  district,
  city,
  state,
  zip_code,
  type,
  extension_kind,
  responsible_teacher,
  development_objective,
  enable_students_evidences,
  enable_students_description,
  enable_students_conclusion,
  courses,
  macro_areas,
  disciplines,
  unities,
  competences_specialities,
  name,
  description,
  start_at,
  end_at,
  workload,
  students_limit,
  orientation,

  removed_students,
  extra_students,
  removed_feedback,
}) => ({
  name,
  description,
  start_at: `${moment(start_at).format('YYYY-MM-DD')}`,
  end_at: `${moment(end_at).format('YYYY-MM-DD')}`,
  workload: workload ? workload.replace(':', '.') : null,
  address: {
    street: address,
    number: address_number,
    complement,
    district,
    city,
    state,
    zip_code,
  },
  extension_project: {
    type,
    extension_kind,
    responsible_teacher,
    development_objective,
    enable_students_evidences,
    enable_students_description,
    enable_students_conclusion,
    students_limit: students_limit ? students_limit : 0,
    orientation,
    removed_students,
    extra_students,
    removed_feedback,
  },
  target_audiences: {
    courses: courses ? courses : [],
    macro_areas: macro_areas ? macro_areas : [],
    disciplines:
      extension_kind === 'extracurricular'
        ? disciplines
          ? disciplines
          : []
        : disciplines
        ? [disciplines]
        : [],
    unities: unities ? unities : [],
    semesters: [],
  },
  competences_specialities,
});

export const extensionDataAdapter = ({
  extension_project,
  name,
  description,
  end_at,
  start_at,
  attraction_image,
  target_audiences,
  address,
  competences_specialities,
  workload,
  files,
}) => ({
  address: address.street,
  address_number: address.number,
  complement: address.complement ? address.complement : '',
  district: address.district,
  city: address.city,
  state: address.state,
  zip_code: address.zip_code,
  extension_kind: extension_project.extension_kind,
  responsible_teacher: extension_project.responsible_teacher,
  development_objective: extension_project.development_objective,
  enable_students_evidences: extension_project.enable_students_evidences,
  enable_students_description: extension_project.enable_students_description,
  enable_students_conclusion: extension_project.enable_students_conclusion,
  courses: target_audiences[0].courses,
  macro_areas: target_audiences[0].macro_areas,
  disciplines: target_audiences[0].disciplines,
  unities: target_audiences[0].unities,
  competences_specialities,
  support_materials: extension_project.support_materials,
  name,
  description,
  start_at,
  end_at,
  workload,
  students_limit: extension_project.students_limit,
  orientation: extension_project.orientation,
  files,
  attraction_image,
  removed_students: extension_project.removed_students,
  extra_students: extension_project.extra_students,
  // removed_student
});
