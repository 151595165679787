import React, { useContext, useState } from 'react';
import {
  CallToAction,
  Container,
  ContainerLeft,
  ContainerRight,
  Section,
  WarningMessage,
  WrapperContent,
} from './style';
import { IoIosArrowForward } from 'react-icons/io';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import LanguageCardForm from 'views/MyProfile/components/language/LanguageCardForm';
import { deleteLanguage, getLanguages } from 'services/student/languages';
import ModalInformation from 'components/informationModal';
import InformationModal from 'components/informationModal';
import { GenericButtons } from '../GenericButtons';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import {
  configModalDefault,
  configModalMobile,
} from 'views/MyProfile/components/language/ConfigModalData';
import { actionsProfileStore } from 'stores/profile.store';

export function LanguagesContent({
  cardData,
  cardDataKey,
  openModalWithContent,
  handleIsLoadingState,
  closeModal,
  gamificationRef,
  shouldHideItem,
}) {
  const { universityColor, fetchGamificationData, sectionRef } =
    useContext(ProfileContext);
  const [controlInformationModal, setControlInformationModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { updateCard } = actionsProfileStore;

  function handleOpenLink() {
    window.open('https://go.eduq.tec.br/r/8dbce583b6d62ee', '_blank');
    setOpenModal(false);
  }
  function handleCloseModal() {
    setOpenModal(false);
  }

  const isMobile = window.innerWidth <= 768;
  const configModal = isMobile ? configModalMobile : configModalDefault;
  const handleButtonClick = isMobile ? handleCloseModal : handleOpenLink;

  const callToAddLanguages = () => {
    openModalWithContent(
      mergeContextsHelper(
        <LanguageCardForm
          cardDataKey={cardDataKey}
          languagesState={cardData[cardDataKey]}
          closeModal={closeModal}
          handleIsLoadingState={handleIsLoadingState}
          fetchGamificationData={fetchGamificationData}
          setOpenModal={setOpenModal}
        />
      )
    );
  };

  const callToEditLanguages = (languageItem) => {
    openModalWithContent(
      mergeContextsHelper(
        <LanguageCardForm
          isEdit
          dataLanguage={languageItem}
          languagesState={cardData[cardDataKey]}
          cardDataKey={cardDataKey}
          closeModal={closeModal}
          handleIsLoadingState={handleIsLoadingState}
          setOpenModal={setOpenModal}
          fetchGamificationData={fetchGamificationData}
        />
      )
    );
  };

  async function excludeLanguage(languageId) {
    handleIsLoadingState(true);
    await deleteLanguage(languageId);
    const response = await getLanguages();
    cardDataKey && updateCard(cardDataKey, response);
    await fetchGamificationData();
    handleIsLoadingState(false);
    customSnackbar('Idioma excluído com sucesso!', 'confirmation');
    setControlInformationModal(false);
  }

  const config = {
    modalWidth: 382,
    bubbleText: `Atenção!`,
    ParagraphText: `Deseja realmente excluir esse idioma?`,
    buttonConfirmText: 'SIM, EXCLUIR!',
    showButtonCancel: true,
    buttonCancelText: 'VOLTAR',
    style: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  };
  const renderWarningMessage = () => {
    return (
      <WarningMessage universityColor={universityColor}>
        <p universityColor={universityColor}>
          <strong>Importante:</strong> O teste só pode ser realizado uma única
          vez.
        </p>
      </WarningMessage>
    );
  };

  return (
    <Container ref={sectionRef[gamificationRef]}>
      <Section>
        <InformationModal
          modalOpenState={openModal}
          buttonConfirmFunction={handleButtonClick}
          config={configModal}
          universityColor={universityColor}
          closeModalFunction={handleCloseModal}
        >
          {renderWarningMessage()}
        </InformationModal>
        {controlInformationModal && (
          <ModalInformation
            universityColor={universityColor}
            modalOpenState={controlInformationModal}
            config={config}
            buttonConfirmFunction={() =>
              excludeLanguage(controlInformationModal)
            }
            buttonCancelFunction={() => setControlInformationModal(false)}
          />
        )}
        {cardData.hasOwnProperty(cardDataKey) &&
          Array.isArray(cardData[cardDataKey]) &&
          cardData[cardDataKey]?.map((item) => (
            <WrapperContent>
              <ContainerLeft>
                <strong> {item.language.label} </strong>
                <span> Nível {item.fluence_level.label} </span>
              </ContainerLeft>
              <ContainerRight>
                <GenericButtons
                  showEdit={true}
                  showDelet={true}
                  shouldHideItem={shouldHideItem}
                  handleFuncEdit={() => callToEditLanguages(item)}
                  handleFuncDelet={() => setControlInformationModal(item.id)}
                />
              </ContainerRight>
            </WrapperContent>
          ))}
        <CallToAction
          disabled={shouldHideItem}
          showButton
          universityColor={universityColor}
          onClick={callToAddLanguages}
        >
          Adicionar <IoIosArrowForward />
        </CallToAction>
      </Section>
    </Container>
  );
}
