import React, { Fragment } from 'react';
import {
  ContainerTab,
  Title,
  Paragraph,
  ButtonCard,
  ContainerButtons,
} from './styles';
import { RiGraduationCapLine } from 'react-icons/ri';
import { MdOutlineGroups } from 'react-icons/md';
import CreateExtensionModalButton from '../CreateModal/Index';

export default function PrincipalTab({
  handleCurricularExtension,
  handleExtracurricularExtension,
}) {
  const buttons = [
    {
      title: 'Extensão Curricular',
      image: <RiGraduationCapLine size={65} color={'#009291'} />,
      onClick: handleCurricularExtension,
    },
    {
      title: 'Extensão Extracurricular',
      image: <MdOutlineGroups size={65} color={'#009291'} />,
      onClick: handleExtracurricularExtension,
    },
  ];

  return (
    <ContainerTab>
      <Title>
        Que tipo de <b>projeto de extensão</b> você deseja <b>gerenciar</b>?
      </Title>
      <Paragraph>
        Aqui você pode gerenciar e cadastrar projetos de extensão curriculares e
        extracurriculares.
      </Paragraph>

      <ContainerButtons>
        {buttons.map((index) => (
          <ButtonCard key={index} onClick={index.onClick}>
            {index.title}
            {index.image}
          </ButtonCard>
        ))}
      </ContainerButtons>
    </ContainerTab>
  );
}
