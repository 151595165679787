import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  BackButton,
  CloseButton,
  Container,
  FinishProjectButton,
  Footer,
  NextButton,
  StepIndicatorContainer,
  Wrapper,
} from './styled';
import { RiCloseFill } from 'react-icons/ri';
import StepIndicator from 'components/StepIndicator';
import { StepOne } from './Steps/StepOne';
import { StepTwo } from './Steps/StepTwo';
import * as Yup from 'yup';
import { finalizeProjectForm } from 'views/StaffPages/Opportunities/services';
import { FinishProjectAdapterForm } from './Steps/StepOne/utils/FinishProjectAdapter';
import { customSnackbar, SnackbarType } from 'components/CustomSnackBar/customSnackbar';
import StepIndicatorWithText from 'components/StepIndicatorWithText';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';

export default function ModalFinishProject({ step, setStep }) {
  const {
    extensionProjectId,
    setModalFinishProject,
    setExtensionProjectId,
    refModalExtensionProject,
    formDataFinishProject,
    setFormValues,
    getAllToFinishProjects,
    informationItem,
    setInformationItem,
    setNewModalFinish,
  } = useContext(ExtensionProjectsContext);

  const [allStudentsEvaluated, setAllStudentsEvaluated] = useState(informationItem.extension_project.students_all_evaluated);

  const [hasRegister, setHasRegister] = useState(false);

  const refStepOne = useRef();
  const refStepTwo = useRef();
  const pageRef = useRef(null);

  useEffect(() => {
    if (refModalExtensionProject.current) {
      refModalExtensionProject.current.scrollIntoView();
    }
  }, [refModalExtensionProject]);

  useEffect(() => {
    toTop()
  }, [])

  const handleBackHome = () => {
    setModalFinishProject(false);
    setExtensionProjectId(null);
    setInformationItem(null);
    setFormValues({
      externalPublic: null,
      impactedPublic: null,
      scores: {
        score: null,
      },
    });
  };

  function renderStep() {
    switch (step) {
      case 1:
        return <StepOne refStepOne={refStepOne} />;
      case 2:
        return (
          <StepTwo setHasRegister={setHasRegister} setAllStudentsEvaluated={setAllStudentsEvaluated} />
        );
    }
  }

  function handleBack() {
    setStep(1);
  }

  function handleSubmitForm() {
    if (refStepOne.current && step === 1) {
      const data = refStepOne?.current?.getData();
      setFormValues(data);
    }
    setStep(2);
  }

  function handleChangeStep(step) {
    switch (step) {
      case 1:
        handleBack();
        break;
      case 2:
        handleSubmitForm();
        break;
    }
  }

  const handleSubmit = async () => {
    if (step === 2) {
      try {
        await finalizeProjectForm(
          extensionProjectId,
          FinishProjectAdapterForm({
            ...formDataFinishProject,
          })
        );
      } catch (err) {
        if (err.response.status === 400) {
          return customSnackbar(
            err.response?.data?.length > 0
              ? err.response.data[0]
              : 'Ocorreu um erro ao finalizar o projeto, tente novamente mais tarde',
            'error'
          );
        }
        return customSnackbar(
          'Ocorreu um erro ao finalizar o projeto, tente novamente mais tarde',
          'error'
        );
      }

      customSnackbar(
        'Projeto finalizado com sucesso!',
        SnackbarType.CONFIRMATION,
      )

      await getAllToFinishProjects(null, true);
      setNewModalFinish(false);
      setExtensionProjectId(null);
      setFormValues({
        externalPublic: null,
        impactedPublic: null,
      });
    }
  };

  const toTop = () => {
    pageRef?.current ? 
    pageRef.current.scrollIntoView({ block: 'start' }) 
    :toTop();
  }

  return (
    <Container ref={pageRef}>
      <Wrapper>
        <StepIndicatorContainer>
          <StepIndicatorWithText
            count={2}
            currentStep={step}
            titles={['Dados do projeto', 'Avaliar estudantes']}
            onStepChange={handleChangeStep}
          />
        </StepIndicatorContainer>
        {renderStep()}
      </Wrapper>
      <Footer>
        {step === 1 && (
          <NextButton type={'button'} onClick={handleSubmitForm} />
        )}
        {step === 2 && (
          <>
            <BackButton type={'button'} onClick={handleBack} />
            <div>
              <FinishProjectButton
                disabled={!allStudentsEvaluated}
                onClick={handleSubmit}
              >
                Finalizar projeto
              </FinishProjectButton>
            </div>
          </>
        )}
      </Footer>
    </Container>
  );
}
