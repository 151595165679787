import React, { useState, useEffect } from 'react';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import oportunidades from 'assets/img/oportunidades.png';
import PrincipalTab from './components/TabHomeVisualization';
import TabCurriculumProjects from './screens/Curriculum';
import TabExtracuriculumProjects from './screens/Extracurriculum';
import ExtensionProjectsProvider from './context/ExtensionProjectsContext';
import CreateExtensionProvider from './components/CreateModal/CreateExtensionContext';

export default function ExtensionProjectEngine({
  handleIsLoadingState,
  props,
  defaultCompetence,
  metadata,
}) {
  const [activeTab, setActiveTab] = useState(null);
  const [kind, setKind] = useState('curricular');

  const tabs = [`Extensão curricular`, `Extensão extracurricular`];

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const handleCurricularExtension = () => {
    setActiveTab(0);
    setKind('curricular');
  };

  const handleExtracurricularExtension = () => {
    setActiveTab(1);
    setKind('extracurricular');
  };

  const renderizeTab = (step) => {
    if (step === 0) {
      return (
        <TabCurriculumProjects
          handleIsLoadingState={handleIsLoadingState}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          defaultCompetence={defaultCompetence}
          metadata={metadata}
          kind={kind}
        />
      );
    }
    if (step === 1) {
      return (
        <TabExtracuriculumProjects
          handleIsLoadingState={handleIsLoadingState}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          defaultCompetence={defaultCompetence}
          metadata={metadata}
          kind={kind}
        />
      );
    }

    return (
      <PrincipalTab
        handleCurricularExtension={handleCurricularExtension}
        handleExtracurricularExtension={handleExtracurricularExtension}
      />
    );
  };

  const handleTabChange = (event, value) => {
    setActiveTab(value);
    setKind(value === 0 ? 'curricular' : 'extracurricular');
  };

  return (
    <ExtensionProjectsProvider
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      metadata={metadata}
      kind={kind}
    >
      <CreateExtensionProvider kind={kind}>
        <CompanyHeader
          title="Projetos de Extensão"
          backgroundImage={oportunidades}
          handleTabChange={handleTabChange}
          activeTab={activeTab}
          tabs={activeTab !== null ? tabs : null}
          kind={kind}
        />
        {renderizeTab(activeTab)}
      </CreateExtensionProvider>
    </ExtensionProjectsProvider>
  );
}
