import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { DelaySearchHelper } from '../Helpers/DelaySearchHelper';
import FairAndEventsService from '../Services';

export const StudentHandlers = (
  visualizationContextInstance,
  visualizationStudentContextInstance
) => {
  const getListingStudentEvent = async (
    eventId,
    query = '',
    pagination = ''
  ) => {
    const response = await FairAndEventsService.getEventStudents(
      eventId,
      query,
      pagination
    );
    const { results, count, next, previous } = response;

    const {
      setListing,
      setListingPaginationData,
    } = visualizationStudentContextInstance;
    setListing(results);
    setListingPaginationData({ count, next, previous });
  };

  const handleClickRemoveStudent = async (studentUuid, eventId, feedback) => {
    const response = await FairAndEventsService.putRemoveStudentFromEvent(
      studentUuid,
      feedback
    );

    const { limit, currentPage } = visualizationStudentContextInstance;

    const parameters = `limit=${limit}&offset=${
      (currentPage - 1) * limit || 0
    }`;
    await getListingStudentEvent(eventId, _, parameters);
  };

  const handleClickRemoveBulkStudents = async (
    studentUuids,
    eventId,
    feedback
  ) => {
    const response = await FairAndEventsService.putBulkRemoveStudentFromEvent(
      studentUuids,
      feedback
    );

    const { limit, currentPage } = visualizationStudentContextInstance;

    const parameters = `limit=${limit}&offset=${
      (currentPage - 1) * limit || 0
    }`;
    getListingStudentEvent(eventId, _, parameters);
  };

  const onConfirmPresenceStudent = async (
    studentUuid,
    eventId,
    pagination = false
  ) => {
    try {
      const response = await FairAndEventsService.postConfirmPresenceStudent(
        studentUuid
      );

      customSnackbar('Presença confirmada com sucesso!', 'confirmation');
    } catch (error) {
      customSnackbar(
        'Ocorreu um erro ao confirmar a presença do estudante!',
        'error'
      );
    }

    const { currentPage, limit } = visualizationStudentContextInstance;
    const parameters = `limit=${limit}&offset=${
      (currentPage - 1) * limit || 0
    }`;

    getListingStudentEvent(eventId, _, pagination && parameters);
  };

  const onRemovePresenceStudent = async (
    studentUuid,
    presenceId,
    eventId,
    pagination = false
  ) => {
    try {
      const response = await FairAndEventsService.putRemovePresenceStudent(
        studentUuid,
        presenceId
      );
      customSnackbar('Presença retirada com sucesso!', 'confirmation');
    } catch (error) {
      customSnackbar(
        'Ocorreu um erro ao retirar a presença do estudante!',
        'error'
      );
    }

    const { currentPage, limit } = visualizationStudentContextInstance;
    const parameters = `limit=${limit}&offset=${
      (currentPage - 1) * limit || 0
    }`;

    getListingStudentEvent(eventId, _, pagination && parameters);
  };

  const reSendStudentCertificate = async (studentUuid, eventId) => {
    const response = await FairAndEventsService.postReSendCertificate(
      studentUuid
    );
    getListingStudentEvent(eventId);
  };

  const onFilter = async (eventId, query) => {
    const getWithParam = async (signal) => {
      try {
        const response = await FairAndEventsService.getEventStudents(
          eventId,
          query,
          '',
          signal
        );
        const { results, count, next, previous } = response;
        const {
          setListing,
          setListingPaginationData,
          searchQuery,
          setCurrentPage,
        } = visualizationStudentContextInstance;
        if (!response.message) {
          setListing(results);
          setListingPaginationData({ count, next, previous });
          setCurrentPage(1);
          searchQuery.current = query;
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          return;
        }
      }
    };

    DelaySearchHelper.delay(getWithParam, 500);
  };

  const bulkStudentType = async (checkedStudents, type, eventId) => {
    const { currentPage, limit } = visualizationStudentContextInstance;
    const parameters = `limit=${limit}&offset=${
      (currentPage - 1) * limit || 0
    }`;

    const currentService =
      type === 'presence' ? 'postBulkRemoveStudents' : 'postBulkConfirmStudent';

    const response = await FairAndEventsService[currentService](
      checkedStudents,
      eventId
    );
    getListingStudentEvent(eventId, _, parameters);
  };

  const getRegistrationCounters = async (eventId) => {
    return await FairAndEventsService.getEventRegistrationCounters(eventId);
  };

  return {
    getListingStudentEvent,
    handleClickRemoveStudent,
    onConfirmPresenceStudent,
    onRemovePresenceStudent,
    reSendStudentCertificate,
    onFilter,
    bulkStudentType,
    handleClickRemoveBulkStudents,
    getRegistrationCounters,
  };
};
