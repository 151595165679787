import {
  ButtonActionsDoc,
  ContainerButtons,
  ContainerColumn,
  ContainerDoc,
  ContainerIcon,
  ContainerImageName,
  Link,
  Paragraph,
} from 'views/StaffPages/ExtensionProject/components/Modal/ModalSupportMaterial/styled';
import { RiEyeLine, RiFileCopyLine } from 'react-icons/ri';
import moment from 'moment';
import Tooltip from 'components/NewTooltip';
import { BiTrash } from 'react-icons/bi';

import { UploadType } from 'views/Student/ExtensionProject/enums/upload_type';
import { useContext, useEffect, useState } from 'react';

import BaseLayoutContext from 'contexts/base-layout';
import GenericMessage from 'components/GenericMessage';
import {
  actionViewingaCopying,
  getItemName,
  getTypeIcon,
  handleClickViewPdf,
  handleCopyUrl,
} from 'components/GenericModalUpload/utils/resource';
import { MaterialsSkeleton } from '../Skeleton/MaterialsSkeleton';
import { actionsGenericUpload } from 'components/GenericModalUpload/store/genericUpload.store';

export function ExternalListing({
  data,
  onRemove,
  setSelectedImage,
  genericMessage,
  viewAction = false,
  finishedProject,
  unpublishCard,
}) {
  const { openModalWithContent } = useContext(BaseLayoutContext);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const { changeItems } = actionsGenericUpload;
  function handleOpenUrl(value) {
    window.open(value, '_blank');
  }

  useEffect(() => {
    if (data) {
      const timer = setTimeout(() => {
        setShowSkeleton(false);
      }, 250);
      return () => clearTimeout(timer);
    } else {
      changeItems([]);
      setShowSkeleton(true);
    }
  }, [data]);

  const returnItemName = (item) => {
    if (item.type === UploadType.LINK) {
      return (
        <Link onClick={() => handleOpenUrl(item.link)}>
          {getItemName(item)}
        </Link>
      );
    }

    if (item.type === UploadType.DOCUMENT || item.type === UploadType.IMAGE) {
      return <Paragraph>{getItemName(item)}</Paragraph>;
    }
  };

  const renderListMaterials = (items) => {
    if (showSkeleton) {
      return Array.from({ length: 3 }).map((_, index) => (
        <MaterialsSkeleton key={index} />
      ));
    }

    if (items?.length === 0 && genericMessage) {
      return (
        <GenericMessage
          resetIndex={true}
          title="Nenhum material de apoio encontrado."
          subtitle="No momento não existem materiais de apoio disponíveis"
        />
      );
    }

    if (items?.length > 0) {
      return items?.map((item) => (
        <ContainerDoc key={item}>
          <ContainerImageName>
            <ContainerIcon>{getTypeIcon(item)}</ContainerIcon>
            <ContainerColumn>
              {returnItemName(item)}
              <Paragraph data>
                Adicionada em {moment(new Date()).format('DD/MM/YYYY')}
              </Paragraph>
            </ContainerColumn>
          </ContainerImageName>
          <ContainerButtons>
            {viewAction && (
              <Tooltip
                direction="top"
                colorSchema="black"
                text={item.type === UploadType.LINK ? 'Copiar' : 'Visualizar'}
              >
                <ButtonActionsDoc>
                  {item.type === UploadType.LINK ? (
                    <RiFileCopyLine
                      size={24}
                      color={'#009291'}
                      alt="eye"
                      onClick={() => handleCopyUrl()}
                    />
                  ) : (
                    <RiEyeLine
                      size={20}
                      color="#009291"
                      onClick={() =>
                        actionViewingaCopying(
                          item,
                          openModalWithContent,
                          setSelectedImage
                        )
                      }
                    />
                  )}
                </ButtonActionsDoc>
              </Tooltip>
            )}
            {!finishedProject && !unpublishCard && (
              <Tooltip direction="top" colorSchema="black" text="Excluir">
                <ButtonActionsDoc onClick={() => onRemove(item.id)}>
                  <BiTrash size={20} color="#009291" />
                </ButtonActionsDoc>
              </Tooltip>
            )}
          </ContainerButtons>
        </ContainerDoc>
      ));
    }
  };

  return renderListMaterials(data);
}
