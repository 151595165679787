import React, { useEffect, useRef, Fragment, useMemo, useState } from 'react';
import { useField } from '@unform/core';
import JoditEditor from 'jodit-react';
import { ContainerLabelInput, UnInputLabel } from './styled';
import { ErrorContainer } from '../SelectField/styled';
import errorIcon from 'assets/Matriz/error.svg';

function DescriptionInput({
  label,
  name,
  handleChange,
  charCount = '/200',
  charLimit = 200,
  placeholder = 'Digite aqui...',
  marginLeft,
}) {
  const inputRef = useRef(null);
  const [description, setDescription] = useState('');
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    setDescription(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        setDescription(value);
      },
      clearValue: (_) => {
        setDescription('');
      },
    });
  }, [fieldName, registerField]);

  const config = useMemo(
    () => ({
      theme: 'summer',
      style: {
        'color-text': 'red',
        colorBorder: 'black',
        'color-panel': 'blue',
      },
      placeholder: placeholder,
      language: 'pt_br',
      readonly: false,
      height: '10em',
      buttons: ['bold', 'italic', 'underline'],
      buttonsMD: ['bold', 'italic', 'underline'],
      buttonsSM: ['bold', 'italic', 'underline'],
      buttonsXS: ['bold', 'italic', 'underline'],
      removeButtons: [
        'source',
        '|',
        '|',
        'ul',
        'ol',
        '|',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'image',
        'table',
        '|',
        'left',
        'center',
        'right',
        'justify',
        '|',
        'undo',
        'redo',
        '|',
        'hr',
        'eraser',
        'fullsize',
      ],
      showXPathInStatusbar: false,
      showCharsCounter: true,
      limitChars: charLimit,
      showWordsCounter: false,
      toolbarAdaptive: true,
      allowResizeX: false,
      allowResizeY: false,
      toolbarSticky: false,
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      tabIndex: 0,
      disablePlugins: ['powered-by-jodit'],
      defaultActionOnPaste: 'insert_only_text',
    }),
    [charLimit]
  );

  const handleUpdate = (value) => {
    if (value !== defaultValue) {
      inputRef.current.focus();
    }
  };

  const joditEditorMemo = useMemo(
    () => (
      <JoditEditor
        ref={inputRef}
        value={description}
        config={config}
        onBlur={handleUpdate}
        onChange={(newContent) => {
          if (handleChange) {
            handleChange(newContent);
          }
        }}
      />
    ),
    [description]
  );

  return (
    <Fragment>
      <ContainerLabelInput
        error={error}
        charCount={charCount}
        marginLeft={marginLeft}
      >
        {label && <UnInputLabel htmlFor={name}>{label}</UnInputLabel>}
        <div className="EditJodit">{joditEditorMemo}</div>
      </ContainerLabelInput>
      {error && (
        <ErrorContainer>
          <img src={errorIcon} alt="error-icon" />
          <span>{error}</span>
        </ErrorContainer>
      )}
    </Fragment>
  );
}

export default DescriptionInput;
