import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentStudent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  margin-top: 30px;
`;

export const HeaderStudent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  width: 100%;
  justify-content: space-between;
`;

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }
`;

export const CheckBoxInput = styled.input`
  width: 20px;
  height: 20px;

  &:checked {
    background-color: #009291;
  }
`;

export const Name = styled.p`
  margin: 0;
  color: rgba(96, 96, 98, 1);
  font-size: 18px;
  font-weight: 700;
`;

export const Paragrap = styled.p`
  margin: 0;
  color: rgba(96, 96, 98, 1);
  font-size: 16px;
  font-weight: 400;

  text-align: start;
`;

export const ContentCourse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const ButtonDisapprove = styled.button`
  color: rgba(218, 5, 5, 1);
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 15px;
  text-decoration: underline;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const ButtonApprove = styled.button`
  border: none;
  background-color: #009291;
  color: #fff;
  border-radius: 2px;
  padding: 10px;
  font-weight: 500;
  font-size: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const ButtonEvidences = styled.button`
  border: none;
  border-radius: 2px;
  background-color: rgba(0, 146, 145, 0.2);
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const ContentTexts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerTexts = styled.div`
  width: 100%;
  max-width: 48%;
  height: 140px;

  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 3px;
  background-color: rgba(250, 250, 250, 1);
  overflow: auto;

  padding: 14px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(229, 229, 229, 1);
`;
export const StatusContainer = styled.div`
  width: auto;
  height: 38px;
  gap: 10px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid;
  font-family: Roboto, sans-serif;
  cursor: default;
  font-size: 15px;
  font-weight: 500;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ status }) =>
    status === 'approved'
      ? `
      border-color: #009291;
      color: #009291;
    `
      : `
      border-color: #DA0505;
      color: #DA0505;
    `}
`;
