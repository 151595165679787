import React from 'react';
import { Container, SubContainer } from './styled';
import GenericInternalTab from 'components/GenericInternalTab';
import { NextProjects } from './NextProjects';
import { FinishedProjects } from './Finished';
import { ArchivedProjects } from './Archived';
import { useProjectExtensionContext } from '../../../context';

export default function InternalTab() {
  const { tabsCount, activeInternalTab, setActiveInternalTab } =
    useProjectExtensionContext();

  const handleTabChange = (_, value) => {
    if (activeInternalTab !== value) {
    }
    setActiveInternalTab(value);
  };

  const tabs = [
    `Próximas atividades ${
      typeof tabsCount.enrolled === 'number' ? `(${tabsCount.enrolled})` : ''
    }`,
    `Projetos realizados ${
      typeof tabsCount.finished === 'number' ? `(${tabsCount.finished})` : ''
    }`,
    `Arquivados ${
      typeof tabsCount.archived === 'number' ? `(${tabsCount.archived})` : ''
    }`,
  ];

  const components = {
    0: <NextProjects />,
    1: <FinishedProjects />,
    2: <ArchivedProjects />,
  };

  const returnCurrentComponentTab = () => {
    return components[activeInternalTab];
  };

  return (
    <Container>
      <SubContainer>
        <div style={{ width: '100%' }}>
          <GenericInternalTab
            tabs={tabs}
            activeTab={activeInternalTab}
            handleTabChange={handleTabChange}
            zIndex={'100'}
          />
        </div>
      </SubContainer>
      {returnCurrentComponentTab()}
    </Container>
  );
}
