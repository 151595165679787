export const configCloseProject = {
  bubbleText: 'Atenção!',
  ParagraphText: `<b>Tem certeza que deseja sair?</b>`,
  TwoParagraphText: `As avaliações já realizadas <b>serão salvas</b> e você poderá continuar quando retornar.`,
  buttonConfirmText: 'SIM, QUERO SAIR',
  buttonCancelText: 'CONTINUAR NA PÁGINA',
  showButtonCancel: true,
};

export const returnConfigOneDisapproved = (name, quantity) => {
  const configDisapproveOneStudent = {
    bubbleText: 'Enviar feedback',
    ParagraphText:
      quantity && quantity > 0
        ? `Mande um feedback para os ${quantity} estudantes selecionados explicando o motivo da <b>reprovação<b/>`
        : `Mande um feedback para <b>${name}</b> explicando o motivo da <b>reprovação</b>.`,
    TwoParagraphText: `<b>Feedback*</b>`,
    buttonConfirmText: 'ENVIAR',
    showInput: true,
    buttonCloseX: true,
    showButtonCancel: false,
    style: {
      inputHeight: '270px',
      maxWidth: '400px',
    },
    buttons: {
      flexDirection: 'column',
    },
  };

  return configDisapproveOneStudent;
};

export const returnConfigAproveStudents = (name, quantity) => {
  const configApproveStudent = {
    bubbleText: 'Atenção!',
    ParagraphText:
      quantity && quantity > 0
        ? `Tem certeza que deseja <b>aprovar</b> ${quantity} estudantes? `
        : `Tem certeza que deseja aprovar <b>${name}</b>?`,
    TwoParagraphText: `Esta ação <b>não</b> poderá ser desfeita`,
    buttonConfirmText: 'SIM, QUERO APROVAR',
    buttonCancelText: 'VOLTAR',
    showInput: false,
    buttonCloseX: true,
    showButtonCancel: true,
    buttons: {
      flexDirection: 'column',
    },
  };

  return configApproveStudent;
};
