import { StyledButton } from 'components/Dialog/style';
import styled from 'styled-components';

export const ContainerExternal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  gap: 20px;
  transition: 0.2s;

  @media (max-width: 388px) {
    transition: 0.2s;
    padding: 30px 15px !important;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;

  > div {
    width: 100%;
    display: grid;
    gap: 1.1rem;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  position: relative;
  background-color: transparent;
  box-shadow: none;
  margin-top: 10px;
  align-self: flex-end;

  .scrollButtons {
    margin-bottom: -1px;
    color: #777777;
  }

  .borderContainer {
    border: 1px solid;
  }

  .tabButton {
    font-size: 18px;
    color: #777777;
    font-weight: 500;
    max-width: fit-content;
    text-align: center;
    text-transform: none;
    z-index: 2;
  }

  .formControl {
    margin: 8px;
    min-width: 200px;
  }

  .selectEmpty {
    margin-top: 16px;
  }

  .selectLabel {
    white-space: nowrap;
  }

  .cssLabel {
    &.cssFocused {
      color: #3498db;
    }
    font-size: 14px;
  }

  .tabIndicator {
    height: 3px;
    z-index: 2;
    background-color: #3498db;
  }
`;

const JobHeaderTab = (theme) => ({
  appBarHolder: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: 10,
    alignSelf: 'flex-end',
    display: 'flex',
  },

  tabIndicator: {
    backgroundColor: theme.universityColor,
    height: 3,
    zIndex: 2,
  },
  scrollButtons: {
    borderBottom: '1px solid #EDEDED',
    marginBottom: '-1px',
    [theme.breakpoints.down('lg')]: {
      color: '#777777',
    },
  },
  borderContainer: {
    border: '1px solid',
  },
  tabButton: {
    fontSize: 18,
    color: '#777777',
    fontWeight: '500',
    maxWidth: 'fit-content',
    textAlign: 'center',
    textTransform: 'none',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      minWidth: 180,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  selectLabel: {
    whiteSpace: 'nowrap',
  },
  cssLabel: {
    '&$cssFocused': {
      color: theme.universityColor,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  cssFocused: {},
});

export default JobHeaderTab;

export const ContainerGenericMessage = styled.div`
  position: relative;
  width: 60vw;
`;

export const ContainerSwitch = styled.div`
  margin-left: 15px;
`;

export const FilterContainer = styled.div`
  display: flex;
  right: 2rem;
  top: 1rem;
  position: absolute;
  transition: 0.2s;

  @media (max-width: 1440px) {
    transition: 0.2s;
    right: 0rem;
    top: -2.5rem;
  }

  @media (max-width: 959px) {
    transition: 0.2s;
    top: -4rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.3em;
  font-weight: 700;
  color: #606062;
  margin: 30px 6px 0;

  @media (max-width: 388px) {
    max-width: 230px;
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;

  .custom-indicator {
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'} !important;
  }
`;

export const HeaderTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
  transition: 0.2s;

  @media (max-width: 700px) {
    transition: 0.2s;
    gap: 0px;
    flex-direction: column;
  }
`;

export const ContentTabs = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px !important;
  transition: 0.2s;

  @media (max-width: 388px) {
    transition: 0.2s;
    padding: 20px 15px !important;
  }
`;

export const ButtonMatch = styled.button`
  width: 180px;
  height: 45px;
  padding: 12px 20px;
  border-radius: 3px;
  border: none;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  font-size: 1em;
  font-weight: 400;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  transition: 0.2s;

  @media (max-width: 700px) {
    transition: 0.2s;
    margin: 20px;
  }

  @media (max-width: 500px) {
    transition: 0.2s;
    width: 100%;
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
`;

export const ButtonFinishedJobs = styled.button`
  padding: 24px 35px;
  background: #fff;
  border: 1px solid #e5e5e5;
  cursor: pointer;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;
