import httpRequest from 'services/university/HttpRequest';

class ProjectExtensionApi {
  baseUrl = `api/internal/students/me`;
  defaultLimit = 10;

  async getAvailableExtensions(query = `limit=${this.defaultLimit}`) {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/extension-project?${query}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getImagesAvailableExtensions(query = `limit=${this.defaultLimit}`) {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/extension-project-images?${query}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getEnrolledExtensionProject(query = `limit=${this.defaultLimit}`) {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/enrolled-extension-project?${query}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getImagesEnrolledExtensions(query = `limit=${this.defaultLimit}`) {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/enrolled-extension-project-images?${query}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getFinishedExtensionProject(query = `limit=${this.defaultLimit}`) {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/finished-extension-project?${query}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getImagesFinishedExtensions(query = `limit=${this.defaultLimit}`) {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/finished-extension-project-images?${query}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getArchivedExtensionProject(query = `limit=${this.defaultLimit}`) {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/archived-extension-project?${query}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getImagesArchivedExtensions(query = `limit=${this.defaultLimit}`) {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/archived-extension-project-images?${query}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getDetails({ id }) {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/extension-project/${id}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async sendFinalReport({ projectId, data }) {
    try {
      const response = await httpRequest.post(
        `api/internal/students/extension-project/${projectId}/report`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getFinalReport(projectId) {
    try {
      const response = await httpRequest.get(
        `api/internal/students/extension-project/${projectId}/report`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async uploadEvidenceFile({ projectId, data }) {
    try {
      const response = await httpRequest.post(
        `api/internal/students/extension-project/${projectId}/evidence/file`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async uploadEvidenceLink({ projectId, data }) {
    try {
      const response = await httpRequest.post(
        `api/internal/students/extension-project/${projectId}/evidence/file`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getSupportMaterials({ projectId }) {
    try {
      const response = await httpRequest.get(
        `api/internal/students/extension-project/${projectId}/support-materials`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async materialDownload({ materialId }) {
    try {
      const response = await httpRequest.get(
        `api/internal/students/extension-project/support-materials/${materialId}/download`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async getProjectsCount() {
    try {
      const response = await httpRequest.get(
        `${this.baseUrl}/extension-project/count`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async unsubscribeProject(projectId) {
    try {
      const response = await httpRequest.put(
        `${this.baseUrl}/extension-project/${projectId}/unenroll`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  async enrollInProject(projectId) {
    try {
      const response = await httpRequest.post(
        `${this.baseUrl}/extension-project/${projectId}/enroll`
      );
      return response;
    } catch (error) {
      return error;
    }
  }
}
export const projectExtensionApi = new ProjectExtensionApi();
