import React, { Fragment, useContext, useEffect, useRef } from 'react';
import PublishedCarousel from './Carousel';

import {
  ArrowButton,
  Container,
  Content,
  Divider,
  ListPublishedProjects,
  SubTitle,
  Title,
  Wrapper,
} from './styled';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
  scrollLeftInProgress,
  scrollLeftWaitingForStart,
  scrollRigthInProgress,
  scrollRigthWaitingForStart,
} from './utils/logicScroll';
import { formatNumber } from 'utils/formatNumber';
import GenericMessage from 'components/GenericMessage';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';

export default function PublishedProjects(props) {
  const { activeTab, openStudents, handleOpenStudentsList } = props;
  const {
    futureExtension,
    inProgressExtension,

    setFutureExtension,
    setInProgressExtension,

    paginationTwoCount,
    paginationThreeCount,

    setPaginationOneCurrentPage,
    setPaginationTwoCurrentPage,
    setPaginationThreeCurrentPage,
    setActiveTab,

    inProgressProjects,
    waitingStartProjects,
  } = useContext(ExtensionProjectsContext);

  const waitingForStart = true;
  const inProgress = true;
  const futureProjects = useRef();
  const inProgressContainer = useRef();

  useEffect(() => {
    setFutureExtension(null);
    setInProgressExtension(null);

    setPaginationOneCurrentPage(0);
    setPaginationTwoCurrentPage(0);
    setPaginationThreeCurrentPage(0);
  }, [activeTab]);

  useEffect(() => {
    if (futureExtension === null) return;

    const observer = new IntersectionObserver((entries) => {
      if (
        entries.some((entry) => entry.isIntersecting) &&
        futureExtension.length < paginationTwoCount
      ) {
        setPaginationTwoCurrentPage((prevPage) => prevPage + 8);
      }
    });

    if (futureExtension.length > 0) {
      observer.observe(futureProjects.current);
    }

    return () => observer.disconnect();
  }, [futureExtension, paginationTwoCount]);

  useEffect(() => {
    if (inProgressExtension === null) return;

    const observer = new IntersectionObserver((entries) => {
      if (
        entries.some((entry) => entry.isIntersecting) &&
        inProgressExtension.length < paginationThreeCount
      ) {
        setPaginationThreeCurrentPage((prevPage) => prevPage + 8);
      }
    });

    if (inProgressExtension.length > 0) {
      observer.observe(inProgressContainer.current);
    }

    return () => observer.disconnect();
  }, [inProgressExtension, paginationThreeCount]);

  return (
    <Container>
      <Content>
        {inProgressExtension !== null &&
        inProgressExtension?.length === 0 &&
        futureExtension !== null &&
        futureExtension?.length === 0 ? (
          <GenericMessage title="Não identificamos nenhum Projeto de Extensão publicado!" />
        ) : (
          <Fragment>
            <Wrapper>
              <Title>
                Aguardando início ({formatNumber(waitingStartProjects)})
              </Title>
              <SubTitle>
                Aqui você pode ver seus projetos de extensão que estão
                aguardando a data de início do projeto.
              </SubTitle>
              <Fragment>
                <ListPublishedProjects id="waiting-for-start">
                  <PublishedCarousel
                    array={futureExtension}
                    waitingForStart={waitingForStart}
                    openStudents={openStudents}
                    genericMessage="Não identificamos nenhum Projeto de Extensão aguardando início!"
                    handleOpenStudentsList={handleOpenStudentsList}
                  />
                  <li ref={futureProjects} />
                </ListPublishedProjects>
                {futureExtension?.length > 0 && futureExtension !== null && (
                  <div className="scroll-buttons">
                    <ArrowButton onClick={scrollLeftWaitingForStart}>
                      <IoIosArrowBack size={25} color="#ffffff" />
                    </ArrowButton>
                    <ArrowButton onClick={scrollRigthWaitingForStart}>
                      <IoIosArrowForward size={25} color="#ffffff" />
                    </ArrowButton>
                  </div>
                )}
              </Fragment>
            </Wrapper>
            <Divider></Divider>

            <Wrapper>
              <Title>Em andamento ({formatNumber(inProgressProjects)})</Title>
              <SubTitle>
                Aqui você pode ver seus projetos de extensão que estão em
                andamento.
              </SubTitle>

              <Fragment>
                <ListPublishedProjects id="in-progress">
                  <PublishedCarousel
                    array={inProgressExtension}
                    openStudents={openStudents}
                    genericMessage="Não identificamos nenhum Projeto de Extensão em andamento!"
                    handleOpenStudentsList={handleOpenStudentsList}
                    inProgress={inProgress}
                  />
                  <li ref={inProgressContainer} />
                </ListPublishedProjects>
                {inProgressExtension !== null &&
                  inProgressExtension?.length > 0 && (
                    <div className="scroll-buttons">
                      <ArrowButton onClick={scrollLeftInProgress}>
                        <IoIosArrowBack size={25} color="#ffffff" />
                      </ArrowButton>
                      <ArrowButton onClick={scrollRigthInProgress}>
                        <IoIosArrowForward size={25} color="#ffffff" />
                      </ArrowButton>
                    </div>
                  )}
              </Fragment>
            </Wrapper>
          </Fragment>
        )}
      </Content>
    </Container>
  );
}
