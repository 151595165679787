import React, {
  useRef,
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { Form } from '@unform/web';

import { Content, StepTitle, Wrapper } from './style';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import { CreateExtensionContext } from '../../CreateExtensionContext';
import { LabelWithRequiredIndicator } from 'utils/shouldAddAsterisk';
import { IoInformationCircleOutline } from 'react-icons/io5';
import Tooltip from 'components/NewTooltip';

export default function StepOne({ extensionKind, isEdit }) {
  const formRef = useRef(null);
  const {
    setStepOneFormRef,
    macroAreas,
    unities,
    getCourses,
    courses,
    getDisciplines,
    disciplines,
    teachers,
    formData,
    returnTitleModal,
    setCoursesLabel,
    setDisciplinesLabel,
  } = useContext(CreateExtensionContext);

  const [firstSelectValue, setFirstSelectValue] = useState([]);

  useEffect(() => {
    setStepOneFormRef(formRef);
  }, [formRef]);

  const handleMacroAreas = useCallback(
    async (e) => {
      await getCourses(e);
      setFirstSelectValue(e);
    },
    [getCourses]
  );

  const handleCourses = useCallback(
    async (e) => {
      if (extensionKind === 'curricular') {
        await getDisciplines(e);
      }
      e && setCoursesLabel(e.map((course) => course.label));
    },
    [getDisciplines, extensionKind]
  );

  useEffect(() => {
    if (formData) {
      formRef.current.setFieldValue('macro_areas', formData.macro_areas);
      formRef.current.setFieldValue('courses', formData.courses);
      formRef.current.setFieldValue('unities', formData.unities);
      formRef.current.setFieldValue(
        'responsible_teacher',
        formData.responsible_teacher
      );
    }
  }, [formData]);

  useEffect(() => {
    if (formData?.disciplines) {
      formRef.current.setFieldValue(
        'disciplines',
        formData?.disciplines[0] || formData?.disciplines
      );
    } else {
      formRef.current.setFieldValue('disciplines', []);
    }
  }, [formData, disciplines]);

  const handleDisciplines = (e) => {
    const getLabelName = (label) => label.split(' - ')[1];
    if (Array.isArray(e)) {
      setDisciplinesLabel(e.map((d) => getLabelName(d.label)));
    } else if (e && e.label) {
      setDisciplinesLabel([getLabelName(e.label)]);
    }
  };
  useEffect(() => {
    if (isEdit) return;

    if (firstSelectValue?.length === 0) {
      formRef.current.setFieldValue('courses', '');
      formRef.current.setFieldValue('disciplines', '');
      return;
    }

    const relatedCourses = courses.filter(({ relatedAreas = [] }) =>
      firstSelectValue.some((area) => relatedAreas.includes(area))
    );

    formRef.current.setFieldValue(
      'courses',
      relatedCourses.length ? relatedCourses : []
    );
    formRef.current.setFieldValue(
      'disciplines',
      relatedCourses.length ? [] : []
    );
  }, [firstSelectValue, courses, isEdit]);

  return (
    <Content>
      <h3 className="title">{returnTitleModal()}</h3>

      <StepTitle margin={'1.875rem 0'}>Público alvo</StepTitle>

      <Form ref={formRef} initialData={formData}>
        <UnformSelect
          name="macro_areas"
          options={macroAreas}
          isMulti
          marginTop={'0px'}
          placeholder={'Selecione'}
          label={
            <Wrapper>
              <LabelWithRequiredIndicator
                text="Área de atuação"
                indicator={extensionKind === 'curricular'}
              />
            </Wrapper>
          }
          onChange={handleMacroAreas}
          isDisabled={isEdit}
        />

        <UnformSelect
          name="courses"
          isMulti
          placeholder={'Selecione'}
          label={
            <Wrapper>
              <LabelWithRequiredIndicator
                text="Cursos relacionados"
                indicator={extensionKind === 'curricular'}
              />
            </Wrapper>
          }
          options={courses}
          onChange={handleCourses}
          isDisabled={isEdit}
        />
        {extensionKind === 'curricular' && (
          <UnformSelect
            name="disciplines"
            placeholder={'Selecione'}
            label={
              <Wrapper>
                <LabelWithRequiredIndicator
                  text="Disciplina"
                  indicator={extensionKind === 'curricular'}
                />
                <Tooltip
                  text={`Mostramos na listagem as
                        disciplinas definidas como
                        disciplinas de projetos de
                        extensão.`}
                  direction="top"
                  colorSchema="black"
                  minWidth="210px"
                  isMultiLine
                  textAlign="center"
                >
                  <IoInformationCircleOutline size={16} />
                </Tooltip>
              </Wrapper>
            }
            options={disciplines}
            onChange={handleDisciplines}
            isDisabled={isEdit}
          />
        )}

        <UnformSelect
          name="responsible_teacher"
          label={
            <Wrapper>
              <LabelWithRequiredIndicator
                text="Professor responsável"
                indicator={true}
              />
            </Wrapper>
          }
          options={
            teachers
              ? teachers.map((i) => ({ label: i.name, value: i.id }))
              : []
          }
        />
        <UnformSelect
          name="unities"
          isMulti
          label={
            <Wrapper>
              <LabelWithRequiredIndicator
                text="Unidades/Polos"
                indicator={false}
              />
            </Wrapper>
          }
          options={unities}
          isDisabled={isEdit}
        />
      </Form>
    </Content>
  );
}
