import styled from 'styled-components';

export const Overlay = styled.div`
  ${({ show }) => (show ? 'display: flex;' : 'display: none;')};
  position: fixed;
  top: 0;
  left: 0;
  width: ${({widthModal}) => widthModal || '100vw'};
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: 80vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 3rem;
  right: 3rem;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: white;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #f0f0f0;
  }
`;
