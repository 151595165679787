import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Header,
  CustomButton,
  ContainerButton,
  ContentButton,
  CustomButtonOutline,
  ContainerInfo,
  BackButton,
  FragmentColor,
  TextName,
  ContainerContent,
  ReversedColumnOnMobileContainerContent,
  JobTagsContainer,
  TextParagraph,
  CustomTextParagraph,
  BoxModal,
  ContainerColumn,
  ContentIcon,
  ContainerLogo,
  ListParagraph,
  ContainerIconAndText,
} from './styles';
import { IoIosArrowBack } from 'react-icons/io';
import BaseLayoutContext from 'contexts/base-layout';
import ReactHtmlParser from 'react-html-parser';
import {
  dialogAlertJobOffers,
  dialogConfirmApplication,
  openCoverLetterForm,
  renderLabelCurrency,
  renderList,
} from './useCases/utils';
import { jobDetailsAdapter } from 'adapters/job/jobDetailsAdapter';
import ModalInformation from 'components/informationModal';
import {
  coverLetterConfig,
  IModalConfigExternalJobEmail,
  returnConfigContracted,
} from 'views/JobOffers/components/Card/useCases/IModalConfig';
import {
  applyJobOffer,
  getStudentApplicationDetails,
  getStudentJobDetails,
  studentContracted,
} from 'services/student/jobOffers';
import ConfirmApplicationDialog from 'views/JobOffers/components/ConfirmApplicationDialog';
import {
  checkCurriculumFilled,
  checkHasCoverLetter,
} from 'services/student/profile';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import CancelApplicationDialog from 'views/JobOffers/components/CancelApplicationDialog';
import { triggerEvent, triggerPageView } from 'utils/analytics';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { CurriculumModal } from 'views/MyProfile/components/CurriculumModal/CurriculumModal';
import { Mixpanel } from 'utils/mixpanel';
import WallNotFound from 'components/ValNotFound/wallNotFound';
import LogoNetworkNotFound from 'assets/Network/logoCompanyNotFound';
import { SkeletonDetails } from 'views/JobOffers/components/JobDetails/SkeletonDetails';
import { wrapperIconsJobOffers } from 'views/JobOffers/components/utils/wrapperIconsJobOffers';
import moment from 'moment';
import BlankModalInformation from '../BlankInformationModal';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import JobOffersApi from '../../views/JobOffers/services/JobOffersApi';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import GamificationLevelOneIncompleteModal from 'views/JobOffers/components/Tabs/TabsJobOffers/GamificationLevelOneIncompleteModal';
import { configLocationModal } from 'views/JobOffers/components/utils/wrapperTextsConfig';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import ProfileForm from 'views/MyProfile/components/Profile/ProfileForm';
import { ModalsContext } from 'components/modals/contexts/modalContext';

export const JobDetailsModal = ({
  onClose,
  jobId,
  handleLoadJobs,
  externalJob,
  networking,
  appliedJob,
  closeCompanyDetailsModal,
  closeJobDetails,
  selectedJob,
}) => {
  const {
    openSnackbar,
    academic_profile,
    handleIsLoadingState,
    openModalWithContent,
    closeModal,
    setGlobalState,
    history,
    interests,
    life_interests,
  } = useContext(BaseLayoutContext);
  const {
    updateAvatarState,
    fetchGamificationData,
    profileData,
    setProfileData,
    updateProfileState,
    handleShowSpecialNeeds,
    zipCodeStudent,
    stateAndCityStudent,
  } = useContext(JobOffersContext);

  const [job, setJob] = useState(null);
  const [externalModalOpen, setExternalModalOpen] = useState(false);
  const [confirmApplication, setConfirmApplication] = useState(false);
  const [cancelApplication, setCancelApplication] = useState(false);
  const [modalConfig, setModalConfig] = useState(IModalConfigExternalJobEmail);
  const [configCoverLetter, setConfigCoverLetter] = useState(false);
  const [curriculumValidation, setCurriculumValidation] = useState(false);
  const [hasCoverLetter, setHasCoverLetter] = useState(
    academic_profile.has_cover_letter
  );
  const [confirmContracted, setConfirmContracted] = useState(false);
  const [messageNotFound, setMessageNotFound] = useState(false);
  const [openProfileForm, setOpenProfileForm] = useState(false);
  const { IconSuitcase, IconLocation, IconHouse, IconPCD, IconCheck } =
    wrapperIconsJobOffers();

  const { universityColor } = useContext(BaseLayoutContext);
  const { gamificationLevel } = useContext(ProfileContext);
  const [location, setLocation] = useState(null);

  const [
    openGamificationLevelOneIncompleteModal,
    setOpenGamificationLevelOneIncompleteModal,
  ] = useState(false);
  const [openModalLocalization, setOpenModalLocalization] = useState(false);
  const [selectJobId, setSelectJobId] = useState(null);

  const gamificationLevelOneIncomplete = gamificationLevel <= 1;

  useEffect(() => {
    if (appliedJob) {
      const loadApplicationDetails = async () => {
        const response = await getStudentApplicationDetails(jobId);
        if (response.message && externalJob) {
          setMessageNotFound(true);
        }
        if (!response.message) {
          setJob(jobDetailsAdapter(response.job_offer));
        }
      };
      loadApplicationDetails();
    }

    if (!appliedJob) {
      const loadDetail = async () => {
        const response = await getStudentJobDetails(jobId, networking);
        if (response.message && externalJob) {
          setMessageNotFound(true);
        }
        if (!response.message) {
          setJob(jobDetailsAdapter(response));
        }
      };
      loadDetail();
    }
  }, []);

  const checkCurriculum = async () => {
    const check = await checkCurriculumFilled();
    if (!check) {
      setCurriculumValidation(true);
    }
    return check;
  };

  const handleApplyJob = async () => {
    if (!(await checkCurriculum())) return;
    return dialogConfirmApplication(
      hasCoverLetter || academic_profile.has_cover_letter,
      setConfirmApplication,
      setConfigCoverLetter
    );
  };

  useEffect(() => {
    const checkCoverLetter = async () => {
      const response = await checkHasCoverLetter();
      setHasCoverLetter(response);
    };
    checkCoverLetter();
  }, []);

  useEffect(() => {
    setGlobalState(hasCoverLetter);
  }, [hasCoverLetter]);

  const openLocalizationModal = async (jobId, jobOffer) => {
    setSelectJobId(jobId);
    setLocation(jobOffer);
    if (
      !gamificationLevelOneIncomplete &&
      jobOffer &&
      jobOffer !== stateAndCityStudent
    ) {
      setOpenModalLocalization(true);
    } else {
      await candidateJobOffer(jobId);
    }
  };

  const applyJob = async (jobOffer) => {
    handleIsLoadingState(true);
    const response = await applyJobOffer(jobOffer.id);
    handleIsLoadingState(false);
    setConfigCoverLetter(false);
    closeCompanyDetailsModal && closeCompanyDetailsModal();
    closeJobDetails && closeJobDetails();

    setJob({ ...job, hasApplication: true });

    if (response.message) {
      return customSnackbar(
        'Erro ao candidatar a vaga, tente novamente mais tarde.',
        'error'
      );
    }

    triggerEvent('click', 'Candidatar à Vaga', 'aluno');
    Mixpanel.track('Menu Vagas: Clicou - Confirmar Candidatura');
    handleLoadJobs && (await handleLoadJobs());

    return customSnackbar(
      'Agora você está concorrendo a essa vaga!',
      'confirmation'
    );
  };

  const cancelJob = async (applicationId) => {
    handleIsLoadingState(true);
    const response = await JobOffersApi.fetchCancelJobOffer(applicationId);
    setJob({ ...job, hasApplication: false });
    handleIsLoadingState(false);
    if (response.message) {
      return customSnackbar(
        'Erro ao cancelar candidatura à vaga, tente novamente mais tarde.',
        'error'
      );
    }
    triggerPageView('student/vagas/candidatura/cancelar');
    Mixpanel.track('Menu Vagas: Clicou - Cancelar Candidatura');
    handleLoadJobs && (await handleLoadJobs());
    return customSnackbar('Sua candidatura foi cancelada!', 'confirmation');
  };

  const validadeEmailOrLink = (jobOffer) => {
    jobOffer?.divulgationLink
      ? window.open(jobOffer.divulgationLink, '_blank')
      : (window.location.href = `mailto:${jobOffer?.jobEmail}`);
  };

  const handleContracted = async (id) => {
    const response = await studentContracted(id);
    if (!response.message) {
      customSnackbar(
        'Parabéns! O informe de contratação foi realizado com sucesso.',
        'confirmation'
      );
      triggerEvent('student/vagas/contratado');
      Mixpanel.track('Menu Vagas: Clicou - Fui Contratado');
      setTimeout(() => {
        const searchParams =
          window.location.href.includes('?') &&
          window.location.href.split('?')[1];
        history.push({
          pathname: '/',
          search: searchParams,
          state: { addExperience: true },
        });
      }, 1000);
      setConfirmContracted(false);
    } else {
      customSnackbar('Erro ao informar contratação!', 'error');
    }
  };

  const validationCancelButton =
    job?.hasApplication &&
    job?.isJobDone === false &&
    job?.isContractedByPlatform === false &&
    !networking;

  const handleExternalApply = async (jobOffer) => {
    const check = await checkCurriculumFilled();
    if (!check) {
      setCurriculumValidation(true);
      return;
    }
    validadeEmailOrLink(jobOffer);
    await applyJob(jobOffer);
  };

  const callToExternalApply = async (jobOffer) => {
    if (!(await checkCurriculum())) return;
    if (jobOffer?.hasApplication) {
      validadeEmailOrLink(jobOffer);
      return;
    }

    dialogAlertJobOffers(jobOffer, setModalConfig, setExternalModalOpen);
  };

  function returnButton(jobOffer) {
    if (jobOffer?.divulgationLink || jobOffer?.jobEmail) {
      return {
        label: 'Acessar Vaga',
        onClick: () => callToExternalApply(jobOffer),
      };
    }

    if (validationCancelButton) {
      return {
        label: 'Cancelar Candidatura',
        onClick: () => {
          setCancelApplication(true);
        },
      };
    }

    if (!jobOffer?.hasApplication && !networking)
      return {
        label: 'Candidatar-se',
        onClick: () => openLocalizationModal(jobOffer.id, jobOffer.location),
      };

    return null;
  }

  const validationProfessionalRequirements =
    job?.selectedExtraCourses?.length > 0;

  const validationAcademicRequirements =
    job?.selectedAcademicRequirements?.courses?.length > 0;

  const validationAcademicSemesterRequirements =
    job?.selectedAcademicRequirements?.semesters?.length > 0;

  const validationBehavioralRequirements = job?.selectedBehaviors?.length > 0;

  const showHireButton =
    !job?.isCandidate && job?.isAnswered && job?.isInteresting && !networking;

  const handleCloseJobDetailsModal = () => {
    setJob(null);
    setConfirmApplication(false);
    setExternalModalOpen(false);
    onClose();
  };

  function pluralize(quantity, singular, plural) {
    return quantity === 1 ? `${quantity} ${singular}` : `${quantity} ${plural}`;
  }

  const quantity = job?.jobsOpenings;
  const returnQuantity =
    quantity !== null
      ? pluralize(quantity, 'vaga disponível', 'vagas disponíveis')
      : null;

  const handleCloseModalLocalization = () => {
    setOpenModalLocalization(false);
  };

  const isGamificationLevelOneIncomplete = () => {
    setOpenGamificationLevelOneIncompleteModal(gamificationLevelOneIncomplete);
    return gamificationLevelOneIncomplete;
  };

  async function candidateJobOffer() {
    setOpenModalLocalization && setOpenModalLocalization(false);

    if (isGamificationLevelOneIncomplete()) {
      return;
    }

    if (!hasCoverLetter) {
      return setConfigCoverLetter(true);
    }

    await applyJob(job);
  }

  const onClickEdit = () => {
    setOpenProfileForm(true);
    setOpenModalLocalization(false);
  };

  return (
    <>
      {openLocalizationModal && (
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={openModalLocalization}
          config={configLocationModal}
          buttonCancelFunction={handleCloseModalLocalization}
          buttonConfirmFunction={() => candidateJobOffer(selectJobId)}
          closeModalFunction={handleCloseModalLocalization}
          buttonThreeFunction={() => onClickEdit(selectJobId)}
        />
      )}

      {openGamificationLevelOneIncompleteModal && (
        <GamificationLevelOneIncompleteModal
          universityColor={universityColor}
          onCloseModal={() => {
            setOpenGamificationLevelOneIncompleteModal(false);
          }}
          handleIsLoadingState={handleIsLoadingState}
          academic_profile={academic_profile}
          interests={interests}
          lifeInterests={life_interests}
        />
      )}

      <BoxModal>
        <Container>
          {!messageNotFound ? (
            <FragmentColor color={universityColor}>
              <BackButton
                onClick={handleCloseJobDetailsModal}
                color={universityColor}
              >
                <IoIosArrowBack />
                <p>Voltar</p>
              </BackButton>
            </FragmentColor>
          ) : (
            <BackButton
              onClick={handleCloseJobDetailsModal}
              color={universityColor}
            >
              <IoIosArrowBack />
              <p>Voltar</p>
            </BackButton>
          )}

          {externalModalOpen && (
            <ModalInformation
              modalOpenState={externalModalOpen}
              buttonConfirmFunction={() => {
                handleExternalApply(job);
              }}
              universityColor={universityColor}
              setModalOpen={setExternalModalOpen}
              config={modalConfig}
              closeModalFunction={() => setExternalModalOpen(false)}
            />
          )}
          {confirmApplication && (
            <ConfirmApplicationDialog
              id={jobId}
              candidateJobOfferDetails={() => applyJob(job)}
              setClose={setConfirmApplication}
              isOpen={confirmApplication}
            />
          )}
          {configCoverLetter && (
            <BlankModalInformation
              universityColor={universityColor}
              modalOpenState={configCoverLetter}
              config={coverLetterConfig}
              buttonCancelFunction={() => applyJob(job)}
              buttonConfirmFunction={() => {
                setConfigCoverLetter(false);
                closeCompanyDetailsModal && closeCompanyDetailsModal();
                closeJobDetails && closeJobDetails();
                openCoverLetterForm(
                  openSnackbar,
                  closeModal,
                  handleIsLoadingState,
                  setHasCoverLetter,
                  openModalWithContent,
                  universityColor
                );
              }}
              closeModalFunction={setConfigCoverLetter}
            />
          )}
          {cancelApplication && (
            <CancelApplicationDialog
              deleteAplication={() => cancelJob(jobId)}
              id={jobId}
              setClose={setCancelApplication}
              isOpen={cancelApplication}
            />
          )}
          {curriculumValidation &&
            mergeContextsHelper(
              <CurriculumModal
                setClose={setCurriculumValidation}
                isOpen={curriculumValidation}
                closeModals={() => {
                  handleCloseJobDetailsModal();
                  closeCompanyDetailsModal && closeCompanyDetailsModal();
                  closeJobDetails && closeJobDetails();
                }}
                isFromJobOffers
              />
            )}
          {confirmContracted && (
            <ModalInformation
              modalOpenState={confirmContracted}
              buttonConfirmFunction={() => handleContracted(job.id)}
              universityColor={universityColor}
              setModalOpen={setConfirmContracted}
              config={returnConfigContracted(job)}
              closeModalFunction={() => setConfirmContracted(false)}
            />
          )}
          {messageNotFound && (
            <WallNotFound
              text={`
                A vaga que você está procurando não está
                alinhada ao seu perfil ou não existe mais`}
              title="Oops!"
              titleButton="Acessar outras vagas"
              actionButton={() => onClose()}
            />
          )}
          {!job && !messageNotFound ? (
            <SkeletonDetails
              handleCloseModal={handleCloseJobDetailsModal}
              universityColor={universityColor}
              hasIcon={true}
            />
          ) : (
            !messageNotFound && (
              <ContainerInfo>
                <Header>
                  <ContainerLogo>
                    <LogoNetworkNotFound color={universityColor} />
                  </ContainerLogo>
                  <div className="titles-header">
                    <TextName>{job.jobCreatorName}</TextName>
                    <TextName isName>{job.name}</TextName>
                  </div>
                </Header>

                <Box>
                  <ReversedColumnOnMobileContainerContent>
                    <ContainerColumn>
                      {showHireButton && (
                        <CustomButtonOutline
                          universityColor={universityColor}
                          onClick={() => setConfirmContracted(true)}
                        >
                          Fui contratado (a)
                        </CustomButtonOutline>
                      )}
                      {returnButton(job) && (
                        <CustomButton
                          universityColor={universityColor}
                          onClick={() => returnButton(job).onClick()}
                        >
                          {returnButton(job).label}
                        </CustomButton>
                      )}

                      <CustomTextParagraph
                        fontSize={'13px'}
                        fontWeight={'400'}
                        color={'#949494'}
                      >
                        CÓDIGO: {job.id}
                      </CustomTextParagraph>

                      <CustomTextParagraph fontSize={'24px'} fontWeight={'700'}>
                        {job.name}
                      </CustomTextParagraph>

                      {job.startedAt && (
                        <CustomTextParagraph
                          fontSize={'14px'}
                          fontWeight={'400'}
                          isItalicStyle
                          color={'#949494'}
                        >
                          Publicada em{' '}
                          {moment(job.startedAt).format('DD/MM/YYYY')}
                        </CustomTextParagraph>
                      )}

                      {job.endAt && (
                        <CustomTextParagraph
                          fontSize={'17px'}
                          fontWeight={'400'}
                        >
                          Inscreva-se até o dia{' '}
                          {moment(job.endAt).format('DD/MM/YYYY')}
                        </CustomTextParagraph>
                      )}
                    </ContainerColumn>
                  </ReversedColumnOnMobileContainerContent>

                  <JobTagsContainer>
                    {job.modality && (
                      <ContainerIconAndText>
                        <ContentIcon universityColor={universityColor}>
                          <IconHouse color={universityColor} />
                        </ContentIcon>
                        <TextParagraph>{job.modality}</TextParagraph>
                      </ContainerIconAndText>
                    )}
                    <ContainerIconAndText>
                      <ContentIcon universityColor={universityColor}>
                        <IconLocation color={universityColor} />
                      </ContentIcon>
                      <TextParagraph>
                        {job.location ? job.location : 'Não informado'}
                      </TextParagraph>
                    </ContainerIconAndText>
                    {job.selectedJobsType && (
                      <ContainerIconAndText>
                        <ContentIcon universityColor={universityColor}>
                          <IconSuitcase color={universityColor} />
                        </ContentIcon>
                        <TextParagraph>
                          {renderList(job.selectedJobsType)}
                        </TextParagraph>
                      </ContainerIconAndText>
                    )}
                    {job.jobsOpenings !== null ? (
                      <ContainerIconAndText>
                        <ContentIcon universityColor={universityColor}>
                          <IconCheck color={universityColor} />
                        </ContentIcon>
                        <TextParagraph>{returnQuantity}</TextParagraph>
                      </ContainerIconAndText>
                    ) : null}
                    {job.selectedDisability &&
                    job.selectedDisability.length > 0 ? (
                      <ContainerIconAndText>
                        <ContentIcon universityColor={universityColor}>
                          <IconPCD color={universityColor} />
                        </ContentIcon>
                        <TextParagraph>Exclusiva para PCD</TextParagraph>
                      </ContainerIconAndText>
                    ) : null}
                  </JobTagsContainer>

                  {job.description ? (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>
                          Atividades desenvolvidas pelo profissional{' '}
                        </TextParagraph>
                        <CustomTextParagraph textAlign={'justify'}>
                          {ReactHtmlParser(
                            job.description.replace(/style="[^"]*"/g, '')
                          )}
                        </CustomTextParagraph>
                      </ContainerColumn>
                    </ContainerContent>
                  ) : null}

                  {validationAcademicRequirements ? (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>
                          Requisitos acadêmicos
                        </TextParagraph>
                        <TextParagraph>
                          {renderList(job.selectedAcademicRequirements.courses)}
                        </TextParagraph>
                      </ContainerColumn>
                    </ContainerContent>
                  ) : null}

                  {validationAcademicSemesterRequirements ? (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>
                          Andamento do curso desejado
                        </TextParagraph>
                        <TextParagraph>
                          {renderList(
                            job.selectedAcademicRequirements.semesters
                          )}
                        </TextParagraph>
                      </ContainerColumn>
                    </ContainerContent>
                  ) : null}

                  {validationBehavioralRequirements && (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>
                          Requisitos comportamentais
                        </TextParagraph>
                        {renderList(job.selectedBehaviors)}
                      </ContainerColumn>
                    </ContainerContent>
                  )}

                  {validationProfessionalRequirements && (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>
                          Requisitos profissionais
                        </TextParagraph>
                        <TextParagraph>
                          {renderList(job.selectedExtraCourses)}
                        </TextParagraph>
                      </ContainerColumn>
                    </ContainerContent>
                  )}

                  {job?.experienceTimeRequired ? (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>
                          Pré requisitos obrigatórios
                        </TextParagraph>
                        <TextParagraph>
                          {renderList(job.experienceTimeRequired)}
                        </TextParagraph>
                      </ContainerColumn>
                    </ContainerContent>
                  ) : null}

                  {job.shift ? (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>Turno</TextParagraph>
                        <TextParagraph>{job.shift.join(', ')}</TextParagraph>
                      </ContainerColumn>
                    </ContainerContent>
                  ) : null}

                  {!job.divulgationLink && job.selectedBenefits > 0 ? (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>Benefícios</TextParagraph>
                        {renderList(job.selectedBenefits)}
                      </ContainerColumn>
                    </ContainerContent>
                  ) : null}

                  {job.anotherBenefit ? (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>
                          Detalhes dos benefícios
                        </TextParagraph>
                        <TextParagraph>
                          {ReactHtmlParser(job.anotherBenefit)}
                        </TextParagraph>
                      </ContainerColumn>
                    </ContainerContent>
                  ) : null}

                  {job.disability && job.disability.length > 0 ? (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>PCD</TextParagraph>
                        {job.disability.map((disability, index) => (
                          <ListParagraph key={index}>
                            {capitalizeFirstLetter(disability)}
                          </ListParagraph>
                        ))}
                      </ContainerColumn>
                    </ContainerContent>
                  ) : null}

                  {job.salaryRange.label !== '' || job.salary !== null ? (
                    <ContainerContent>
                      <ContainerColumn>
                        <TextParagraph isTitleDetails>
                          Faixa salarial
                        </TextParagraph>
                        <TextParagraph>
                          {`${
                            job.salaryCoin &&
                            job.salary > 0 &&
                            renderLabelCurrency(job.salaryCoin)
                          }` +
                            ` ${
                              job.salary > 0
                                ? new Intl.NumberFormat(`id`, {
                                    minimumFractionDigits: 2,
                                  }).format(job.salary)
                                : ' Não informado'
                            }`}
                        </TextParagraph>
                      </ContainerColumn>
                    </ContainerContent>
                  ) : null}

                  {job && (
                    <ContainerButton alignButton="center">
                      <ContentButton>
                        {showHireButton && (
                          <CustomButtonOutline
                            universityColor={universityColor}
                            onClick={() => setConfirmContracted(true)}
                          >
                            Fui contratado (a)
                          </CustomButtonOutline>
                        )}
                        {returnButton(job) && (
                          <CustomButton
                            universityColor={universityColor}
                            onClick={() => returnButton(job).onClick()}
                          >
                            {returnButton(job).label}
                          </CustomButton>
                        )}
                      </ContentButton>
                    </ContainerButton>
                  )}
                </Box>
              </ContainerInfo>
            )
          )}
        </Container>
      </BoxModal>

      {openProfileForm && (
        <BoxModal>
          <Container>
            <FragmentColor>
              <BackButton onClick={() => setOpenProfileForm(false)}>
                <IoIosArrowBack />
                <p>Voltar</p>
              </BackButton>
            </FragmentColor>
            <div
              style={{
                background: '#FFF',
                width: 'fit-content',
                margin: 'auto',
              }}
            >
              <ProfileForm
                handleShowSpecialNeeds={handleShowSpecialNeeds}
                handleIsLoadingState={handleIsLoadingState}
                universityColor={universityColor}
                updateAvatarState={updateAvatarState}
                updateProfileState={updateProfileState}
                closeModal={() => setOpenProfileForm(false)}
                profileData={profileData}
                setProfileData={setProfileData}
                fetchGamificationData={fetchGamificationData}
                isCandidate={true}
                jobId={jobId}
                applyToJobOffer={candidateJobOffer}
                zipCodeStudent={zipCodeStudent}
              />
            </div>
          </Container>
        </BoxModal>
      )}
    </>
  );
};
