import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 520px) {
    padding-bottom: ${({ isStudent }) => isStudent ? '3rem' : '0'}
  }
`;
