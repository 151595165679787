import { IconWrapper, Row } from '../Card/styled';
import { SkeletonBox } from './styled';
import React, { useMemo } from 'react';

export const InfoRowSkeleton = () => {
  const randomSkeletonTextWidth = useMemo(() => {
    return Math.floor(Math.random() * (180 - 110 + 1) + 110);
  }, []);

  return (
    <Row>
      <IconWrapper>
        <SkeletonBox height="18px" width="18px" />
      </IconWrapper>
      <SkeletonBox height="21px" width={`${randomSkeletonTextWidth}px`} />
    </Row>
  );
};
