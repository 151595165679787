import styled from 'styled-components';
import Select from './SearchableSelect';

const dnaGreen = '#009291';

export const Container = styled.div`
  margin-top: 10px;
  background-color: white;
  width: 100%;
  padding: 22px 68px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  ${(props) => props.half && 'width: 50%'};
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  gap: ${(props) => (props.row ? '30px' : '2px')};
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`;

export const WrapperColor = styled.div`
  display: flex;
  ${(props) => props.half && 'width: 50%'};
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  gap: 5px;
`;

export const SelectWrapper = styled.div`
  width: 40%;
`;

export const MainTitle = styled.h2`
  color: ${dnaGreen};
  font-size: 22px;
  font-weight: 700;
  margin: 0;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  color: #4b4b4d;
  margin-bottom: ${(props) => (props.mb ? props.mb : '8px')};
`;

export const SubTitle = styled.h5`
  font-size: 1rem;
  font-weight: 400;
  color: #606062;
  margin: 0px;
  line-height: 18px;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`;

export const ColorInput = styled.input`
  -webkit-appearence: none;
  -moz-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  background-color: transparent;

  ::-webkit-color-swatch {
    border-radius: 3px;
    border: none;
  }
`;

export const Input = styled.input`
  font-size: 16px;
  max-height: 44px;
  width: ${(props) => (props.isColorInput ? '40%' : '75%')};
  border: ${(props) =>
    props.error ? '2px solid #ff0000' : '1px solid #c4c4c4'};
  padding: 12px;
  border-radius: 4px;
  margin: auto 0;

  ::placeholder {
    color: #c4c4c4;
  }
`;

export const PreviewButton = styled.button`
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  background-color: ${dnaGreen};
  padding: 10px 20px;
  color: white;
  font-weight: 400;
  width: fit-content;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  margin: 32px 0 14px auto;
  background-color: ${dnaGreen};
  padding: 10px 20px;
  color: white;
  font-weight: 400;
  width: fit-content;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin-top: auto;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: -16px;
  line-height: 16px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 85%;
  height: 500px;

  background-color: #ffffff;
  height: 100%;
`;

export const InputLabel = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 17px;
  color: #4b4b4d;
  margin-bottom: 8px;
`;

const CustomSelectWrapper = styled.div`
  .custom-select__control {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    height: 44px;
  }

  .custom-select__value-container {
  }
`;

export const CustomSelect = (props) => {
  return (
    <CustomSelectWrapper>
      <Select classNamePrefix="custom-select" {...props} />
    </CustomSelectWrapper>
  );
};
