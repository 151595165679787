import * as Yup from 'yup';
import {
  stepOneSchema,
  stepThreeSchema,
  stepTwoSchema,
} from './validationSchemas';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

/**
 * @function
 * @param {Object} formRef
 * @param {Object} validationSchema
 *
 * @returns {Boolean}
 */

async function validateStep(formRef, validationSchema, setFormValues, kind) {
  if (formRef?.current) {
    const formData = formRef.current.getData();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setFormValues(formData);
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          formRef.current.setFieldError(error.path, error.message);
        });
      }
      return false;
    }
  }
  return false;
}

export async function stepOneValidator(formRef, setFormValues, kind) {
  let oneSchema = stepOneSchema;

  setFormValues({ extension_kind: kind });

  if (kind === 'curricular') {
    oneSchema = oneSchema.shape({
      disciplines: Yup.string().required('Escolha uma Disciplina.'),
      courses: Yup.array()
        .of(Yup.string())
        .required('Escolha ao menos um Curso relacionado.'),
      macro_areas: Yup.array()
        .of(Yup.string())
        .required('Escolha ao menos uma Área de atuação.'),
    });
  } else {
    oneSchema = oneSchema.shape({
      courses: Yup.array().of(Yup.string()),
      macro_areas: Yup.array().of(Yup.string()),
    });
  }
  return validateStep(formRef, oneSchema, setFormValues);
}

export async function stepTwoValidator(formRef, setFormValues) {
  return validateStep(formRef, stepTwoSchema, setFormValues);
}

export async function stepThreeValidator(formRef, setFormValues, hasCep) {
  let threeSchema = stepThreeSchema;

  if (hasCep === true) {
    threeSchema = threeSchema.shape({
      address: Yup.string().required('Campo obrigatório'),
      district: Yup.string().required('Campo obrigatório'),
      city: Yup.string().required('Campo obrigatório'),
      address_number: Yup.string().required('Campo obrigatório'),
    });
  }
  return validateStep(formRef, threeSchema, setFormValues);
}

export async function stepFiveValidator(countStudents) {
  if (countStudents) {
    return true;
  } else {
    customSnackbar('O projeto não pode ser finalizado sem alunos!', 'alert')
    return false
  }
}
