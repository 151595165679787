import styled from 'styled-components';

export const BackgroundModal = styled.div`
  width: ${({ fullSize }) => (fullSize ? '100%' : 'calc(100% - 259px)')};
  height: 100%;

  position: fixed;
  top: 0;
  right: 0;

  background-color: #00000040;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 300;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Modal = styled.div`
  width: 680px;
  height: auto;
  background-color: #fff;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  border-radius: 12px;

  position: absolute;
  top: 35px;
  margin-left: -10px;
`;

export const HeaderModal = styled.div`
  width: 100%;
  height: 85px;
  background-color: #009291;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 40px;

  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`;

export const BodyModal = styled.div`
  padding: 50px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const TitleModal = styled.h2`
  margin: 0;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
`;

export const ButtonClose = styled.button`
  width: 44px;
  height: 44px;

  border: none;
  border-radius: 50%;

  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const ContentButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const ButtonUpload = styled.button`
  width: 90px;
  height: 44px;

  background-color: #009291;
  border: none;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  color: #fff;
  font-weight: 400;

  cursor: pointer;

  &:hover:not(:disabled) {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
    color: #949494;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`;
