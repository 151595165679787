import GenericMessage from 'components/GenericMessage';
import { CloseButton, Container, HeaderModal, WrapperStudents } from './style';
import { RiCloseFill } from 'react-icons/ri';
import ExtensionStudent from '../steps/StepFive/components/Student';
import Pagination from 'components/CustomPagination';
import { useContext, useEffect, useState } from 'react';
import { CreateExtensionContext } from '../../CreateExtensionContext';
import { StudentSkeleton } from '../Skeletons/StudentSkeleton';

export function ModalAddStudents({
  setControlModalListing,
  students,
  count,
  search,
  fetchFindStudents,
  buildBodyParams,
  setStudents
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(24);
  const [offset, setOffset] = useState(0);
  const { formData } = useContext(CreateExtensionContext);

  const onPageChange = (page) => {
    setCurrentPage(page);
    const mathOffset = (page - 1) * limit || 0;
    setOffset(mathOffset);
  };

  useEffect(() => {
    const queryParams = {
      limit,
      offset,
      name_or_code: search,
    };

    const bodyParams = buildBodyParams();
    fetchFindStudents(bodyParams, queryParams);
  }, [limit, offset, search]);

  const renderStudentListing = (studentArray) => {
    if (studentArray === null) {
      return [1, 2, 3].map(() => <StudentSkeleton matchView={true} />);
    }

    if (studentArray?.length === 0) {
      return (
        <GenericMessage
          resetIndex={true}
          title="Nenhum resultado encontrado"
          subtitle={`No momento não existem “${search}” disponíveis.`}
        />
      );
    }

    if (studentArray?.length > 0) {
      return studentArray?.map((student) => (
        <ExtensionStudent
          student={student}
          setControlModalListing={setControlModalListing}
          addStudentMode
        />
      ));
    }
  };

  const handleCloseModal = () => {
    setStudents(null)
    setControlModalListing(false)
  }

  return (
    <Container>
      <CloseButton onClick={handleCloseModal}>
        <RiCloseFill size={22} color="#606062" />
      </CloseButton>

      <HeaderModal>
        <p>Adicionar estudantes</p>
        <h3>{formData.name}</h3>
        <p>
          {count} Resultados encontrados para "{search}"
        </p>
      </HeaderModal>
      <WrapperStudents>{renderStudentListing(students)}</WrapperStudents>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={count}
        onPageChange={onPageChange}
        setCurrentPage={setCurrentPage}
        setLimit={setLimit}
        limit={limit}
        showLimit={false}
      />
    </Container>
  );
}
