import React, { useEffect } from 'react';
import TabsManager from './screens';
import { ContextProvider } from './context';

export default function ExtensionEngine({ handleIsLoadingState }) {
  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  return (
    <ContextProvider>
      <TabsManager />
    </ContextProvider>
  );
}
