import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import { useVisualizationStudentContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationStudent';
import { StudentHandlers } from 'views/StaffPages/FairAndEvents/Handlers/StudentHandlers';
import { useEffect, useRef, useState } from 'react';
import StudentCoursesModal from '../../StudentCoursesModal';
import Button from '../../Button';
import altAvatar from 'assets/img/placeholder.jpg';
import { FaCheck } from 'react-icons/fa';
import {
  ContainerImageAndInformations,
  ContainerListing,
  Wrapper,
  Container,
  Header,
  LabelPresence,
} from './styled';
import { CoreHandlers } from 'views/StaffPages/FairAndEvents/Handlers/CoreHandlers';
import ModalInformation from 'components/informationModal';
import Search from '../../SearchBar';
import CertificateModal from '../../CertificateModal';

export default function ListingFinished() {
  const [studentCourseModalOpen, setStudentCourseModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [informationModalOpen, setInformationModalOpen] = useState(false);

  const visualizationStudentContextInstance = useVisualizationStudentContext();
  const visualizationContextInstance = useVisualizationContext();

  const [certificateModalOpen, setCertificateModalOpen] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState({});

  const CoreHandlersFactory = CoreHandlers(visualizationContextInstance);

  const studentHandlerFactory = StudentHandlers(
    visualizationContextInstance,
    visualizationStudentContextInstance
  );

  const { detailsObject, currentTab } = visualizationContextInstance;

  const { listing, currentPage, limit } = visualizationStudentContextInstance;
  const eventId = detailsObject.id;

  const scrollRef = useRef(null);

  const handleClickMoreCourses = (studentData) => {
    setStudentCourseModalOpen(true);
    setSelectedStudent(studentData);
  };

  const handleClickReSendCertificate = (studentData) => {
    setSelectedStudent(studentData);
    studentHandlerFactory.reSendStudentCertificate(
      studentData.uuid,
      eventId,
      studentData
    );

    setInformationModalOpen(true);
  };

  const handleClickSeeCertificate = async (studentData) => {
    const response = await CoreHandlersFactory.studentEventCertificate(
      studentData.uuid,
      eventId
    );
    setSelectedCertificate(response);
    setCertificateModalOpen(true);
  };

  useEffect(() => {
    scrollRef?.current?.scrollIntoView();
  }, [scrollRef]);

  useEffect(() => {
    if (eventId) {
      const parameters = `limit=${limit}&offset=${
        (currentPage - 1) * limit || 0
      }`;
      studentHandlerFactory.getListingStudentEvent(eventId, _, parameters);
    }
  }, [eventId]);

  function StudentCard({ studentData, handleClickMoreCourses, currentButton }) {
    const avatarSource = studentData?.student.avatar
      ? studentData.student.avatar
      : altAvatar;

    const formatCourseAndPeriod = () => {
      const courses = studentData?.student.courses?.map((item) => {
        return {
          course: item.course__name,
          semester: item.semester,
        };
      });

      const firstCourse = courses[0]?.course;
      const firstPeriod = courses[0]?.semester + '°';
      const coursesCount = courses.length;
      return { firstCourse, firstPeriod, coursesCount };
    };

    const { firstCourse, firstPeriod, coursesCount } = formatCourseAndPeriod();
    const formatStringCourseAndPeriod = `${firstCourse} - ${firstPeriod} período`;

    return (
      <Container>
        <Wrapper height={'244px'} bottom={'-14px'}>
          <ContainerImageAndInformations active={studentData.presence}>
            <div>
              <img src={avatarSource} />

              <div className="checkActive">
                <FaCheck color={'#FFF'} size={14} />
              </div>
            </div>
            <div>
              <h4>{studentData.student.name}</h4>
              <p>
                {formatStringCourseAndPeriod}
                {coursesCount > 1 && (
                  <span onClick={() => handleClickMoreCourses(studentData)}>
                    + {coursesCount}
                  </span>
                )}
              </p>
              <LabelPresence presence={studentData.presence}>
                {studentData.presence
                  ? 'Presença confirmada'
                  : 'Presença não confirmada'}
              </LabelPresence>
              <span>ID: {studentData.student.external_id}</span>
            </div>
          </ContainerImageAndInformations>

          {studentData.certification && studentData.presence && (
            <div>
              <Button
                theme="unfilled"
                width="100%"
                onClick={() => handleClickReSendCertificate(studentData)}
              >
                Reenviar certificado
              </Button>

              <Button
                theme="flat"
                width="100%"
                onClick={() => handleClickSeeCertificate(studentData)}
              >
                Visualizar certificado
              </Button>
            </div>
          )}

          <div className="divider" />
        </Wrapper>
      </Container>
    );
  }

  const renderStudentList = listing.map((studentData) => (
    <StudentCard
      key={studentData.uuid}
      studentData={studentData}
      handleClickMoreCourses={handleClickMoreCourses}
    />
  ));

  return (
    <ContainerListing>
      <CertificateModal
        open={certificateModalOpen}
        certificate={selectedCertificate}
        onClose={() => setCertificateModalOpen(false)}
      />
      <Header ref={scrollRef}>
        <h4>Visualizar estudantes e reenviar certificado</h4>
        <span>
          Aqui você pode reenviar o certificado dos estudantes que participaram
          do evento. Eles também tem acesso ao <br /> certificado através do
          sistema e podem fazer o download acessando os detalhes desse evento.
        </span>
        <div className="filtersAndBulk">
          <div>
            <Search
              onChange={(event) =>
                studentHandlerFactory.onFilter(eventId, event.target.value)
              }
            />
          </div>
        </div>
      </Header>
      <ModalInformation
        modalOpenState={informationModalOpen}
        closeModalFunction={() => setInformationModalOpen(false)}
        config={{
          bubbleText: 'Certificado enviado com sucesso!',
          ParagraphText: `O certificado foi enviado com sucesso para o e-mail ${selectedStudent?.student?.email}`,
          buttonConfirmText: 'OK, ENTENDI',
        }}
        buttonConfirmFunction={() => setInformationModalOpen(false)}
      />
      <StudentCoursesModal
        studentData={selectedStudent}
        open={studentCourseModalOpen}
        onClose={() => setStudentCourseModalOpen(false)}
      />
      {renderStudentList}
    </ContainerListing>
  );
}
