import styled from 'styled-components';
import arrowRight from 'assets/Oportutinites/Setadireita.svg';
import arrowLeft from 'assets/Oportutinites/Setaesquerda.svg';
import LoadingUpdate from 'components/LoadingUpdate/LoadigUpdate';
import { Elipsis } from 'components/LoadingUpdate/LoadingUptStyles';

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  padding: 0 10rem;
`;

export const NextButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${arrowRight});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  margin-left: auto;
`;

export const SubmitButton = styled.button`
  width: 106px;
  height: 40px;
  border-radius: 3px;
  border: none;

  background-color: #009291;
  color: #ffff;

  cursor: pointer;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;

  &:disabled {
    background-color: #f5f5f5;
    color: #949494;
    cursor: default;
  }
`;

export const PrevButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${arrowLeft});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;

  &:disabled {
    background-color: #c8c8c8;
  }
`;

export const Loading = styled(LoadingUpdate)`
  &${Elipsis} {
    .lds-ellipsis {
      display: flex !important;
      justify-content: center;
      position: relative;
      width: auto;
      height: auto !important;
      gap: 8px;
      padding: 1.5px 2.5px;
    }
    .lds-ellipsis div {
      position: unset;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: ${({ color }) => color || '#fff'} !important;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 0px !important;
      animation: lds-ellipsis1 0.6s infinite;
      margin-right: 0px;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 0px !important;
      animation: lds-ellipsis2 0.6s infinite;
      margin-left: -12px;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 24px;
      animation: lds-ellipsis2 0.6s infinite;
      margin-left: 0px;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 48px;
      animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(12px, 0);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
  }
`;
