import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  BackgroundModal,
  ContentHeader,
  ContentTexts,
  Title,
  TextHeader,
  ButtonList,
  ContentModal,
  ContentCloseModal,
  ButtonCloseModal,
  Container,
  Content,
  PaginationContainer,
} from './styled';
import { IoClose } from 'react-icons/io5';
import EnrolledStudentsSkeleton from '../EnrolledStudentsSkeleton';
import EnrolledStudents from '../EnrolledStudents';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import { getListExtensionProjectStudents } from 'views/StaffPages/ExtensionProject/services';
import Pagination from 'components/CustomPagination';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export default function NewModalStudents({
  status,
  onClose,
  openListModal,
  extracurriculum,
  setOpenModalStudents,
}) {
  const {
    extensionProjectId,
    currentPagePagination,
    setCurrentPagePagination,
    idAttraction
  } = useContext(ExtensionProjectsContext);
  const [page, setPage] = useState(1);
  const [studentCount, setStudentCount] = useState(0);
  const [students, setStudents] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [limitPerPage, setLimitPerPage] = useState(20);

  async function getStudents(page = 1) {
    setRequestInProgress(true);
    const offset = (page - 1) * limitPerPage;
    try {
      const data = await getListExtensionProjectStudents(
        extensionProjectId,
        limitPerPage,
        offset
      );
      const { results, count } = data;
      setStudentCount(count);
      setStudents(results);
    } catch (error) {
      customSnackbar('Erro ao buscar lista de estudantes inscritos:', 'error');
    } finally {
      setRequestInProgress(false);
    }
  }

  async function changePage(page) {
    setStudents([]);
    setCurrentPagePagination(() => page);
    await getStudents(page);
  }

  useEffect(() => {
    getStudents();
  }, []);

  const renderItems = () => {
    if (requestInProgress) {
      return Array(8)
        .fill(1)
        .map((_, index) => <EnrolledStudentsSkeleton key={index} />);
    }

    if (students.length === 0) {
      return <p>Não há estudantes cadastrados para este projeto.</p>;
    }

    return students.map((registeredStudent) => (
      <EnrolledStudents
        key={registeredStudent.id}
        registeredStudent={registeredStudent}
        getStudents={getStudents}
        limitPerPage={limitPerPage}
        offset={(page - 1) * limitPerPage}
        status={status}
        students={students}
        showStatus={false}
        setScore={false}
        finalization={false}
        extracurriculum={extracurriculum}
        setOpenModalStudents={setOpenModalStudents}
      />
    ));
  };
  return (
    <BackgroundModal>
      <Container>
        <ContentCloseModal>
          <ButtonCloseModal onClick={onClose}>
            <IoClose size={22} color="#606062" />
          </ButtonCloseModal>
        </ContentCloseModal>
        <Content>
          <ContentHeader>
            <ContentTexts>
              <Title>Lista de Estudantes ({studentCount})</Title>
              <TextHeader>
                Confira os estudantes que estão inscritos no projeto de
                extensão.
              </TextHeader>
            </ContentTexts>
            <ButtonList onClick={() => openListModal(idAttraction)}>Exportar lista</ButtonList>
          </ContentHeader>

          <ContentModal>{renderItems()}</ContentModal>

          <PaginationContainer>
            <Pagination
              className="pagination-bar"
              currentPage={currentPagePagination}
              totalCount={studentCount}
              onPageChange={changePage}
              limit={limitPerPage}
              setLimit={setLimitPerPage}
              setCurrentPage={setCurrentPagePagination}
              showLimit={false}
            />
          </PaginationContainer>
        </Content>
      </Container>
    </BackgroundModal>
  );
}
