import React, { useContext, useState } from 'react';
import {
  ActionButton,
  ActionsWrapper,
  Container,
  IconWrapper,
  MaterialListWrapper,
  MaterialTile,
  ModalDescription,
  TextWrapper,
} from './styled';
import BaseLayoutContext from 'contexts/base-layout';
import GenericModal from 'views/Student/Mentorship/components/GenericModal';
import { useProjectExtensionContext } from '../../context';
import {
  JPGIcon,
  MP4Icon,
  PDFIcon,
  PNGIcon,
  RARIcon,
  ZIPIcon,
} from 'assets/ComponentIcons/evidencesIcon';
import { DefaultEyeIcon } from 'assets/ComponentIcons/DefaultEye';
import moment from 'moment';
import { RiDownloadLine, RiFileCopyLine, RiLink } from 'react-icons/ri';
import {
  customSnackbar,
  SnackbarType,
} from '../../../../../components/CustomSnackBar/customSnackbar';
import Tooltip from 'components/NewTooltip';
import { handleClickViewPdf } from 'components/GenericModalUpload/utils/resource';
import { ImageViewer } from '../ImageViewer';
import { GenerateBlobPdf } from 'utils/generateBlobPdf';
import { GenerateBlobImage } from 'utils/generateBlobImage';

export function SupportMaterialModal({ title, onClose, isOpen, modalData }) {
  const { universityColor, openModalWithContent } =
    useContext(BaseLayoutContext);
  const { studentFirstName } = useProjectExtensionContext();
  const [selectedImage, setSelectedImage] = useState(null);
  const color = universityColor || '#009291';

  const getSuffix = (material) => {
    return (
      material?.image?.suffix || material?.file?.suffix || material?.type || ''
    );
  };

  const getName = (material) => {
    return material?.image?.name || material?.file?.name;
  };

  const icons = {
    '.pdf': <PDFIcon color={color} size={40} />,
    '.png': <PNGIcon color={color} size={40} />,
    '.jpg': <JPGIcon color={color} size={40} />,
    '.jpeg': <JPGIcon color={color} size={40} />,
    '.mp4': <MP4Icon color={color} size={40} />,
    '.zip': <ZIPIcon color={color} size={40} />,
    '.rar': <RARIcon color={color} size={40} />,
    'link': <RiLink color={color} size={40} />,
  };

  const handleClickViewProject = (material) => {
    if (material.file) {
      handleClickViewPdf(material.file, openModalWithContent);
    }

    if (material.image) {
      setSelectedImage(material.image.file);
    }
  };

  async function handleClickDownloadProject(material) {
    const fileUrl = material.file ? material.file.file : material.image?.file;
    const fileName = material.file ? material.file.name : material.image?.name;
    const fileSuffix = material.file
      ? material.file.suffix
      : material.image.suffix;

    if (material.file) {
      const link = await GenerateBlobPdf(fileUrl);
      const downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.rel = 'noopener noreferrer';
      downloadLink.href = link;
      downloadLink.download = `${fileName}.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    if (material.image) {
      const link = await GenerateBlobImage(fileUrl);
      const downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.rel = 'noopener noreferrer';
      downloadLink.href = link;
      downloadLink.download = `${fileName}${fileSuffix}`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  const handleClickCopyProjectUrl = (url) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then((_) =>
          customSnackbar(
            'URL copiado para a área de transferência com sucesso!',
            SnackbarType.CONFIRMATION
          )
        )
        .catch((err) =>
          customSnackbar(`Erro ao copiar o URL: ${err}`, SnackbarType.ERROR)
        );
    }
  };

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} title={title}>
      <ImageViewer
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />
      <Container>
        <ModalDescription>
          <b>{studentFirstName}</b>, aqui você consegue visualizar documentos,
          links ou imagens que o seu professor deixou como material de apoio.
        </ModalDescription>
        <MaterialListWrapper>
          {Array.isArray(modalData)
            ? modalData.map((material) => (
                <MaterialTile color={universityColor} key={material.key}>
                  <IconWrapper>{icons[`${getSuffix(material)}`]}</IconWrapper>
                  <TextWrapper color={universityColor}>
                    {material.type === 'link' ? (
                      <Tooltip
                        direction="top"
                        colorSchema="black"
                        text="Ir para URL"
                      >
                        <a
                          href={material.link}
                          target="_blank"
                          color={universityColor}
                        >
                          URL
                        </a>
                      </Tooltip>
                    ) : (
                      <p>{getName(material)}</p>
                    )}
                    <span>
                      Adicionado em{' '}
                      {moment(material.uploaded_at).format('DD/MM/YYYY')}
                    </span>
                  </TextWrapper>
                  <ActionsWrapper>
                    {material.type === 'link' ? (
                      <Tooltip
                        direction="top"
                        colorSchema="black"
                        text="Copiar URL"
                      >
                        <ActionButton
                          onClick={() =>
                            handleClickCopyProjectUrl(material.link)
                          }
                        >
                          <RiFileCopyLine size={24} color={color} alt="eye" />
                        </ActionButton>
                      </Tooltip>
                    ) : (
                      <>
                        <Tooltip
                          direction="top"
                          colorSchema="black"
                          text="Visualizar"
                        >
                          <ActionButton
                            onClick={() => handleClickViewProject(material)}
                          >
                            <DefaultEyeIcon size={24} color={color} alt="eye" />
                          </ActionButton>
                        </Tooltip>
                        <Tooltip
                          direction="top"
                          colorSchema="black"
                          text="Download"
                        >
                          <ActionButton
                            onClick={() => handleClickDownloadProject(material)}
                          >
                            <RiDownloadLine
                              size={24}
                              color={color}
                              alt="download"
                            />
                          </ActionButton>
                        </Tooltip>
                      </>
                    )}
                  </ActionsWrapper>
                </MaterialTile>
              ))
            : null}
        </MaterialListWrapper>
      </Container>
    </GenericModal>
  );
}
