import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import {
  Container,
  ContainerListing,
  Wrapper,
  ContainerImageAndInformations,
  Header,
} from './styled';
import { useVisualizationStudentContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationStudent';
import { StudentHandlers } from 'views/StaffPages/FairAndEvents/Handlers/StudentHandlers';
import { useEffect, useRef, useState } from 'react';
import StudentCoursesModal from '../../StudentCoursesModal';
import Button from '../../Button';

import altAvatar from 'assets/img/placeholder.jpg';
import { FaCheck } from 'react-icons/fa';
import Search from '../../SearchBar';
import CustomCheckbox from '../../CustomCheckbox';
import { MdOutlineChecklist } from 'react-icons/md';
import { CoreHandlers } from 'views/StaffPages/FairAndEvents/Handlers/CoreHandlers';
import FeedbackModal from 'components/FeedbackModal';

export default function ListingAvaiablesEvents() {
  const visualizationContextInstance = useVisualizationContext();
  const visualizationStudentContextInstance = useVisualizationStudentContext();
  const [selectedStudent, setSelectedStudent] = useState({});
  const [studentCouseModalOpen, setStudentCourseModalOpen] = useState(false);
  const [bulkRemoving, setBulkRemoving] = useState(false);

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const [checkedStudents, setCheckedStudents] = useState([]);

  const [checkedAll, setCheckedAll] = useState(false);
  const [loadingPreviousResults, setLoadingPreviousResults] = useState(false);
  const [restartCheckBox, setRestartCheckBox] = useState(false);

  const scrollRef = useRef(null);

  const studentHandlerFactory = StudentHandlers(
    visualizationStudentContextInstance,
    visualizationStudentContextInstance
  );

  const CoreHandlersFactory = CoreHandlers(visualizationContextInstance);

  const { detailsObject } = visualizationContextInstance;

  const {
    listing,
    currentPage,
    limit,
    setCurrentPage,
  } = visualizationStudentContextInstance;

  const eventId = detailsObject.id;

  useEffect(() => {
    scrollRef?.current?.scrollIntoView();
  }, [scrollRef]);

  useEffect(() => {
    if (eventId) {
      const parameters = `limit=${limit}&offset=${
        (currentPage - 1) * limit || 0
      }`;
      studentHandlerFactory.getListingStudentEvent(eventId, _, parameters);
    }
  }, [eventId]);

  useEffect(() => {
    const loadPrevious = async () => {
      try {
        setLoadingPreviousResults(true);
        const parameters = `limit=${limit}&offset=${
          (currentPage - 2) * limit || 0
        }`;
        setCurrentPage(currentPage - 1);
        await studentHandlerFactory.getListingStudentEvent(
          eventId,
          _,
          parameters
        );
      } finally {
        setLoadingPreviousResults(false);
      }
    };

    if (
      (!listing || listing?.length === 0) &&
      currentPage > 1 &&
      !loadingPreviousResults
    ) {
      loadPrevious();
    }
  }, [listing, currentPage, loadingPreviousResults]);

  const handleClickMoreCourses = (studentData) => {
    setSelectedStudent(studentData);
    setStudentCourseModalOpen(true);
  };

  const onChangeStudentCheckbox = (event, studentData) => {
    const checked = event.target.checked;

    if (checked) {
      setCheckedStudents((prev) => [...prev, studentData.uuid]);
      return;
    }

    setCheckedStudents((prev) =>
      prev.filter((uuid) => uuid !== studentData.uuid)
    );
  };

  const onClickRemoveStudent = (studentData) => {
    setBulkRemoving(false);
    setSelectedStudent(studentData);
    setFeedbackModalOpen(true);
  };

  const onClickBulkRemove = () => {
    setBulkRemoving(true);
    setFeedbackModalOpen(true);
  };

  const onCloseFeedbackModal = () => {
    setFeedbackModalOpen(false);
    setBulkRemoving(false);
  };

  const controllConfirmRemove = async (feedback) => {
    try {
      if (bulkRemoving) {
        await studentHandlerFactory.handleClickRemoveBulkStudents(
          checkedStudents,
          eventId,
          feedback
        );
        setBulkRemoving(false);
      } else {
        await studentHandlerFactory.handleClickRemoveStudent(
          selectedStudent.uuid,
          eventId,
          feedback
        );
      }
      setRestartCheckBox(true);
    } finally {
      setCheckedStudents([]);
      setCheckedAll(false);
      setFeedbackModalOpen(false);
      setRestartCheckBox(false);
    }
  };

  if (!listing) {
    return null;
  }

  const createCardStudent = () => {
    if (listing?.length === 0) return;

    return listing.map((studentData) => {
      const avatarSource = studentData.student.avatar
        ? studentData.student.avatar
        : altAvatar;

      const formatCourseAndPeriod = () => {
        const firstCourse = studentData.student.courses[0]?.course__name;
        const firstPeriod = studentData.student.courses[0]?.semester + '°';
        const coursesCount = studentData.student.courses?.length - 1;

        return { firstCourse, firstPeriod, coursesCount };
      };

      const {
        firstCourse,
        firstPeriod,
        coursesCount,
      } = formatCourseAndPeriod();
      const formatStringCourseAndPeriod = `${firstCourse} - ${firstPeriod} período`;

      return (
        <Container>
          <Wrapper>
            <ContainerImageAndInformations active={true}>
              <div>
                {checkedAll && (
                  <input
                    type="checkbox"
                    checked={checkedStudents.includes(studentData.uuid)}
                    onChange={(event) =>
                      onChangeStudentCheckbox(event, studentData)
                    }
                  />
                )}
                <img src={avatarSource} />
              </div>
              <div>
                <h4>{studentData.student.name}</h4>
                <p>
                  {formatStringCourseAndPeriod}
                  {coursesCount > 0 && (
                    <span onClick={() => handleClickMoreCourses(studentData)}>
                      + {coursesCount}
                    </span>
                  )}
                </p>
                <span>ID: {studentData.student.external_id}</span>
              </div>
            </ContainerImageAndInformations>

            <div>
              <Button
                theme="flat"
                width="100%"
                onClick={() => onClickRemoveStudent(studentData)}
              >
                Retirar estudante da lista
              </Button>
            </div>
          </Wrapper>

          <div className="divider" />
        </Container>
      );
    });
  };

  const onChangeCheckbox = (checked) => {
    setCheckedAll(checked);

    if (checked) {
      const students = listing.map((student) => {
        return student.uuid;
      });

      setCheckedStudents(students);
      return;
    }

    setCheckedStudents([]);
  };

  return (
    <ContainerListing>
      <FeedbackModal
        isOpen={feedbackModalOpen}
        required={true}
        config={{
          bubbleText: 'Envie seu feedback',
          paragraphText:
            'Para retirar o estudante da lista, envie um feedback para ele:',
          buttonConfirmText: 'RETIRAR ESTUDANTE',
        }}
        onConfirm={controllConfirmRemove}
        onClose={onCloseFeedbackModal}
      />

      <Header ref={scrollRef}>
        <h4>Visualizar inscritos</h4>
        <span>
          Verifique os estudantes que se inscreveram para participar do evento
        </span>

        <div className="headerButtons">
          <Button
            icon={<MdOutlineChecklist color="#FFF" />}
            theme="dark"
            onClick={() => CoreHandlersFactory.exportList(eventId)}
          >
            Exportar lista
          </Button>
        </div>

        <div className="filtersAndBulk">
          <div>
            <CustomCheckbox
              onChange={onChangeCheckbox}
              unCheck={restartCheckBox}
            />
            <Search
              onChange={(event) =>
                studentHandlerFactory.onFilter(eventId, event.target.value)
              }
            />
          </div>
          {checkedAll && (
            <Button theme="flatUniversityColor" onClick={onClickBulkRemove}>
              Retirar {checkedStudents.length}{' '}
              {checkedStudents.length > 1 ? 'estudantes' : 'estudante'}
            </Button>
          )}
        </div>
      </Header>

      <StudentCoursesModal
        studentData={selectedStudent}
        open={studentCouseModalOpen}
        onClose={() => setStudentCourseModalOpen(false)}
      />
      {createCardStudent()}
    </ContainerListing>
  );
}
