import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Container,
  ProjectListContainer,
  SeeMoreButton,
  Title,
  WhiteBox,
} from '../styled';
import ProjectCard from 'views/Student/ExtensionProject/components/Card';
import { projectExtensionApi } from '../../../../services/api';
import GenericMessage from 'components/GenericMessage';
import { ProjectCardSkeleton } from '../../../../components/CardSkeleton';
import ModalFeedbackExtensionProject from 'views/StaffPages/ExtensionProject/components/Modal/ModalViewFeedback';
import BaseLayoutContext from 'contexts/base-layout';
import { useProjectExtensionContext } from 'views/Student/ExtensionProject/context';
import { getExtensionProjectFeedback } from 'views/Student/Extracurricular/services';
import DetailsModal from 'views/Student/ExtensionProject/components/DetailsModal';

export const ArchivedProjects = () => {
  const [archivedProjects, setArchivedProjects] = useState(null);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [page, setPage] = useState(1);
  const [feedback, setFeedback] = useState();
  const [hasNextPage, setHasNextPage] = useState(false);
  const { universityColor } = useContext(BaseLayoutContext);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const { openDetailsModal, setModalData, closeDetailsModal, modalData } =
    useProjectExtensionContext();

  const fetchArchivedProjects = async () => {
    const [responseExtensions, responseImages] = await Promise.all([
      projectExtensionApi.getArchivedExtensionProject(),
      projectExtensionApi.getImagesArchivedExtensions(),
    ]);
    setIsLoadingList(false);
    const extensions = responseExtensions.results;
    setHasNextPage(!!responseExtensions.next);
    const images = responseImages.results;
    if (extensions != null) {
      if (images) {
        const extensionsWithImages = extensions.map((extension) => {
          const image = images.find((image) => image.id === extension.id);
          extension.image = image.attraction_image;
          return extension;
        });
        setArchivedProjects(extensionsWithImages);
        return;
      }
      setArchivedProjects(extensions);
    }
  };

  const openFeedbackModal = async (data) => {
    setModalData(data);
    const feedbackResponse = await getExtensionProjectFeedback(
      data.general_feedback_id || data.individual_feedback_id
    );
    setFeedbackModalOpen(true);
    setFeedback(feedbackResponse);
  };

  useEffect(() => {
    fetchArchivedProjects();
  }, []);

  const buttons = [
    {
      label: 'Detalhes',
      isDetails: true,
      onClick: openDetailsModal,
    },
    {
      label: 'Feedback',
      onClick: openFeedbackModal,
    },
  ];

  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (10 - 3 + 1) + 3);
  }, []);

  const handleNextPage = useCallback(async () => {
    setIsLoadingList(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 10;
    const [responseExtensions, responseImages] = await Promise.all([
      projectExtensionApi.getArchivedExtensionProject(
        `limit=10&offset=${offset}`
      ),
      projectExtensionApi.getImagesArchivedExtensions(
        `limit=10&offset=${offset}`
      ),
    ]);
    setIsLoadingList(false);
    const extensions = responseExtensions.results;
    setHasNextPage(!!responseExtensions.next);
    const images = responseImages.results;
    if (extensions != null) {
      if (images) {
        const extensionsWithImages = extensions.map((extension) => {
          const image = images.find((image) => image.id === extension.id);
          extension.image = image.attraction_image;
          return extension;
        });
        setArchivedProjects([...archivedProjects, ...extensionsWithImages]);
        return;
      }
      setArchivedProjects([...archivedProjects, ...extensions]);
    }
  }, [archivedProjects, page]);

  return (
    <Container>
      <WhiteBox>
        <Title>
          Aqui você pode visualizar as atividades de extensão que foram
          descontinuadas, seja pelo projeto ter sido despublicado ou por você
          não ter sido selecionado para participar.
        </Title>
        {archivedProjects?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhum projeto de extensão encontrado"
            subtitle="Os projetos de extensão arquivados aparecerão aqui."
          />
        ) : (
          <ProjectListContainer>
            {archivedProjects?.map((item, index) => (
              <ProjectCard key={index} item={item} buttons={buttons} />
            ))}
            {isLoadingList &&
              Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
                <ProjectCardSkeleton key={index} />
              ))}
          </ProjectListContainer>
        )}
        {hasNextPage && !isLoadingList && (
          <SeeMoreButton onClick={handleNextPage} color={universityColor}>
            Ver mais
          </SeeMoreButton>
        )}
      </WhiteBox>
      {feedbackModalOpen && (
        <ModalFeedbackExtensionProject
          isOpen={feedbackModalOpen}
          setOpenModalFeedback={setFeedbackModalOpen}
          feedback={feedback}
          isStudent={true}
          customMessage="Veja o feedback que sua instituição de ensino te enviou:"
        />
      )}
      <DetailsModal item={modalData} onClose={closeDetailsModal} />
    </Container>
  );
};
