import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Container,
  Wrapper,
  SelectWrapper,
  WrapperColor,
  Title,
  SubTitle,
  Input,
  ColorInput,
  PreviewButton,
  SubmitButton,
  InputWrapper,
  ErrorMessage,
  Content,
  InputLabel,
  CustomSelect as Select,
} from './styled';

import {
  getIesEmailConfig,
  generatePreview,
  updateIesEmailConfig,
} from './services';

import * as Yup from 'yup';

import PreviewModal from './PreviewModal';
import BaseLayoutContext from 'contexts/base-layout';

const initialState = {
  general: { label: 'Link de direcionamento', value: 'redirect_link' },
  extension: { label: 'Link de direcionamento', value: 'redirect_link' },
  mentorship: { label: 'Link de direcionamento', value: 'redirect_link' },
  job_offer: { label: 'Link de direcionamento', value: 'redirect_link' },
  internship: { label: 'Link de direcionamento', value: 'redirect_link' },
};

const errorsInitialState = {
  general: null,
  extension: null,
  mentorship: null,
  job_offer: null,
  internship: null,
};

/**
 *
 * @typedef {keyof typeof initialState} FieldType
 */

function SettingsPage({ setNoScroll }) {
  const { handleIsLoadingState, openSnackbar, metadata } = useContext(
    BaseLayoutContext
  );
  const [color, setColor] = useState('');
  const [html, setHtml] = useState();
  const [previewModalVisibility, setPreviewModalVisibility] = useState(false);
  const [errors, setErrors] = useState(errorsInitialState);
  const [universityName, setUniversityName] = useState();
  const [colorError, setColorError] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(initialState);

  const inputsRefs = useRef({
    general: null,
    extension: null,
    mentorship: null,
    job_offer: null,
    internship: null,
  });

  useEffect(() => {
    setUniversityName(metadata.university);
  }, []);

  useEffect(() => {
    getCurrentConfig();
  }, []);

  const getCurrentConfig = async () => {
    const config = await getIesEmailConfig();

    const {
      general_contact_type = null,
      general_contact = '',
      extension_contact_type = null,
      extension_contact = null,
      mentorship_contact_type = null,
      mentorship_contact = null,
      job_offer_contact_type = null,
      job_offer_contact = null,
      internship_contact_type = null,
      internship_contact = null,
    } = config;

    const adapterConfig = {
      general: { type: general_contact_type, value: general_contact },
      extension: { type: extension_contact_type, value: extension_contact },
      mentorship: { type: mentorship_contact_type, value: mentorship_contact },
      job_offer: { type: job_offer_contact_type, value: job_offer_contact },
      internship: { type: internship_contact_type, value: internship_contact },
    };

    Object.keys(adapterConfig).forEach((key) => {
      if (!!adapterConfig[key].type) {
        setSelectedOptions((prev) => ({
          ...prev,
          [key]:
            adapterConfig[key].type === 'redirect_link'
              ? { label: 'Link de direcionamento', value: 'redirect_link' }
              : { label: 'E-mail de contato', value: 'contact_email' },
        }));
        inputsRefs.current[key].value = adapterConfig[key].value;
      } else {
        setSelectedOptions((prev) => ({
          ...prev,
          [key]: { label: 'Link de direcionamento', value: 'redirect_link' },
        }));
        inputsRefs.current[key].value = null;
      }
    });

    setColor(config.email_color, handleIsLoadingState(false));
  };

  const setDataToPreview = async () => {
    setHtml(await generatePreview(color.replace('#', '')));
    setPreviewModalVisibility(true);
    setNoScroll(true);
  };

  const submitConfig = async () => {
    const isColorValid = await validateColor();

    if (!isColorValid) {
      return;
    }

    const formData = new FormData();

    formData.append('email_color', color);

    function createPayload() {
      return new Promise((resolve, _) => {
        let validation = [];
        Object.keys(selectedOptions).forEach(async (key, i, arr) => {
          let isValid = true;
          if (selectedOptions[key].value === 'contact_email') {
            isValid = await validateEmail(key);
          } else {
            isValid = await validateLink(key);
          }
          if (isValid) {
            formData.append(
              `${key}_contact_type`,
              !!inputsRefs.current[key].value ? selectedOptions[key].value : ''
            );
            formData.append(`${key}_contact`, inputsRefs.current[key].value);
          }
          validation.push(isValid);

          if (i == arr.length - 1) {
            resolve(!validation.includes(false));
          }
        });
      });
    }

    let isValid = await createPayload();

    if (isValid) {
      await updateIesEmailConfig(formData);
      openSnackbar('Configuração salva com sucesso', false);
    }
  };

  const validateEmail = async (key) => {
    try {
      await validations.emailValidation.validate(inputsRefs.current[key].value);
      setErrors((prev) => ({ ...prev, [key]: null }));
      return true;
    } catch (err) {
      setErrors((prev) => ({ ...prev, [key]: err.errors[0] }));
      return false;
    }
  };

  const validateLink = async (key) => {
    try {
      await validations.linkValidation.validate(inputsRefs.current[key].value);
      setErrors((prev) => ({ ...prev, [key]: null }));
      return true;
    } catch (err) {
      setErrors((prev) => ({ ...prev, [key]: err.errors[0] }));
      return false;
    }
  };

  const validations = {
    emailValidation: Yup.string()
      .email('Insira um e-mail válido')
      .max(255, 'Máximo de 255 caracteres'),
    linkValidation: Yup.string()
      .url('Insira um link válido')
      .max(500, 'Máximo de 500 caracteres'),
    colorValidation: Yup.string()
      .notRequired()
      .test(
        'colorValidation',
        'Informe uma cor hexadecimal válida',
        function (value) {
          if (!value || value.length === 0) {
            return true;
          }
          return /^#([0-9a-f]{3}){1,2}\b/i.test(value);
        }
      ),
  };

  /**
   *
   * @param {*} e
   * @param {FieldType} input
   */
  const handleFieldTypeChange = (e, input = 'general') => {
    if (inputsRefs.current[input]) {
      inputsRefs.current[input].value = '';
    }
    setSelectedOptions((prev) => ({ ...prev, [input]: e }));
  };

  const selectOptions = [
    { label: 'Link de direcionamento', value: 'redirect_link' },
    { label: 'E-mail de contato', value: 'contact_email' },
  ];

  const validateColor = async () => {
    try {
      await validations.colorValidation.validate(color);
      setColorError('');
      return true;
    } catch (err) {
      setColorError('Informe uma cor hexadecimal válida para a cor principal');
      return false;
    }
  };

  return (
    <Content>
      {previewModalVisibility && (
        <PreviewModal
          html={html}
          closeModal={() => {
            setPreviewModalVisibility(false);
            setNoScroll(false);
          }}
        />
      )}
      <Container>
        <Wrapper mb="32px">
          <SubTitle>
            Aqui você pode personalizar os e-mails de notificações que serão
            enviados automaticamente para os estudantes e para as empresas. Isso
            é importante para fortalecer a marca da sua Instituição de Ensino.
          </SubTitle>
        </Wrapper>
        <Wrapper row={true} mb="32px">
          <Wrapper half={true}>
            <Title>Cor principal</Title>
            <SubTitle>
              Aplicada no cabeçalho e na cor do botão de ação.
            </SubTitle>
          </Wrapper>
          <WrapperColor row={true}>
            <ColorInput
              style={
                !colorError
                  ? {
                      marginTop: '-5px',
                    }
                  : {}
              }
              type="color"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
            <div
              style={
                colorError
                  ? {
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '5px',
                    }
                  : {}
              }
            >
              <Input
                value={color}
                onChange={(e) => {
                  setColor(e.target.value);
                }}
                isColorInput={true}
                maxLength={7}
              />
              {colorError && <ErrorMessage>{colorError}</ErrorMessage>}
            </div>
          </WrapperColor>
        </Wrapper>
        <Wrapper>
          <Wrapper mb="24px">
            <Title mb="4px">Contato da Instituição</Title>
            <SubTitle>
              Insira nos campos abaixo um link (como whatsapp e chat de suporte)
              ou um e-mail para que os seus estudantes entrem em contato com a
              Instituição ao clicar no botão de ”Contatar equipe UNIEXEMPLO”.
              Você pode visualizar o botão ao clicar em "pré-visualizar e-mail”.
            </SubTitle>
          </Wrapper>
          <Wrapper row={true} mb="20px">
            <SelectWrapper>
              <InputLabel>Contato padrão</InputLabel>
              <Select
                placeholder="Tipo"
                options={selectOptions}
                value={selectedOptions.general}
                onChange={(e) => {
                  if (e) {
                    handleFieldTypeChange(e, 'general');
                  }
                }}
              />
            </SelectWrapper>
            <InputWrapper>
              <Input
                error={errors.general}
                placeholder={
                  selectedOptions.general.value === 'link'
                    ? 'https://www.example.com'
                    : 'e-mail@example.com'
                }
                ref={(el) => (inputsRefs.current.general = el)}
              />
              {errors.general && <ErrorMessage>{errors.general}</ErrorMessage>}
            </InputWrapper>
          </Wrapper>
          <Wrapper row={true} mb="20px">
            <SelectWrapper>
              <InputLabel>Extensão</InputLabel>
              <Select
                placeholder="Tipo"
                options={selectOptions}
                value={selectedOptions.extension}
                onChange={(e) => {
                  if (e) {
                    handleFieldTypeChange(e, 'extension');
                  }
                }}
              />
            </SelectWrapper>
            <InputWrapper>
              <Input
                error={errors.extension}
                placeholder={
                  selectedOptions.extension.value === 'link'
                    ? 'https://www.example.com'
                    : 'e-mail@example.com'
                }
                ref={(el) => (inputsRefs.current.extension = el)}
              />
              {errors.extension && (
                <ErrorMessage>{errors.extension}</ErrorMessage>
              )}
            </InputWrapper>
          </Wrapper>
          <Wrapper row={true} mb="20px">
            <SelectWrapper>
              <InputLabel>Mentorias</InputLabel>
              <Select
                placeholder="Tipo"
                options={selectOptions}
                value={selectedOptions.mentorship}
                onChange={(e) => {
                  if (e) {
                    handleFieldTypeChange(e, 'mentorship');
                  }
                }}
              />
            </SelectWrapper>
            <InputWrapper>
              <Input
                error={errors.mentorship}
                placeholder={
                  selectedOptions.mentorship.value === 'link'
                    ? 'https://www.example.com'
                    : 'e-mail@example.com'
                }
                ref={(el) => (inputsRefs.current.mentorship = el)}
              />
              {errors.mentorship && (
                <ErrorMessage>{errors.mentorship}</ErrorMessage>
              )}
            </InputWrapper>
          </Wrapper>
          <Wrapper row={true} mb="20px">
            <SelectWrapper>
              <InputLabel>Vagas</InputLabel>
              <Select
                placeholder="Tipo"
                options={selectOptions}
                value={selectedOptions.job_offer}
                onChange={(e) => {
                  if (e) {
                    handleFieldTypeChange(e, 'job_offer');
                  }
                }}
              />
            </SelectWrapper>
            <InputWrapper>
              <Input
                error={errors.job_offer}
                placeholder={
                  selectedOptions.job_offer.value === 'link'
                    ? 'https://www.example.com'
                    : 'e-mail@example.com'
                }
                ref={(el) => (inputsRefs.current.job_offer = el)}
              />
              {errors.job_offer && (
                <ErrorMessage>{errors.job_offer}</ErrorMessage>
              )}
            </InputWrapper>
          </Wrapper>
          <Wrapper row={true} mb="32px">
            <SelectWrapper>
              <InputLabel>Estágios</InputLabel>
              <Select
                placeholder="Tipo"
                options={selectOptions}
                value={selectedOptions.internship}
                onChange={(e) => {
                  if (e) {
                    handleFieldTypeChange(e, 'internship');
                  }
                }}
              />
            </SelectWrapper>
            <InputWrapper>
              <Input
                error={errors.internship}
                placeholder={
                  selectedOptions.internship.value === 'link'
                    ? 'https://www.example.com'
                    : 'e-mail@example.com'
                }
                ref={(el) => (inputsRefs.current.internship = el)}
              />
              {errors.internship && (
                <ErrorMessage>{errors.internship}</ErrorMessage>
              )}
            </InputWrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper row={true}>
          <Wrapper>
            <Title mb="4px">Simulação</Title>
            <SubTitle mb="12px">
              Verifique se a cor escolhida é de fácil legibilidade com o branco.
            </SubTitle>
            <PreviewButton onClick={setDataToPreview}>
              Pré-visualizar e-mail
            </PreviewButton>
          </Wrapper>
        </Wrapper>
        <SubmitButton onClick={submitConfig}>Salvar ajustes</SubmitButton>
      </Container>
    </Content>
  );
}

export default SettingsPage;
