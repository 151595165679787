import React from 'react';
import { ActionButtonsSkeleton, Container, SkeletonBox } from './styled';
import { RowWrapper } from '../Card/styled';
import { InfoRowSkeleton } from './infoRowSkeleton';

export function ProjectCardSkeleton() {
  return (
    <Container>
      <SkeletonBox height="185px" width="100%" borderRadius="11px 11px 0 0" />
      <RowWrapper>
        <span className="project-code">
          <SkeletonBox height="17px" width="120px" />
        </span>
        <span className="project-name">
          <SkeletonBox height="19px" width="180px" />
        </span>
        <InfoRowSkeleton />
        <InfoRowSkeleton />
        <InfoRowSkeleton />
        <InfoRowSkeleton />
      </RowWrapper>
      <ActionButtonsSkeleton>
        <SkeletonBox width="137px" height="40px" />
        <SkeletonBox width="100px" height="40px" />
      </ActionButtonsSkeleton>
    </Container>
  );
}
