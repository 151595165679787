import React, { useContext, useEffect, useState } from 'react';

import { Content, CustomButton } from '../style';
import { Infos } from './style';
import {
  customSnackbar,
  SnackbarType,
} from 'components/CustomSnackBar/customSnackbar';

import {
  actionsGenericUpload,
  useGenericUpload,
} from 'components/GenericModalUpload/store/genericUpload.store';

import GenericModalUpload from 'components/GenericModalUpload';
import { ExternalListing } from 'components/GenericModalUpload/components/Listing/ExternalListing';
import { ImageViewer } from 'views/Student/ExtensionProject/components/ImageViewer';
import { CreateExtensionContext } from '../../../CreateExtensionContext';
import {
  deleteSupportMaterials,
  getSupportMaterials,
} from 'views/StaffPages/ExtensionProject/services/urls';
import { uploadDocuments } from 'views/StaffPages/ExtensionProject/services';
import { Loading } from '../../Footer/style';

export default function StepFour({ idProjectEdit }) {
  const [openModalMaterials, setOpenModalMaterials] = useState(false);
  const { removeItem, changeItems } = actionsGenericUpload;
  const { listingItems } = useGenericUpload((state) => state.listingItems);
  const [selectedImage, setSelectedImage] = useState(null);
  const { returnTitleModal, nextStep } = useContext(CreateExtensionContext);
  const [loading, setLoading] = useState(false);

  async function getListingMaterial() {
    const response = await getSupportMaterials(idProjectEdit);
    changeItems(response.results);
  }

  const handleSaveMaterial = async (material) => {
    if (idProjectEdit) {
      const updatedFormData = new FormData();

      try {
        if (material.type === 'file') {
          updatedFormData.append('files', material.uploadedContent);
        } else if (material.type === 'image') {
          updatedFormData.append('images', material.uploadedContent);
        } else if (material.type === 'link') {
          updatedFormData.append('links', material.uploadedContent);
        }
        if (material.uploadedContent) {
          await uploadDocuments(updatedFormData, idProjectEdit);
          getListingMaterial();
        }

        customSnackbar(
          'Material de apoio adicionado com sucesso!',
          SnackbarType.CONFIRMATION
        );
      } catch (err) {
        customSnackbar(
          'Erro ao adicionar material de apoio. Por favor, tente novamente.',
          SnackbarType.ERROR
        );
      }
    }
  };

  useEffect(() => {
    if (idProjectEdit) {
      getListingMaterial();
    }
  }, [idProjectEdit]);

  const openModalAddMaterials = () => {
    setOpenModalMaterials(true);
  };

  const removeMaterial = async (id) => {
    if (idProjectEdit && listingItems.length > 0) {
      await deleteSupportMaterials(idProjectEdit, id);
      removeItem(id);
    } else {
      removeItem(id);
    }
  };

  const handleNext = async () => {
    setLoading(true);
    await nextStep();
    setLoading(false);
  };

  return (
    <Content>
      <h3 className="title">{returnTitleModal()}</h3>
      {openModalMaterials && (
        <GenericModalUpload
          setOpenModalUpload={setOpenModalMaterials}
          onSave={handleSaveMaterial}
          removeItem={removeMaterial}
          fullSizeModal={true}
          limit={{ file: 2, image: 2, link: 2 }}
        />
      )}
      <Infos>
        <p className="highlight">
          <b>Material de apoio</b> (opcional)
        </p>
        <p className="info">
          Aqui você consegue adicionar documentos, links ou imagens como
          material de apoio para seus alunos. Esses conteúdos estarão sempre
          disponíveis para os estudantes no card do projeto de extensão.
        </p>
        <ExternalListing
          data={listingItems}
          onRemove={removeMaterial}
          setSelectedImage={setSelectedImage}
          viewAction
        />

        <div className="buttons">
          <CustomButton
            outlined={listingItems?.length > 0}
            onClick={() => openModalAddMaterials()}
          >
            {listingItems?.length === 0
              ? 'Anexar material de apoio'
              : 'Anexar outros documentos'}
          </CustomButton>
          {listingItems?.length === 0 && (
            <CustomButton outlined onClick={handleNext} disabled={loading}>
              {loading ? <Loading color="#009291" /> : 'Agora não'}
            </CustomButton>
          )}
        </div>
      </Infos>
      <ImageViewer
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
        widthModal="100%"
      />
    </Content>
  );
}
