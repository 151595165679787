import styled from 'styled-components';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
`;

export const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(100vw - 260px);
  height: 100%;
  overflow-y: scroll;
  left: 16rem;
  background-color: #fff;
  padding: 2rem 2rem;

  @media screen and (max-width: 963px) {
    left: 0;
    width: 100vw;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const CloseButton = styled.button`
  width: 40px;
  background-color: #efeeec;
  border: none;
  border-radius: 50%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
`;
export const MainContent = styled.div`
  width: 100%;
`;

export const TitleSection = styled.div`
  font-family: Roboto;
  margin-bottom: 30px;
  > h1 {
    font-weight: 700;
    font-size: 20px;
    text-align: start;
    line-height: 27px;
    color: #009291;
    margin: 0;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: left;
    color: #606062;
  }
`;
