import styled from 'styled-components';

export const BackgroundModal = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(100vw - 260px);
  height: 100%;
  align-items: center;
  justify-content: center;
  left: 16rem;

  @media screen and (max-width: 963px) {
    left: 0;
    width: 100vw;
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

export const ContentCloseModal = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const ButtonCloseModal = styled.button`
  width: 40px;
  background-color: #efeeec;
  border: none;
  border-radius: 50%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
