import React, { useState, useContext } from 'react';
import { Wrapper, ContainerTypeName, ContainerActions, PopUp } from './style';
import LINK from 'assets/ModalUploadEvidences/iconsFormatType/Link.svg';
import redTrash from 'assets/ExtensionProject/redTrash.svg';
import edit from 'assets/ExtensionProject/edit.svg';
import ButtonWithMenu from '../ButtonWithMenu';
import ModalInformation from 'components/informationModal';
import { createAnalyzeEvidence } from 'views/StaffPages/Opportunities/services';
import ModalFeedBack from '../ModalFeedBack';
import { colorsStatus } from '../ButtonWithMenu/Status';
import moment from 'moment';
import { Status } from '../style';
import ModalFeedbackExtensionProject from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Modal/ModalViewFeedback';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';

export default function ListLinks({
  functionDeleteEvidence,
  item,
  idPost,
  activeTab,
  refreshFiles,
  getAllToFinishProjects,
}) {
  const { name, link } = item;

  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [config, setConfig] = useState({});

  const [openModalFeedBack, setOpenModalFeedBack] = useState(false);
  const [modalSeeFeedback, setModalSeeFeedback] = useState(false);

  const handleClickRecuseInsideModal = async (message, id) => {
    setOpenModalFeedBack(false);
    await createAnalyzeEvidence(id, {
      status: 'refused',
      feedback: message.feedback,
    });
    refreshFiles(null, true);
  };

  const handleConfirmDelete = async () => {
    await functionDeleteEvidence(idPost, item.id);
    getAllToFinishProjects(true);
    refreshFiles(null, true);

    setModalInformationOpen(false);
  };

  const handleConfirmAcceptEvidence = async () => {
    setModalInformationOpen(false);
    await createAnalyzeEvidence(item.id, {
      status: 'accepted',
      feedback: null,
    });
    refreshFiles(null, true);
  };

  const configDelete = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza que deseja excluir a evidência "${name}"?`,
    buttonConfirmText: 'SIM, EXCLUIR EVIDÊNCIA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
    },
    buttonConfirmFunction: handleConfirmDelete,
  };

  const configAcceptEvidence = {
    bubbleText: 'Atenção!',
    ParagraphText: `Ao aprovar uma evidência, esta ação <strong>não poderá ser desfeita</strong>. <br/> <br/> Tem certeza que deseja seguir em frente?`,
    buttonConfirmText: 'SIM, APROVAR',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    buttonConfirmFunction: handleConfirmAcceptEvidence,
  };

  const handleClickAcceptEvidence = () => {
    setConfig(configAcceptEvidence);
    setModalInformationOpen(true);
  };

  const handleClickDelete = () => {
    setConfig(configDelete);
    setModalInformationOpen(true);
  };

  const handleClickRecuseEvidence = () => {
    setOpenModalFeedBack(true);
  };

  const defaultInterfaceStudent = [
    { name: 'Aceitar evidência', onChange: handleClickAcceptEvidence },
    { name: 'Recusar evidência', onChange: handleClickRecuseEvidence },
  ];
  const defaultInterfaceUniversity = [
    { name: 'Excluir evidência', onChange: handleClickDelete },
  ];

  const currentInterface = {
    0: defaultInterfaceStudent,
    1: defaultInterfaceUniversity,
  };

  const handleClickSeeFeedback = () => {
    setModalSeeFeedback(true);
  };

  const interfaceWhenHasAnalyze = () => {
    if (
      item.analyze &&
      Object.keys(item.analyze).length > 0 &&
      item.analyze.feedback
    ) {
      if (item.analyze.feedback.feedback) {
        currentInterface[activeTab].push({
          name: 'Visualizar feedback',
          onChange: handleClickSeeFeedback,
        });
      }
      return currentInterface[activeTab].filter(
        (item) =>
          item.name !== 'Aceitar evidência' && item.name !== 'Recusar evidência'
      );
    }

    return currentInterface[activeTab];
  };

  const interfaces = interfaceWhenHasAnalyze();

  return (
    <Wrapper>
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={config}
        buttonConfirmFunction={config.buttonConfirmFunction}
        buttonCancelFunction={() => setModalInformationOpen(false)}
      />
      <ModalFeedBack
        isOpen={openModalFeedBack}
        buttonInsideFunction={handleClickRecuseInsideModal}
        setOpen={setOpenModalFeedBack}
        item={item}
      />
      {modalSeeFeedback && (
        <ModalFeedbackExtensionProject
          isOpen={modalSeeFeedback}
          setOpenModalFeedback={setModalSeeFeedback}
          feedback={item.analyze.feedback}
        />
      )}
      <ContainerTypeName>
        <img src={LINK} alt="iconType" />
        <div>
          <a href={link} target="_blank" className="nameEvidence">
            {name}
            {item.analyze && (
              <Status
                color={colorsStatus[item.analyze.status].color}
                background={colorsStatus[item.analyze.status].background}
              >
                {colorsStatus[item.analyze.status].label}
              </Status>
            )}
            {item.new_evidence && (
              <Status
                color={colorsStatus['new'].color}
                background={colorsStatus['new'].background}
              >
                {colorsStatus['new'].label}
              </Status>
            )}
          </a>
          <p className="date">
            <span style={{ fontWeight: 'bold', color: '#009291' }}>
              Enviado por:{' '}
            </span>{' '}
            <span> {item.created_by} | </span>
            {moment(item.created_at).format('DD/MM/YYYY')} às{' '}
            {moment(item.created_at).format('H:mm ')}
          </p>
        </div>
      </ContainerTypeName>
      {interfaces.length > 0 && (
        <ButtonWithMenu
          insideMenus={interfaces}
          menuLabel={'ações'}
          offsetX={-80}
          buttonWidth={'80px'}
          item={item}
        />
      )}
    </Wrapper>
  );
}
