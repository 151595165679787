import React, { useEffect, useState } from 'react';
import urls from '../../constants/urls';
import { JobDetailsModal } from 'components/JobDetailsModal';
import JobOffersProvider from './context/JobOffersContext';
import ProfileProvider from 'views/Profile/Context/ProfileContext';

function ReceiveJob({ handleIsLoadingState, match, setUserData }) {
  const returnAllJobs = () => {
    handleIsLoadingState(true);
    window.open(`${urls.URL_FRONT}/#/vagas`, '_self');
  };
  const [gamificationData, setGamificationData] = useState(null);

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  return (
    <JobOffersProvider
      setGamificationData={setGamificationData}
      setUserData={setUserData}
    >
      <ProfileProvider
        gamificationData={gamificationData}
        setGamificationData={setGamificationData}
      >
        <JobDetailsModal
          onClose={() => returnAllJobs()}
          jobId={match.params.id}
          externalJob
        />
      </ProfileProvider>
    </JobOffersProvider>
  );
}

export default ReceiveJob;
