import styled from 'styled-components';

export const StepperContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StepItem = styled.div`
  display: flex;
  align-items: center;
`;

export const StepNumber = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ active, completed }) =>
    active ? '#009291' : completed ? 'rgba(0, 146, 145, 0.5)' : '#F5F5F5'};
  color: ${({ active, completed }) =>
    active || completed ? '#fff' : '#CDCDCD'};
  font-size: 20px;
  font-weight: bold;
`;

export const StepTitle = styled.span`
  margin-left: 8px;
  font-size: 18px;
  color: #606062;

  font-weight: ${({ active, completed }) => (active ? 'bold' : 'normal')};
`;

export const StepSeparator = styled.div`
  width: 50px;
  height: 2px;
  background-color: #e0e0e0;
  margin: 0 8px;
`;
