import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonApprove,
  ButtonDisapprove,
  ButtonEvidences,
  CheckBoxInput,
  Container,
  ContainerTexts,
  ContentButtons,
  ContentCourse,
  ContentInfos,
  ContentStudent,
  ContentTexts,
  Divider,
  HeaderStudent,
  Name,
  Paragrap,
  StatusContainer,
} from './styled';
import Tooltip from 'components/NewTooltip';
import { FiPaperclip } from 'react-icons/fi';
import ModalDisapproveStudent from '../Modals/ModalDisapprove';
import ModalApproveStudent from '../Modals/ModalApproveStudent';
import placeholderImage from 'assets/img/placeholder.jpg';
import EvidenceViewModal from '../../../EvidenceViewModal';
import {
  customSnackbar,
  SnackbarType,
} from '../../../../../../../../components/CustomSnackBar/customSnackbar';
import { ExtensionProjectsContext } from '../../../../../context/ExtensionProjectsContext';

export default function StudentsToFinish({
  registeredStudent,
  refreshStudentList,
  handleCheckboxChange,
}) {
  const [modalDisapprove, setModalDisapprove] = useState(false);
  const [returnButtonStatus, setReturnButtonStatus] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [modalApprove, setModalApprove] = useState(false);
  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const { informationItem } = useContext(ExtensionProjectsContext);

  useEffect(() => {
    if (
      registeredStudent.status === 'approved' ||
      registeredStudent.status === 'disapproved'
    ) {
      setReturnButtonStatus(true);
    }
  }, [registeredStudent.status]);

  const onClickDisapprove = () => {
    setModalDisapprove(true);
  };

  const onClickApproveStudents = () => {
    setModalApprove(true);
  };

  const handleChange = (event) => {
    const { checked } = event.target;
    setIsChecked(checked);
    handleCheckboxChange(registeredStudent.id, checked);
  };

  const openEvidenceModal = () => {
    setIsEvidenceModalOpen(true);
  };

  const closeEvidenceModal = () => {
    setIsEvidenceModalOpen(false);
  };

  return (
    <Container>
      {modalDisapprove && (
        <ModalDisapproveStudent
          students={[registeredStudent]}
          id={registeredStudent.student_id}
          name={registeredStudent.student_name}
          modalDisapprove={modalDisapprove}
          setModalDisapprove={setModalDisapprove}
          refreshStudentList={refreshStudentList}
        />
      )}

      {modalApprove && (
        <ModalApproveStudent
          students={[registeredStudent]}
          id={registeredStudent.student_id}
          name={registeredStudent.student_name}
          modalApprove={modalApprove}
          setModalApprove={setModalApprove}
          refreshStudentList={refreshStudentList}
        />
      )}

      {isEvidenceModalOpen && (
        <EvidenceViewModal
          item={registeredStudent}
          isOpen={isEvidenceModalOpen}
          onClose={closeEvidenceModal}
          title="Visualizar Evidências"
        />
      )}

      <ContentStudent>
        <HeaderStudent>
          <ContentInfos>
            {registeredStudent.status === 'pending' && (
              <CheckBoxInput onChange={handleChange} type="checkbox" />
            )}
            <img
              src={registeredStudent.student_avatar}
              alt="avatar"
              onError={(event) => {
                event.target.src = placeholderImage;
                event.target.onerror = null;
              }}
            />
            <ContentCourse>
              <Name>{registeredStudent.student_name}</Name>
              <Paragrap>
                {registeredStudent.student_course} -{' '}
                {registeredStudent.student_semester}° semestre
              </Paragrap>
              <Paragrap>Código: {registeredStudent.student_id}</Paragrap>
            </ContentCourse>
          </ContentInfos>

          <ContentButtons>
            {returnButtonStatus ? (
              <StatusContainer
                onClick={() => {
                  customSnackbar(
                    'Não é possível alterar o status de um estudante após a avaliação.',
                    SnackbarType.ERROR
                  );
                }}
                status={registeredStudent.status}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {registeredStudent?.status === 'approved'
                  ? 'Estudante aprovado'
                  : 'Estudante reprovado'}
              </StatusContainer>
            ) : null}
            {!isChecked && !returnButtonStatus && (
              <>
                <ButtonDisapprove onClick={() => onClickDisapprove()}>
                  Reprovar
                </ButtonDisapprove>

                <ButtonApprove onClick={() => onClickApproveStudents()}>
                  Aprovar estudante
                </ButtonApprove>
              </>
            )}
            {informationItem?.extension_project?.enable_students_evidences && (
              <Tooltip
                direction="top"
                text={'Visualizar evidências'}
                colorSchema="black"
              >
                <ButtonEvidences onClick={openEvidenceModal}>
                  <FiPaperclip size={18} color="#009291" />
                </ButtonEvidences>
              </Tooltip>
            )}
          </ContentButtons>
        </HeaderStudent>

        <ContentTexts>
          {informationItem?.extension_project?.enable_students_description && (
            <ContainerTexts>
              <Paragrap>
                <b>Descrição da atividade: </b>
                <p
                  dangerouslySetInnerHTML={{
                    __html: registeredStudent.description,
                  }}
                />
              </Paragrap>
            </ContainerTexts>
          )}
          {informationItem?.extension_project?.enable_students_conclusion && (
            <ContainerTexts>
              <Paragrap>
                <b>Conclusões: </b>
                <p
                  dangerouslySetInnerHTML={{
                    __html: registeredStudent.conclusions,
                  }}
                />
              </Paragrap>
            </ContainerTexts>
          )}
        </ContentTexts>

        <Divider />
      </ContentStudent>
    </Container>
  );
}
