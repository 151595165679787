import React, { useEffect, useState, useContext } from 'react';
import { Container, DividerInfos, WrapperInformations } from './styled';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import { ImageViewer } from 'views/Student/ExtensionProject/components/ImageViewer';
import { getStudentEvidenceApi } from 'views/StaffPages/ExtensionProject/services';
import GenericModal from 'views/StaffPages/Mentorship/components/GenericModal';
import EvidenceList from './EvidenceList';
import GenericMessage from 'components/GenericMessage';

export default function EvidenceViewModal({ onClose, isOpen, title, item }) {
  const { extensionProjectId } = useContext(ExtensionProjectsContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [evidences, setEvidences] = useState([]);
  const [loading, setLoading] = useState(false);
  const studentId = item.student_id;

  const fetchEvidences = async () => {
    setLoading(true);
    try {
      const data = await getStudentEvidenceApi(extensionProjectId, studentId);
      setEvidences(data.results);
    } catch (err) {
      console.error('Erro ao carregar as evidências', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchEvidences();
    }
  }, [isOpen, studentId, extensionProjectId]);

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} title={title}>
      <Container>
        <WrapperInformations>
          <div>
            <strong>{item?.student_name}</strong>
            <p>
              {item?.student_course} - {item?.student_semester}° semestre{' '}
              <DividerInfos />
              Código: {item?.student_id}
            </p>
          </div>
        </WrapperInformations>

        {evidences.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhuma evidência encontrada"
            subtitle={`${item.student_name} não adicionou nenhuma evidência ao projeto de extensão`}
          />
        ) : (
          <EvidenceList
            evidences={evidences}
            setSelectedImage={setSelectedImage}
          />
        )}
      </Container>

      <ImageViewer
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />
    </GenericModal>
  );
}
