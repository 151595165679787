import React, { useContext, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import {
  AddButton,
  Column,
  Container,
  ErrorMessage,
  IconWrapper,
  Input,
} from './styled';
import { RiLinkM } from 'react-icons/ri';
import BaseLayoutContext from 'contexts/base-layout';
import * as Yup from 'yup';
import { UploadType } from '../../enums/upload_type';

export default function LinkInput({ label, name, onAddEvidence, ...rest }) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error: fieldError,
  } = useField(name);
  const { universityColor } = useContext(BaseLayoutContext);
  const [localError, setLocalError] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleAddEvidence = () => {
    const schema = Yup.object().shape({
      url: Yup.string()
        .url('URL inválida.')
        .max(500, 'No máximo 500 caracteres.')
        .required('Campo obrigatório.'),
    });

    schema
      .validate({ url: inputRef.current.value })
      .then(() => {
        onAddEvidence(inputRef.current.value, UploadType.LINK);
        inputRef.current.value = '';
        setLocalError('');
      })
      .catch((err) => {
        if (err instanceof Yup.ValidationError) {
          setLocalError(err.message);
        }
      });
  };

  return (
    <Container>
      <Column>
        <IconWrapper>
          <RiLinkM size={20} color={universityColor} />
        </IconWrapper>
        <Input
          ref={inputRef}
          color={universityColor}
          defaultValue={defaultValue}
          placeholder={'Adicione seu link aqui'}
          maxLength={500}
          {...rest}
        />
        <ErrorMessage>{localError || fieldError}</ErrorMessage>
      </Column>
      <AddButton
        color={universityColor}
        onClick={handleAddEvidence}
        type={'button'}
      >
        Adicionar
      </AddButton>
    </Container>
  );
}
