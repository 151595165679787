import React, { Fragment, useContext, useEffect } from 'react';
import ExtensionProject from '../../ExtensionProject';
import { ExtensionProjectsContext } from '../../context/ExtensionProjectsContext';

export default function TabCurriculumProjects({
  setActiveTab,
  activeTab,
  metadata,
}) {
  const {
    setFutureExtension,
    setInProgressExtension,
    setOpenForSubscriptionsExtension,
    setPaginationOneCurrentPage,
    setPaginationTwoCurrentPage,
    setPaginationThreeCurrentPage,
    refModalExtensionProject,
    extracurriculum,
    setActiveTabExtensionProject,
  } = useContext(ExtensionProjectsContext);

  useEffect(() => {
    setFutureExtension(null);
    setInProgressExtension(null);
    setOpenForSubscriptionsExtension(null);
    setPaginationOneCurrentPage(0);
    setPaginationTwoCurrentPage(0);
    setPaginationThreeCurrentPage(0);
    setActiveTabExtensionProject(0);
  }, [activeTab, setActiveTabExtensionProject]);

  return (
    <Fragment>
      <div ref={refModalExtensionProject}>
        <ExtensionProject
          extracurriculum={extracurriculum}
          metadata={metadata}
          setActiveTab={setActiveTab}
        />
      </div>
    </Fragment>
  );
}
