import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Container,
  ProjectListContainer,
  SeeMoreButton,
  Title,
  WhiteBox,
} from '../styled';
import ProjectCard from 'views/Student/ExtensionProject/components/Card';
import { projectExtensionApi } from '../../../../services/api';
import BaseLayoutContext from 'contexts/base-layout';
import GenericMessage from 'components/GenericMessage';
import { ProjectCardSkeleton } from '../../../../components/CardSkeleton';
import ModalInformation from 'components/informationModal';
import { useProjectExtensionContext } from 'views/Student/ExtensionProject/context';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { unsubscribeProject } from 'views/Student/Extracurricular/services';
import { FinalReportModal } from 'views/Student/ExtensionProject/components/FinalReportModal';
import DetailsModal from 'views/Student/ExtensionProject/components/DetailsModal';

export const NextProjects = () => {
  const { universityColor } = useContext(BaseLayoutContext);
  const {
    modalData,
    setModalData,
    setFinalReportOpen,
    openDetailsModal,
    fetchTabsCount,
    enrolledProjects,
    setEnrolledProjects,
    closeDetailsModal,
  } = useProjectExtensionContext();
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [unsubscribeModalOpen, setUnsubscribeModalOpen] = useState(false);
  const isEnrolledTab = true;
  const isProjectStarted = (startAt) => {
    const projectStartDate = new Date(startAt);
    const currentDate = new Date();
    return projectStartDate <= currentDate;
  };

  const fetchEnrolledProjects = async () => {
    const [responseExtensions, responseImages] = await Promise.all([
      projectExtensionApi.getEnrolledExtensionProject(),
      projectExtensionApi.getImagesEnrolledExtensions(),
    ]);
    setIsLoadingList(false);
    const extensions = responseExtensions.results;
    setHasNextPage(!!responseExtensions.next);
    const images = responseImages.results;
    if (extensions != null) {
      if (images) {
        const extensionsWithImages = extensions.map((extension) => {
          const image = images.find((image) => image.id === extension.id);
          extension.image = image.attraction_image;
          return extension;
        });
        setEnrolledProjects(extensionsWithImages);
        return;
      }
      setEnrolledProjects(extensions);
    }
  };

  useEffect(() => {
    fetchEnrolledProjects();
  }, []);

  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (10 - 3 + 1) + 3);
  }, []);

  const handleNextPage = useCallback(async () => {
    setIsLoadingList(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 10;
    const [responseExtensions, responseImages] = await Promise.all([
      projectExtensionApi.getEnrolledExtensionProject(
        `limit=10&offset=${offset}`
      ),
      projectExtensionApi.getImagesEnrolledExtensions(
        `limit=10&offset=${offset}`
      ),
    ]);
    setIsLoadingList(false);
    const extensions = responseExtensions.results;
    setHasNextPage(!!responseExtensions.next);
    const images = responseImages.results;
    if (extensions != null) {
      if (images) {
        const extensionsWithImages = extensions.map((extension) => {
          const image = images.find((image) => image.id === extension.id);
          extension.image = image.attraction_image;
          return extension;
        });
        setEnrolledProjects([...enrolledProjects, ...extensionsWithImages]);
        return;
      }
      setEnrolledProjects([...enrolledProjects, ...extensions]);
    }
  }, [enrolledProjects, page]);

  const handleUnsubscribeDropdown = (item) => {
    setUnsubscribeModalOpen(true);
    setModalData(item);
  };

  const handleUnsubscribe = (item) => {
    unsubscribeProject(item.extension_project_id);
    setUnsubscribeModalOpen(false);
    setEnrolledProjects((prevProjects) =>
      prevProjects.filter(
        (project) => project.extension_project_id !== item.extension_project_id
      )
    );
    fetchTabsCount();
    customSnackbar('Inscrição cancelada com sucesso!', 'confirmation');
  };

  function handleFillReport(item) {
    setModalData(item);
    setFinalReportOpen(true);
  }

  const configModal = {
    bubbleText: 'Atenção!',
    modalWidth: 370,
    modalHeight: 384,
    ParagraphText: `Tem certeza que deseja cancelar a sua inscrição no projeto de extensão?`,
    buttonConfirmText: 'NÃO CANCELAR',
    buttonCancelText: 'SIM, CANCELAR INSCRIÇÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px',
      marginButtons: '0px',
      marginTop: '40px',
      marginBottom: '-20px',
    },
  };

  const getButtons = (project) => {
    const isExtracurricularProject =
      project.extension_kind === 'extracurricular';
    const isReportNotSent = project.report_sent === false;
    const projectHasStarted = isProjectStarted(project.start_at);

    const buttons = [
      {
        label: 'Detalhes',
        isDetails: true,
        onClick: openDetailsModal,
      },
    ];

    if (!isExtracurricularProject && isReportNotSent && projectHasStarted) {
      buttons.push({
        label: 'Preencher relatório',
        isDetails: false,
        onClick: handleFillReport,
      });
    }

    return buttons;
  };

  const dropdownMenuItems = [
    {
      label: 'Preencher Relatório',
      onClick: handleFillReport,
    },
    {
      label: 'Cancelar Inscrição',
      onClick: handleUnsubscribeDropdown,
    },
  ];
  return (
    <Container>
      <WhiteBox>
        <Title>
          Aqui você pode ver as atividades de extensão que você irá participar!
          Fique atento aos detalhes do projeto!
        </Title>
        {enrolledProjects?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhum projeto de extensão encontrado"
            subtitle="Os projetos de extensão disponíveis aparecerão aqui."
          />
        ) : (
          <ProjectListContainer>
            {enrolledProjects?.map((item, index) => (
              <ProjectCard
                key={index}
                item={item}
                buttons={getButtons(item)}
                dropdownMenuItems={dropdownMenuItems}
                hideStatus={isEnrolledTab}
              />
            ))}
            {isLoadingList &&
              Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
                <ProjectCardSkeleton key={index} />
              ))}
          </ProjectListContainer>
        )}
        {hasNextPage && !isLoadingList && (
          <SeeMoreButton onClick={handleNextPage} color={universityColor}>
            Ver mais
          </SeeMoreButton>
        )}
      </WhiteBox>
      <ModalInformation
        modalOpenState={unsubscribeModalOpen}
        buttonConfirmFunction={() => setUnsubscribeModalOpen(false)}
        buttonCancelFunction={() => handleUnsubscribe(modalData)}
        config={configModal}
        universityColor={universityColor}
      />
      <DetailsModal
        item={modalData}
        onClose={closeDetailsModal}
        fetchEnrolledProjects={fetchEnrolledProjects}
        isEnrolledTab={isEnrolledTab}
      />
      <FinalReportModal
        item={modalData}
        fetchEnrolledProjects={fetchEnrolledProjects}
      />
    </Container>
  );
};
