import httpRequest from 'services/university/HttpRequest';

const getSupportMaterials = async (projectId) => {
  return await httpRequest.get(
    `api/internal/university/extension-project/${projectId}/support-materials`
  );
};

const postSupportMaterials = async (projectId, data) => {
  return await httpRequest.post(
    `api/internal/university/extension-project/${projectId}/support-materials/file`,
    data
  );
};

const downloadSupportMaterials = async (id) => {
  return await httpRequest.get(
    `/api/internal/university/extension-project/support-material/${id}/download`
  );
};

const recoverSupportMaterials = async (projectId, id) => {
  return await httpRequest.get(
    `api/internal/university/extension-project/${projectId}/support-materials/${id}`
  );
};

const changeSupportMaterials = async (projectId, id) => {
  return await httpRequest.patch(
    `api/internal/university/extension-project/${projectId}/support-materials/${id}`
  );
};

const deleteSupportMaterials = async (projectId, id) => {
  return await httpRequest.delete(
    `api/internal/university/extension-project/${projectId}/support-materials/${id}`
  );
};

const getUniversityTeachers = async () => {
  return await httpRequest.get(`api/internal/university/teachers`);
};

const getFinalReports = async (opportunitiesId) => {
  return await httpRequest.get(
    `api/internal/university/extension-project/${opportunitiesId}/reports`
  );
};

const updateStudentApprovalStatus = async (
  extensionProjectId,
  status,
  feedback,
  studentIds
) => {
  const body = {
    status,
    feedback,
    reports: studentIds,
  };
  return await httpRequest.patch(
    `/api/internal/university/extension-project/${extensionProjectId}/reports/evaluate`,
    body
  );
};

const downloadSpreadsheetStudents = async (opportunitiesId, data) => {
  return await httpRequest.post(
    `/api/internal/university/extension-project/${opportunitiesId}/students-spreadsheet`,
    data
  );
};

const getListWaitingStartExtension = async (queryParams) => {
  return await httpRequest.get(
    `/api/internal/university/waiting-start-extension-project/${queryParams}`
  );
};

const removeStudent = async (extensionProjectId, studentId, data) => {
  try {
    const response = await authenticatedAPI.put(
      `/api/internal/university/extension-project/${extensionProjectId}/students/${studentId}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export {
  getSupportMaterials,
  postSupportMaterials,
  downloadSupportMaterials,
  recoverSupportMaterials,
  changeSupportMaterials,
  deleteSupportMaterials,
  getUniversityTeachers,
  getFinalReports,
  downloadSpreadsheetStudents,
  getListWaitingStartExtension,
  updateStudentApprovalStatus,
  removeStudent,
};
