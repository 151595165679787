import styled from 'styled-components';

export const ModalContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem 2rem;
`;

export const FieldLabel = styled.p`
  font-size: 18px !important;
  font-family: Roboto, sans-serif;
  color: #606062;
  font-weight: 700 !important;
  padding-top: 2rem;
  margin-bottom: 6px !important;

  > span {
    font-size: 16px !important;
    font-weight: 400;
  }
`;

export const FieldLabelLarge = styled.span`
  display: flex;
  font-size: 18px !important;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: #606062;
  margin-bottom: 8px;
`;

export const FieldLabelDescription = styled.p`
  font-size: 16px !important;
  font-family: Roboto, sans-serif;
  color: #606062;
  margin-bottom: 10px !important;
`;

export const SendButton = styled.button`
  display: flex;
  border: none;
  background-color: ${({ color }) => color || '#009291'};
  padding: 0 2rem;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 2rem;
  margin-left: auto;
  border-radius: 3px;

  &:hover:not(:disabled) {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
    color: #949494;
  }
`;

const backgroundColor = (color = '#009291') => {
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, 0.1)`;
};

export const EvidenceList = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const UploadTile = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
  width: 100%;
  background-color: ${({ color }) => backgroundColor(color)};
`;
export const UploadActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

export const ListTitle = styled.p`
  width: 100%;
  text-align: left;
  color: #606062;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  margin: 2rem 0 1rem 0;
`;

export const UploadTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  > p,
  a {
    margin: 0;
    font-size: 18px;
    color: #606062;
  }

  > a {
    text-decoration: underline;
    cursor: pointer;
    inline-size: 390px;
    overflow-wrap: break-word;
  }

  > span {
    margin: 0;
    font-size: 16px;
    color: #949494;
  }
`;
export const OverflowOfEvidenceErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 32px;
  background-color: #ffeded;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;

  > span {
    color: #da0505;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    display: flex;
    flex: 1;
    text-align: left;
  }
`;

export const OverflowOfEvidenceErrorCloseButtonWrapper = styled.div`
  border-radius: 50%;
  background-color: #da0505;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
