import moment from 'moment';

export const payloadCreateExternalJob = (data) => {
  return {
    disability: data.disability ? data.disability : null,
    color_or_race: data.color_or_race ? data.color_or_race : null,
    gender_identity: data.gender_identity ? data.gender_identity : null,
    administrative_selection_process: data.administrative_selection_process
      ? data.administrative_selection_process
      : false,
    application_type: data.application_type,
    description: data.description,
    name: data.name,
    external: {
      divulgation_link:
        !data.job_email && data.divulgation_link ? data.divulgation_link : '',
      job_email: !data.divulgation_link && data.job_email ? data.job_email : '',
      modality_type: data.modality_type,
      courses: data.courses,
    },
    required_additional_courses_areas: [],
    required_professional_areas: [],
    start_offer_at: moment(data.start_offer_at).format('DD/MM/YYYY'),
    end_offer_at: moment(data.end_offer_at).format('DD/MM/YYYY'),
    courses_matching_rule: { profession: null, courses: [], semesters: [] },
    enabled_just_for_unities: [],
    enabled_just_for_universities: data.enabled_just_for_universities
      ? data.enabled_just_for_universities
      : [],
    zip_code: data.zip_code,
    address: data.address,
    address_number: data.address_number,
    address_complement: data.address_complement,
    district: data.district,
    city: data.city,
    state: data.state,
  };
};
