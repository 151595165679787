import React from 'react';
import { IconWrapper, ItemWrapper, StringList } from './styled';

export const InfoRowStringList = ({ icon, label, values }) => {
  return (
    <ItemWrapper>
      <div className="container">
        <IconWrapper>{icon}</IconWrapper>
        <div className="text-wrapper">
          <strong>{label}</strong>
          <StringList>
            {values?.map((objective) => (
              <li key={objective.value}>{objective.label}</li>
            ))}
          </StringList>
        </div>
      </div>
    </ItemWrapper>
  );
};
