import FilterModal from 'components/FilterModal';
import React, { useState, useEffect, useContext } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, ContainerFlexDirection } from './styled';
import { getExtensionProjectFiltersToFinish } from 'views/StaffPages/Opportunities/services';
import { shape } from 'prop-types';
import { ExtensionProjectsContext } from '../../context/ExtensionProjectsContext';

export function FilterToFinishedExtensionProjects({
  appliedFilters,
  setAppliedFilters,
  filterState,
  setFilterState,
  filterOptions,
  setFilterOptions,
  setFilters,
  activeTab,
  setQueryFilter,
}) {
  const { getAllToFinishProjects, setToFinishProjects, kind } = useContext(
    ExtensionProjectsContext
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        setLoading(true);
        const response = await getExtensionProjectFiltersToFinish(kind);
        setFilterOptions(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchFilterOptions();
  }, [kind]);

  function renderFilterContent() {
    return [
      <ContainerFlexDirection>
        <Filter
          name={'name_or_code'}
          label={'Nome ou código do projeto'}
          type={FilterType.TEXT}
        />

        <Filter
          name={'macro_areas'}
          label={'Área de atuação'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.macro_areas}
        />

        <FilterGrid>
          <Filter
            name={'courses'}
            label={'Curso'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.courses}
          />

          <Filter
            name={'disciplines'}
            label={'Disciplina'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.disciplines}
          />
        </FilterGrid>

        <FilterGrid>
          <Filter
            name={'periods'}
            label={'Período'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.periods}
          />

          <Filter
            name={'unities'}
            label={'Unidade/Polo'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.unities}
          />
        </FilterGrid>

        <Filter
          name={'development_objective'}
          label={'ODS (Objetivos de Desenvolvimento Sustentável)'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.ods}
        />

        <Filter
          name={'responsible_teacher'}
          label={'Professor responsável'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.responsible_teachers}
        />
      </ContainerFlexDirection>,
    ];
  }

  function onApplyFilters() {
    setToFinishProjects(null);
    getAllToFinishProjects(filterState, true);
    setAppliedFilters(filterState);
  }

  function onClearFilters() {
    setToFinishProjects(null);
    getAllToFinishProjects(true);
    setAppliedFilters({});
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      loading={loading}
      disabled={loading}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}

FilterToFinishedExtensionProjects.propTypes = {
  filterOptions: shape.isRequired,
};
