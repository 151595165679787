import { create } from 'zustand';

const initialState = {
  listingItems: null,
};

export const GenericUpload = create((set) => ({
  listingItems: initialState.listingItems,

  changeItems: (state) =>
    set(() => {
      return { listingItems: state };
    }),
  removeItem: (id) =>
    set((state) => ({
      listingItems: state.listingItems.filter((state) => state.id !== id),
    })),
  updateListing: (value) =>
    set((state) => {
      const updatedData = [...state.listingItems, value];
      return { listingItems: updatedData };
    }),
}));

export const actionsGenericUpload = (() => {
  const changeItems = GenericUpload.getState().changeItems;
  const updateListing = GenericUpload.getState().updateListing;
  const removeItem = GenericUpload.getState().removeItem;

  return {
    changeItems,
    updateListing,
    removeItem,
  };
})();

export const useGenericUpload = () => {
  const listingItems = GenericUpload((state) => state.listingItems);
  return { listingItems };
};
