import React from 'react';
import backgroundMentoring from 'assets/img/mentorship-background.jpg';
import CompanyViewsHeader from 'components/ViewsHeader/CompanyViewsHeader';
import { MyProjectsTab } from './MyProjectsTab';
import { AvailableProjectsTab } from './AvailableProjectsTab';
import DetailsModal from '../components/DetailsModal';
import { useProjectExtensionContext } from '../context';
import { SupportMaterialModal } from '../components/SupportMaterialModal';

export default function TabsManager() {
  const {
    detailsModalOpen,
    modalData,
    closeDetailsModal,
    supportMaterialModalOpen,
    closeSupportMaterialModal,
    activeTab,
    setActiveTab,
    tabsCount,
  } = useProjectExtensionContext();

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  return (
    <>
      <CompanyViewsHeader
        title="Projetos de extensão"
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        backgroundImage={backgroundMentoring}
        tabs={[
          `Meus Projetos ${
            typeof tabsCount.my_projects === 'number'
              ? `(${tabsCount.my_projects})`
              : ''
          }`,
          `Projetos disponíveis ${
            typeof tabsCount.opened === 'number' ? `(${tabsCount.opened})` : ''
          }`,
        ]}
      />
      {activeTab === 0 && <MyProjectsTab />}
      {activeTab === 1 && <AvailableProjectsTab />}

      <SupportMaterialModal
        modalData={modalData?.supportMaterial?.results}
        isOpen={supportMaterialModalOpen}
        onClose={closeSupportMaterialModal}
        title={'Material de apoio'}
      />
    </>
  );
}
