import styled from 'styled-components';
import search from 'assets/JobOffer/CheckboxCheck.svg';

export const StepFourContainer = styled.div`
  p.highlight {
    color: #009291;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 4px;

    b {
      font-weight: 700;
    }
  }

  p.info {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  .buttons {
    button {
      margin-right: 20px;
    }
  }

  .notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    p.info {
      text-align: center;
      font-weight: 400;
      margin-bottom: 0.25rem;
    }
    a {
      font-family: 'Roboto', sans-serif;
      color: #009291;
      font-weight: 700;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
`;

export const ContentFormInput = styled.div`
  width: 100%;

  .inputFeedback {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    margin-top: -10px;
    color: #606062;
  }
`;

export const SearchInput = styled.input`
  padding-left: 8px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 231px 9px;
  background-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 42px;
  width: 243px;
  margin-bottom: 40px;

  ::placeholder {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.1px;
  }

  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.1px;
`;

export const ContainerSearchInput = styled.div`
  position: relative;
  width: fit-content;

  .wrapper-search {
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    position: absolute;
    right: 6px;
    top: 6px;

    background-color: #009291;
  }
`;
