import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: -13px;
  display: flex;
  align-items: center;
  flex-direction: column;

  footer {
    display: flex;
    margin-top: 2rem;
    width: 90%;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const PaginationContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    justify-content: center;

    @media (max-width: 1080px) {
      justify-content: flex-start;
    }
  }
`;

export const Wrapper = styled.div`
  width: 90%;

  background-color: #ffffff;
  margin-top: 45px;

  border-radius: 10px;

  position: relative;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #009291;

  padding: 53px 0 13px 35px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  color: #606062;
  padding: 0 35px;
`;

export const Score = styled.p`
  font-size: 16px;
  color: #606062;
  font-weight: 700;
  padding: 23px 35px 0;
`;

export const ContainerButton = styled.div`
  width: 90%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .wrapper-pagination {
    margin-top: 61px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const ButtonBack = styled.button`
  width: 100%;
  max-width: 260px;

  background-color: transparent;
  border: none;

  color: #4b4b4d;
  font-size: 19px;
  font-weight: 400;

  display: flex;
  align-items: center;

  margin-top: 61px;
  padding: 0;

  cursor: pointer;

  svg {
    margin-right: 10px;
  }
`;

export const ContainerInput = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const StudentsButtons = styled.button`
  border: none;
  border-radius: 5px;
  color: ${(props) => (props.approved ? '#fff' : '#da0505')};

  background-color: ${(props) => (props.approved ? '#009291' : 'transparent')};
  border: none;
  font-weight: 600;
  font-size: 15px;
  padding: 13px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const ContainerStudentButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
`;