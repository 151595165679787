import styled from 'styled-components';
import Setadireita from '../../../assets/Oportutinites/Setadireita.svg';
import Setaesquerda from '../../../assets/Oportutinites/Setaesquerda.svg';
import Lixeira from '../../../assets/Oportutinites/Lixeira.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 5px;
`;

export const MultiStepContainer = styled.div`
  height: 5em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MultiStepDiv = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efeeec;
  font-weight: bold;
  z-index: 2;
`;

export const MultiStepDivActive = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #009291;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-weight: bold;
  z-index: 2;
  margin-left: 55px;
`;

export const DivIndicatorActive = styled.div`
  width: 123px;
  height: 5px;
  background-color: #009291;
  margin-top: 12px;
  margin-left: -2px;
`;

export const DivIndicator = styled.div`
  width: 123px;
  height: 5px;
  background-color: #efeeec;
  margin-top: 12px;
  margin-left: -2px;
  position: fixed;
  top: 30px;
`;

export const WrapperStep = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: relative;
  margin-top: -50px;
`;

export const DescImg = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 10vh;
`;

export const Img = styled.img`
  width: 12%;
  margin-top: -10px;
  margin-right: 1.5em;
`;

export const Title = styled.h4`
  color: #009291;
  font-weight: 400;
`;

export const SubTitle = styled.p`
  color: #000000;
  margin-top: -5px;
  font-weight: 100;
  font-size: 12px;
`;
export const Choices = styled.div`
  margin-top: 25px;
  display: flex;
  margin-left: 3px;
`;
export const ChoicesTitle = styled.span`
  font-size: 17px;
`;

export const ChoicesContainer = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
export const ChoicesCard = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4b4b4d;
  justify-content: center;
  background-color: ${(props) => (props.checked ? '#009291' : '#f0f0f0')};
  width: 120px;
  height: 91px;
  border-radius: 8px;
  margin-right: 25px;
  margin-top: 10px;
  background-image: url(${(props) => props.checked ? props.imageTwo : props.imageOne});
  background-position: center;
  background-position-y: 20px;
  background-repeat: no-repeat;
  background-size: 35%;
`;
export const ChoiceInput = styled.input`
  width: '0px';
  opacity: 0;
  margin-left: -2px;
`;

export const ChoiceImg = styled.img`
  margin-bottom: 8px;
  width: 35%;
`;

export const Text = styled.span`
  margin-top: 40px;
`;

export const TextChoice = styled.span`
  margin-top: 55px;
  color: ${(props) => (props.checked ? '#FFFF' : '#000')};
`;

export const SelectOptions = styled.div`
  margin: 20px 0;
`;

export const SelectStyled = styled.select`
  width: 100%;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  color: #000000;
`;

export const ButtonsContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
`;

export const NextButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${Setadireita});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
`;

export const PrevsButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${Setaesquerda});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
`;
export const Abount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const AboutTitle = styled.h3`
  color: #009291;
  font-weight: bold;
  font-size: 18px;
`;

export const ButtonPickFile = styled.button`
  color: #ffffff;
  width: 40%;
  height: 35px;
  font-size: 15px;
  font-weight: bold;
  background-color: #009291;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  margin-top: 5px;
`;

export const FileWrapper = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
`;

export const FileContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  display: flex;
  min-height: 30px;
  height: auto;
  align-items: center;
`;
export const ButtonDelet = styled.button`
  width: 23px;
  height: 23px;
  background-image: url(${Lixeira});
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 5px;
`;
export const WrapperModality = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: 8px;
`;
export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  width: 96%;
  margin-top: 10px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: right;
  gap: 4px;
  color: #606062;
  font-size: 12px;
`;

export const Button = styled.button`
  margin-top: 20px;
  color: #ffffff;
  width: 40%;
  height: 35px;
  font-size: 15px;
  font-weight: bold;
  background-color: #009291;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

export const FinishButton = styled.button`
  color: #ffffff;
  width: 25%;
  height: 35px;
  font-size: 15px;
  font-weight: bold;
  background-color: #009291;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:disabled {
    background-color: #69b6b8;
    cursor: wait;
  }
`;

export const SearchInputDiv = styled.div`
  margin-top: 1.3em;
`;

export const WrapperCompetencies = styled.div`
  max-height: 300px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  padding: 10px;
`;

export const DivCompetencies = styled.div`
  padding: 5px;
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

export const SelectedCompetencies = styled.div`
  background-color: #ffffff;
  color: #606062;

  border-radius: 4px;
  border: 1px solid #606062;

  margin: 4px 7px;
  padding: 8px;

  display: flex;
  align-items: center;

  border-radius: 12px;
`;

export const ErrorMessage = styled.span`
  color: #f00;
  margin-top: ${props => props.marginTop || '10px'};
  margin-bottom: ${props => props.marginBottom};

  font-size: 12px;
`