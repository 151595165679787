import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 250px);
  padding: 0 10rem;
  padding-bottom: 53px;

  h3.title {
    color: #009291;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    margin: 0px 0px 2.5rem 0px;
  }

  h4.stepTitle {
    font-size: 18px;
    color: #009291;
    font-weight: 700;
    line-height: 24px;
    margin: 1.875rem 0;
  }

  p.stepSubtitle {
    font-family: 'Roboto', sans-serif;
    color: #606062;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 30px;
  }

  .optionSwitchRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;

    .label {
      margin-right: 5rem;
      h4 {
        color: #606062;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 22px;
        margin-bottom: 0.25rem;
      }
      p {
        font-size: 1rem;
        line-height: 18px;
        font-weight: 400;
        color: #949494;
      }
    }
  }
  .optionSwitchRow + .optionSwitchRow {
    border-top: #e5e5e5 1px solid !important;
    padding-top: 1.25rem;
  }
`;

export const StepTitle = styled.p`
  font-size: 20px;
  color: #009291;
  font-weight: 700;
  line-height: 24px;
  margin: ${({ margin }) => margin || '0.875rem 0'};
`;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;

  flex-direction: ${(props) => (props.isData ? 'row' : 'column')};
  gap: ${(props) => (props.isData ? '30px' : 'unset')};

  margin-top: 18px;

  label {
    color: #606062;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .hiddenInput {
    display: none;
  }

  div.informations {
    margin-top: 35px;
    color: #606062;

    h4 {
      font-weight: 600;
    }

    > p {
      max-width: 265px;
      font-weight: 400;
    }
  }

  span.error {
    font-size: 14px;
    margin: 6px 0 6px 0;
    display: flex;
    gap: 12px;

    &:before {
      content: url(${error});
      width: 10px;
      height: 15px;
      margin: auto 0;
    }
  }

  div.image {
    display: flex;
    margin-top: 55px;
    align-items: center;
    justify-content: center;
    width: 200px;
    background-color: #f9f9f9;
    height: 100px;
    border: 1px dashed gray;
    position: relative;

    > .removeImage {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: #00000033;
      right: 0;
      cursor: pointer;
      top: 0;
    }

    > .unSelectedImage {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .selectedImage {
      width: 195px;
      height: 92px;
      object-fit: cover;
      position: relative;
    }
  }
`;

export const UploadImageButton = styled.span`
  display: flex;
  align-items: center;
  color: #009291;
  font-weight: bold;
  margin-top: 8px;
  gap: 5px;
  cursor: pointer;
`;

export const CustomButton = styled.button`
  background: #009291;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
  padding: 10px 20px;
  outline: none;
  border: none;
  border: 1px solid #009291;
  min-width: 120px;
  min-height: 44px;
  text-align: center;

  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    filter: drop-shadow(4px 4px 4px #0003) brightness(1.025);
  }

  ${(props) =>
    props.outlined &&
    `
    background:#FFFFFF !important;
    color: #009291 !important;
    border: 1px solid #009291;
  `}
`;

export const WrapperAddress = styled.div`
  display: flex;
  gap: 1rem;
`;

export const SwitchContainer = styled.div`
  display: flex;
  height: 29px;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  color: #009291;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ffffff;
  justify-content: center;

  :hover {
    .alert-dialog {
      display: flex;
    }
  }

  .alert-dialog {
    padding: 5px 10px;
    bottom: 33px;
    display: none;
    z-index: 10;
    min-width: 60px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    color: #000;
    /* border: rgba(0, 0, 0, 0.75) solid 1px; */

    border-radius: 4px;
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 7px 7px;
      border-color: transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -7px;
      left: 39%;
      transform: scaleY(-1);
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 7px 7px;
      border-color: rgba(0, 0, 0, 0.75) transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: -7px;
      left: 39%;
      transform: scaleY(-1);
    }

    span {
      color: #ffffff;
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
    }
  }
`;

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  color: #606062;
  gap: 3px;

  svg {
    margin-left: 3px;
    margin-bottom: -4px;
  }
`;
