import { useIcons } from 'views/StaffPages/CompaniesManagement/Hooks/useIcons';
import { Container, Box, CloseButton } from '../../../Styles/BaseModal';
import { Content, Subtitle, Title, BgDocument, Document } from './style';
import { useRef, useContext, useState, useEffect } from 'react';
import PdfViewer from 'components/UploadEvidences/components/PdfViwer';
import BaseLayoutContext from 'contexts/base-layout';

export default function ViewStageDataModal(props) {
  const {
    isOpen,
    setOpenModalViewStageData,
    setUsingPreviewPdf,
    usingPreviewPdf,
    documents,
  } = props;

  const { PDFIcon, AiOutlineEye, IoClose } = useIcons();

  const modalViewStageData = useRef();

  const { openModalWithContent } = useContext(BaseLayoutContext);
  const [fileData, setFileData] = useState(null);

  const closeModalButton = () => {
    setOpenModalViewStageData(false);
  };

  useEffect(() => {
    if (fileData) {
      callModal(fileData);
    }
  }, [fileData]);

  const handleClosePreview = () => {
    setUsingPreviewPdf(false);
    setFileData(null);
  };

  const callModal = (file) => {
    setUsingPreviewPdf(true);
    openModalWithContent(
      <PdfViewer
        universityColor={universityColor}
        file={file}
        name={returnName()}
      />,
      null,
      null,
      () => handleClosePreview()
    );
  };

  const handleClickPreview = (file) => {
    setFileData(file);
  };

  return (
    <Container usingPreviewPdf={usingPreviewPdf} isOpen={isOpen}>
      <Box ref={modalViewStageData}>
        <Content>
          <CloseButton onClick={closeModalButton}>
            <IoClose />
          </CloseButton>
          <Title>Visualizar dados de estágio</Title>
          <Subtitle>
            Confira aqui os documentos de estágio no qual você fez o upload.
          </Subtitle>
          {documents?.map((document) => (
            <BgDocument>
              <Document>
                <PDFIcon color="#009291" />
                <div className="name-term">
                  <strong> {document?.label} </strong>
                  <span> Termo de compromisso de estágio </span>
                </div>
              </Document>
              <div className="button-icons">
                <button onClick={() => handleClickPreview(document?.value)}>
                  <AiOutlineEye size={25} color="#009291" />
                </button>
              </div>
            </BgDocument>
          ))}
        </Content>
      </Box>
    </Container>
  );
}
