import React, {
  useRef,
  useContext,
  useEffect,
  useState,
  Fragment,
} from 'react';
import { Form } from '@unform/web';
import {
  Content,
  ContainerInput,
  StepTitle,
  WrapperAddress,
  SwitchContainer,
} from './style';
import { CreateExtensionContext } from '../../CreateExtensionContext';
import Switch from 'components/CustomSwitch';
import { Grid } from '@material-ui/core';
import Input from 'components/UnformFields/inputField';
import { handleFieldMask } from 'utils/validation';
import { maskZipCode } from 'utils/inputMasks';
import { zipCodeRequest } from 'services/zipCode';
import { LabelWithRequiredIndicator } from 'utils/shouldAddAsterisk';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';

export default function StepThree() {
  const {
    competences,
    extensionProjectOptions,
    setStepThreeFormRef,
    formData,
    setFormValues,
    returnTitleModal,
    setHasCep,
  } = useContext(CreateExtensionContext);

  const { developmentObjectives = [] } = extensionProjectOptions;
  const [zipCodeError, setZipCodeError] = useState(false);
  const [inputBlur, setInputBlur] = useState(false);
  const [cepData, setCepData] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const [switchEvidence, setSwitchEvidence] = useState(
    formData?.enable_students_evidences
  );
  const [switchActivityDescription, setSwitchActivityDescription] = useState(
    formData?.enable_students_description
  );
  const [switchActivityConclusions, setSwitchActivityConclusions] = useState(
    formData?.enable_students_conclusion
  );

  const formRef = useRef(null);

  useEffect(() => {
    setStepThreeFormRef(formRef);
  }, [formRef]);

  useEffect(() => {
    if (!zipCodeError && cepData) {
      formRef?.current?.setErrors({});
      formRef?.current?.setFieldValue('address', cepData.logradouro);
      formRef?.current?.setFieldValue('city', cepData.localidade);
      formRef?.current?.setFieldValue('district', cepData.bairro);
      formRef?.current?.setFieldValue('state', cepData.estado);
      formRef?.current?.setFieldValue('zip_code', cepData.cep);
    }
  }, [zipCodeError, cepData]);

  const handleZipCode = async () => {
    setInputBlur(true);
    const value = formRef?.current?.getFieldValue('zip_code');
    if (value.length > 0) {
      formRef?.current?.setFieldError('zip_code', '');
    }
    if (value.length === 0) {
      formRef?.current?.setFieldError('zip_code', 'Informação Obrigatória');
    }

    if (value.length === 9) {
      try {
        await zipCodeRequest(value).then((data) => {
          if ((data && data.erro) || !data) {
            setZipCodeError(true);
            formRef?.current?.setFieldError('zip_code', 'Digite um CEP válido');
            return false;
          } else {
            setCepData(data);
            setHasCep(true);

            formRef?.current?.setFieldError('zip_code', '');
            setZipCodeError(false);
            return true;
          }
        });
      } catch (e) {
        formRef?.current?.setFieldValue('address', '');
        formRef?.current?.setFieldValue('city', '');
        formRef?.current?.setFieldValue('district', '');
        formRef?.current?.setFieldValue('state', '');
        setZipCodeError(true);
      }
    } else {
      formRef?.current?.setFieldValue('address', '');
      formRef?.current?.setFieldValue('city', '');
      formRef?.current?.setFieldValue('district', '');
      formRef?.current?.setFieldValue('state', '');
      setZipCodeError(true);
      setHasCep(false);
    }
  };

  const handleChangeCheckedEvidence = (checked) => {
    setSwitchEvidence(checked);
    setFormValues({ enable_students_evidences: checked });
  };

  const handleChangeCheckedDescription = (checked) => {
    setSwitchActivityDescription(checked);
    setFormValues({ enable_students_description: checked });
  };

  const handleChangeCheckedConclusions = (checked) => {
    setSwitchActivityConclusions(checked);
    setFormValues({ enable_students_conclusion: checked });
  };

  const handleBlurZipCode = async () => {
    formRef?.current?.setErrors({});
    const value = formRef?.current?.getFieldValue('zip_code');

    if (value.length < 9) {
      formRef?.current?.setFieldError('zip_code', 'Digite um CEP válido');
    }
  };

  return (
    <Content>
      <h3 className="title">{returnTitleModal()}</h3>

      <StepTitle margin={'1.875rem 0 0'}>Detalhes do projeto</StepTitle>

      <Form ref={formRef} initialData={formData}>
        <ContainerInput>
          <UnformSelect
            name="development_objective"
            label={
              <label>
                <LabelWithRequiredIndicator
                  text="Objetivo de Desenvolvimento Sustentável (ODS) relacionadas"
                  indicator={true}
                />
              </label>
            }
            isMulti
            placeholder={'Selecione'}
            options={developmentObjectives ?? []}
          />
        </ContainerInput>
        <ContainerInput>
          <UnformSelect
            name="competences_specialities"
            label={
              <label>
                <LabelWithRequiredIndicator
                  text="Competências"
                  indicator={false}
                />
                <p>
                  Selecione até <b>5 competências/especialidades</b> que serão
                  aprendidas pelos estudantes ao realizar esse projeto de
                  extensão.
                </p>
              </label>
            }
            isMulti
            placeholder={'Selecione'}
            options={competences ?? []}
          />
        </ContainerInput>

        <StepTitle margin={'1.875rem 0 1rem'}>Finalização do projeto</StepTitle>

        <p className="stepSubtitle">
          Habilite a solicitação de informações aos estudantes, que serão
          pedidas ao final das atividades para comprovar a presença e o
          desenvolvimento no projeto de extensão.
        </p>

        <div className="optionSwitchRow">
          <div className="label">
            <h4>Envio de evidências</h4>
            <p>
              Ao habilitar esse campo, serão solicitados documentos ou qualquer
              outro tipo de material que comprove que o estudante realmente
              realizou a atividade.
            </p>
          </div>
          <SwitchContainer>
            <div className="alert-dialog">
              <span>{switchEvidence ? 'Desativar' : 'Ativar'}</span>
            </div>
            <Switch
              checked={switchEvidence}
              onChange={handleChangeCheckedEvidence}
            />
          </SwitchContainer>
        </div>
        <div className="optionSwitchRow">
          <div className="label">
            <h4>Descrição da atividade</h4>
            <p>
              Ao habilitar esse campo, o estudante deverá descrever todas as
              atividades realizadas durante o projeto.
            </p>
          </div>
          <div className="switch">
            <SwitchContainer>
              <div className="alert-dialog">
                <span>
                  {switchActivityDescription ? 'Desativar' : 'Ativar'}
                </span>
              </div>
              <Switch
                checked={switchActivityDescription}
                onChange={handleChangeCheckedDescription}
              />
            </SwitchContainer>
          </div>
        </div>
        <div className="optionSwitchRow">
          <div className="label">
            <h4>Conclusões sobre a atividade</h4>
            <p>
              Ao habilitar esse campo, o estudante deverá resumir os principais
              aprendizados e resultados do projeto.
            </p>
          </div>
          <div className="switch">
            <SwitchContainer>
              <div className="alert-dialog">
                <span>
                  {switchActivityConclusions ? 'Desativar' : 'Ativar'}
                </span>
              </div>
              <Switch
                checked={switchActivityConclusions}
                onChange={handleChangeCheckedConclusions}
              />
            </SwitchContainer>
          </div>
        </div>

        <StepTitle>Local de realização do projeto (Opcional)</StepTitle>
        <WrapperAddress>
          <Grid container spacing={8}>
            <Grid item xs={12} md={4}>
              <Input
                name="zip_code"
                placeholder="Ex.: 00000-000"
                label="CEP"
                onKeyUp={(e) => {
                  handleFieldMask(e, maskZipCode);
                }}
                maxLength={9}
                onChange={handleZipCode}
                onBlur={handleBlurZipCode}
                onSearchIcon={true}
              />
            </Grid>
            {!zipCodeError && inputBlur && (
              <Fragment>
                <Grid item xs={12} md={8}>
                  <Input
                    name="address"
                    label="Rua/Avenida *"
                    placeholder="Avenida/Rua"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    name="address_number"
                    label="Número *"
                    placeholder="Digite aqui..."
                    type="number"
                    min="0"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    name="address_complement"
                    label="Complemento"
                    placeholder="Digite aqui..."
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    name="district"
                    label="Bairro *"
                    placeholder="Digite aqui..."
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input name="city" label="Cidade *" disabled />
                </Grid>

                <Grid item xs={12} md={8}>
                  <Input name="state" label="UF *" disabled />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </WrapperAddress>
      </Form>
    </Content>
  );
}
