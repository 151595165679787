import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import BaseLayoutContext from '../../../../contexts/base-layout';

export const JobOfferContext = createContext();

export default function JobOfferProvider({ children, metadata }) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const stepOneRef = useRef(null);
  const stepTwoRef = useRef(null);
  const stepThreeRef = useRef(null);

  const ExternalJobRef = useRef(null);

  const [data, setData] = useState({
    quantity: Number(1),
    start_offer_at: currentDate,
  });
  const [companyData, setCompanyData] = useState({});
  const [companys, setCompanys] = useState([]);
  const [jobOfferStep, setjobOfferStep] = useState(0);
  const [registerSteps, setRegisterSteps] = useState(0);
  const [externalJobOffer, setExternalJobOffer] = useState(false);
  const [optionsProfession, setOptionsProfession] = useState({});
  const [functionToLogin, setFunctionToLogin] = useState(() => {});
  const [zIndex, setZIndex] = useState(1164);
  const [selectedLocations, setSelectedLocations] = useState();

  const [listJobs, setListJobs] = useState(null);

  const [selectedUniversities, setSelectedUniversities] = useState([]);

  const [dataFunction, setDataFunction] = useState({});

  const { openModalWithContent } = useContext(BaseLayoutContext);

  const isMaster = metadata.staff_role === 'educational_group_board';

  const [cacheContext, setCacheContext] = useState([]);
  const [currentJobFilter, setCurrentJobFilter] = useState(null);

  const resetAllData = () => {
    setData({ quantity: Number(1), start_offer_at: new Date() });
    setjobOfferStep(0);
    setExternalJobOffer(false);
    setRegisterSteps(0);
    setOptionsProfession({});

    stepOneRef.current ? stepOneRef.current.reset() : null;
    stepTwoRef.current ? stepTwoRef.current.reset() : null;
    stepThreeRef.current ? stepThreeRef.current.reset() : null;
  };

  const NextStepRegister = () => {
    setRegisterSteps((prevsStep) => prevsStep + 1);
  };

  const prevsStepRegister = () => {
    setRegisterSteps((prevsStep) => prevsStep - 1);
  };

  const nextJobOfferStep = () => {
    setjobOfferStep((prevsStep) => prevsStep + 1);
  };

  const prevsJobOfferStep = () => {
    setjobOfferStep((prevsStep) => prevsStep - 1);
  };

  const setDataFunctions = (values) => {
    setDataFunction((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  const setFormValues = (values) => {
    setData((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  const setCompanyValues = (values) => {
    setCompanyData((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  return (
    <JobOfferContext.Provider
      value={{
        setFormValues,
        data,
        jobOfferStep,
        setjobOfferStep,
        registerSteps,
        setRegisterSteps,
        NextStepRegister,
        nextJobOfferStep,
        externalJobOffer,
        setExternalJobOffer,
        prevsStepRegister,
        setCompanyValues,
        functionToLogin,
        setFunctionToLogin,
        companyData,
        prevsJobOfferStep,
        cacheContext,
        setCacheContext,
        openModalWithContent,
        setData,
        resetAllData,
        stepOneRef,
        stepTwoRef,
        stepThreeRef,
        optionsProfession,
        setOptionsProfession,
        ExternalJobRef,
        setDataFunctions,
        dataFunction,
        currentJobFilter,
        setCurrentJobFilter,
        zIndex,
        setZIndex,
        companys,
        setCompanys,
        isMaster,
        selectedUniversities,
        setSelectedUniversities,
        selectedLocations,
        setSelectedLocations,
        listJobs,
        setListJobs,
        metadata: metadata,
      }}
    >
      {children}
    </JobOfferContext.Provider>
  );
}
