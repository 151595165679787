export const LabelWithRequiredIndicator = ({ text, indicator }) => {
  const shouldAddAsterisk = indicator === true;

  return (
    <>
      <b>
        {text}
        {shouldAddAsterisk && '*'}{' '}
      </b>
      {shouldAddAsterisk ? '(Obrigatório)' : '(Opcional)'}
    </>
  );
};
