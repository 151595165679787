import React, { useContext, useState } from 'react';
import ModalFinishProject from '../../../../components/Modal/ModalFinishProject';
import ModalClose from '../../../../components/Modal/ModalTrackEnrollments/components/Modals/ModalCloseProject';
import GenericModal from '../../../../components/Modal/GenericModal';
import { ExtensionProjectsContext } from '../../../../context/ExtensionProjectsContext';

export default function NewModalFinishProjects() {
  const [closeProject, setCloseProject] = useState(false);
  const [step, setStep] = useState(1);
  const {  setFormDataFinishProject, setNewModalFinish} = useContext(ExtensionProjectsContext);

  const handleCloseModal = () => {
    step === 2 ? setCloseProject(true) : step === 1 && setNewModalFinish(false);
  };

  const handleConfirmCloseModal = () => {
    setNewModalFinish(false);
    setCloseProject(false);
    setStep(1);
    setFormDataFinishProject({})
  }

  return (
    <GenericModal onClose={() => handleCloseModal()}>
      {closeProject && (
        <ModalClose
          setCloseProject={setCloseProject}
          handleConfirmCloseModal={handleConfirmCloseModal}
          closeProject={closeProject}
        />
      )}
      <ModalFinishProject step={step} setStep={setStep} />
    </GenericModal>
  );
}
