import styled from 'styled-components';

export const ModalBackground = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 12px !important;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
`;
export const DivModal = styled.div`
  width: 686px;
  height: auto;
  background-color: #fff;
  border-radius: 12px;

  position: absolute;
  top: 25px;

  .container-box {
    display: flex;
    justify-content: space-between;

    padding: 0 33px;
    gap: 1.5rem;
  }

  .align-arrow {
    display: flex;
    align-items: flex-start;
    margin: 38px 33px 38px 33px;
  }
`;
export const Title = styled.h4`
  color: #009291;
  font-size: 17px;
  font-weight: bold;

  margin: 13px 33px 13px 33px;

  text-align: start;
`;
export const Container = styled.div`
  padding: 20px;

  width: 100%;
  background-color: #fff;
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  text-align: justify;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #606062;
  margin: 0;
`;

export const Information = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #606062;
  text-align: start;
  margin-bottom: 32px;
`;

export const BackToHome = styled.button`
  background-color: transparent;
  cursor: pointer;
  height: 15px;
  border: none;
  display: flex;
  align-items: center;
  padding: unset;

  svg {
    margin-left: -2px;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 33px;
`;

export const HeaderModal = styled.div`
  width: 100%;
  height: 85px;

  background-color: #009291;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const TitleModal = styled.h2`
  color: #fff;
  margin: 0;
  font-size: 22px;
  font-weight: 700;
`;
