import React, { useContext, useState } from 'react';
import { CreateExtensionContext } from '../../CreateExtensionContext';

import {
  NextButton,
  PrevButton,
  Footer as FooterContainer,
  SubmitButton,
  Loading,
} from './style';

export default function Footer({ isEdit }) {
  const {
    nextStep,
    previousStep,
    currentStep,
    kind,
    setControlModalFeedbackStudent,
    isFeedbackSent,
    newExcludeStudents,
    hasStudents,
  } = useContext(CreateExtensionContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFinalStep =
    (kind === 'curricular' && currentStep === 5) ||
    (kind === 'extracurricular' && currentStep === 4);

  const handlePublishClick = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      if (isEdit && newExcludeStudents && newExcludeStudents.length > 0) {
        if (!isFeedbackSent) {
          setControlModalFeedbackStudent(true);
        } else {
          await nextStep();
        }
      } else {
        await nextStep();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FooterContainer>
      {currentStep !== 1 && <PrevButton onClick={previousStep} />}
      {!isFinalStep ? (
        <NextButton onClick={nextStep} />
      ) : (
        <SubmitButton
          disabled={!hasStudents && kind === 'curricular'}
          onClick={handlePublishClick}
        >
          {isSubmitting ? <Loading /> : 'Publicar'}
        </SubmitButton>
      )}
    </FooterContainer>
  );
}
