export default function PDFIcon({ color, size = '26' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6504 29H5.31797C5.2166 28.9412 5.10531 28.9858 5.0004 28.9709C4.31068 28.9766 3.56709 28.1536 3.52811 27.2647C3.50471 26.7302 3.51818 26.1951 3.52811 25.6599C3.52811 25.5387 3.53448 25.4132 3.64932 25.3303C3.82724 25.2424 4.01864 25.2686 4.20578 25.2686C9.59922 25.2686 14.9927 25.2686 20.3861 25.2686C21.3665 25.2686 21.543 25.1084 21.6379 24.1344C21.6533 24.0029 21.6488 23.8698 21.6245 23.7396C21.6096 21.3741 21.6245 19.008 21.6181 16.6425C21.6181 15.9918 21.6181 15.3418 21.6181 14.691C21.6146 14.0198 21.3225 13.7249 20.6505 13.7242C15.1838 13.7242 9.71689 13.7242 4.24972 13.7242C4.03707 13.7242 3.81377 13.7681 3.616 13.6391C3.51251 13.5228 3.51605 13.3782 3.51605 13.2379C3.51605 9.45732 3.51605 5.67818 3.51605 1.90045C3.51616 1.75896 3.53234 1.61793 3.56426 1.48009C3.77691 0.539441 4.43686 0.00780043 5.39665 0.00638272C7.71555 0.00638272 10.034 0.00638272 12.3519 0.00638272C14.1893 0.00638272 16.0269 0.00638272 17.8647 0.00638272C17.9661 0.00638272 18.0717 -0.0198439 18.1688 0.0340291C18.2248 0.1758 18.2135 0.328914 18.2135 0.478483C18.2135 2.01316 18.2418 3.54925 18.2035 5.08604C18.1766 6.16776 18.665 7.01484 19.8984 7.19701C20.0487 7.2438 20.1479 7.36147 20.2528 7.46709C21.8889 9.10171 23.5245 10.7373 25.1595 12.3738C25.2715 12.4858 25.3977 12.5907 25.436 12.758C25.4564 12.8697 25.4644 12.9833 25.4601 13.0968C25.4601 17.7554 25.4601 22.414 25.4601 27.0726C25.4601 27.6567 25.2701 28.1607 24.8477 28.5747C24.6229 28.8084 24.3202 28.9514 23.997 28.9766C23.8808 28.9865 23.761 28.9405 23.6504 29Z"
        fill="#E2E5E6"
      />
      <path
        d="M3.61942 24.4839C2.7532 24.4811 1.88698 24.4778 1.02075 24.474C0.36648 24.474 0.000708856 24.1125 0 23.4646C0 20.5167 0 17.5686 0 14.6202C0 13.973 0.367189 13.6143 1.02075 13.6108C1.88698 13.6065 2.7532 13.6035 3.61942 13.6016C3.74205 13.6016 3.86397 13.6101 3.9866 13.6101H20.588C21.4124 13.6101 21.7222 13.9255 21.7222 14.7577V23.2994C21.7222 23.4412 21.7172 23.583 21.7151 23.7247C21.6073 24.3166 21.3373 24.5505 20.7227 24.5832C20.6192 24.5888 20.5157 24.5867 20.4115 24.5867C15.0039 24.5867 9.59601 24.5867 4.18792 24.5867C3.99582 24.5846 3.79026 24.6257 3.61942 24.4839Z"
        fill={color}
      />
      <path
        d="M18.1633 0.0820312L25.2901 7.21737C25.2292 7.41302 25.0576 7.37899 24.9187 7.38183C24.3729 7.39175 23.8271 7.38608 23.2812 7.38608C22.3406 7.38608 21.3992 7.38608 20.4586 7.38608C20.2888 7.39404 20.12 7.3566 19.9695 7.27763C18.8303 7.22021 18.1059 6.4667 18.1045 5.33536C18.1045 3.69082 18.1045 2.04603 18.1045 0.401016C18.1066 0.294688 18.0669 0.177018 18.1633 0.0820312Z"
        fill="#B0B8BE"
      />
      <path
        d="M3.62012 24.4825C3.74275 24.4825 3.86467 24.4733 3.9873 24.4733H20.6093C21.2401 24.4733 21.4953 24.2997 21.7193 23.7227C21.7193 23.958 21.7271 24.194 21.7257 24.4315C21.7257 24.9816 21.333 25.38 20.7879 25.38C15.0665 25.38 9.34554 25.38 3.62508 25.38C3.62366 25.0794 3.62201 24.7803 3.62012 24.4825Z"
        fill="#CAD1D8"
      />
      <path
        d="M19.9697 7.28042C21.6355 7.28042 23.3016 7.27806 24.9679 7.27333C25.0763 7.27333 25.1954 7.31161 25.2904 7.21875C25.3748 7.28964 25.3457 7.39313 25.3457 7.48386C25.3457 9.20496 25.3457 10.9263 25.3457 12.6479C25.2883 12.6004 25.2266 12.5571 25.1741 12.5061C23.4814 10.8157 21.7898 9.12415 20.0995 7.43141C20.052 7.38391 20.0123 7.33075 19.9697 7.28042Z"
        fill="#CAD1D8"
      />
      <path
        d="M8.8188 19.2495C8.8188 18.5995 8.8188 17.9494 8.8188 17.3001C8.8188 16.9719 8.96057 16.7819 9.2753 16.7671C10.0267 16.7302 10.7866 16.6742 11.5295 16.8096C12.7069 17.0222 13.4753 18.077 13.4221 19.3253C13.3583 20.8359 12.3999 21.756 10.8887 21.7581C10.4374 21.7581 9.98534 21.7581 9.53262 21.7581C8.9308 21.7581 8.81809 21.6468 8.81738 21.0578L8.8188 19.2495Z"
        fill="white"
      />
      <path
        d="M3.95209 19.2137C3.95209 18.563 3.94783 17.9129 3.95209 17.2629C3.95209 16.8872 4.12576 16.688 4.49507 16.6845C5.10681 16.6788 5.71998 16.6413 6.3303 16.7086C7.30781 16.8171 7.95145 17.594 7.88978 18.5828C7.82599 19.6241 7.17383 20.2614 6.12969 20.2947C5.79086 20.3053 5.45132 20.3046 5.11248 20.2947C4.95086 20.2897 4.89983 20.345 4.89983 20.5074C4.90989 20.7802 4.90326 21.0534 4.87998 21.3254C4.85091 21.6089 4.66733 21.767 4.41781 21.7564C4.35609 21.7555 4.29514 21.7425 4.23847 21.7181C4.1818 21.6936 4.13053 21.6582 4.08758 21.6139C4.04464 21.5695 4.01087 21.5172 3.98823 21.4597C3.96559 21.4023 3.95451 21.341 3.95563 21.2793C3.945 20.5917 3.95563 19.9034 3.95563 19.2158L3.95209 19.2137Z"
        fill="white"
      />
      <path
        d="M14.4315 19.2426C14.4315 18.6117 14.4315 17.9801 14.4315 17.3493C14.4315 16.9204 14.5733 16.7552 14.9986 16.7474C15.8088 16.7325 16.6191 16.7354 17.4293 16.7474C17.8014 16.7524 17.9964 16.9296 17.9921 17.2011C17.9864 17.496 17.7561 17.681 17.3797 17.6831C16.8055 17.6831 16.2299 17.6959 15.6564 17.6782C15.4239 17.6704 15.3651 17.749 15.3828 17.9617C15.3981 18.1781 15.3981 18.3953 15.3828 18.6117C15.3722 18.7847 15.431 18.8386 15.6047 18.835C16.0945 18.8251 16.5843 18.835 17.0741 18.8315C17.2577 18.8315 17.4349 18.8584 17.5703 18.9931C17.7057 19.1278 17.7894 19.283 17.7121 19.4744C17.6752 19.5675 17.61 19.6466 17.5256 19.7005C17.4413 19.7545 17.3421 19.7805 17.2421 19.775C16.7148 19.782 16.1867 19.7913 15.66 19.775C15.4296 19.7672 15.3764 19.8458 15.3828 20.0585C15.3998 20.4441 15.392 20.8304 15.3828 21.2168C15.3771 21.5641 15.2127 21.7498 14.9291 21.7477C14.6222 21.7477 14.4329 21.5577 14.4329 21.2203C14.4266 20.5639 14.4315 19.9047 14.4315 19.2426Z"
        fill="white"
      />
      <path
        d="M9.77735 19.2611C9.77735 18.7897 9.77735 18.3191 9.77735 17.8477C9.77735 17.7527 9.73978 17.6265 9.91345 17.635C10.3742 17.6506 10.835 17.5953 11.295 17.6761C11.4816 17.707 11.6594 17.7775 11.8163 17.8831C11.9732 17.9886 12.1056 18.1267 12.2045 18.2879C12.795 19.2044 12.3781 20.5264 11.3942 20.8093C10.898 20.951 10.3827 20.8596 9.87659 20.8695C9.74474 20.8695 9.77806 20.754 9.77806 20.6767C9.77594 20.2032 9.77735 19.7318 9.77735 19.2611Z"
        fill={color}
      />
      <path
        d="M4.90602 18.4953C4.90602 18.26 4.9117 18.0246 4.90602 17.79C4.90106 17.6482 4.94502 17.5859 5.09671 17.5901C5.41641 17.5979 5.73468 17.5823 6.0558 17.5951C6.61508 17.6171 6.9773 17.9927 6.97163 18.5272C6.96596 19.0355 6.58602 19.3856 6.02744 19.3934C5.72688 19.3977 5.42562 19.3849 5.12507 19.3977C4.94785 19.4055 4.8961 19.3417 4.90532 19.1723C4.91524 18.9469 4.90602 18.7207 4.90602 18.4953Z"
        fill={color}
      />
    </svg>
  );
}
