import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 280px;
  height: auto;
  border: 1px solid #cdcdcd;
  border-radius: 12px;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 185px;
  padding: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #0000001c;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    z-index: 2;
  }
`;

export const Flag = styled.div`
  width: 280px;
  height: 37px;
  padding: 12px 22px 12px 22px;
  gap: 5px;
  border-radius: 10px 10px 0 0;
  margin-top: -2px;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: ${(props) => props.textColor || '#fff'};
  background-color: ${(props) => props.backgroundColor || '#929292'};

  z-index: 10;
`;

export const TextName = styled.span`
  font-size: 18px;
  color: ${({ color }) => color || '#606062'};
  margin: 0.5rem 0;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;

  &:hover {
    color: ${({ color }) => color || '#606062'};
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 1rem;

  .project-code {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    color: #949494;
    margin: 8px 0;
  }

  .project-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
    margin-bottom: 5px;
  }

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: 15px;
  font-weight: 400;
  color: #606062;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  padding: 4px 0;
  font-family: Roboto, sans-serif;

  > span {
    white-space: ${({ truncate }) => (truncate ? 'nowrap' : 'normal')};
    overflow: ${({ truncate }) => (truncate ? 'hidden' : 'visible')};
    text-overflow: ${({ truncate }) => (truncate ? 'ellipsis' : 'clip')};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  align-self: flex-start;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  width: 280px;
  padding: 0 1rem;
  margin-top: 10px;
`;

export const ActionButton = styled.button`
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  border: ${(props) =>
    props.isDetails ? `1px solid ${props.universityColor}` : 'none'};
  background-color: ${(props) =>
    props.isDetails ? 'white' : props.universityColor || '#009291'};
  color: ${(props) => (props.isDetails ? props.universityColor : '#fff')};
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
`;

export const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #606062;
  z-index: 1000;
`;

export const DropdownItem = styled.button`
  padding: 8px 12px;
  background-color: #fff;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const CounterBadge = styled.div`
  position: absolute;
  background-color: #ffff;
  color: ${({ color }) => color || '#606062'};
  padding: 5px 10px;
  z-index: 5;

  width: 156px;
  height: 22px;
  top: 155px;
  left: 11px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
`;

export const FullEnrollmentMessage = styled.div`
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  line-height: 12.89px;
  text-align: center;
  margin-top: -10px;
  padding: 0 15px;
  margin-bottom: 10px;
`;
