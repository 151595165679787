import styled from 'styled-components';

export const Infos = styled.div`
  p.highlight {
    color: #009291;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 4px;

    b {
      font-weight: 700;
    }
  }

  p.info {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  .buttons {
    button {
      margin-right: 20px;
      margin-top: 30px;
    }
  }
`;
