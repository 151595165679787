import {
  ButtonFinish,
  ContainerButtons,
  ContainerCard,
  Content,
  ContentButton,
  DataContent,
  Image,
  MissingTime,
  Title,
  VacanciesFilled,
  ViewStudents,
} from './styled';

import { BiUserCircle } from 'react-icons/bi';
import { RiBook3Line } from 'react-icons/ri';
import moment from 'moment';
import Loading from '../../../../components/Loading';
import { useContext, useState } from 'react';

import { DetailsButton, Divider } from '../styled';
import Tooltip from 'components/NewTooltip';
import ModalShowEvidence from 'components/ModalShowEvidence';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import {
  getDetailsExtensionProject,
  getEvidences,
} from 'views/StaffPages/ExtensionProject/services';
import {
  MdCalendarMonth,
  MdOutlineSchool,
  MdOutlineHideImage,
} from 'react-icons/md';
import { getLimitedText } from 'views/StaffPages/ExtensionProject/utils/limitedText';

export function ToFinishCard({
  ref,
  item,
  waitingForStart,
  setDetails,
  setIsOpen,
}) {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingEvidences, setLoadingEvidences] = useState(false);
  const [imageError, setImageError] = useState(false);

  const {
    setExtensionProjectId,
    setUsingFormUpload,
    setIdPostEvidence,
    setEvidenceFiles,
    setEvidenceLinks,
    setInformationItem,
    setIdProjectBeeingProcessed,
    setOpenModalStudents,
    setStatus,
    setIdAttraction,
    extracurriculum,
    setNewModalFinish,
  } = useContext(ExtensionProjectsContext);

  const [openShowModalEvidence, setOpenShowModalEvidence] = useState(false);
  const [configModalShowEvidence, setConfigModalShowEvidence] = useState({
    id: item.extension_project.id,
    useTab: false,
    seeAllEvidencesStudent: true,
  });

  const handleClickSeeAllEvidences = () => {
    setOpenShowModalEvidence(true);
    setConfigModalShowEvidence({
      id: item.extension_project.id,
      useTab: true,
      seeAllEvidencesStudent: false,
    });
  };

  const handleMoreInfo = async (item) => {
    setExtensionProjectId(item.extension_project.id);
    setLoadingDetails(true);
    const data = await getDetailsExtensionProject(item.id);
    setDetails(data);
    setIsOpen(true);
    setLoadingDetails(false);
  };

  const handleClickSeeEvidence = () => {
    setConfigModalShowEvidence({
      id: item.extension_project.id,
      useTab: false,
      seeAllEvidencesStudent: true,
    });
    setOpenShowModalEvidence(true);
  };

  const handleOpenEnrolledModal = (item) => {
    setIdAttraction(item.id);
    setExtensionProjectId(item.extension_project.id);
    setStatus(item.is_published);
    setOpenModalStudents(true);
  };

  function handleFinishProject() {
    setExtensionProjectId(item.extension_project.id);
    setNewModalFinish(true);
    setInformationItem(item);
  }

  const handleOpenEvidence = async () => {
    const { extension_project } = item;
    const { id } = extension_project;
    setLoadingEvidences(true);
    const { results } = await getEvidences(id);

    const files = results.filter(
      (item) => item.file !== null || item.image !== null
    );
    const links = results.filter(
      (item) => item.name !== '' && item.link !== ''
    );

    setEvidenceLinks(links);
    setEvidenceFiles(files);
    setUsingFormUpload(true);
    setLoadingEvidences(false);

    setIdProjectBeeingProcessed(id);

    setIdPostEvidence(id);
  };

  const strReduced = (str) => {
    if (str.length > 20) {
      return `${str.substr(0, 20)}...`;
    }
    return str;
  };

  const formattedDisciplineName =
    item.extension_project.discipline_name.charAt(0).toUpperCase() +
    item.extension_project.discipline_name.slice(1).toLowerCase();
  const limitedDisciplineName = getLimitedText(formattedDisciplineName, 36);

  return (
    <ContainerCard ref={ref}>
      {ModalShowEvidence && (
        <ModalShowEvidence
          open={openShowModalEvidence}
          setOpen={setOpenShowModalEvidence}
          {...configModalShowEvidence}
        />
      )}
      <Image>
        {item.attraction_image.logo ? (
          <MdOutlineHideImage size={64} color="#a3a2a2" />
        ) : (
          <img
            src={item.attraction_image.image}
            alt="Imagem do projeto"
            onError={() => setImageError(true)}
          />
        )}
      </Image>

      <VacanciesFilled>
        <p>{item.extension_project.reminder}</p>
      </VacanciesFilled>
      {waitingForStart && (
        <MissingTime border={item.attraction_image.logo}>
          <BiTimeFive size={17} />
          <p>{item.extension_project.reminder}</p>
        </MissingTime>
      )}
      <DataContent>
        <p className="projectCode" style={{ margin: '0', textAlign: 'start' }}>
          CÓDIGO: {item.extension_project.id}
        </p>
        <Title title={item.name}>{item.name}</Title>
        <Content>
          <div className="wrapper-content">
            <MdCalendarMonth size={24} color="#009291" />
            <p>
              De {''}
              {moment(item.start_at).format('DD/MM/YYYY')} {''}a {''}
              {moment(item.end_at).format('DD/MM/YYYY')} {''}
            </p>
          </div>

          <div className="wrapper-content">
            <BiUserCircle size={24} color="#009291" />
            {item.extension_project.responsible_teacher ? (
              <Tooltip text={item.extension_project.responsible_teacher}>
                <p>
                  Prof.{' '}
                  {getLimitedText(
                    item.extension_project.responsible_teacher,
                    25
                  )}
                </p>
              </Tooltip>
            ) : (
              <p>Não informado</p>
            )}
          </div>

          {!extracurriculum &&
            item.extension_project.discipline_name !== '' && (
              <div className="wrapper-content">
                <RiBook3Line size={24} color="#009291" />
                <p>
                  Disciplina:{' '}
                  <>
                    {limitedDisciplineName}
                    {item.extension_project.discipline_count > 1 && (
                      <button
                        onClick={() => handleMoreInfo(item)}
                        className="button-discipline"
                      >
                        +{item.extension_project.discipline_count - 1}
                      </button>
                    )}
                  </>
                </p>
              </div>
            )}

          <div className="wrapper-content">
            <MdOutlineSchool size={24} color="#009291" />
            <p>
              {(item.extension_project.students_registered > 1 ||
                item.extension_project.students_registered === 0) &&
                `${item.extension_project.students_registered} estudantes inscritos`}
              {item.extension_project.students_registered === 1 &&
                `${item.extension_project.students_registered} estudante inscrito`}
              {item.extension_project.students_registered > 0 && (
                <ViewStudents onClick={() => handleOpenEnrolledModal(item)}>
                  ver
                </ViewStudents>
              )}
            </p>
          </div>
        </Content>
        <ContainerButtons>
          <ContentButton>
            <ButtonFinish onClick={() => handleFinishProject()}>
              Finalizar projeto
            </ButtonFinish>
          </ContentButton>
        </ContainerButtons>
        <Divider />
        <DetailsButton onClick={() => handleMoreInfo(item)}>
          {loadingDetails ? (
            <Loading color={'#009291'} />
          ) : (
            'VISUALIZAR DETALHES'
          )}
        </DetailsButton>
      </DataContent>
    </ContainerCard>
  );
}
