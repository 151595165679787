export const configModalStudentsList = (email) => {
  const configEmailModal = {
    bubbleText: 'Baixar lista de inscritos',
    modalWidth: 370,
    buttonConfirmWidth: 170,
    buttonConfirmHeight: 40,
    buttonCancelWidth: 75,
    buttonCancelHeight: 38,
    ParagraphText: `Os dados foram exportados e em breve você receberá a lista de inscritos no e-mail:`,
    buttonConfirmText: 'ENVIAR',
    buttonCancelText: 'CANCELAR',
    showButtonCancel: false,
    buttonCloseX: true,
  };

  return configEmailModal;
};

export const configUnpublishProjects = {
  bubbleText: 'Atenção!',
  buttonConfirmWidth: 290,
  buttonConfirmHeight: 40,
  buttonCancelWidth: 290,
  buttonCancelHeight: 40,
  modalWidth: 370,
  ParagraphText: `Não é possível remover todos os estudantes de um projeto que já está publicado.`,
  TwoParagraphText:
    'Se preferir, você pode despublicar o projeto ou voltar para mantê-lo como está.',
  buttonConfirmText: 'VOLTAR',
  buttonCancelText: 'DESPUBLICAR PROJETO',
  showButtonCancel: true,
  buttonCloseX: true,
};
