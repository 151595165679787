import React, { useContext, useRef } from 'react';
import ModalInformation from 'components/informationModal';
import { returnConfigOneDisapproved } from './configModals';
import { Form } from '@unform/web';
import { ContainerInput } from '../../styled';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';
import { updateStudentApprovalStatus } from 'views/StaffPages/ExtensionProject/services/urls';

export default function ModalDisapproveStudent({
  id,
  name,
  students,
  modalDisapprove,
  setModalDisapprove,
  quantity,
  refreshStudentList,
}) {
  const formRef = useRef();
  const { extensionProjectId } = useContext(ExtensionProjectsContext);

  const handleSubmitModal = async () => {
    try {
      const feedback = formRef.current
        ? formRef.current.getFieldValue('feedback')
        : '';
      const response = await updateStudentApprovalStatus(
        extensionProjectId,
        'disapproved',
        feedback,
        students.map((student) =>
          typeof student === 'number' ? student : student.id
        )
      );
      refreshStudentList && refreshStudentList(response);
      customSnackbar('Estudantes reprovados com sucesso!', 'confirmation');
      setModalDisapprove(false);
    } catch (error) {
      console.error('Erro ao reprovar estudantes:', error);
      customSnackbar('Ocorreu um erro, tente novamente.', 'error');
    }
  };

  return (
    <ModalInformation
      modalOpenState={modalDisapprove}
      config={returnConfigOneDisapproved(name, quantity)}
      buttonConfirmFunction={handleSubmitModal}
      closeModalFunction={() => setModalDisapprove(false)}
    >
      <ContainerInput>
        <Form ref={formRef}>
          <DescriptionInput
            name="feedback"
            marginLeft="-60px"
            charLimit={200}
            charCount="/200"
          />
        </Form>
      </ContainerInput>
    </ModalInformation>
  );
}
