import { useProjectExtensionContext } from '../../context';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  EvidenceList,
  FieldLabel,
  FieldLabelDescription,
  FieldLabelLarge,
  ListTitle,
  ModalContentWrapper,
  OverflowOfEvidenceErrorCloseButtonWrapper,
  OverflowOfEvidenceErrorContainer,
  SendButton,
  UploadActionsWrapper,
  UploadTextColumn,
  UploadTile,
} from './styled';
import { SelectField } from '../../../../StaffPages/Mentorship/components/SelectField';
import InputDesc from '../../../../StaffPages/Opportunities/Components/Form/inputDesc';
import { FilesInput } from '../FilesInput';
import BaseLayoutContext from 'contexts/base-layout';
import moment from 'moment';
import { PopUp } from '../../../Learning/components/Screens/ExtensionProject/components/Modal/ModalShowEvidence/ListFiles/style';
import { ActionButton } from '../SupportMaterialModal/styled';
import {
  RiCloseLine,
  RiDeleteBin7Line,
  RiFileCopyLine,
  RiLinkM,
} from 'react-icons/ri';
import { DefaultEyeIcon } from 'assets/ComponentIcons/DefaultEye';
import { JPGIcon, PDFIcon, PNGIcon } from 'assets/ComponentIcons/evidencesIcon';
import PdfViewer from 'components/UploadEvidences/components/PdfViwer';
import LinkInput from '../LinkInput';
import { UploadType } from '../../enums/upload_type';
import { ImageViewer } from '../ImageViewer';
import {
  customSnackbar,
  SnackbarType,
} from 'components/CustomSnackBar/customSnackbar';
import { projectExtensionApi } from '../../services/api';
import GenericModal from '../GenericModal';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';

export const FinalReportModal = ({ item, fetchEnrolledProjects }) => {
  const { finalReportOpen, setFinalReportOpen, closeDetailsModal } =
    useProjectExtensionContext();
  const formRef = useRef(null);
  const [evidenceType, setEvidenceType] = useState(null);
  const { universityColor, openModalWithContent } =
    useContext(BaseLayoutContext);
  const [evidences, setEvidences] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isEverythingFilled, setIsEverythingFilled] = useState(false);

  const overflowEvidenceTypeError = useMemo(() => {
    const evidenceTypes = evidences.map((evidence) => evidence.type);
    const uniqueEvidenceTypes = [...new Set(evidenceTypes)];
    return uniqueEvidenceTypes.length !== evidenceTypes.length;
  }, [evidences]);

  const handleClose = () => {
    setEvidenceType(null);
    formRef.current.reset();
    setFinalReportOpen(false);
    setEvidences([]);
  };

  const developmentObjectiveOptions = item?.development_objective;

  const evidenceTypeOptions = [
    { value: 'file', label: 'Documento' },
    { value: 'link', label: 'Link' },
    { value: 'image', label: 'Imagem' },
  ];

  function checkIfEverythingFilled(data) {
    const formData = data || formRef.current.getData();
    const odsIsFilled = formData.development_objective?.length > 0;
    const activitiesDescriptionIsFilled =
      !item?.enable_students_description ||
      (formData.description?.length > 0 &&
        formData.description !== '<p><br></p>');
    const conclusionsIsFilled =
      !item?.enable_students_conclusion ||
      (formData.conclusions?.length > 0 &&
        formData.conclusions !== '<p><br></p>');
    const evidencesIsFilled =
      !item?.enable_students_evidences ||
      (evidences.length > 0 && !overflowEvidenceTypeError);
    setIsEverythingFilled(
      odsIsFilled &&
        activitiesDescriptionIsFilled &&
        conclusionsIsFilled &&
        evidencesIsFilled
    );
  }

  useEffect(() => {
    checkIfEverythingFilled();
  }, [evidences]);

  const color = universityColor || '#009291';

  const icons = {
    pdf: <PDFIcon color={color} size={40} />,
    jpg: <JPGIcon color={color} size={40} />,
    jpeg: <JPGIcon color={color} size={40} />,
    png: <PNGIcon color={color} size={40} />,
    url: <RiLinkM color={color} size={40} />,
  };

  async function handleSubmit() {
    const formData = formRef.current.getData();
    const payload = {
      development_objective: formData.development_objective.map(
        (objective) => objective.value
      ),
      description: formData.description || '',
      conclusions: formData.conclusions || '',
    };

    const finalReportRequest = async () =>
      await projectExtensionApi.sendFinalReport({
        projectId: item.project_id || item.extension_project_id,
        data: payload,
      });
    const evidencesRequests = evidences.map((evidence) => {
      const formData = new FormData();
      formData.append('type', evidence.type);
      switch (evidence.type) {
        case UploadType.LINK:
          formData.append('name', '');
          formData.append('link', evidence.value);
          return async () =>
            await projectExtensionApi.uploadEvidenceLink({
              projectId: item.project_id || item.extension_project_id,
              data: formData,
            });
        case UploadType.IMAGE:
          formData.append('image', evidence.value);
          return async () =>
            await projectExtensionApi.uploadEvidenceFile({
              projectId: item.project_id || item.extension_project_id,
              data: formData,
            });
        case UploadType.DOCUMENT:
          formData.append('file', evidence.value);
          return async () =>
            await projectExtensionApi.uploadEvidenceFile({
              projectId: item.project_id || item.extension_project_id,
              data: formData,
            });
      }
    });

    try {
      await Promise.all([
        finalReportRequest(),
        ...evidencesRequests.map((request) => request()),
      ]);
      customSnackbar(
        'Relatório enviado com sucesso!',
        SnackbarType.CONFIRMATION
      );
      handleClose();
      closeDetailsModal();
      fetchEnrolledProjects();
    } catch (error) {
      customSnackbar(
        'Erro ao enviar o relatório. Por favor, tente novamente.',
        SnackbarType.ERROR
      );
    }
  }

  const handleChangeEvidenceType = (evidenceType) => {
    setEvidenceType(evidenceType?.value);
  };

  function handleClickViewPdf(file) {
    openModalWithContent(
      <PdfViewer
        universityColor={universityColor}
        file={file}
        name={file.name}
      />,
      null,
      null,
      () => {}
    );
  }

  function handleCopyUrl(url) {
    navigator.clipboard.writeText(url).then(
      () => {
        customSnackbar(
          'URL copiada para a área de transferência com sucesso!',
          SnackbarType.CONFIRMATION
        );
      },
      () => {
        customSnackbar(
          'Erro ao copiar o URL para a área de transferência: ',
          SnackbarType.ERROR
        );
      }
    );
  }

  const handleClickDeleteEvidence = (index) => {
    const newEvidences = [...evidences];
    newEvidences.splice(index, 1);
    setEvidences(newEvidences);
    formRef.current.reset();
  };

  const handleAddEvidence = (value, type) => {
    if (value) {
      setEvidences([
        ...evidences,
        {
          type,
          value,
        },
      ]);
    }
  };

  function handleOpenUrl(value) {
    window.open(value, '_blank');
  }

  return (
    <GenericModal
      isOpen={finalReportOpen}
      onClose={handleClose}
      title={'Relatório final'}
    >
      <ModalContentWrapper>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FieldLabel>
            Objetivo de Desenvolvimento Sustentável (ODS)*{' '}
            <span>(Obrigatório)</span>
          </FieldLabel>
          <FieldLabelDescription>
            Neste campo você deverá selecionar uma ou mais ODS entre as que a
            sua instituição de ensino selecionou para você.
          </FieldLabelDescription>
          <SelectField
            name={'development_objective'}
            placeholder={'Selecione'}
            options={developmentObjectiveOptions}
            onChange={(value) => {
              const formData = formRef.current.getData();
              formData.development_objective = value;
              checkIfEverythingFilled(formData);
            }}
            isMulti
          />
          {item?.enable_students_description && (
            <>
              <FieldLabel>
                Descreva a atividade* <span>(Obrigatório)</span>
              </FieldLabel>
              <FieldLabelDescription>
                Neste campo você irá descrever todas as atividades realizadas
                durante o projeto. Essas informações são essenciais para
                avaliarmos o seu desempenho.
              </FieldLabelDescription>
              <DescriptionInput
                name="description"
                marginLeft="-60px"
                charLimit={250}
                charCount="/250"
                handleChange={(value) => {
                  const formData = formRef.current.getData();
                  formData.description = value;
                  checkIfEverythingFilled(formData);
                }}
              />
            </>
          )}
          {item?.enable_students_conclusion && (
            <>
              <FieldLabel>
                Conclusões* <span>(Obrigatório)</span>
              </FieldLabel>
              <FieldLabelDescription>
                Neste campo você irá resumir os principais aprendizados e
                resultados do projeto.
              </FieldLabelDescription>
              <DescriptionInput
                name="conclusions"
                marginLeft="-60px"
                charLimit={250}
                charCount="/250"
                handleChange={(value) => {
                  const formData = formRef.current.getData();
                  formData.conclusions = value;
                  checkIfEverythingFilled(formData);
                }}
              />
            </>
          )}
          {item?.enable_students_evidences && (
            <>
              <FieldLabel>
                Upload de evidências* <span>(Obrigatório)</span>
              </FieldLabel>
              <FieldLabelDescription>
                São documentos ou qualquer outro tipo de material que comprove
                que você realmente realizou as atividades descritas
                anteriormente.
              </FieldLabelDescription>
              <FieldLabelLarge>Selecione o tipo de evidência*</FieldLabelLarge>
              <SelectField
                name={'evidence_type'}
                placeholder={'Selecione'}
                options={evidenceTypeOptions}
                onChange={handleChangeEvidenceType}
              />
              {evidenceType && (
                <>
                  {evidenceType === UploadType.LINK ? (
                    <LinkInput name={'url'} onAddEvidence={handleAddEvidence} />
                  ) : (
                    <FilesInput
                      name={'file'}
                      formRef={formRef}
                      fileSizeMBLimit={5}
                      type={evidenceType}
                      filesLimit={1}
                      onChange={handleAddEvidence}
                    />
                  )}
                </>
              )}
            </>
          )}
          {evidences.length > 0 && (
            <ListTitle>Evidências adicionadas</ListTitle>
          )}
          <EvidenceList>
            {evidences.map((evidence, index) => {
              if (evidence.type === UploadType.LINK) {
                return (
                  <UploadTile key={index} color={universityColor}>
                    {icons['url']}
                    <UploadTextColumn>
                      <a onClick={() => handleOpenUrl(evidence.value)}>
                        {evidence.value}
                      </a>
                      <span>
                        Adicionada em {moment(new Date()).format('DD/MM/YYYY')}
                      </span>
                    </UploadTextColumn>
                    <UploadActionsWrapper>
                      <PopUp
                        label="Remover"
                        universityColor={universityColor || '#009291'}
                      >
                        <ActionButton
                          type={'button'}
                          onClick={() => handleClickDeleteEvidence(index)}
                        >
                          <RiDeleteBin7Line
                            size={24}
                            color={universityColor || '#009291'}
                            alt="delete"
                          />
                        </ActionButton>
                      </PopUp>
                      <PopUp
                        label="Copiar"
                        universityColor={universityColor || '#009291'}
                      >
                        <ActionButton
                          onClick={() => {
                            handleCopyUrl(evidence.value);
                          }}
                          type={'button'}
                        >
                          <RiFileCopyLine
                            size={24}
                            color={universityColor || '#009291'}
                            alt="eye"
                          />
                        </ActionButton>
                      </PopUp>
                    </UploadActionsWrapper>
                  </UploadTile>
                );
              }
              const file = evidence.value;
              const fileExtension = file.name.split('.').pop();
              return (
                <UploadTile key={index} color={universityColor}>
                  {icons[fileExtension]}
                  <UploadTextColumn>
                    <p>{file.name}</p>
                    <span>
                      Adicionada em{' '}
                      {moment(file.lastModifiedDate).format('DD/MM/YYYY')}
                    </span>
                  </UploadTextColumn>
                  <UploadActionsWrapper>
                    <PopUp
                      label="Remover"
                      universityColor={universityColor || '#009291'}
                    >
                      <ActionButton
                        type={'button'}
                        onClick={() => handleClickDeleteEvidence(index)}
                      >
                        <RiDeleteBin7Line
                          size={24}
                          color={universityColor || '#009291'}
                          alt="eye"
                        />
                      </ActionButton>
                    </PopUp>
                    <PopUp
                      label="Visualizar"
                      universityColor={universityColor || '#009291'}
                    >
                      <ActionButton
                        onClick={() => {
                          if (evidence.type === UploadType.IMAGE) {
                            setSelectedImage(file);
                          } else {
                            handleClickViewPdf(file);
                          }
                        }}
                        type={'button'}
                      >
                        <DefaultEyeIcon
                          size={24}
                          color={universityColor || '#009291'}
                          alt="eye"
                        />
                      </ActionButton>
                    </PopUp>
                  </UploadActionsWrapper>
                </UploadTile>
              );
            })}
            {overflowEvidenceTypeError && (
              <OverflowOfEvidenceErrorContainer>
                <OverflowOfEvidenceErrorCloseButtonWrapper>
                  <RiCloseLine size={12} color={'#FFEDED'} />
                </OverflowOfEvidenceErrorCloseButtonWrapper>
                <span>Só é permitido o envio de um arquivo de cada tipo.</span>
              </OverflowOfEvidenceErrorContainer>
            )}
          </EvidenceList>
          <SendButton
            type={'submit'}
            color={universityColor}
            disabled={!isEverythingFilled}
            title={
              !isEverythingFilled
                ? 'Preencha todos os campos antes de enviar'
                : ''
            }
          >
            Enviar
          </SendButton>
        </Form>
      </ModalContentWrapper>
      <ImageViewer
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />
    </GenericModal>
  );
};
