import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import { useVisualizationStudentContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationStudent';
import { StudentHandlers } from 'views/StaffPages/FairAndEvents/Handlers/StudentHandlers';
import { useEffect, useState } from 'react';
import StudentCoursesModal from 'views/StaffPages/FairAndEvents/UiComponents/StudentCoursesModal';
import Button from '../../../../Button';
import altAvatar from 'assets/img/placeholder.jpg';
import { FaCheck } from 'react-icons/fa';
import {
  ContainerImageAndInformations,
  ContainerListing,
  Wrapper,
  Container,
  Header,
  LabelPresence,
} from '../../styled';
import CustomCheckbox from '../../../../CustomCheckbox';
import Search from '../../../../SearchBar';
import { getCheckInConfirmationConfig } from 'views/StaffPages/FairAndEvents/StaticOptions/CheckInConfirmationOptions';
import ModalInformation from 'components/informationModal';
import GenericMessage from 'components/GenericMessage';

export default function ListingToFinish() {
  const [studentCourseModalOpen, setStudentCourseModalOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [restartCheckBox, setRestartCheckBox] = useState(false);

  const visualizationStudentContextInstance = useVisualizationStudentContext();
  const visualizationContextInstance = useVisualizationContext();

  const studentHandlerFactory = StudentHandlers(
    visualizationContextInstance,
    visualizationStudentContextInstance
  );

  const { detailsObject } = visualizationContextInstance;
  const {
    listing,
    selectedStudent,
    setSelectedStudent,
    checkedStudents,
    setCheckedStudents,
    checkedAll,
    setCheckedAll,
    typeChecked,
    setTypeChecked,
    currentPage,
    limit,
  } = visualizationStudentContextInstance;
  const eventId = detailsObject.id;

  useEffect(() => {
    if (eventId) {
      const parameters = `limit=${limit}&offset=${
        (currentPage - 1) * limit || 0
      }`;
      studentHandlerFactory.getListingStudentEvent(eventId, _, parameters);
    }
  }, [eventId]);

  const handleClickMoreCourses = (studentData) => {
    setStudentCourseModalOpen(true);
    setSelectedStudent(studentData);
  };

  const config = getCheckInConfirmationConfig(checkedStudents.length);

  const currentButton = (presence, studentData) => {
    if (presence) {
      return (
        <Button
          theme="unfilled"
          width="100%"
          customColor="#CF0000"
          onClick={() =>
            studentHandlerFactory.onRemovePresenceStudent(
              studentData.uuid,
              studentData.presence_id,
              eventId,
              true
            )
          }
        >
          Retirar presença
        </Button>
      );
    }

    return (
      <Button
        theme="unfilled"
        width="100%"
        onClick={() =>
          studentHandlerFactory.onConfirmPresenceStudent(
            studentData.uuid,
            eventId,
            true
          )
        }
      >
        Confirmar presença
      </Button>
    );
  };

  function StudentCard({ studentData, handleClickMoreCourses, currentButton }) {
    const avatarSource = studentData.student.avatar
      ? studentData.student.avatar
      : altAvatar;

    const formatCourseAndPeriod = () => {
      const firstCourse = studentData.student.courses[0]?.course__name;
      const firstPeriod = studentData.student.courses[0]?.semester + '°';
      const coursesCount = studentData.student.courses?.length - 1;

      return { firstCourse, firstPeriod, coursesCount };
    };
    const { firstCourse, firstPeriod, coursesCount } = formatCourseAndPeriod();
    const formatStringCourseAndPeriod = `${firstCourse} - ${firstPeriod} período`;

    const checked =
      checkedStudents.includes(studentData.uuid) ||
      checkedStudents.includes(studentData.presence_id);

    const onChangeStudentCheckbox = (event) => {
      const checked = event.target.checked;

      if (checked) {
        setCheckedStudents((prev) => [...prev, studentData.uuid]);
        return;
      }

      setCheckedStudents((prev) =>
        prev.filter((uuid) => uuid !== studentData.uuid)
      );
    };

    return (
      <Container>
        <Wrapper>
          <ContainerImageAndInformations active={studentData.presence}>
            <div>
              {checked && (
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={onChangeStudentCheckbox}
                />
              )}

              <img src={avatarSource} />
              <div className="checkActive">
                <FaCheck color={'#FFF'} size={14} />
              </div>
            </div>
            <div>
              <h4>{studentData.student.name}</h4>
              <p>
                {formatStringCourseAndPeriod}
                {coursesCount > 0 && (
                  <span onClick={() => handleClickMoreCourses(studentData)}>
                    + {coursesCount}
                  </span>
                )}
              </p>
              <LabelPresence presence={studentData.presence}>
                {studentData.presence
                  ? 'Presença confirmada'
                  : 'Presença não confirmada'}
              </LabelPresence>
              <span>ID: {studentData.student.external_id}</span>
            </div>
          </ContainerImageAndInformations>

          <div>{currentButton(studentData.presence, studentData)}</div>
        </Wrapper>

        <div className="divider" />
      </Container>
    );
  }

  const renderStudentList = listing.map((studentData) => (
    <StudentCard
      key={studentData.uuid}
      studentData={studentData}
      handleClickMoreCourses={handleClickMoreCourses}
      currentButton={currentButton}
    />
  ));

  const onChangeCheckbox = (checked) => {
    setCheckedAll(checked);
  };

  const handleClickOption = (checked, type) => {
    setTypeChecked(type);
    setCheckedAll(true);

    if (type === 'presence') {
      const students = listing.filter((student) => student.presence);
      setCheckedStudents(students.map((student) => student.presence_id));
    }

    if (type === 'noPresence') {
      const students = listing.filter((student) => !student.presence);
      setCheckedStudents(students.map((student) => student.uuid));
    }
  };

  const optionsCheckbox = [
    {
      label: 'Com presença confirmada',
      type: 'presence',
      onClick: handleClickOption,
    },
    {
      label: 'Sem presença confirmada',
      type: 'noPresence',
      onClick: handleClickOption,
    },
  ];

  const onClearOption = () => {
    setCheckedStudents([]);
    setCheckedAll(false);
    setTypeChecked(null);
  };

  const confirmedStudents = listing.filter((student) => student.presence);

  const handleConfirmButtonFunction = async () => {
    try {
      await studentHandlerFactory.bulkStudentType(
        checkedStudents,
        typeChecked,
        eventId
      );
      onClearOption();
      setRestartCheckBox(true);
      setModalInformationOpen(false);
    } finally {
      setRestartCheckBox(false);
    }
  };

  return (
    <>
      <ModalInformation
        config={config[typeChecked]}
        buttonConfirmFunction={handleConfirmButtonFunction}
        buttonCancelFunction={() => setModalInformationOpen(false)}
        closeModalFunction={() => setModalInformationOpen(false)}
        modalOpenState={modalInformationOpen}
      />
      <Header>
        <h4>Estudantes inscritos</h4>
        <span>
          Para finalizar o evento, confira todos os estudantes que se
          inscreveram. Apenas os estudantes com presença <br /> confirmada serão
          considerados na avaliação.
        </span>

        <h4 className="studentsConfirmed">
          Estudantes confirmados:{' '}
          <span>
            {confirmedStudents.length}/{listing.length}
          </span>
        </h4>

        <div className="filtersAndBulk">
          <div>
            <CustomCheckbox
              onChange={onChangeCheckbox}
              options={optionsCheckbox}
              onClearOption={onClearOption}
              unCheck={restartCheckBox}
            />
            <Search
              onChange={(event) =>
                studentHandlerFactory.onFilter(eventId, event.target.value)
              }
            />
          </div>
          {listing &&
            listing.length > 0 &&
            checkedStudents.length > 0 &&
            checkedAll && (
              <Button
                theme="flatUniversityColor"
                onClick={() => setModalInformationOpen(true)}
              >
                {typeChecked === 'presence'
                  ? `Retirar ${checkedStudents.length} ${
                      checkedStudents.length > 1 ? 'estudantes' : 'estudante'
                    }`
                  : `Confirmar ${checkedStudents.length} ${
                      checkedStudents.length > 1 ? 'estudantes' : 'estudante'
                    }`}
              </Button>
            )}
        </div>
      </Header>

      <StudentCoursesModal
        studentData={selectedStudent}
        open={studentCourseModalOpen}
        onClose={() => setStudentCourseModalOpen(false)}
      />
      {listing.length === 0 ? (
        <GenericMessage
          resetIndex={true}
          title="Nenhum estudante encontrado"
          subtitle="No momento não possui estudantes disponíveis"
        />
      ) : (
        renderStudentList
      )}
    </>
  );
}
