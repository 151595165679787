import React, { createContext, useState, useContext, useEffect } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { extensionService } from './services/extension.service';
import {
  stepFiveValidator,
  stepOneValidator,
  stepThreeValidator,
  stepTwoValidator,
} from './validators/validationFunctions';
import { extensionDataPayload } from './validators/extensionProjectAdapter';
import {
  deleteProjectImage,
  extensionProjectFile,
  uploadDocuments,
} from '../../services';
import { useGenericUpload } from 'components/GenericModalUpload/store/genericUpload.store';
import { handleCreateProject, handleEditProject } from './utils/resources';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export const CreateExtensionContext = createContext();

export default function CreateExtensionProvider({
  children,
  kind,
  getModalInstance,
  getCountProjects,
  appliedFilters,
  setFutureExtension,
  setPaginationTwoCurrentPage,
  setResetAllFutureListing,
  setInProgressExtension,
  setPaginationThreeCurrentPage,
  setResetInProgressListing,
}) {
  const { metadata } = useContext(BaseLayoutContext);

  const [currentStep, setCurrentStep] = useState(1);
  const [stepOneFormRef, setStepOneFormRef] = useState(null);
  const [stepTwoFormRef, setStepTwoFormRef] = useState(null);
  const [stepThreeFormRef, setStepThreeFormRef] = useState(null);
  const [coursesLabel, setCoursesLabel] = useState([]);
  const [disciplinesLabel, setDisciplinesLabel] = useState([]);
  //Form data
  const [macroAreas, setMacroAreas] = useState([]);
  const [unities, setUnities] = useState([]);
  const [courses, setCourses] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [studentsListing, setStudentsListing] = useState(null);
  const [extensionProjectOptions, setExtensionProjectOptions] = useState({});
  const [competences, setCompetences] = useState([]);
  const educationalGroup =
    metadata && metadata.staff_role === 'educational_group_board';

  const [formData, setFormData] = useState({});
  const { listingItems } = useGenericUpload((state) => state.listingItems);
  const [selectedImage, setSelectedImage] = useState(null);

  const [excludedStudent, setExcludedStudent] = useState([]);
  const [extraStudent, setExtraStudent] = useState([]);
  const [newExcludeStudents, setNewExcludeStudents] = useState([]);

  const [idEditing, setIdEditing] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const [controlModalFeedbackStudent, setControlModalFeedbackStudent] =
    useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const [hasCep, setHasCep] = useState(null);
  const [hasStudents, setHasStudents] = useState(null);

  const setFormValues = (values) => {
    setFormData((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  //Form data Gets

  const getProjectOptions = async () => {
    try {
      const result = await extensionService.getExtensionProjectOptions();
      setExtensionProjectOptions(result);
    } catch (e) {
      setExtensionProjectOptions({});
    }
  };

  const getCompetences = async () => {
    try {
      const result = await extensionService.getCompetencies();
      setCompetences(result);
    } catch (e) {
      setCompetences([]);
    }
  };

  const getUnities = async () => {
    try {
      const result = await extensionService.getUnities('', educationalGroup);
      setUnities(result);
    } catch (e) {
      setUnities([]);
    }
  };
  const getMacroAreas = async () => {
    try {
      const result = await extensionService.getMacroAreas('', educationalGroup);
      setMacroAreas(result);
    } catch (e) {
      setMacroAreas([]);
    }
  };

  const getCourses = async (macroAreas) => {
    try {
      const result = await extensionService.getCourses(macroAreas);
      setCourses(result);
    } catch (e) {
      setCourses([]);
    }
  };

  const getDisciplines = async (courses) => {
    try {
      const result = await extensionService.getDisciplines(courses);
      setDisciplines(result);
    } catch (e) {
      setDisciplines([]);
    }
  };

  const getTeachers = async (courses) => {
    try {
      const result = await extensionService.getTeachers();
      setTeachers(result);
    } catch (e) {
      setTeachers([]);
    }
  };

  const getStudentsListing = async (bodyParams, queryParams) => {
    try {
      const result = await extensionService.getStudents(
        bodyParams,
        queryParams
      );
      setStudentsListing(result.data);
      return result.data;
    } catch (e) {
      setStudentsListing(null);
    }
  };

  const getFormData = async () => {
    try {
      await Promise.all([
        await getMacroAreas(),
        await getUnities(),
        await getProjectOptions(),
        await getCompetences(),
        await getTeachers(),
      ]);
    } catch (e) {
    } finally {
    }
  };

  const validateSteps = async () => {
    if (currentStep === 4) return true;

    const validationSteps = {
      curricular: {
        1: async () => stepOneValidator(stepOneFormRef, setFormValues, kind),
        2: async () => stepTwoValidator(stepTwoFormRef, setFormValues),
        3: async () =>
          stepThreeValidator(stepThreeFormRef, setFormValues, hasCep),
        5: async () => stepFiveValidator(hasStudents),
      },
      extracurricular: {
        1: async () => stepTwoValidator(stepTwoFormRef, setFormValues),
        2: async () => stepOneValidator(stepOneFormRef, setFormValues, kind),
        3: async () =>
          stepThreeValidator(stepThreeFormRef, setFormValues, hasCep),
      },
    };

    return validationSteps[kind]?.[currentStep]?.();
  };

  const nextStep = async () => {
    const modalInstance = getModalInstance();
    const isFinalStep =
      (kind === 'curricular' && currentStep === 5) ||
      (kind === 'extracurricular' && currentStep === 4);

    if (!(await validateSteps())) return null;

    if (isFinalStep) {
      await handleSubmit();
      modalInstance.closeFunc(modalInstance);
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  useEffect(() => {
    if (excludedStudent?.length > 0) {
      setFormValues({ removed_students: excludedStudent });
    }
    if (extraStudent?.length > 0) {
      setFormValues({ extra_students: extraStudent });
    }
  }, [excludedStudent, extraStudent]);

  const handleSubmit = async () => {
    const adaptedData = extensionDataPayload(formData);
    let response;

    if (idEditing) {
      response = await handleEditProject(adaptedData, idEditing);
    } else {
      response = await handleCreateProject(adaptedData);
    }

    if (response?.data) {
      await handleFileUploads(
        response.data.extension_project.id,
        response.data.id,
        idEditing
      );
      handlePostSubmission();
    }
  };

  const handleFileUploads = async (idProject, uuidProject, idEditing) => {
    if (selectedImage !== null && !idEditing) {
      await extensionProjectFile(selectedImage, uuidProject);
    }

    if (selectedImage !== null && imageChanged && idEditing) {
      await extensionProjectFile(selectedImage, uuidProject);
    }

    if (selectedImage === null && imageChanged && idEditing) {
      await deleteProjectImage(uuidProject);
    }

    if (listingItems?.length > 0 && !idEditing) {
      const updatedFormData = new FormData();
      listingItems.forEach((item) => {
        if (item.type === 'file') {
          updatedFormData.append('files', item.file);
        } else if (item.type === 'image') {
          updatedFormData.append('images', item.file);
        } else if (item.type === 'link') {
          updatedFormData.append('links', item.link);
        }
      });

      if (listingItems.length > 0) {
        await uploadDocuments(updatedFormData, idProject);
      }
    }
  };

  const handlePostSubmission = () => {
    setFormData({});
    // Reset future Listing
    setFutureExtension(null);
    setPaginationTwoCurrentPage(0);
    setResetAllFutureListing((prev) => !prev);

    // Reset In Progress Listing
    setInProgressExtension(null);
    setPaginationThreeCurrentPage(0);
    setResetInProgressListing((prev) => !prev);

    getCountProjects();
    customSnackbar('Projeto publicado com sucesso!', 'confirmation');
  };

  const previousStep = () =>
    setCurrentStep(() => (currentStep === 1 ? currentStep : currentStep - 1));
  /**
   *
   * @param {1|2|3|4|5} step
   * @returns {Void}
   */
  const toStep = (step) => setCurrentStep(step);

  //useStates
  useState(() => {
    (async () => {
      await getFormData();
    })();
  }, []);

  const returnTitleModal = () => {
    if (idEditing) {
      return kind === 'extracurricular'
        ? 'Editar Projeto de Extensão Extracurricular'
        : 'Editar Projeto de Extensão Curricular';
    } else {
      return kind === 'extracurricular'
        ? 'Cadastrar Projeto de Extensão Extracurricular'
        : 'Cadastrar Projeto de Extensão Curricular';
    }
  };

  return (
    <CreateExtensionContext.Provider
      value={{
        nextStep,
        previousStep,
        toStep,
        currentStep,
        setStepOneFormRef,
        setStepTwoFormRef,
        setStepThreeFormRef,

        //formData
        macroAreas,
        unities,
        getCourses,
        courses,
        getDisciplines,
        disciplines,
        extensionProjectOptions,
        competences,
        teachers,
        formData,
        studentsListing,
        setFormValues,
        getStudentsListing,
        kind,
        validateSteps,
        excludedStudent,
        setExcludedStudent,
        extraStudent,
        setExtraStudent,
        setIdEditing,
        idEditing,
        imageChanged,
        setImageChanged,
        selectedImage,
        setSelectedImage,
        coursesLabel,
        setCoursesLabel,
        disciplinesLabel,
        setDisciplinesLabel,
        returnTitleModal,
        controlModalFeedbackStudent,
        setControlModalFeedbackStudent,
        isFeedbackSent,
        setIsFeedbackSent,
        newExcludeStudents,
        setNewExcludeStudents,
        setHasCep,
        setHasStudents,
        hasStudents,
      }}
    >
      {children}
    </CreateExtensionContext.Provider>
  );
}
