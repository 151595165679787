import React, { useContext } from 'react';
import { Container, StepItem } from './styles';
import BaseLayoutContext from 'contexts/base-layout';
import useMediaQuery from 'hooks/useMediaQuery';

export default function StepIndicator({
  count,
  className,
  currentStep,
  onStepChange,
  toStepFunction = null,
  clarifyPastSteps = false,
  validation,
  isEdit,
  disabled = false,
  ...rest
}) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { universityColor } = useContext(BaseLayoutContext);

  const handleClick = async (index) => {
    const nextStep = index + 1;
    const isAdvancing = nextStep > currentStep;

    if (isAdvancing) {
      await handleAdvance(nextStep, index);
    } else {
      handleBack(nextStep, index);
    }
  };

  const handleAdvance = async (nextStep, index) => {
    if (toStepFunction !== null) {
      if (await validation()) {
        toStepFunction(nextStep);
      }
    } else if (currentStep !== index + 1 && onStepChange) {
      onStepChange(index + 1);
    }
  };

  const handleBack = (nextStep, index) => {
    if (toStepFunction !== null) {
      toStepFunction(nextStep);
    } else if (currentStep !== index + 1 && onStepChange) {
      onStepChange(index + 1);
    }
  };

  return (
    <Container isMobile={isMobile} className={className}>
      {Array.from({ length: count }).map((_, index) => {
        return (
          <StepItem
            universityColor={
              universityColor || rest?.universityColor || '#009291'
            }
            key={index + 1}
            onClick={() => handleClick(index)}
            active={index + 1 === currentStep}
            pastStep={clarifyPastSteps && index + 1 < currentStep}
            disabled={disabled}
          >
            <span>{index + 1}</span>
          </StepItem>
        );
      })}
    </Container>
  );
}
