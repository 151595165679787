import EnrolledStudentsSkeleton from '../EnrolledStudentsSkeleton';
import { Fragment, useEffect, useState } from 'react';
import { ContainerDashed } from '../../../ModalStylingPattern/styles';
import StudentsToFinish from '../StudentsToFinish';
import ModalDisapproveStudent from '../Modals/ModalDisapprove';
import { ContainerStudentButtons, StudentsButtons } from '../../styled';
import ModalApproveStudent from '../Modals/ModalApproveStudent';

export function EnrolledStudentsList(props) {
  const {
    ordering,
    status,
    limitPerPage,
    page,
    getStudents,
    students,
    requestInProgress,
    showStatus = true,
    setScore,
    finalization = false,
    modalEnrolledStudents,
    search,
    setAllStudentsEvaluated,
  } = props;

  const offset = (page - 1) * limitPerPage;
  const [selectStudents, setSelectStudents] = useState([]);
  const [modalDisapproveStudents, setModalDisapproveStudents] = useState(false);
  const [modalApprove, setModalApprove] = useState(false);

  useEffect(() => {
    getStudents({ limitPerPage, offset, ordering });
  }, [modalEnrolledStudents]);

  const handleCheckboxChange = (studentId, isChecked) => {
    if (isChecked) {
      setSelectStudents((prev) => [...prev, studentId]);
    } else {
      setSelectStudents((prev) => prev.filter((id) => id !== studentId));
    }
  };

  const onClickDisapproveStudents = () => {
    if (selectStudents.length > 0) {
      setModalDisapproveStudents(true);
    }
  };

  const onClickApproveStudents = () => {
    if (selectStudents.length > 0) {
      setModalApprove(true);
    }
  };

  const filterData = (dataArray, search) => {
    const lowercaseSearch = (search || '').toLowerCase();

    return dataArray.filter((item) => {
      const lowercaseItemName = (item.student_name || '').toLowerCase();
      const itemId = item.id.toString();
      return (
        lowercaseSearch === '' ||
        lowercaseItemName.includes(lowercaseSearch) ||
        itemId.includes(lowercaseSearch)
      );
    });
  };

  const refreshStudentList = (response) => {
    setSelectStudents([]);
    getStudents({ limitPerPage, offset, ordering });
    setAllStudentsEvaluated(response.students_all_evaluated);
  };

  const renderItems = () => {
    const filteredData = filterData(students, search);
    if (requestInProgress) {
      return Array(8)
        .fill(1)
        .map((_, index) => <EnrolledStudentsSkeleton key={index} />);
    }

    if (filteredData?.length === 0) {
      return (
        <ContainerDashed>
          <p>Nenhum estudante encontrado.</p>
        </ContainerDashed>
      );
    }

    if (students?.length > 0) {
      return (
        <>
          {selectStudents.length > 0 && (
            <ContainerStudentButtons>
              <StudentsButtons onClick={onClickDisapproveStudents}>
                Reprovar {selectStudents.length} estudantes
              </StudentsButtons>
              <StudentsButtons approved onClick={onClickApproveStudents}>
                Aprovar {selectStudents.length} estudantes
              </StudentsButtons>
            </ContainerStudentButtons>
          )}

          {students.map((registeredStudent) => (
            <StudentsToFinish
              key={registeredStudent.id}
              registeredStudent={registeredStudent}
              getStudents={getStudents}
              handleCheckboxChange={handleCheckboxChange}
              students={students}
              limitPerPage={limitPerPage}
              offset={offset}
              status={status}
              showStatus={showStatus}
              setScore={setScore}
              finalization={finalization}
              refreshStudentList={refreshStudentList}
            />
          ))}
        </>
      );
    }
  };

  return (
    <Fragment>
      {modalDisapproveStudents && (
        <ModalDisapproveStudent
          students={selectStudents}
          quantity={selectStudents.length}
          modalDisapprove={modalDisapproveStudents}
          setModalDisapprove={setModalDisapproveStudents}
          refreshStudentList={refreshStudentList}
        />
      )}

      {modalApprove && (
        <ModalApproveStudent
          students={selectStudents}
          quantity={selectStudents.length}
          modalApprove={modalApprove}
          setModalApprove={setModalApprove}
          refreshStudentList={refreshStudentList}
        />
      )}

      {renderItems()}
    </Fragment>
  );
}
