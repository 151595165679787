import React from 'react';
import { ButtonClose, HeaderModal, TitleModal } from '../styles/BaseComponent';
import { RiCloseFill } from 'react-icons/ri';

export default function GenericHeaderModal({ title, closeModal }) {
  return (
    <HeaderModal>
      <TitleModal>{title}</TitleModal>
      <ButtonClose onClick={closeModal}>
        <RiCloseFill size={25} />
      </ButtonClose>
    </HeaderModal>
  );
}
