import axios from 'axios';
import { authenticatedAPI } from 'services/api/api';

const CancelToken = axios.CancelToken;

let cancelAvailableExtensions;
let cancelImagesAvailableExtensions;

const getExtensions = async (query = '') => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/extension-project?${query}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelAvailableExtensions = c;
      }),
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const postSubscribe = async (id) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/extension-project/${id}/enroll`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const patchSubscribe = async (id, registrationId) => {
  const response = authenticatedAPI
    .patch(
      `/api/internal/students/me/extension-project/${id}/enroll/${registrationId}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCountEnrolled = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/extension-project/count`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getImagesExtesionProject = async (query = 'limit=10') => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/extension-project-images?${query}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelImagesAvailableExtensions = c;
      }),
    })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const postCreateReaction = async (id, body) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/attractions/${id}/reaction`, { ...body })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const cancelGetPublishedExtensions = () => {
  cancelAvailableExtensions && cancelAvailableExtensions();
  cancelImagesAvailableExtensions && cancelImagesAvailableExtensions();
};

export {
  getExtensions,
  postSubscribe,
  getCountEnrolled,
  getImagesExtesionProject,
  postCreateReaction,
  patchSubscribe,
  cancelGetPublishedExtensions,
};
