import React from 'react';
import { IconWrapper, ItemWrapper } from './styled';

const InfoRow = ({ icon, label, value }) => {
  return (
    <ItemWrapper>
      <div className="container">
        <IconWrapper>{icon}</IconWrapper>
        <div className="text-wrapper">
          <strong>{label}</strong>
          <p>{value}</p>
        </div>
      </div>
    </ItemWrapper>
  );
};

export default InfoRow;
