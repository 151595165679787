import React, { useState } from 'react';
import { PiCaretDownBold } from 'react-icons/pi';
import {
  ActionButton,
  ButtonsContainer,
  DropdownContainer,
  DropdownMenu,
  DropdownItem,
} from './styled';

const ActionButtons = ({
  item,
  universityColor,
  buttons = [],
  dropdownMenuItems,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const handleDropdownToggle = () => setShowDropdown(!showDropdown);
  const isProjectStarted = (startAt) => {
    const projectStartDate = new Date(startAt);
    const currentDate = new Date();
    return projectStartDate <= currentDate;
  };

  const filteredDropdownMenuItems = dropdownMenuItems?.filter((menuItem) => {
    if (menuItem.label === 'Preencher Relatório') {
      if (item.report_sent) {
        return false;
      }

      if (!isProjectStarted(item.start_at)) {
        return false;
      }
    }

    return true;
  });
  return (
    <ButtonsContainer>
      {buttons?.map((button, index) => (
        <ActionButton
          key={index}
          onClick={() => button.onClick(item)}
          universityColor={universityColor}
          isDetails={button.isDetails}
        >
          {button.label}
        </ActionButton>
      ))}

      {item.extension_kind !== 'curricular' && dropdownMenuItems && (
        <DropdownContainer>
          <ActionButton
            onClick={handleDropdownToggle}
            universityColor={universityColor}
          >
            Ações
            <PiCaretDownBold size={14} />
          </ActionButton>
          {showDropdown && (
            <DropdownMenu>
              {filteredDropdownMenuItems.map((menu, index) => (
                <DropdownItem key={index} onClick={() => menu.onClick(item)}>
                  {menu.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </DropdownContainer>
      )}
    </ButtonsContainer>
  );
};

export default ActionButtons;
