import React, { useContext, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { useField } from '@unform/core';
import errorIcon from 'assets/Matriz/error.svg';
import { ErrorContainer } from './styled';
import BaseLayoutContext from 'contexts/base-layout';

export function SelectField(props) {
  const {
    options,
    isMulti,
    placeholder = 'Selecione',
    name,
    value,
    useSearchDropDownIcon,
    disabled,
    ...rest
  } = props;
  const selectRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  const { universityColor } = useContext(BaseLayoutContext);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        return ref.state?.value;
      },
      setValue: (ref, value) => {
        ref.select?.setValue(value);
      },
      clearValue: (ref) => {
        ref?.select?.clearValue();
      },
    });
  }, [fieldName, registerField, options]);

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: '192px',
      fontSize: '13px',
      textAlign: 'left',
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),

    placeholder: (base) => ({
      ...base,
      fontSize: '13px',
    }),

    indicatorSeparator: () => ({
      ...(useSearchDropDownIcon && { display: 'none' }),
    }),

    control: (baseStyles, _) => ({
      ...baseStyles,
      ...(error && { borderColor: '#FFD8D8' }),
      ...(error && { background: '#FFF5F5' }),
    }),
  };

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: getShadeColor(
          universityColor ? universityColor : '#009291',
          0.35
        ),
        primary50: getShadeColor(
          universityColor ? universityColor : '#009291',
          0.5
        ),
        primary: universityColor || '#009291',
      },
    };
  }

  function getShadeColor(color, percent) {
    const f = parseInt(color.slice(1), 16);
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = f >> 16;
    const G = (f >> 8) & 0x00ff;
    const B = f & 0x0000ff;
    return `#${(
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)}`;
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Nenhuma opção encontrada</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <>
      <Select
        ref={selectRef}
        name={name}
        placeholder={placeholder}
        theme={selectTheme}
        components={{
          NoOptionsMessage,
        }}
        value={value}
        styles={selectStyles}
        options={options}
        classNamePrefix="react-select"
        isMulti={isMulti}
        onFocus={clearError}
        isDisabled={disabled}
        {...rest}
      />
      {error && (
        <>
          <ErrorContainer>
            <img src={errorIcon} alt="error-icon" />
            <span className={'error-label'}>{error}</span>
          </ErrorContainer>
        </>
      )}
    </>
  );
}
