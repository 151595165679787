import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

const getColor = ({ color }) => {
  return color || '#009291';
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 2rem;
  width: 100%;
  transition: all 0.2s ease-in-out;

  > input {
    display: none;
  }
`;

export const DropContent = styled.div`
  display: flex;
  padding: 1.5rem 4rem;
  justify-content: center;
  align-items: center;
  border: 2px dashed #949494;

  &.drop-overlay {
    border: 2px dashed #009291;
  }

  &.invalid-dropped-file {
    border: 2px dashed #da0505;
  }

  flex-direction: column;
  width: 100%;
  border-radius: 10px;
`;

export const DropOverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  padding: 2rem 0;
  color: ${(props) => (props.hasError ? '#DA0505' : '#606062')};
  margin: 0;

  > p {
    font-size: 16px;
    color: ${(props) => (props.hasError ? '#DA0505' : '#606062')};
    margin: 0;
  }
`;

export const DropRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  strong {
    font-weight: 700;
  }

  p,
  strong {
    font-size: 18px;
    color: #606062;
    margin-bottom: 0;
    text-align: center;
  }

  span {
    color: #949494;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 16px;
  color: #da0505 !important;
  margin-top: 0.5rem !important;
  white-space: nowrap !important;
`;

export const Row = styled.div`
  display: flex;
  gap: ${(props) => (props.gap ? props.gap : '1rem')};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : 'flex-start'};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};

  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ''}
  > button {
    margin-top: 8px;
  }

  > span {
    margin-left: auto;
  }

  > b {
    margin-bottom: 9px;
  }

  > p.error {
    display: flex;
    margin: 0;
    gap: 12px;

    &:before {
      content: url(${error});
      width: 10px;
      height: 15px;
      margin: auto 0;
    }
  }
`;

export const IconCircularWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${getColor};
  width: 72px;
  height: 72px;
`;
