import { fetchAllData } from 'views/StaffPages/Dashboard/Services/handleRequests';
import {
  BoxContent,
  BoxMain,
  DataContent,
  GridArea,
  SubtitleFilter,
  Title,
} from 'views/StaffPages/Dashboard/Styles/BaseComponent';
import { CustomBarChart } from 'views/StaffPages/Dashboard/UiComponents/Graphics/BarChart';
import { CustomHorizontalBarChart } from 'views/StaffPages/Dashboard/UiComponents/Graphics/HorizontalBarChart';
import { CustomLineChart } from 'views/StaffPages/Dashboard/UiComponents/Graphics/LineChart';
import { CustomPieChart } from 'views/StaffPages/Dashboard/UiComponents/Graphics/PieChart';
import { DataSectionGraduates } from 'views/StaffPages/Dashboard/UiComponents/Sections/DataSectionGraduates';
import { LegendSection } from 'views/StaffPages/Dashboard/UiComponents/Sections/LegendSection';
import { StatWidget } from 'views/StaffPages/Dashboard/UiComponents/Sections/StatWidget';
import {
  returnCircularProgressItens,
  returnGraphic,
} from 'views/StaffPages/Dashboard/Utils/componentRendering';
import { useEffect, useState, useContext } from 'react';
import { DashboardContext } from 'views/StaffPages/Dashboard/Context';
import { LinearGraphSkeleton } from 'views/StaffPages/Dashboard/UiComponents/Skeletons/LinearGraphSkeleton';
import { VerticalBarGraphSkeleton } from 'views/StaffPages/Dashboard/UiComponents/Skeletons/BarGraphSkeleton';
import { LegendJourney } from 'views/StaffPages/Dashboard/UiComponents/Legends/LegendJourneys';
import { HorizontalGraphSkeleton } from 'views/StaffPages/Dashboard/UiComponents/Skeletons/HorizontalGraphSkeleton';
import { GraphicSection } from 'views/StaffPages/Dashboard/UiComponents/Sections/GraphicSection';
import moment from 'moment';
import {
  returnInformationsGraduates,
  returnLabelStudentGraduatesLogin,
} from 'views/StaffPages/Dashboard/Utils/formatDataGraphics';

export function GraduatesTab(params) {
  const [marketRelationship, setMarketRelationship] = useState(null);
  const [accessData, setAccessData] = useState({ result: { chart: null } });
  const [studentLogins, setStudentLogins] = useState(null);
  const [studentsCurriculum, setStudentsCurriculum] = useState(null);
  const [systemUsage, setSystemUsage] = useState(null);
  const [journeyStatus, setJourneyStatus] = useState(null);
  const [employedUnemployed, setEmployedUnemployed] = useState(null);
  const [studentWorkingInArea, setStudentWorkingInArea] = useState(null);
  const [careerObjective, setCareerObjective] = useState(null);
  const [professionalStatusChange, setProfessionalStatusChange] = useState(
    null
  );
  const { fetchMe, queryFilter, setLoading, informationPeriod } = useContext(
    DashboardContext
  );

  useEffect(() => {
    if (fetchMe) {
      fetchAllData(
        [
          setStudentsCurriculum,
          setAccessData,
          setStudentLogins,
          setMarketRelationship,
          setSystemUsage,
          setJourneyStatus,
          setEmployedUnemployed,
          setStudentWorkingInArea,
          setCareerObjective,
          setProfessionalStatusChange,
        ],
        'done',
        queryFilter,
        setLoading
      );
    }
  }, [fetchMe, queryFilter]);

  return (
    <BoxMain>
      <BoxContent>
        <Title>Dados de acesso dos estudantes</Title>
        {'start_date' in informationPeriod && (
          <SubtitleFilter>
            Período de referência:{' '}
            {moment(informationPeriod.start_date).format('DD/MM/YYYY')}{' '}
            {informationPeriod.end_date !== undefined &&
              `à ${moment(informationPeriod.end_date).format('DD/MM/YYYY')}`}
          </SubtitleFilter>
        )}
        <DataContent>
          <GridArea>
            <StatWidget
              padding={'14px'}
              data={accessData?.result?.chart || null}
              stringFormatter={returnInformationsGraduates}
              countSkeleton={[1, 2, 3, 4]}
              graduated={true}
            />
            <StatWidget
              padding={'14px'}
              data={studentLogins?.result?.chart || null}
              stringFormatter={returnLabelStudentGraduatesLogin}
              countSkeleton={[1, 2]}
              graduated={false}
            />
          </GridArea>
        </DataContent>
        <GraphicSection
          data={studentsCurriculum}
          separateTitles={['Ativos', 'Ativos com currículo']}
        >
          {returnGraphic(
            studentsCurriculum,
            CustomLineChart,
            LinearGraphSkeleton
          )}
        </GraphicSection>
        <GraphicSection
          title="Utilização do sistema"
          subtitle={`Aqui está o total de estudantes formados que busca o seu autodesenvolvimento através das funcionalidades do sistema.`}
          data={systemUsage?.result?.chart}
        >
          {returnGraphic(
            systemUsage?.result?.chart,
            CustomBarChart,
            VerticalBarGraphSkeleton
          )}
        </GraphicSection>
        <GraphicSection
          title="Evolução nas jornadas"
          subtitle="Aqui está o total de estudantes em cada etapa da Jornada."
          data={journeyStatus?.result?.chart}
        >
          {returnGraphic(
            journeyStatus?.result?.chart,
            CustomHorizontalBarChart,
            HorizontalGraphSkeleton,
            'value',
            null,
            LegendJourney
          )}
        </GraphicSection>
      </BoxContent>
      <BoxContent>
        <Title>Status profissional</Title>
        <GraphicSection
          title="Situação profissional"
          subtitle="Aqui está o total de estudantes formados e sua situação profissional."
          data={employedUnemployed?.result?.chart}
        >
          {returnGraphic(
            employedUnemployed?.result?.chart,
            CustomHorizontalBarChart,
            HorizontalGraphSkeleton,
            'value'
          )}
        </GraphicSection>
        <GraphicSection
          title="Atuação profissional"
          subtitle="Aqui está o total de estudantes formados trabalhando e sua área de formação."
          data={studentWorkingInArea?.result?.chart}
        >
          {returnGraphic(
            studentWorkingInArea?.result?.chart,
            CustomHorizontalBarChart,
            HorizontalGraphSkeleton,
            'value'
          )}
        </GraphicSection>
        <LegendSection
          title="Relacionamento com o mercado"
          subtitle={`Aqui está o total de estudantes formados trabalhando e seu tipo de ocupação.`}
          graphicalData={marketRelationship?.result?.chart}
          loading={studentWorkingInArea}
        >
          <CustomPieChart graphicalData={marketRelationship?.result?.chart} />
        </LegendSection>
        <GraphicSection
          title="Objetivo de carreira*"
          subtitle={`
            Aqui estão todos os objetivos de carreira dos seus 
            ${marketRelationship?.result?.total} estudantes ativos.`}
          noStyle={false}
          messageFooter="*Esses dados representam as informações a partir de Janeiro de 2023"
          data={careerObjective}
        >
          {returnCircularProgressItens(careerObjective)}
        </GraphicSection>
        <DataSectionGraduates
          data={professionalStatusChange?.result?.chart}
          loading={professionalStatusChange}
        />
      </BoxContent>
    </BoxMain>
  );
}
