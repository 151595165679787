import React, { useRef, useState, useContext, useEffect, useMemo } from 'react';
import { Form } from '@unform/web';
import { JoditTextArea } from 'components/UnformFields/JoditTextArea';
import Input from 'components/UnformFields/inputField';
import Gallery from 'assets/SettingsPage/Profile/Gallery';
import DatePickerCustom from 'components/Opportunities/DatePicker';
import { IoTrashOutline } from 'react-icons/io5';
import unSelectedImage from 'assets/FairAndEvents/unSelectedImage.svg';

import {
  Content,
  ContainerInput,
  ImageContainer,
  UploadImageButton,
  StepTitle,
} from './style';
import InputWorkload from 'components/UnformFields/InputWorkload';
import { CreateExtensionContext } from '../../CreateExtensionContext';
import { LabelWithRequiredIndicator } from 'utils/shouldAddAsterisk';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { returnImgFromb64 } from '../../utils/resources';

export default function StepTwo({ extensionKind, isEdit }) {
  const [imageError, setImageError] = useState('');
  const [previewImage, setPreviewImage] = useState('');

  const inputRef = useRef(null);
  const formRef = useRef(null);
  const {
    setStepTwoFormRef,
    formData,
    selectedImage,
    setSelectedImage,
    setImageChanged,
    returnTitleModal,
  } = useContext(CreateExtensionContext);

  const handleClickRemoveImage = () => {
    setSelectedImage(null);
    setPreviewImage('');
    setImageError('');
    setImageChanged(true);
  };

  const handleClickUploadImage = () => {
    inputRef?.current.click();
    setImageChanged(true);
  };

  useEffect(() => {
    setStepTwoFormRef(formRef);
  }, [formRef]);

  const handleChangeImage = (event) => {
    setImageError('');
    const image = event.target.files?.[0];

    if (image) {
      if (image.size > 2097152) {
        customSnackbar('Selecione um arquivo inferior à 2MB!', 'error');
        return;
      }
      if (image.name.length > 100) {
        customSnackbar(
          'O nome do arquivo não pode superar 100 caracteres.',
          'error'
        );
        return;
      }
      const previewURL = URL.createObjectURL(image);
      setPreviewImage(previewURL);
      setSelectedImage(image);
      event.target.value = null;
    }
  };

  const minStartDate = useMemo(() => {
    const today = new Date();
    const todayMoreOneDay = new Date(today.setDate(today.getDate() + 1));
    if (isEdit) {
      const currentStartDate = new Date(formData.start_at);
      if (currentStartDate < todayMoreOneDay) {
        return currentStartDate;
      } else {
        return todayMoreOneDay;
      }
    }
    return todayMoreOneDay;
  });

  const minEndDate = useMemo(() => {
    const today = new Date();
    const todayMoreOneDay = new Date(today.setDate(today.getDate() + 1));
    if (isEdit) {
      const currentEndDate = new Date(formData.end_at);
      if (currentEndDate < todayMoreOneDay) {
        return currentEndDate;
      } else {
        return todayMoreOneDay;
      }
    }
    return todayMoreOneDay;
  });

  useEffect(() => {
    if (formData?.attraction_image && formData?.attraction_image.value) {
      returnImgFromb64(
        formData.attraction_image,
        setPreviewImage,
        setSelectedImage
      );
    }

    if (selectedImage) {
      const previewURL = URL.createObjectURL(selectedImage);
      setPreviewImage(previewURL)
    }
  }, [formData]);

  const uploadButtonDisabled = previewImage?.length > 0;

  return (
    <Content>
      <h3 className="title">{returnTitleModal()}</h3>

      <StepTitle margin={'1.875rem 0 0.875rem 0'}>Dados básicos</StepTitle>

      <Form ref={formRef} initialData={formData}>
        <ContainerInput>
          <Input
            name={'name'}
            placeholder="Digite aqui..."
            label={
              <label style={{ color: '#606062' }}>
                <LabelWithRequiredIndicator
                  text="Nome do projeto"
                  indicator={true}
                />
              </label>
            }
          />
        </ContainerInput>

        <ContainerInput>
          <label htmlFor="">
            <LabelWithRequiredIndicator text="Descrição" indicator={true} />
          </label>
          <JoditTextArea
            name="description"
            placeholder="Digite aqui..."
            onInput={(e) => verifyMaxLength(e)}
            limitChars={'10'}
          />
        </ContainerInput>

        <ContainerInput>
          <label htmlFor="">
            <LabelWithRequiredIndicator text="Instruções" indicator={false} />
          </label>
          <JoditTextArea
            name="orientation"
            placeholder="Digite aqui..."
            onInput={(e) => verifyMaxLength(e)}
            limitChars={'10'}
          />
        </ContainerInput>

        <ContainerInput isData>
          <div style={{ width: '100%' }}>
            <label htmlFor="start_at">
              <b>Data inicial do projeto*</b> (Obrigatório)
            </label>
            <DatePickerCustom
              name="start_at"
              placeholder="Ex: 15/02/2022"
              minDate={minStartDate}
            />
          </div>
          <div style={{ width: '100%' }}>
            <label htmlFor="end_at">
              <LabelWithRequiredIndicator
                text="Data final do projeto"
                indicator={true}
              />
            </label>
            <DatePickerCustom name="end_at" placeholder="Ex: 15/02/2022" minDate={minEndDate} />
          </div>
        </ContainerInput>
        <ContainerInput isData>
          <div
            style={{
              width: extensionKind === 'extracurricular' ? '100%' : '48%',
            }}
          >
            <label htmlFor="workload">
              <LabelWithRequiredIndicator
                text="Carga horária"
                indicator={true}
              />
            </label>
            <InputWorkload name="workload" placeholder="Ex.: 20:00" />
          </div>
          {extensionKind === 'extracurricular' && (
            <div style={{ width: '100%' }}>
              <label style={{ color: '#606062' }}>
                <LabelWithRequiredIndicator
                  text="Quantidade de vagas"
                  indicator={false}
                />
              </label>
              <Input
                name={'students_limit'}
                placeholder="Digite aqui..."
                height={'41px'}
                removeMargin={true}
                marginTop={'0px'}
                type="number"
              />
            </div>
          )}
        </ContainerInput>

        <ImageContainer>
          <input
            type="file"
            className="hiddenInput"
            ref={inputRef}
            onChange={handleChangeImage}
            accept="image/png, image/jpeg"
          />
          <div className="informations">
            <h4>Imagem de destaque</h4>
            <p>
              Adicione uma imagem em formato JPEG ou PNG com a dimensão de 1600
              x 838
            </p>
            <UploadImageButton onClick={handleClickUploadImage}>
              <Gallery
                color={'#009291'}
                size={25}
                disabled={uploadButtonDisabled}
              />
              Adicionar imagem
            </UploadImageButton>
            {imageError && <span className="error">{imageError}</span>}
          </div>

          <div className="image">
            {previewImage ? (
              <>
                <img className="selectedImage" src={previewImage} />
                <div className="removeImage" onClick={handleClickRemoveImage}>
                  <IoTrashOutline size={21} color={'#FFF'} />
                </div>
              </>
            ) : (
              <img className="unSelectedImage" src={unSelectedImage} alt="" />
            )}
          </div>
        </ImageContainer>
      </Form>
    </Content>
  );
}
