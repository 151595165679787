import React, { useContext, useState } from 'react';
import {
  ButtonContainer,
  DetailsButton,
  StudentImage,
  GrayGradient,
  IconWrapper,
  Container,
  TextName,
  Row,
  WrapperInformations,
  RescheduleButton,
  DetailsButtonExpired,
  CanceledButton,
  Flag,
  Tooltip,
  TooltipContent,
} from './styled';
import { HouseLaptopIcon } from 'assets/mentorship/HouseLaptopIcon';
import { CalendarIcon } from 'assets/mentorship/CalendarIcon';
import { ClockIcon } from 'assets/mentorship/ClockIcon';
import BaseLayoutContext from 'contexts/base-layout';
import userIcon from 'assets/img/userIcon.png';
import { formatDateTime } from 'utils/formatDateTime';
import { useMentorship } from 'views/Student/Mentorship/context/MentorshipContext';
import { CircleAlert } from 'assets/mentorship/CircleAlert ';

export function MentorshipCard({
  item,
  openDetailsModal,
  openRescheduleModal,
  openCancelModal,
  textButton,
  buttonText,
  buttonCanceledText,
  showCanceledInfo,
  openDetailsExpiredModal,
  showDetailsExpiredButton,
  currentTab,
}) {
  const { handleOpenMentorModal } = useMentorship();
  const { universityColor } = useContext(BaseLayoutContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleClick = () => {
    if (item?.mentor) {
      handleOpenMentorModal(item);
    } else {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 1000);
    }
  };
  const {
    shortFormattedDate,
    simpleFormattedDate,
    formattedStartHour,
    formattedEndHour,
  } = formatDateTime(item?.date, item?.start_hour, item?.end_hour);

  const handleImageError = (e) => {
    e.target.src = userIcon;
    e.target.onerror = null;
  };

  const isRescheduledByInstitution =
    item?.status === 'reschedule_request' && item?.rescheduled_by !== 'student';

  return (
    <Container>
      <GrayGradient />
      <StudentImage
        src={item?.mentor_avatar || userIcon}
        onError={handleImageError}
        margin="13px auto 0 auto"
      />
      <>
        <TextName color={universityColor} onClick={handleClick}>
          {item?.mentor?.label || 'Mentor não identificado'}
        </TextName>

        {showTooltip && (
          <Tooltip>
            <TooltipContent>Mentor não identificado</TooltipContent>
          </Tooltip>
        )}
      </>
      <Row>
        <IconWrapper color={universityColor}>
          <CalendarIcon color={universityColor} />
        </IconWrapper>
        <span>
          {item?.status === 'requested'
            ? shortFormattedDate
            : simpleFormattedDate}
        </span>
      </Row>
      <Row>
        <IconWrapper color={universityColor}>
          <ClockIcon color={universityColor} />
        </IconWrapper>
        <span>
          Das {formattedStartHour} às {formattedEndHour}
        </span>
      </Row>
      <Row>
        <IconWrapper color={universityColor}>
          <HouseLaptopIcon color={universityColor} />
        </IconWrapper>
        <span>{item?.mentorship_modality}</span>
      </Row>
      {currentTab === 1 && (
        <>
          <ButtonContainer>
            <RescheduleButton
              color={universityColor}
              onClick={() => openRescheduleModal(item)}
            >
              Reagendar
            </RescheduleButton>
            <DetailsButton
              color={universityColor}
              onClick={() => openDetailsModal(item)}
            >
              {buttonText}
            </DetailsButton>
          </ButtonContainer>
          {isRescheduledByInstitution ? (
            <Flag>
              <CircleAlert color="#f09d51" size={18} />
              Aguardando sua resposta
            </Flag>
          ) : (
            <CanceledButton onClick={() => openCancelModal(item)}>
              {buttonCanceledText}
            </CanceledButton>
          )}
        </>
      )}
      {currentTab === 2 && (
        <>
          <ButtonContainer>
            <DetailsButton
              marginBottom="10px"
              color={universityColor}
              onClick={() => openDetailsModal(item)}
            >
              {textButton}
            </DetailsButton>
          </ButtonContainer>
          {showDetailsExpiredButton && (
            <DetailsButtonExpired
              color={universityColor}
              onClick={() => openDetailsExpiredModal(item)}
            >
              Detalhes
            </DetailsButtonExpired>
          )}

          {showCanceledInfo && (
            <WrapperInformations>
              {item?.status === 'not_realized' && 'Mentoria não realizada'}
              {item?.status === 'canceled' &&
                (item?.canceled_by === 'student'
                  ? 'Mentoria cancelada por você'
                  : 'Mentoria cancelada pela Instituição')}
            </WrapperInformations>
          )}
        </>
      )}
    </Container>
  );
}
