import { description } from 'assets/jss/material-dashboard-pro-react';
import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export let stepOneSchema = Yup.object().shape({
  responsible_teacher: Yup.string().required(
    'Escolha um Professor responsável.'
  ),
});

export const stepTwoSchema = Yup.object().shape({
  workload: Yup.string()
    .required('Você deve informar a carga horária.')
    .max(10, 'A carga horária deve ter no máximo 10 caracteres.'),
  start_at: Yup.date()
    .typeError('Por favor, informe uma data válida')
    .required('Campo obrigatório'),
  end_at: Yup.date()
    .required('Campo obrigatório')
    .typeError('Por favor, informe uma data válida')
    .min(Yup.ref('start_at'), 'A data deve ser maior que a data de início.'),
  name: Yup.string().required('O nome é obrigatório.'),
  description: Yup.string().required('A descrição é obrigatória.'),
});

export const stepThreeSchema = Yup.object().shape({
  development_objective: Yup.array()
    .of(Yup.string())
    .required('Escolha ao menos um Objetivo de Desenvolvimento Sustentável.'),
  competences_specialities: Yup.array()
    .of(Yup.number().typeError())
    .max(5, 'Selecione até 5 competências/especialidades'),
});
