import { authenticatedAPI } from 'auth/jwt/api.service';
import { buildQueryParams } from '../utils/resources';

class ExtensionService {
  getUnities = async () => {
    try {
      const response = await authenticatedAPI.get(
        `api/internal/university/jobs/unities`
      );
      return response.data;
    } catch (e) {
      return e;
    }
  };

  getMacroAreas = async (params, educationalGroup) => {
    let url = educationalGroup
      ? `/api/internal/educational-group/attractions/macro-areas?${params}`
      : `/api/internal/university/attractions/macroareas`;

    try {
      const response = await authenticatedAPI.get(url);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getCourses = async (macroAreas) => {
    const params = new URLSearchParams();
    macroAreas.forEach((v) => {
      params.append('macroareas', v.value);
    });

    let url = `/api/internal/university/attractions/macroareas/course?${params.toString()}`;

    try {
      const response = await authenticatedAPI.get(url);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getDisciplines = async (courses) => {
    const params = new URLSearchParams();
    courses.forEach((v) => {
      params.append('courses', v.value.replace(/\D/gim, ''));
    });

    try {
      const response = await authenticatedAPI.get(
        `api/internal/university/extension-project/disciplines?${params.toString()}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getExtensionProjectOptions = async () => {
    try {
      const response = await authenticatedAPI.options(
        `/api/internal/university/extension-project/create`
      );
      let data = {};

      data.projectTypes =
        response.data.actions.POST.extension_project.children.type.choices.map(
          (option) => {
            return {
              value: option.value,
              label: option.display_name,
            };
          }
        );
      data.developmentObjectives =
        response.data.actions.POST.extension_project.children.development_objective.choices.map(
          (option) => {
            return {
              value: option.value,
              label: option.display_name,
            };
          }
        );
      return data;
    } catch (error) {
      return error;
    }
  };

  getCompetencies = async () => {
    try {
      const response = await authenticatedAPI.get(
        `/api/internal/university/attractions/competences`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getTeachers = async () => {
    try {
      const response = await authenticatedAPI.get(
        `/api/internal/university/teachers`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getStudents = async (bodyParams, queryParams) => {
    try {
      const response = await authenticatedAPI.post(
        `/api/internal/university/extension-project/eligible-students${buildQueryParams(
          queryParams
        )}`,
        bodyParams
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  findStudents = async (bodyParams, queryParams) => {
    try {
      const response = await authenticatedAPI.post(
        `/api/internal/university/extension-project/find-students${buildQueryParams(
          queryParams
        )}`,
        bodyParams
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
}

export const extensionService = new ExtensionService();
