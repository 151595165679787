import styled from 'styled-components';

const getColor = ({ color }) => {
  return color || '#009291';
};

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
`;

export const WrapperInformations = styled.div`
  max-width: 560px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #606062;
  text-align: end;

  > span {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #009291;
    margin-left: 5px;
  }

  > div {
    flex-direction: column;
  }
`;

export const GridSide = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`;

export const GridList = styled.div`
  display: grid;

  @media screen and (max-width: 768px) {
    gap: 10px;
    margin-top: 0;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  color: #606062;

  .container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .text-wrapper strong {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }

  .text-wrapper p {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-top: 4px;
  }
`;

export const InfoLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 700;
  color: ${({ color }) => color || '#009291'};
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const IconWrapper = styled.div`
  align-items: flex-start;
  align-self: flex-start;
`;

export const Flag = styled.div`
  width: auto;
  height: 29px;
  padding: 6px 12px;
  gap: 5px;
  border-radius: 13px;
  color: ${getColor};
  font-weight: 600;
  background-color: ${getColor}20;
  margin-top: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-family: Roboto, sans-serif;
  line-height: 30px;
  text-align: left;
  color: #606062;

  h2 {
    font-size: 22px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const StringList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  text-align: left;
  font-weight: 400;
  color: #606062;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  margin: 2rem 2rem 2rem auto;
  justify-content: flex-end;
  gap: 1rem;
`;

export const CancelSubscriptionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${getColor};
  border-radius: 3px;
  border: none;
  height: 40px;
  padding: 0 8px;
  text-decoration: underline;
  font-family: Roboto, sans-serif;
  background-color: transparent;
  cursor: pointer;
`;

export const FillReportButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  border-radius: 3px;
  border: none;
  height: 40px;
  padding: 0 8px;
  font-family: Roboto, sans-serif;
  background-color: ${getColor};
  cursor: pointer;
`;
