import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Container,
  ProjectListContainer,
  SeeMoreButton,
  Title,
  WhiteBox,
} from '../styled';
import ProjectCard from 'views/Student/ExtensionProject/components/Card';
import { projectExtensionApi } from '../../../../services/api';
import GenericMessage from 'components/GenericMessage';
import BaseLayoutContext from 'contexts/base-layout';
import { ProjectCardSkeleton } from '../../../../components/CardSkeleton';
import { useProjectExtensionContext } from '../../../../context';
import ModalFeedbackExtensionProject from 'views/StaffPages/ExtensionProject/components/Modal/ModalViewFeedback';
import DetailsModal from 'views/Student/ExtensionProject/components/DetailsModal';

export const FinishedProjects = () => {
  const [finishedProjects, setFinishedProjects] = useState(null);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { universityColor } = useContext(BaseLayoutContext);
  const { openDetailsModal, closeDetailsModal, modalData } =
    useProjectExtensionContext();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedback, setFeedback] = useState();

  const fetchFinishedProjects = async () => {
    const [responseExtensions, responseImages] = await Promise.all([
      projectExtensionApi.getFinishedExtensionProject(),
      projectExtensionApi.getImagesFinishedExtensions(),
    ]);
    setIsLoadingList(false);
    const extensions = responseExtensions.results;
    setHasNextPage(!!responseExtensions.next);
    const images = responseImages.results;
    if (extensions != null) {
      if (images) {
        const extensionsWithImages = extensions.map((extension) => {
          const image = images.find((image) => image.id === extension.id);
          extension.image = image.attraction_image;
          return extension;
        });
        setFinishedProjects(extensionsWithImages);
        return;
      }
      setFinishedProjects(extensions);
    }
  };

  useEffect(() => {
    fetchFinishedProjects();
  }, []);

  const openFeedbackModal = async (id) => {
    const feedbackResponse = await projectExtensionApi.getFinalReport(id);
    setFeedbackModalOpen(true);
    setFeedback(feedbackResponse);
  };

  const getButtons = (item) => {
    const buttons = [
      {
        label:
          item.has_feedback && item.is_finished
            ? 'Detalhes'
            : 'Visualizar detalhes',
        isDetails: !!item.has_feedback && item.is_finished,
        onClick: openDetailsModal,
      },
    ];

    if (item.has_feedback && item.is_finished) {
      buttons.push({
        label: 'Feedback',
        onClick: () => openFeedbackModal(item.extension_project_id),
      });
    }

    return buttons;
  };
  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (10 - 3 + 1) + 3);
  }, []);

  const handleNextPage = useCallback(async () => {
    setIsLoadingList(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 10;
    const [responseExtensions, responseImages] = await Promise.all([
      projectExtensionApi.getFinishedExtensionProject(
        `limit=10&offset=${offset}`
      ),
      projectExtensionApi.getImagesFinishedExtensions(
        `limit=10&offset=${offset}`
      ),
    ]);
    setIsLoadingList(false);
    const extensions = responseExtensions.results;
    setHasNextPage(!!responseExtensions.next);
    const images = responseImages.results;
    if (extensions != null) {
      if (images) {
        const extensionsWithImages = extensions.map((extension) => {
          const image = images.find((image) => image.id === extension.id);
          extension.image = image.attraction_image;
          return extension;
        });
        setFinishedProjects([...finishedProjects, ...extensionsWithImages]);
        return;
      }
      setFinishedProjects([...finishedProjects, ...extensions]);
    }
  }, [finishedProjects, page]);

  return (
    <Container>
      <WhiteBox>
        <Title>
          Aqui você pode ver as atividades de extensão que já foram realizadas.
        </Title>

        {finishedProjects?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhum projeto de extensão encontrado"
            subtitle="Os projetos que você realizar e finalizar aparecerão aqui."
          />
        ) : (
          <ProjectListContainer>
            {finishedProjects?.map((item, index) => (
              <ProjectCard key={index} item={item} buttons={getButtons(item)} />
            ))}
            {isLoadingList &&
              Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
                <ProjectCardSkeleton key={index} />
              ))}
          </ProjectListContainer>
        )}
        {hasNextPage && !isLoadingList && (
          <SeeMoreButton onClick={handleNextPage} color={universityColor}>
            Ver mais
          </SeeMoreButton>
        )}
      </WhiteBox>
      {feedbackModalOpen && (
        <ModalFeedbackExtensionProject
          isOpen={feedbackModalOpen}
          setOpenModalFeedback={setFeedbackModalOpen}
          feedback={feedback}
          isStudent={true}
          customMessage="O professor deixou uma mensagem sobre o seu projeto de extensão."
        />
      )}
      <DetailsModal item={modalData} onClose={closeDetailsModal} />
    </Container>
  );
};
