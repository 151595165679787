import styled from 'styled-components';
import Flag from '../../assets/JobOffer/flag.svg';

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  top: 0;
  right: 0;
  width: 100%;
  background: #e5e5e5;
  height: 100vh;
  z-index: 1100;
  -webkit-transition: all 350ms ease 0s;
  transition: all 350ms ease 0s;
  overflow: auto;

  &.withSidebarMinimized {
    width: calc(100% - 80px);
  }

  @media (max-width: 959px) {
    &.withSidebarMinimized {
      width: 100%;
    }
    width: 100%;
  }

  .sidebarMiniIcon {
    color: #999;
    margin: 0;
    font-size: 32px;

    & svg {
      width: 32px;
      height: 32px;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButton = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${({ universityColor }) =>
    universityColor ? universityColor : '#009291'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  border: none;
`;

export const GhostDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: ${({ minActive }) => (minActive ? '80px' : '260px')};
  z-index: 1100;
  -webkit-transition: all 350ms ease 0s;
  transition: all 350ms ease 0s;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 959px) {
    width: 0;
  }
`;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  > h1 {
    font-size: 30px;
    font-weight: 700;
    color: ${({ universityColor }) =>
      universityColor ? universityColor : '#009291'};
    margin: 3rem 0 0 0;
    padding: 0;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 25px;
    }
  }

  @media (max-width: 375px) {
    h1 {
      font-size: 22px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  text-align: start;
  padding: 0 2rem;

  height: ${(props) => (props.alignModal ? '100%' : 'unset')};
  align-items: ${(props) => (props.alignModal ? 'center' : 'unset')};
  justify-content: ${(props) => (props.alignModal ? 'center' : 'unset')};

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const CustomButton = styled.button`
  height: 36px;
  cursor: pointer;
  color: white;
  background-color: ${({ universityColor }) =>
    universityColor ? universityColor : '#009291'};
  border: none;
  border-radius: 4px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  @media (max-width: 768px) {
    height: 33px;
    font-size: 11px;
  }

  @media (max-width: 467px) {
    width: 100%;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 467px) {
    flex-direction: ${(props) => (props.adjustButton ? 'column' : 'row')};
    margin-top: 20px;
  }
`;

export const CustomButtonOutline = styled(CustomButton)`
  background-color: ${(props) =>
    props.layout ? props.universityColor : 'transparent'};
  border: 2px solid
    ${({ universityColor }) => (universityColor ? universityColor : '#009291')};
  color: ${(props) => (props.layout ? '#fff' : props.universityColor)};
  font-weight: 700;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 10px;

  justify-content: ${(props) =>
    props.alignButton ? props.alignButton : 'space-between'};

  @media (max-width: 768px) {
    padding: ${(props) => props.alignButton && '0 2.1rem'};
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #4b4b4d;
  margin: 2rem 0 0 0;

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 375px) {
    font-size: 18px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 1.4rem;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: 700;
  margin: ${({ noMargin }) => (noMargin ? '0' : '1.2rem 0 0 0')};

  &:first-child {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;
    margin-top: 1rem;
  }

  @media (max-width: 425px) {
    font-size: 1.12rem;
    margin-top: 1rem;
  }

  @media (max-width: 375px) {
    font-size: 1rem;
  }
`;

export const Box = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 1rem;
  padding: 2rem;

  background-color: #ffffff;

  display: flex;
  flex-direction: column;

  font-weight: 400;

  .description-job {
    font-size: 1.1rem;
    line-height: 1.4rem;
    color: #4b4b4d;
    margin-bottom: 33px;
  }

  p {
    font-size: 1.1rem;
    color: #4b4b4d;

    a {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    p {
      font-size: 1rem;
      margin-top: 5px;
    }
  }

  @media (max-width: 375px) {
    p {
      font-size: 0.9rem;
    }
  }
`;

export const DescriptionJobContainer = styled.div`
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: #4b4b4d;
`;

export const ListContainer = styled.div`
  margin: 12px 0 0 0;

  ul {
    list-style: none;
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }

  ul li {
    font-size: 1.1rem;
    color: #4b4b4d;
    text-transform: capitalize;
  }

  ul li:not(:last-child) {
    margin-bottom: 12px;
  }

  ul li::before {
    content: url(${Flag});
    margin-right: 10px;
  }

  ul li:last-child {
    display: ${(props) => props.selectedBenefits && 'none'};
  }

  @media (max-width: 768px) {
    margin: 5px 0 0 0;
    ul li {
      font-size: 1rem;
    }
  }

  @media (max-width: 375px) {
    ul li {
      font-size: 0.9rem;
    }

    ul li::before {
      margin-right: 7px;
    }
  }
`;

export const BoxContainer = styled.div`
  padding: ${(props) => (props.isDetailsMatch ? 'unset' : '1.5rem')};
  width: 100%;
  @media (max-width: 475px) {
    padding: 0.7rem;
  }
`;

export const BoxModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #eeeeee;
  z-index: 1300;
  width: 100%;
  height: 100%;

  @media (max-width: 960px) {
    width: 100%;
    padding: 0rem;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0rem 3rem 0rem;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 18px 0 #0000001a;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
`;

export const ContainerCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const ContainerContent = styled.div`
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  margin-top: 30px;
  justify-content: space-between;

  ul {
    margin: 0;
    padding: 0 15px;
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: red;
  gap: 1rem;
`;

export const BackButton = styled.button`
  width: 35px;
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-radius: 6px;
  border: none;

  font-family: Roboto;
  font-size: 18px;

  margin-top: 1.5rem;
  margin-left: 1.5rem;
  padding: 0.3rem;
  gap: 0.5rem;

  cursor: pointer;
  position: relative;

  > p {
    position: absolute;
    left: 35px;

    margin: 0;
    color: ${(props) => props.color || '#009291'};
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    width: 100px;

    > p {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
    transition: 0.3s;
  }

  > svg {
    fill: ${(props) => props.color || '#009291'};
    width: 25px;
    height: 25px;
    transition: fill 0.5s ease-in-out;
  }

  @media (max-width: 768px) {
    > p {
      display: inherit;
      opacity: 1;
    }

    width: 100px;
  }
`;

export const FragmentColor = styled.div`
  width: 100%;
  border-top-left-radius: 9px;
  border-top-left-radius: 9px;
  min-height: 190px;
  background: linear-gradient(to bottom, ${(props) => props.color}, white);
`;

export const FragmentColorExternalLink = styled.div`
  width: 100%;
  border-radius: 8px;
  height: auto;
`;

export const CustomTextParagraph = styled.p`
  font-size: ${(props) =>
    props.fontSize ? `${props.fontSize}` : props.isCode ? '0.9em' : '1em'};
  font-weight: ${(props) =>
    props.fontWeight ? `${props.fontWeight}` : props.isBold ? 'bold' : '400'};
  color: ${(props) => (props.color ? `${props.color}` : '#606062')};
  margin: 0;
  font-style: ${(props) => (props.isItalicStyle ? 'italic' : 'normal')};
  text-align: ${(props) => (props.textAlign ? `${props.textAlign}` : 'start')};
`;

export const JobTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 15px calc(5px + 1%);
  background-color: #f8fafc;
  margin-top: 30px;
  width: auto;

  @media (max-width: 639px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ReversedColumnOnMobileContainerContent = styled(ContainerContent)`
  gap: 50px;
  transition: 0.2s;

  > div {
    width: auto;
  }

  > div > p {
    line-height: 1.3em;
  }

  @media (max-width: 639px) {
    flex-direction: column-reverse;
    gap: 10px;
    transition: 0.2s;

    > div {
      align-items: center;
    }

    > div > p {
      text-align: center;
    }
  }
`;

export const ButtonDetailsModal = styled.button`
  width: auto;
  height: auto;
  padding: 10px;
  background-color: ${(props) => props.color || '#009291'};
  font-weight: 400;
  font-size: 1em;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const TextParagraph = styled.p`
  font-size: ${(props) => (props.isCode ? '0.9em' : '1em')};
  font-weight: ${(props) => (props.isTitleDetails ? 'bold' : '400')};
  color: #606062;
  margin: 0;
  font-style: ${(props) => (props.isItalicStyle ? 'italic' : 'normal')};

  @media (max-width: 425px) {
    font-size: 14px;
  }

  .application-type-length {
    margin-left: 5px;
    font-size: 16px;
    text-decoration: underline;
    color: #009291;
    font-weight: bold;
  }
`;

export const ListParagraph = styled.li`
  font-size: 1em;
  font-weight: 400;
  color: #606062;
  margin: 0;

  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: ${(props) => (props.isContentEnd ? 'flex-end' : 'flex-start')};
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const TextName = styled.div`
  font-size: ${(props) => (props.isName ? '24px' : '18px')};
  font-weight: ${(props) => (props.isName ? '700' : '500')};
  font-family: Roboto;
  color: #606062;
  line-height: 30px;
  text-align: center;
  text-transform: ${(props) => (props.isName ? 'unset' : 'capitalize')};
  text-decoration: ${(props) => (props.isName ? 'unset' : 'capitalize')};

  @media (max-width: 425px) {
    font-size: ${(props) => (props.isName ? '20px' : '14px')};
  }
`;

export const ButtonTransparent = styled.button`
  width: auto;
  height: auto;
  background-color: transparent;
  font-weight: 400;
  font-size: 1em;
  color: ${(props) => props.color || '#009291'};
  border: none;
  cursor: pointer;
`;

export const ContentIcon = styled.div`
  position: relative;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    opacity: 0.2;
  }
`;

export const ContainerLogo = styled.div`
  margin-top: -6rem;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #cdcdcd;
  ${(props) => (props.img ? `background-image: url(${props.img});` : '')}
  background-size: cover;
  background-position: center;
`;

export const ContainerIconAndText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
`;
