import React, { useContext, useEffect, useState } from 'react';
import { Wrapper, ContainerTypeName, ContainerActions } from './style';

import JPG from 'assets/ModalUploadEvidences/iconsFormatType/JPG.svg';
import MP4 from 'assets/ModalUploadEvidences/iconsFormatType/MP4.svg';
import PDF from 'assets/ModalUploadEvidences/iconsFormatType/PDF.svg';
import PNG from 'assets/ModalUploadEvidences/iconsFormatType/PNG.svg';
import RAR from 'assets/ModalUploadEvidences/iconsFormatType/RAR.svg';
import ZIP from 'assets/ModalUploadEvidences/iconsFormatType/ZIP.svg';
import BaseLayoutContext from 'contexts/base-layout';
import PdfViewer from 'components/UploadEvidences/components/PdfViwer';

import ImageViwer from 'components/UploadEvidences/components/ImageViwer';
import ButtonWithMenu from '../ButtonWithMenu';
import ModalInformation from 'components/informationModal';
import { createAnalyzeEvidence } from 'views/StaffPages/Opportunities/services';
import ModalFeedBack from '../ModalFeedBack';
import { colorsStatus } from '../ButtonWithMenu/Status';
import { Status } from '../style';
import moment from 'moment';
import ModalFeedbackExtensionProject from 'views/Student/Learning/components/Screens/ExtensionProject/components/Modal/ModalViewFeedback';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';

export default function ListFiles({
  idPost,
  item,
  functionDeleteEvidence,
  setUsingPreviewPdf,
  activeTab,
  refreshFiles,
  ctaDownload,
  getAllToFinishProjects,
}) {
  const { openModalWithContent, universityColor } = useContext(
    BaseLayoutContext
  );

  const [fileData, setFileData] = useState(null);
  const [fileIsImage, setFileIsImage] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [config, setConfig] = useState({});

  const [openModalFeedBack, setOpenModalFeedBack] = useState(false);
  const [modalSeeFeedback, setModalSeeFeedback] = useState(false);

  const handleClickRecuseEvidence = () => {
    setOpenModalFeedBack(true);
  };

  const handleClickRecuseInsideModal = async (message, id) => {
    setOpenModalFeedBack(false);
    await createAnalyzeEvidence(id, {
      status: 'refused',
      feedback: message.feedback,
    });
    refreshFiles(null, true);
  };

  useEffect(() => {
    if (fileData) {
      callModal(fileData);
    }
  }, [fileData]);

  useEffect(() => {
    if (fileIsImage) {
      callModalImage();
    }
  }, [fileIsImage]);

  const icons = {
    '.pdf': PDF,
    '.png': PNG,
    '.jpg': JPG,
    '.jpeg': JPG,
    '.mp4': MP4,
    '.zip': ZIP,
    '.rar': RAR,
  };

  const returnName = () => {
    if (item.file?.name && !item.file?.size) {
      return item.file.name;
    }
    if (item.image?.name && !item.image?.size) {
      return item.image.name;
    }
    return item.name;
  };

  const returnIcon = () => {
    if (icons[item.type]) {
      return icons[item.type];
    }

    if (item.file?.suffix) {
      return icons[item.file.suffix];
    }
    if (item.image?.suffix) {
      return icons[item.image.suffix];
    }
  };

  const handleConfirmDelete = async () => {
    const { createdNow } = item;

    if (!createdNow) {
      await functionDeleteEvidence(idPost, item.id);
      getAllToFinishProjects(true);
      refreshFiles(null, true);
    }

    setModalInformationOpen(false);
  };

  const configDelete = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza que deseja excluir a evidência "${returnName()}"?`,
    buttonConfirmText: 'SIM, EXCLUIR EVIDÊNCIA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
    },
    buttonConfirmFunction: handleConfirmDelete,
  };

  const handleClickDeleteIcon = () => {
    setConfig(configDelete);
    setModalInformationOpen(true);
  };

  const handleClosePreview = () => {
    setUsingPreviewPdf(false);
    setFileData(null);
    setFileIsImage(false);
  };

  const callModalImage = () => {
    setUsingPreviewPdf(true);
    openModalWithContent(
      <ImageViwer
        universityColor={universityColor}
        file={item}
        name={returnName()}
        type={item.image.suffix}
        ctaDownload={ctaDownload}
      />,
      null,
      null,
      () => handleClosePreview()
    );
  };

  const callModal = (file) => {
    setUsingPreviewPdf(true);
    openModalWithContent(
      <PdfViewer
        universityColor={universityColor}
        file={file}
        name={returnName()}
      />,
      null,
      null,
      () => handleClosePreview()
    );
  };

  const handleClickPreview = () => {
    if (item.image?.name || item.type === '.png' || item.type === '.jpeg') {
      setFileIsImage(true);
      return;
    }
    const file = item.file.file || item.file;
    setFileData(file);
  };

  const handleConfirmAcceptEvidence = async () => {
    setModalInformationOpen(false);
    await createAnalyzeEvidence(item.id, {
      status: 'accepted',
      feedback: null,
    });
    refreshFiles(null, true);
  };

  const configAcceptEvidence = {
    bubbleText: 'Atenção!',
    ParagraphText: `Ao aprovar uma evidência, esta ação <strong>não poderá ser desfeita</strong>. <br/> <br/> Tem certeza que deseja seguir em frente?`,
    buttonConfirmText: 'SIM, APROVAR',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    buttonConfirmFunction: handleConfirmAcceptEvidence,
  };

  const handleClickAcceptEvidence = () => {
    setConfig(configAcceptEvidence);
    setModalInformationOpen(true);
  };

  const handleClickSeeFeedback = () => {
    setModalSeeFeedback(true);
  };

  const defaultInterfaceStudent = [
    { name: 'Aceitar evidência', onChange: handleClickAcceptEvidence },
    { name: 'Recusar evidência', onChange: handleClickRecuseEvidence },
  ];
  const interfaceFileButtonStudent = [
    ...defaultInterfaceStudent,
    { name: 'Visualizar evidência', onChange: handleClickPreview },
  ];
  const defaultInterfaceUniversity = [
    { name: 'Excluir evidência', onChange: handleClickDeleteIcon },
  ];
  const interfaceFileUniversity = [
    ...defaultInterfaceUniversity,
    { name: 'Visualizar evidência', onChange: handleClickPreview },
  ];

  const currentInterface = {
    0: interfaceFileButtonStudent,
    1: interfaceFileUniversity,
  };

  const interfaceWhenHasAnalyze = () => {
    if (
      item.analyze &&
      Object.keys(item.analyze).length > 0 &&
      item.analyze.feedback
    ) {
      if (item.analyze.feedback.feedback) {
        currentInterface[activeTab].push({
          name: 'Visualizar feedback',
          onChange: handleClickSeeFeedback,
        });
      }
      return currentInterface[activeTab].filter(
        (item) =>
          item.name !== 'Aceitar evidência' && item.name !== 'Recusar evidência'
      );
    }

    return currentInterface[activeTab];
  };

  return (
    <Wrapper>
      <ModalInformation
        universityColor={universityColor}
        modalOpenState={modalInformationOpen}
        config={config}
        buttonConfirmFunction={config.buttonConfirmFunction}
        buttonCancelFunction={() => setModalInformationOpen(false)}
      />
      <ModalFeedBack
        isOpen={openModalFeedBack}
        buttonInsideFunction={handleClickRecuseInsideModal}
        setOpen={setOpenModalFeedBack}
        item={item}
      />
      {modalSeeFeedback && (
        <ModalFeedbackExtensionProject
          isOpen={modalSeeFeedback}
          setOpenModalFeedback={setModalSeeFeedback}
          feedback={item.analyze.feedback}
        />
      )}
      <ContainerTypeName>
        <img src={returnIcon()} alt="iconType" />
        <div>
          <p className="nameEvidence">
            {returnName()}
            {item.analyze && (
              <Status
                color={colorsStatus[item.analyze.status].color}
                background={colorsStatus[item.analyze.status].background}
              >
                {colorsStatus[item.analyze.status].label}
              </Status>
            )}
            {item.new_evidence && (
              <Status
                color={colorsStatus['new'].color}
                background={colorsStatus['new'].background}
              >
                {colorsStatus['new'].label}
              </Status>
            )}
          </p>
          <p className="date">
            <span style={{ fontWeight: 'bold', color: '#009291' }}>
              Enviado por:{' '}
            </span>{' '}
            <span> {item.created_by} | </span>
            {moment(item.created_at).format('DD/MM/YYYY')} às{' '}
            {moment(item.created_at).format('H:mm ')}
          </p>
        </div>
      </ContainerTypeName>
      <ContainerActions>
        <ButtonWithMenu
          insideMenus={interfaceWhenHasAnalyze()}
          menuLabel={'ações'}
          offsetX={-80}
          buttonWidth={'80px'}
          item={item}
        />
      </ContainerActions>
    </Wrapper>
  );
}
