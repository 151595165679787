import React, { useEffect, useState, useContext, useMemo } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import {
  ContainerCard,
  TextTitle,
  ContainerInfo,
  ContainerLogo,
  Row,
  TextRow,
  Container,
  ButtonQuickFilters,
  ContainerCards,
  ContainerQuickFilters,
  DetailJob,
} from './styles';
import LogoJobNetwork from 'assets/Network/logoActivitiesSectors';
import LogoCompanyNetwork from 'assets/Network/logoCompanyNetwork';
import LogoLocationNetwork from 'assets/Network/logoLocationNetwork';
import { ContainerMiniLogo } from 'components/CompanyDetail/styles';
import LogoOnlineNetwork from 'assets/Network/logoOnlineNetwork';
import GenericMessage from 'components/GenericMessage';
import data from 'views/Student/Learning/Utils/workaData/data';
import { JobDetailsModal } from 'components/JobDetailsModal';

export function DisplayJobsNetwork({
  jobsFinished,
  jobsOpen,
  closeCompanyDetailsModal,
  closeJobDetails,
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const [switchButton, setSwitchButton] = useState(1);
  const [controlDetailModal, setControlDetailModal] = useState(false);
  const [selectedJobDetail, setSelectedJobDetail] = useState(false);

  const generalData = useMemo(() => {
    if (switchButton === 1) {
      return jobsOpen;
    }
    if (switchButton === 2) {
      return jobsFinished;
    }
  }, [switchButton, jobsFinished, jobsOpen]);

  const aplacation = (data) => {
    const { applicationType } = data;
    const applicationTypeName =
      applicationType && applicationType[0] && applicationType[0].length > 20
        ? `${applicationType[0].substring(0, 20)}...`
        : applicationType[0];
    return (
      <TextRow color={universityColor}>
        {` ${applicationType[0] ? applicationTypeName : 'Não informado'} `}{' '}
        {applicationType.length > 1 && <b>+{applicationType.length - 1}</b>}
      </TextRow>
    );
  };

  const location = (data) => {
    const { locations } = data;
    const locationName =
      locations[0]?.city || locations[0]?.state
        ? `${locations[0]?.city ? locations[0]?.city : 'Não informado'}/ ${
            locations[0]?.state ? locations[0]?.state : 'Não informado'
          }`
        : 'Não informado';

    return (
      <TextRow color={universityColor}>
        {locationName} {locations.length > 1 && <b>+{locations.length - 1}</b>}
      </TextRow>
    );
  };

  const modality = (data) => {
    const { modalityType, locations } = data;
    if (modalityType !== '') {
      return modalityType;
    }
    if (locations?.length > 0) {
      return 'Remoto';
    }
  };

  useEffect(() => {
    if (controlDetailModal == false) {
      setSelectedJobDetail(false);
    } else {
      const job = generalData.find((data) => data.id == controlDetailModal);
      setSelectedJobDetail(job ?? false);
    }
  }, [controlDetailModal]);

  return (
    <Container>
      {controlDetailModal && (
        <JobDetailsModal
          onClose={() => setControlDetailModal(false)}
          jobId={controlDetailModal}
          networking={switchButton === 2}
          closeCompanyDetailsModal={closeCompanyDetailsModal}
          closeJobDetails={closeJobDetails}
          selectedJob={selectedJobDetail}
        />
      )}
      <ContainerQuickFilters>
        <ButtonQuickFilters
          color={universityColor}
          borderRadius={'8px 0 0 8px'}
          touched={switchButton !== 1}
          onClick={() => {
            setSwitchButton(1);
          }}
        >
          Em aberto ({jobsOpen.length})
        </ButtonQuickFilters>
        <ButtonQuickFilters
          color={universityColor}
          borderRadius={'0 8px 8px 0'}
          touched={switchButton !== 2}
          onClick={() => {
            setSwitchButton(2);
          }}
        >
          Finalizadas ({jobsFinished.length})
        </ButtonQuickFilters>
      </ContainerQuickFilters>
      <ContainerCards>
        {generalData?.length > 0
          ? generalData?.map((data, index) => (
              <ContainerCard>
                <TextTitle>
                  [{data.id}] <b>{data?.name}</b>
                </TextTitle>
                <ContainerInfo>
                  <Row>
                    <ContainerMiniLogo color={universityColor}>
                      <LogoJobNetwork color={universityColor} />
                    </ContainerMiniLogo>
                    {aplacation(data)}
                  </Row>
                  <Row>
                    <ContainerMiniLogo color={universityColor}>
                      {data.modalityType === 'Remoto' ? (
                        <LogoOnlineNetwork color={universityColor} />
                      ) : (
                        <LogoCompanyNetwork color={universityColor} />
                      )}
                    </ContainerMiniLogo>
                    <TextRow>{modality(data)}</TextRow>
                  </Row>
                  <Row>
                    <ContainerMiniLogo color={universityColor}>
                      <LogoLocationNetwork color={universityColor} />
                    </ContainerMiniLogo>
                    <TextRow>{location(data)}</TextRow>
                  </Row>
                </ContainerInfo>
                <DetailJob
                  color={universityColor}
                  onClick={() => setControlDetailModal(data.id)}
                >
                  Visualizar detalhes
                </DetailJob>
              </ContainerCard>
            ))
          : generalData?.length === 0 && (
              <GenericMessage
                title="Nenhuma vaga encontrada"
                subtitle="No momento, não existem vagas disponíveis nessa seção."
                subtitleResponsible="No momento, não existem vagas disponíveis nessa seção."
              />
            )}
      </ContainerCards>
    </Container>
  );
}
