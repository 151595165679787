import styled from 'styled-components';

const CompanyHeaderTabTheme = (theme) => ({
  appBarHolder: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: 10,
    alignSelf: 'flex-end',
    display: 'flex',
  },

  tabIndicator: {
    backgroundColor: theme.universityColor,
    height: 3,
    zIndex: 2,
  },
  scrollButtons: {
    borderBottom: '1px solid #EDEDED',
    marginBottom: '-1px',
    [theme.breakpoints.down('lg')]: {
      color: '#777777',
    },
  },
  borderContainer: {
    border: '1px solid',
  },
  tabButton: {
    fontSize: 18,
    color: '#777777',
    fontWeight: '500',
    maxWidth: 'fit-content',
    textAlign: 'center',
    textTransform: 'none',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      minWidth: 180,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  selectLabel: {
    whiteSpace: 'nowrap',
  },
  cssLabel: {
    '&$cssFocused': {
      color: theme.universityColor,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  cssFocused: {},
});

export default CompanyHeaderTabTheme;

export const Container = styled.div`
  display: flex;
  position: relative;

  .custom-indicator {
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'} !important;
  }
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const ContainerBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  ${(props) => (props.margin ? `margin-top: 40px` : '')}
`;

export const FilterContainer = styled.div`
  display: flex;
  right: 2rem;
  top: 1rem;
  position: absolute;
  transition: 0.2s;

  @media (max-width: 1440px) {
    transition: 0.2s;
    right: 0rem;
    top: -2.5rem;
  }

  @media (max-width: 959px) {
    transition: 0.2s;
    top: -4rem;
  }
`;
