import React, { useEffect, useRef, useState } from 'react';
import {
  BackgroundModal,
  BodyModal,
  ButtonAddMaterial,
  ButtonClose,
  ContainerRow,
  HeaderModal,
  Modal,
  Paragraph,
  TitleModal,
} from './styled';
import { RiCloseFill } from 'react-icons/ri';

import { uploadMaterialFile, uploadDocuments } from '../../../services';
import { UploadType } from '../../../../../Student/ExtensionProject/enums/upload_type';
import {
  customSnackbar,
  SnackbarType,
} from 'components/CustomSnackBar/customSnackbar';
import { ImageViewer } from '../../../../../Student/ExtensionProject/components/ImageViewer';
import {
  actionsGenericUpload,
  actionsSupportMaterials,
  useGenericUpload,
  useSupportMaterial,
} from 'components/GenericModalUpload/store/genericUpload.store';

import {
  deleteSupportMaterials,
  getSupportMaterials,
} from 'views/StaffPages/ExtensionProject/services/urls';
import GenericModalUpload from 'components/GenericModalUpload';
import { ExternalListing } from 'components/GenericModalUpload/components/Listing/ExternalListing';

export default function ModalSupportMaterial({
  setOpenModalSupport,
  idAttraction,
  finishedProject,
  unpublishCard,
}) {
  const [openModalMaterials, setOpenModalMaterials] = useState(false);
  const { listingItems } = useGenericUpload((state) => state.listingItems);
  const { changeItems } = actionsGenericUpload;

  const SupportModal = useRef();

  const [selectedImage, setSelectedImage] = useState(null);

  const closeModalClickOutside = (e) => {
    if (SupportModal.current === e.target) {
      setOpenModalSupport(false);
    }
  };

  const closeSupportModal = () => {
    setOpenModalSupport(false);
  };

  const openModalAddMaterials = () => {
    setOpenModalMaterials(true);
  };

  async function getListingMaterial() {
    const response = await getSupportMaterials(idAttraction);
    changeItems(response.results);
  }

  useEffect(() => {
    changeItems(null);
    getListingMaterial();
  }, [idAttraction]);

  const handleSaveMaterial = async (material) => {
    const updatedFormData = new FormData();

    try {
      if (material.type === 'file') {
        updatedFormData.append('files', material.uploadedContent);
      } else if (material.type === 'image') {
        updatedFormData.append('images', material.uploadedContent);
      } else if (material.type === 'link') {
        updatedFormData.append('links', material.uploadedContent);
      }
      if (material.uploadedContent) {
        await uploadDocuments(updatedFormData, idAttraction);
        getListingMaterial();
      }

      customSnackbar(
        'Material de apoio adicionado com sucesso!',
        SnackbarType.CONFIRMATION
      );
    } catch (err) {
      customSnackbar(
        'Erro ao adicionar material de apoio. Por favor, tente novamente.',
        SnackbarType.ERROR
      );
    }
  };

  const handleDeleteMaterial = async (id) => {
    await deleteSupportMaterials(idAttraction, id);
    await getListingMaterial();
  };

  return (
    <BackgroundModal ref={SupportModal} onClick={closeModalClickOutside}>
      {openModalMaterials && (
        <GenericModalUpload
          onSave={handleSaveMaterial}
          removeItem={handleDeleteMaterial}
          setOpenModalUpload={setOpenModalMaterials}
          limit={{ file: 2, image: 2, link: 2 }}
        />
      )}
      <Modal>
        <HeaderModal>
          <TitleModal>Material de apoio</TitleModal>

          <ButtonClose onClick={closeSupportModal}>
            <RiCloseFill size={25} />
          </ButtonClose>
        </HeaderModal>
        <BodyModal>
          <ContainerRow>
            <Paragraph>
              Aqui estão os documentos, links ou imagens que foram deixados como
              material de apoio para os estudantes participantes do projeto.
            </Paragraph>
            {!finishedProject && !unpublishCard && (
              <ButtonAddMaterial onClick={() => openModalAddMaterials()}>
                Adicionar material
              </ButtonAddMaterial>
            )}
          </ContainerRow>
          <ExternalListing
            data={listingItems}
            onRemove={handleDeleteMaterial}
            setSelectedImage={setSelectedImage}
            finishedProject={finishedProject}
            unpublishCard={unpublishCard}
            genericMessage
            viewAction
          />
        </BodyModal>
      </Modal>
      <ImageViewer
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />
    </BackgroundModal>
  );
}
