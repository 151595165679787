import {
  AccountCircle,
  BusinessCenter,
  Assessment,
  AccountBalance,
  AssignmentTurnedIn,
  Event,
  CastForEducation,
  Description,
  PlayCircleOutline,
  Dvr,
  Clear,
  Settings,
  School,
} from '@material-ui/icons';
import { GrShareOption } from 'react-icons/gr';
import CollegeTalents from 'assets/img/icons/icoCollegeTalents';
import TabIcon from 'assets/FairAndEvents/TabIcon';
import BaseLayout from 'layouts/BaseLayout';
import StudentProfile from 'views/StaffPages/StudentProfile';
import StudentCurriculum from 'views/StaffPages/StudentCurriculum/StudentCurriculum';
import OpportunitiesEngine from '../views/StaffPages/Opportunities/OpportunitiesEngine';
import Videos from 'views/Contents/Contents';
import { InternshipRedirect } from '../components/InternshipRedirect';
import { InternshipLogout } from '../components/InternshipLogout';
import NoAccess from '../views/StaffPages/NoAccess/NoAccess';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Mentorship from '../views/StaffPages/Mentorship';
import { Csm } from '../views/StaffPages/Csm';
import JobEngine from '../views/StaffPages/jobOpeningsRebuild/JobEngine';
import AcademicEngine from '../views/StaffPages/Academic/academicEngine';
import SettingsEngine from 'views/StaffPages/SettingsPage/settingsEngine';
import CompaniesManagementEngine from 'views/StaffPages/CompaniesManagement/CompaniesManagementEngine';
import IndicatorsEngine from '../views/StaffPages/Indicators/IndicatorsEngine';
import FairAndEventsEngine from 'views/StaffPages/FairAndEvents/EngineFairAndEvents';
import staffRoles from 'constants/staffRoles';
import PublicValidateCertificate from 'views/Student/PublicValidateCertificate';
import OpenBase from 'layouts/OpenBase';
import { DashboardEngine } from 'views/StaffPages/Dashboard/DashboardEngine';
import ExtensionProjectEngine from '../views/StaffPages/ExtensionProject';

const firstVisibleMenu = (routes) => {
  let firstVisibleRoute = '';
  for (const route of routes) {
    if (!route.hide) {
      firstVisibleRoute = route.path;
      break;
    }
  }
  return firstVisibleRoute;
};

const universitySwitcher = (userData) => {
  const internshipEnabled = userData.metadata.internship_enabled;
  const hasMentorship = userData.metadata.has_mentorship;
  const hasExtensionProject = userData.metadata.has_extension_project;
  const staffRole = userData.metadata.staff_role;
  const internshipStaff = userData.metadata.internship_staff;
  const menus = userData.metadata.menus;
  const routes = [];

  let defaultPath;

  if (menus) {
    // Default routes
    if (
      menus.includes('company_management') &&
      staffRole !== staffRoles.TEACHER
    ) {
      routes.push({
        path: '/empresas',
        name: 'Gestão de Empresas',
        icon: AccountBalance,
        baseComponent: BaseLayout,
        component: CompaniesManagementEngine,
      });
    }
    if (menus.includes('job_offers_management')) {
      routes.push({
        path: '/vagas',
        name: 'Gestão de Vagas',
        icon: BusinessCenter,
        baseComponent: BaseLayout,
        component: JobEngine,
      });
    }
    if (
      hasExtensionProject &&
      menus.includes('extension_projects') &&
      userData.metadata.features &&
      userData.metadata.features.includes('student_extension_projects')
    ) {
      routes.push({
        path: '/extensao',
        name: 'Extensão',
        icon: GrShareOption,
        baseComponent: BaseLayout,
        component: ExtensionProjectEngine,
      });
    }
    if (menus.includes('learning')) {
      routes.push({
        path: '/cursos',
        name: 'Cursos',
        icon: CastForEducation,
        baseComponent: BaseLayout,
        component: OpportunitiesEngine,
      });
    }
    if (menus.includes('academic_event')) {
      routes.push({
        path: '/feiras-e-eventos',
        name: 'Feiras e Eventos',
        icon: TabIcon,
        baseComponent: BaseLayout,
        component: FairAndEventsEngine,
      });
    }
    if (hasMentorship && staffRole !== staffRoles.UNITY_STAFF) {
      routes.push({
        path: '/mentoria',
        name: 'Mentoria',
        icon: RecordVoiceOverIcon,
        baseComponent: BaseLayout,
        component: Mentorship,
      });
    }
    if (menus.includes('csm') && staffRole !== staffRoles.TEACHER) {
      routes.push({
        path: '/csm',
        name: 'CSM',
        icon: CollegeTalents,
        baseComponent: BaseLayout,
        component: Csm,
      });
    }
    if (
      menus.includes('egress') &&
      staffRole !== staffRoles.TEACHER &&
      staffRole !== staffRoles.ACADEMIC_ASSOCIATION &&
      staffRole !== staffRoles.EDUCATIONAL_GROUP_BOARD
    ) {
      {
        routes.push({
          path: '/egressos',
          name: 'Egressos',
          icon: School,
          baseComponent: BaseLayout,
          component: () => <Csm egressed />,
        });
      }
    }
    if (
      menus.includes('internship_management') &&
      internshipEnabled &&
      (staffRole === staffRoles.TEACHER 
        || staffRole === staffRoles.COURSE_COORDINATOR
        || internshipStaff)
    ) {
      routes.push({
        path: '/estagio',
        name: 'Gestão de Estágio',
        icon: Description,
        baseComponent: BaseLayout,
        component: InternshipRedirect,
        hide: !internshipEnabled,
      });
      routes.push({
        path: '/estagio/logout',
        baseComponent: BaseLayout,
        component: InternshipLogout,
        hide: true,
      });
    }
    if (
      menus.includes('academic') &&
      staffRole !== staffRoles.CAREER &&
      staffRole !== staffRoles.TEACHER &&
      staffRole !== staffRoles.UNITY_STAFF
    ) {
      routes.push({
        path: '/academico',
        name: 'Acadêmico',
        icon: AssignmentTurnedIn,
        baseComponent: BaseLayout,
        component: AcademicEngine,
      });
    }
    if (
      menus.includes('dashboard') &&
      staffRole !== staffRoles.TEACHER &&
      staffRole !== staffRoles.CAREER
    ) {
      routes.push({
        path: '/dashboard',
        name: 'Dashboard',
        icon: Dvr,
        baseComponent: BaseLayout,
        component: DashboardEngine,
      });
    }
    if (menus.includes('employability')) {
      routes.push({
        path: '/empregabilidade',
        name: 'Empregabilidade',
        icon: AccountCircle,
        baseComponent: BaseLayout,
        component: StudentProfile,
      });
    }
    if (
      menus.includes('workability') &&
      staffRole !== staffRoles.CAREER &&
      staffRole !== staffRoles.TEACHER &&
      staffRole !== staffRoles.UNITY_STAFF &&
      staffRole !== null
    ) {
      routes.push({
        path: '/trabalhabilidade',
        name: 'Trabalhabilidade',
        icon: Assessment,
        baseComponent: BaseLayout,
        component: IndicatorsEngine,
      });
    }
    if (staffRole === staffRoles.BOARD) {
      routes.push({
        path: '/configuracoes',
        pathTo: '/configuracoes',
        name: 'Configurações',
        icon: Settings,
        baseComponent: BaseLayout,
        component: SettingsEngine,
      });
    }

    // Hidden routes
    routes.push({
      path: '/share/certificate/event/:registrationId',
      name: 'Validar certificado',
      hide: true,
      baseComponent: OpenBase,
      component: PublicValidateCertificate,
    });
    routes.push({
      path: '/compartilhar/curriculo/:id',
      name: 'Currículo do Futuro',
      icon: Event,
      hide: true,
      baseComponent: BaseLayout,
      component: StudentCurriculum,
    });
    if (menus.includes('academic_event')) {
      routes.push({
        path: '/feiras-e-eventos/:id?/:uuid?',
        name: 'Feiras e Eventos',
        icon: TabIcon,
        hide: true,
        baseComponent: BaseLayout,
        component: FairAndEventsEngine,
      });
    }
    routes.push({
      path: '/seu-sucesso',
      pathTo: '/seu-sucesso',
      name: 'Aprendizagem',
      icon: PlayCircleOutline,
      baseComponent: BaseLayout,
      component: Videos,
      hide: true,
    });
  } else {
    // Special permissions
    if (staffRole === staffRoles.EDUCATIONAL_GROUP_BOARD) {
      while (routes.length) {
        routes.pop();
      }
      routes.push({
        path: '/vagas',
        name: 'Gestão de Vagas',
        icon: BusinessCenter,
        baseComponent: BaseLayout,
        component: JobEngine,
      });
      routes.push({
        path: '/cursos',
        name: 'Cursos',
        icon: CastForEducation,
        baseComponent: BaseLayout,
        component: OpportunitiesEngine,
      });

      routes.push({
        path: '/dashboard',
        name: 'Dashboard',
        icon: Dvr,
        staffRole: 'educationalGroup',
        baseComponent: BaseLayout,
        component: DashboardEngine,
      });
    }
  }

  defaultPath = firstVisibleMenu(routes);

  if (!defaultPath) {
    routes.push({
      path: '/sem-acesso',
      name: 'Sem Acesso',
      icon: Clear,
      baseComponent: BaseLayout,
      component: NoAccess,
    });
    defaultPath = '/sem-acesso';
  }

  // Add redirect route
  routes.push({
    redirect: true,
    path: '*',
    pathTo: defaultPath,
  });

  return routes;
};

export default universitySwitcher;
