export const configAddStudent = {
  bubbleText: 'Adicionar estudante',
  buttonConfirmWidth: 298,
  buttonConfirmHeight: 46,
  modalWidth: 370,
  ParagraphText: `Insira o nome ou código do estudante para adicioná-lo ao projeto.`,
  buttonConfirmText: 'BUSCAR',
  buttonCloseX: true,
  style: {
    marginLeft: '0px',
    fontSize: '18px',
    lineHeight: '24px',
    marginButtons: '0px',
  },
};

export const configFeedbackStudent = {
  bubbleText: 'Enviar feedback',
  buttonConfirmWidth: 298,
  buttonConfirmHeight: 46,
  modalWidth: 370,
  ParagraphText: `Identificamos que com esta alteração, um ou mais estudantes foram removidos do projeto. <p>Mande um feedback para esses estudantes explicando o motivo.</p>`,
  buttonConfirmText: 'ENVIAR FEEDBACK',
  buttonCloseX: true,
  style: {
    marginLeft: '0px',
    fontSize: '18px',
    lineHeight: '24px',
    marginButtons: '30px',
  },
};

export const configWarningModal = {
  bubbleText: 'Atenção!',
  ParagraphText: `<b>Tem certeza que deseja sair?</b>`,
  TwoParagraphText: `As informações não foram salvas, e essa ação não poderá ser desfeita.`,
  buttonConfirmText: 'SIM, QUERO SAIR',
  buttonCancelText: 'CONTINUAR NA PÁGINA',
  showButtonCancel: true,
  buttonConfirmWidth: 298,
  buttonConfirmHeight: 46,
  modalWidth: 370,
  style: {
    marginLeft: '0px',
    fontSize: '18px',
    lineHeight: '24px',
    marginButtons: '0px',
  },
};
