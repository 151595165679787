import { LoadingIcon } from 'assets/Oportutinites/LoadingIcon';
import { IoCloseSharp } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa6';
import { BsExclamationCircle } from 'react-icons/bs';

const statusMap = {
  approved: {
    icon: <FaCheck size={20} color="#048629" />,
    backgroundColor: '#C6FCD4',
    textColor: '#048629',
    text: 'Projeto aprovado',
  },
  unpublished: {
    icon: <BsExclamationCircle size={20} color="#FFFFF" />,
    backgroundColor: '#929292',
    textColor: '#FFFFFF',
    text: 'Projeto despublicado',
  },
  disapproved: {
    icon: <IoCloseSharp size={20} color="#DA0505" />,
    backgroundColor: '#FFEDED',
    textColor: '#DA0505',
    text: 'Não aprovado',
  },
  enrolled: {
    icon: <LoadingIcon color="#FFFFFF" />,
    backgroundColor: '#929292',
    textColor: '#FFFFFF',
    text: 'Aguardando avaliação da Instituição',
  },
  removed_in_finalization: {
    icon: <BsExclamationCircle size={20} color="#FFFFF" />,
    backgroundColor: '#929292',
    textColor: '#FFFFFF',
    text: 'Você não foi selecionado',
  },
  removed: {
    icon: <BsExclamationCircle size={20} color="#FFFFF" />,
    backgroundColor: '#929292',
    textColor: '#FFFFFF',
    text: 'Você não foi selecionado',
  },
};

export const getStatusDetails = (status) => {
  return statusMap[status] || {};
};
