import React, { useContext, useEffect, useState } from 'react';
import placeholder from 'assets/img/placeholder.jpg';
import { StudentItem, ProfileImage } from './style';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { CreateExtensionContext } from 'views/StaffPages/ExtensionProject/components/CreateModal/CreateExtensionContext';
import ModalInformation from 'components/informationModal';

export default function ExtensionStudent({
  student,
  addStudentMode,
  setControlModalListing,
  countStudents,
}) {
  const [sucessfullLoadAvatar, setSucessfullLoadAvatar] = useState(false);
  const {
    excludedStudent,
    extraStudent,
    setExcludedStudent,
    setExtraStudent,
    setNewExcludeStudents,
  } = useContext(CreateExtensionContext);

  const [controlModalRemoveStudent, setControlModalRemoveStudent] =
    useState(false);

  const handleRemoveStudent = (studentId) => {
    if (extraStudent?.includes(studentId)) {
      setExtraStudent((prev) => prev?.filter((id) => id !== studentId));
    }

    setNewExcludeStudents((prevId = []) => [...prevId, studentId]);
    setExcludedStudent((prevId = []) => [...prevId, studentId]);

    customSnackbar(
      'Estudante removido do projeto com sucesso!',
      'confirmation'
    );
  };

  const handleAddStudent = (studentId) => {
    if (excludedStudent?.includes(studentId)) {
      setExcludedStudent((prev) => prev.filter((id) => id !== studentId));
      setNewExcludeStudents((prev) => prev.filter((id) => id !== studentId));
    }
    setExtraStudent((prevId = []) => [...prevId, studentId]);
    setControlModalListing(false);
    customSnackbar(
      'Estudante adicionado à listagem com sucesso!!',
      'confirmation'
    );
  };

  const handleAction = (studentId) => {
    if (addStudentMode) {
      handleAddStudent(studentId);
    } else {
      setControlModalRemoveStudent(true);
    }
  };

  const handleSubmitModal = (studentId) => {
    handleRemoveStudent(studentId);
    setControlModalRemoveStudent(false);
  };

  const handleCloseModal = () => {
    setControlModalRemoveStudent(false);
  };

  const configRemoveStudentModal = {
    bubbleText: 'Atenção!',
    TwoParagraphText:
      countStudents === 1
        ? `Ao remover este estudante, o projeto ficará sem nenhum participante e não poderá ser publicado, a menos que novos estudantes sejam adicionados.<p>Tem certeza de que deseja remover este estudante?</p>`
        : `Tem certeza de que deseja remover o estudante ${student?.name} deste projeto?`,
    buttonConfirmText: 'NÃO REMOVER',
    buttonCancelText: 'SIM, QUERO REMOVER',
    showButtonCancel: true,
    buttonConfirmWidth: 298,
    buttonConfirmHeight: 46,
    modalWidth: 370,
    style: {
      marginLeft: '0px',
      fontSize: '18px',
      lineHeight: '24px',
      marginButtons: '0px',
    },
  };

  return (
    <>
      <StudentItem>
        <div className="line mb-20"></div>
        <div className="row justify-between mb-20">
          <div className="row">
            {!sucessfullLoadAvatar && <ProfileImage src={placeholder} />}
            <ProfileImage
              src={student?.avatar}
              onLoad={() => setSucessfullLoadAvatar(true)}
              style={!sucessfullLoadAvatar ? { display: 'none' } : {}}
            ></ProfileImage>
            <div className="infos">
              <h3 className="name">{student?.name}</h3>
              <p className="info">
                {student?.course} - {student?.semester}º semestre
              </p>
              <p className="info">Código: {student?.external_id}</p>
              <p className="infoHighlight">
                {student?.extension_hours_curricular}h extensão curricular
                realizadas
              </p>
              <p className="infoHighlight">
                {student?.extension_hours_extracurricular}h extensão não
                curricular realizadas
              </p>
            </div>
          </div>
          <div className="buttonContainer">
            <button onClick={() => handleAction(student?.id)}>
              {addStudentMode ? 'Adicionar estudante' : 'Remover estudante'}
            </button>
            {student?.is_added_manually === true && (
              <span>Estudante adicionado manualmente</span>
            )}
          </div>
        </div>
      </StudentItem>

      {controlModalRemoveStudent && (
        <ModalInformation
          config={configRemoveStudentModal}
          closeModalFunction={handleCloseModal}
          modalOpenState={controlModalRemoveStudent}
          buttonConfirmFunction={handleCloseModal}
          buttonCancelFunction={() => handleSubmitModal(student?.id)}
        />
      )}
    </>
  );
}
