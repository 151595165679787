import {
  ButtonDetails,
  ButtonEdit,
  Circle,
  ContainerButtons,
  ContainerCard,
  Content,
  DataContent,
  Image,
  Title,
  ViewStudents,
} from './styled';
import moment from 'moment';
import { BiUserCircle } from 'react-icons/bi';
import { RiBook3Line } from 'react-icons/ri';
import Loading from '../../../../components/Loading';
import { useContext, useState } from 'react';
import Tooltip from 'components/NewTooltip';
import ButtonWithMenu from 'components/ModalShowEvidence/ButtonWithMenu';

import { getDetailsExtensionProject } from 'views/StaffPages/ExtensionProject/services';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import {
  MdCalendarMonth,
  MdOutlineSchool,
  MdOutlineHideImage,
} from 'react-icons/md';
import CreateExtensionProvider, {
  CreateExtensionContext,
} from 'views/StaffPages/ExtensionProject/components/CreateModal/CreateExtensionContext';
import { ModalsContext } from 'components/modals/contexts/modalContext';
import StepsEngine from 'views/StaffPages/ExtensionProject/components/CreateModal/components/StepsEngine';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { getLimitedText } from 'views/StaffPages/ExtensionProject/utils/limitedText';

export function PublishedCard({
  ref,
  item,
  setDetails,
  setIsOpen,
  handleOpenStudentsList,
  handleClickUnpublish,
  waitingForStart,
}) {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [imageError, setImageError] = useState(false);

  const {
    setExtensionProjectId,
    setOpenModalStudents,
    setStatus,
    setIdAttraction,
    extracurriculum,
    kind,
    getCountProjects,
    appliedFilters,

    setFutureExtension,
    setPaginationTwoCurrentPage,
    setResetAllFutureListing,
    setInProgressExtension,
    setPaginationThreeCurrentPage,
    setResetInProgressListing,
  } = useContext(ExtensionProjectsContext);
  const { openModalWithContent } = useContext(ModalsContext);

  const handleClickEdit = async (item) => {
    const modal = openModalWithContent({
      modalContent: mergeContextsHelper(
        <CreateExtensionProvider
          kind={kind}
          getCountProjects={getCountProjects}
          appliedFilters={appliedFilters}
          getModalInstance={() => modal}
          setFutureExtension={setFutureExtension}
          setPaginationTwoCurrentPage={setPaginationTwoCurrentPage}
          setResetAllFutureListing={setResetAllFutureListing}
          setInProgressExtension={setInProgressExtension}
          setPaginationThreeCurrentPage={setPaginationThreeCurrentPage}
          setResetInProgressListing={setResetInProgressListing}
        >
          <StepsEngine
            idProject={item.extension_project.id}
            uuidProject={item.id}
          />
        </CreateExtensionProvider>
      ),
      isNewModalDialog: true,
    });
  };

  const handleMoreInfo = async (item) => {
    setExtensionProjectId(item.extension_project.id);
    setLoadingDetails(true);
    const data = await getDetailsExtensionProject(item.id);
    setDetails(data);
    setIsOpen(true);
    setLoadingDetails(false);
  };

  const formattedDisciplineName =
    item.extension_project.discipline_name.charAt(0).toUpperCase() +
    item.extension_project.discipline_name.slice(1).toLowerCase();
  const limitedDisciplineName = getLimitedText(formattedDisciplineName, 36);

  const menusButtons = [
    {
      name: 'Baixar lista de inscritos',
      onChange: () => handleOpenStudentsList(item.id)
    },
    {
      name: 'Editar Projeto',
      onChange: () => handleClickEdit(item),
    },
    waitingForStart && {
      name: 'Despublicar projeto',
      onChange: () => handleClickUnpublish(),
    },
  ].filter(Boolean);

  const handleOpenEnrolledModal = (item) => {
    setIdAttraction(item.id);
    setExtensionProjectId(item.extension_project.id);
    setStatus(item.is_published);
    setOpenModalStudents(true);
  };

  return (
    <ContainerCard ref={ref}>
      <Image>
        {item.attraction_image.logo ? (
          <MdOutlineHideImage size={64} color="#a3a2a2" />
        ) : (
          <img
            src={item.attraction_image.image}
            alt="Imagem do projeto"
            onError={() => setImageError(true)}
          />
        )}
      </Image>

      <DataContent>
        <p className="projectCode" style={{ margin: '0', textAlign: 'start' }}>
          CÓDIGO: {item.extension_project.id}
        </p>
        <Title title={item.name}>{item.name}</Title>

        <Content>
          <div className="wrapper-content">
            <MdCalendarMonth size={24} color="#009291" />
            <p>
              De {''}
              {moment(item.start_at).format('DD/MM/YYYY')} {''}a {''}
              {moment(item.end_at).format('DD/MM/YYYY')} {''}
            </p>
          </div>

          <div className="wrapper-content">
            <BiUserCircle size={24} color="#009291" />
            {item.extension_project.responsible_teacher ? (
              <Tooltip text={item.extension_project.responsible_teacher}>
                <p>
                  Prof.{' '}
                  {getLimitedText(
                    item.extension_project.responsible_teacher,
                    25
                  )}
                </p>
              </Tooltip>
            ) : (
              <p>Não informado</p>
            )}
          </div>

          <div className="wrapper-content">
            <MdOutlineSchool size={24} color="#009291" />
            <p>
              {(item.extension_project.students_registered > 1 ||
                item.extension_project.students_registered === 0) &&
                `${item.extension_project.students_registered} estudantes inscritos`}
              {item.extension_project.students_registered === 1 &&
                `${item.extension_project.students_registered} estudante inscrito`}
              {item.extension_project.students_registered > 0 && (
                <ViewStudents onClick={() => handleOpenEnrolledModal(item)}>
                  ver
                </ViewStudents>
              )}
            </p>
          </div>

          {!extracurriculum &&
            item.extension_project.discipline_name !== '' && (
              <div className="wrapper-content">
                <RiBook3Line size={24} color="#009291" />
                <p>
                  Disciplina:{' '}
                  <>
                    {limitedDisciplineName}
                    {item.extension_project.discipline_count > 1 && (
                      <button
                        onClick={() => handleMoreInfo(item)}
                        className="button-discipline"
                      >
                        +{item.extension_project.discipline_count - 1}
                      </button>
                    )}
                  </>
                </p>
              </div>
            )}
        </Content>
        <ContainerButtons>
          <ButtonDetails onClick={() => handleMoreInfo(item)}>
            {loadingDetails ? <Loading color={'#fff'} /> : 'Detalhes'}
          </ButtonDetails>
          {item.is_published && (
            <ButtonWithMenu
              item={item}
              insideMenus={menusButtons}
              menuLabel="Ações"
              className="custom-width"
              MinWidth={'6rem'}
              buttonHeight={'41px'}
              fontWeight={'400'}
              fontSize={'16px'}
            />
          )}
        </ContainerButtons>
      </DataContent>
    </ContainerCard>
  );
}
