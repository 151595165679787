import {
  customSnackbar,
  SnackbarType,
} from 'components/CustomSnackBar/customSnackbar';
import { typeIcons, typeSuffixIcons, UploadType } from './enums';
import PdfViewer from 'components/UploadEvidences/components/PdfViwer';

export const getTypeIcon = (item) => {
  switch (item?.type) {
    case UploadType.LINK:
      return typeSuffixIcons['.url'];
    case UploadType.IMAGE:
      return typeSuffixIcons[item?.image?.suffix] || typeIcons[item.suffix];
    case UploadType.DOCUMENT:
      return typeSuffixIcons[item?.file?.suffix] || typeIcons[item.suffix];
  }
};

export const getItemName = (item) => {
  switch (item?.type) {
    case UploadType.LINK:
      return item.link;

    case UploadType.IMAGE:
      const image = item?.image || item?.file;
      return `${image?.name}${image?.suffix ? image?.suffix : ''}`;

    case UploadType.DOCUMENT:
      const file = item.file;
      return `${file?.name}${file?.suffix ? file?.suffix : ''}`;
  }
};

export function handleCopyUrl(url) {
  navigator.clipboard.writeText(url).then(
    () => {
      customSnackbar(
        'URL copiada para a área de transferência com sucesso!',
        SnackbarType.CONFIRMATION
      );
    },
    () => {
      customSnackbar(
        'Erro ao copiar o URL para a área de transferência: ',
        SnackbarType.ERROR
      );
    }
  );
}

export const actionViewingaCopying = (
  item,
  openModalWithContent,
  setSelectedImage
) => {
  switch (item.type) {
    case UploadType.LINK:
      handleCopyUrl(item.link);
      break;
    case UploadType.IMAGE:
      setSelectedImage(item?.image?.file || item?.file);
      break;
    case UploadType.DOCUMENT:
      handleClickViewPdf(item, openModalWithContent);
      break;
  }
};

export const typeOptions = [
  { value: 'file', label: 'Documento' },
  { value: 'link', label: 'Link' },
  { value: 'image', label: 'Imagem' },
];

export function handleClickViewPdf(
  item,
  openModalWithContent,
  downloadAction = true
) {
  const pdfFile = item?.file?.file || item?.file || item;
  const pdfName = item?.file?.name || item?.name;

  openModalWithContent(
    <PdfViewer file={pdfFile} name={pdfName} downloadAction={downloadAction} />,
    null,
    null,
    () => {}
  );
}
