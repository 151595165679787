import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  > h1 {
    font-weight: 700;
    font-size: 20px;
    text-align: start;
    line-height: 21px;
    color: #009291;
    margin: 0;
  }

  > p {
    font-size: 16px;
    line-height: 1.5;
    text-align: start;
    color: #606062;
    margin-top: 0.5rem;
  }

  > strong {
    margin-top: 2rem;
    font-weight: 700;
    font-size: 14px;
  }

  > span {
    font-size: 14px;
    color: #606062;
    margin-bottom: 0.5rem;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: -14px;
`;

export const ContentFilters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0;
`