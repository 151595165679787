import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: flex-start;
  position: relative;
`;
export const Input = styled.input`
  display: flex;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-size: 15px;
  padding: 0.75rem 2rem;
  box-sizing: border-box;

  &::placeholder {
    color: #949494;
    font-size: 15px;
  }

  &:focus {
    border-bottom: 1px solid ${({ color }) => color};
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
`;

export const ErrorMessage = styled.span`
  color: #f00;
  margin-top: 0;
  font-size: 12px;
`;

export const AddButton = styled.button`
  height: 40px;
  padding: 0 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 3px;
  background-color: ${({ color }) => color || '#009291'};
  color: white;
  margin-left: 1rem;
  cursor: pointer;
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  gap: 4px;
  flex-direction: column;
`;
