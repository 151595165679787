import styled from 'styled-components';

export const ContainerTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;

  margin-top: 80px;
`;

export const Title = styled.h1`
  margin-top: 2rem;
  color: #606062;
  font-size: 26px;
`;

export const Paragraph = styled.p`
  margin: 0;
  color: #606062;
  font-size: 16px;
  width: 70%;
  text-align: center;
`;

export const ButtonCard = styled.button`
  width: 220px;
  height: 220px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;

  border: 1px solid #606062;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;

  color: #606062;
  font-weight: 700;
  font-size: 18px;

  gap: 10px;
  padding: 25px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  margin-top: 36px;
`;
