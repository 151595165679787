import React, { useContext, useEffect, useState } from 'react';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import CreateExtensionProvider from './CreateExtensionContext';
import { ModalsContext } from 'components/modals/contexts/modalContext';
import StepsEngine from './components/StepsEngine/index';
import styled from 'styled-components';
import { ExtensionProjectsContext } from '../../context/ExtensionProjectsContext';
import ModalInformation from 'components/informationModal';
import { configWarningModal } from '../../utils/textModal';

const CustonButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #009291;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  outline: none;
  border: none;
  line-height: 20px;
  border: 1px solid #009291;
  transition: all 0.5s;
  width: 150px;
  padding: 10px 0px;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
    filter: drop-shadow(4px 4px 4px #0003) brightness(1.025);
  }
`;

export default function CreateExtensionModalButton(props) {
  const { label } = props;
  const { openModalWithContent, createModal } = useContext(ModalsContext);
  const {
    kind,
    getAllFutureProjects,
    getAllInProgressProjects,
    getCountProjects,
    appliedFilters,
    setFutureExtension,
    setPaginationTwoCurrentPage,
    setResetAllFutureListing,
    setInProgressExtension,
    setPaginationThreeCurrentPage,
    setResetInProgressListing,
  } = useContext(ExtensionProjectsContext);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [modalInstance, setModalInstance] = useState(null);

  const openModal = () => {
    const modal = openModalWithContent({
      modalContent: mergeContextsHelper(
        <CreateExtensionProvider
          kind={kind}
          getCountProjects={getCountProjects}
          appliedFilters={appliedFilters}
          getModalInstance={() => modal}
          setFutureExtension={setFutureExtension}
          setPaginationTwoCurrentPage={setPaginationTwoCurrentPage}
          setResetAllFutureListing={setResetAllFutureListing}
          setInProgressExtension={setInProgressExtension}
          setPaginationThreeCurrentPage={setPaginationThreeCurrentPage}
          setResetInProgressListing={setResetInProgressListing}
        >
          <StepsEngine />
        </CreateExtensionProvider>
      ),
      isNewModalDialog: true,
    });
    setModalInstance(modal);
    modal.closeModal = () => {
      openWarningModal();
    };
  };

  const openWarningModal = () => {
    setIsWarningModalVisible(true);
  };

  const handleConfirmClose = () => {
    if (modalInstance) {
      modalInstance.closeFunc(modalInstance);
      setIsWarningModalVisible(false);
    }
  };

  const handleCancelClose = () => {
    setIsWarningModalVisible(false);
  };

  return (
    <>
      <CustonButton onClick={openModal}>
        {label ?? 'Cadastrar projeto'}
      </CustonButton>

      {isWarningModalVisible && (
        <ModalInformation
          modalOpenState={isWarningModalVisible}
          config={configWarningModal}
          buttonConfirmFunction={handleConfirmClose}
          buttonCancelFunction={handleCancelClose}
          closeModalFunction={handleCancelClose}
        />
      )}
    </>
  );
}
