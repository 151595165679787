import React, { useContext, useRef } from 'react';
import ModalInformation from 'components/informationModal';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { updateStudentApprovalStatus } from 'views/StaffPages/ExtensionProject/services/urls';
import { returnConfigAproveStudents } from './configModals';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';

export default function ModalApproveStudent({
  name,
  students,
  modalApprove,
  setModalApprove,
  quantity,
  refreshStudentList,
}) {
  const { extensionProjectId } = useContext(ExtensionProjectsContext);

  const handleSubmitModal = async () => {
    try {
      const feedback = '';
      const response = await updateStudentApprovalStatus(
        extensionProjectId,
        'approved',
        feedback,
        students.map((student) => typeof student === 'number'? student : student.id)
      );
      refreshStudentList && refreshStudentList(response);
      customSnackbar('Estudantes aprovados com sucesso!', 'confirmation');
      setModalApprove(false);
    } catch (error) {
      customSnackbar('Ocorreu um erro, tente novamente.', 'error');
    }
  };

  return (
    <ModalInformation
      modalOpenState={modalApprove}
      config={returnConfigAproveStudents(name, quantity)}
      buttonConfirmFunction={handleSubmitModal}
      buttonCancelFunction={() => setModalApprove(false)}
      closeModalFunction={() => setModalApprove(false)}
    />
  );
}
