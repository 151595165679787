import React, { useEffect, useContext, Fragment, useRef } from 'react';

import {
  Container,
  Content,
  Title,
  Wrapper,
  SubTitle,
  Divider,
  ContainerDashed,
  ListPublishedProjects,
  ArrowButton,
} from './styled';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import {
  scrollRigthUnpublishProjects,
  scrollLeftUnpublishProjects,
  scrollRigthFinishedProjects,
  scrollLeftFinishedProjects,
} from './utils/logicScroll';

import FinishedProjectsCarousel from './Carousel';
import { formatNumber } from 'utils/formatNumber';
import GenericMessage from 'components/GenericMessage';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';

export default function FinishedProjects(props) {

  const {
    unpublishProjects,
    finishedProjects,
    setCurrentPageUnpublishProject,
    setCurrentPageFinishedProject,
    unpublishProjectCount,
    finishedProjectCount,
    activeTabExtensionProject,

    unpublishedProjects,
    finishedProject,
  } = useContext(ExtensionProjectsContext);

  const finishedProjectsRef = useRef();
  const unpublishedProjectsRef = useRef();

  useEffect(() => {
    setCurrentPageUnpublishProject(0);
    setCurrentPageFinishedProject(0);
  }, [activeTabExtensionProject]);

  useEffect(() => {
    let intersectionObserver;
    if (finishedProjects !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (finishedProjects.length > 7) {
            setCurrentPageFinishedProject((currentPageInsideState) => {
              return currentPageInsideState + 8;
            });
          }
        }
      });

      if (finishedProjects.length === finishedProjectCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (finishedProjects.length > 0) {
        intersectionObserver?.observe(finishedProjectsRef.current);
      }
    }

    return () => intersectionObserver?.disconnect();
  }, [finishedProjects, finishedProjectCount]);

  useEffect(() => {
    let intersectionObserver;

    if (unpublishProjects !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (unpublishProjects.length > 7) {
            setCurrentPageUnpublishProject(
              (currentPageInsideState) => currentPageInsideState + 8
            );
          }
        }
      });

      if (unpublishProjects.length === unpublishProjectCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (unpublishProjects.length > 0) {
        intersectionObserver?.observe(unpublishedProjectsRef.current);
      }
    }

    return () => intersectionObserver?.disconnect();
  }, [unpublishProjects, unpublishProjectCount]);

  return (
    <Container>
      <Content>
        {finishedProjects !== null &&
        finishedProjects.length === 0 &&
        unpublishProjects !== null &&
        unpublishProjects.length === 0 ? (
          <GenericMessage title="Não identificamos nenhum Projeto de Extensão finalizado!" />
        ) : (
          <Fragment>
            <Wrapper>
              <Title marginTop="14px">
                Finalizados ({formatNumber(finishedProject)})
              </Title>
              <SubTitle>
                Aqui você pode ver seus Projetos de Extensão que foram
                finalizados.
              </SubTitle>
              {finishedProjects !== null && finishedProjects.length === 0 ? (
                <GenericMessage title="Não identificamos nenhum Projeto de Extensão finalizado!" />
              ) : (
                <Fragment>
                  <ListPublishedProjects id="finished-project-container">
                    <FinishedProjectsCarousel
                      array={finishedProjects}
                      unpublishCard={false}
                      finishedProject
                    />
                    <li ref={finishedProjectsRef} />
                  </ListPublishedProjects>

                  <div className="scroll-buttons">
                    <ArrowButton onClick={scrollLeftFinishedProjects}>
                      <IoIosArrowBack size={25} color="#ffffff" />
                    </ArrowButton>
                    <ArrowButton onClick={scrollRigthFinishedProjects}>
                      <IoIosArrowForward size={25} color="#ffffff" />
                    </ArrowButton>
                  </div>
                </Fragment>
              )}
            </Wrapper>
            <Divider></Divider>

            <Wrapper>
              <Title>
                Despublicados ({formatNumber(unpublishedProjects)})
              </Title>
              <SubTitle>
                Aqui você pode ver seus projetos de extensão que foram
                despublicados.
              </SubTitle>
              {unpublishProjects !== null && unpublishProjects.length === 0 ? (
                <ContainerDashed height="102px">
                  <p>Não identificamos nenhum projeto despublicado!</p>
                </ContainerDashed>
              ) : (
                <Fragment>
                  <ListPublishedProjects id="unpublish-project-container">
                    <FinishedProjectsCarousel
                      array={unpublishProjects}
                      unpublishCard={true}
                    />
                    <li ref={unpublishedProjectsRef} />
                  </ListPublishedProjects>

                  <div className="scroll-buttons">
                    <ArrowButton onClick={scrollLeftUnpublishProjects}>
                      <IoIosArrowBack size={25} color="#ffffff" />
                    </ArrowButton>
                    <ArrowButton onClick={scrollRigthUnpublishProjects}>
                      <IoIosArrowForward size={25} color="#ffffff" />
                    </ArrowButton>
                  </div>
                </Fragment>
              )}
            </Wrapper>
            <Divider></Divider>
          </Fragment>
        )}
      </Content>
    </Container>
  );
}
