import axios from 'axios';
import getCookieValue from '../../../utils/getCookieValue';
import URLS from '../../../constants/urls';
import { isMasterLet } from 'views/StaffPages/jobOpeningsRebuild/JobController/PublishedJobsTabs';
import { authenticatedAPI } from 'services/api/api';

const getCourses = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/courses`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const createJobOffer = async (jobOffer) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/jobs/create`, jobOffer)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getBackOfficeProfessions = async () => {
  const response = authenticatedAPI
    .get(`api/internal/companies-universities/professions`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getInformationProfessions = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies-universities/professions/retrieve/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTechnicalSkills = (id) => {
  const response = authenticatedAPI
    .get(`api/internal/companies/jobs/additional-and-new-areas`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getBehaviorAttributes = (id) => {
  const response = authenticatedAPI
    .get(`api/internal/companies/jobs/behavioral-attributes`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCities = (cities) => {
  const response = axios
    .get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${cities}/municipios`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getUfsByNumber = (ids) => {
  const response = axios
    .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ids}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const createProfession = (data) => {
  const response = authenticatedAPI
    .post(`api/internal/companies-universities/professions/create`, data)
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};

const getJobDetails = (id) => {
  const response = authenticatedAPI
    .get(`api/internal/companies/jobs/${id}`)
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};

const getJobPartnersDetails = (id) => {
  const response = authenticatedAPI
    .get(`api/internal/companies/partner/jobs/${id}`)
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};

const editJobOffer = (id, data) => {
  const response = authenticatedAPI
    .put(`api/internal/companies/jobs/${id}`, { ...data })
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};

const getJobsCount = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs/count`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getJobsOpeningsCompany = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getJobsPartners = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/partner-jobs?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getHistoryInstitution = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs-history?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getHistoryPartners = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/jobs-partner-history?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCandidates = async (id, partner, query) => {
  let isPartner = partner
    ? `api/internal/companies/partner/jobs/${id}/applications?${query}`
    : `api/internal/companies/jobs/${id}/applications?${query}`;

  const response = authenticatedAPI
    .get(isPartner)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCandidatesHistory = async (id, partner, query) => {
  let isPartner = partner
    ? `api/internal/companies/partner/jobs/history/${id}/applications?${query}`
    : `api/internal/companies/jobs/history/${id}/applications?${query}`;

  const response = authenticatedAPI
    .get(isPartner)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptionsFilterCompany = async (jobID, partner) => {
  const url = partner
    ? `api/internal/companies/partner/jobs/${jobID}/applications/filter`
    : `api/internal/companies/jobs/${jobID}/applications/filter`;

  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptionsFilterHistoryCompany = async (jobID, partner) => {
  const url = partner
    ? `api/internal/companies/partner/jobs/history/${jobID}/applications/filter`
    : `api/internal/companies/jobs/history/${jobID}/applications/filter`;

  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const unPublishJob = async (id) => {
  let url = `api/internal/companies/jobs/${id}/unpublish`;
  const response = authenticatedAPI
    .patch(url, { is_published: false })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateFeedBack = (id, data) => {
  const response = authenticatedAPI
    .patch(`api/internal/companies/jobs/${id}`, { ...data })
    .then((resp) => resp.data)
    .catch((error) => error);

  return response;
};

const filterCandidates = async (id, query, partner, pageQuery) => {
  const url = partner
    ? `api/internal/companies/partner/jobs/${id}/applications?${query}&${pageQuery}`
    : `api/internal/companies/jobs/${id}/applications?${query}&${pageQuery}`;

  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const filterCandidatesHistory = async (id, query, isPartner, pageQuery) => {
  let url = isPartner
    ? `api/internal/companies/partner/jobs/history/${id}/applications?${pageQuery}&${query}`
    : `api/internal/companies/jobs/history/${id}/applications?${pageQuery}&${query}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCompanyCurriculumDetail = async (
  jobOfferId,
  applicationId,
  isPartner
) => {
  let url = isPartner
    ? `api/internal/companies/partner/jobs/${jobOfferId}/applications/${applicationId}`
    : `api/internal/companies/jobs/${jobOfferId}/applications/${applicationId}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCurriculumDetailHistoryPartner = async (jobOfferId, applicationId) => {
  let url = `api/internal/companies/partner/jobs/history/${jobOfferId}/applications/${applicationId}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getJobsHistoryFilters = async () => {
  return authenticatedAPI
    .get(`api/internal/companies/jobs/history/filters`)
    .then((res) => res.data)
    .catch((error) => error);
};

const getPublishedJobsFilters = async (type) => {
  try {
    const response = await authenticatedAPI.get(
      `api/internal/companies/jobs/filter-options`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getFinalCandidates = async (jobId) => {
  if (isMasterLet) {
    return authenticatedAPI
      .get(`api/internal/educational-group/jobs/${jobId}/candidates`)
      .then((res) => res.data)
      .catch((error) => error);
  }
  return authenticatedAPI
    .get(`api/internal/companies/jobs/${jobId}/candidates`)
    .then((res) => res.data)
    .catch((error) => error);
};

const finishJob = async (jobId, data) => {
  return authenticatedAPI
    .patch(`api/internal/companies/jobs/${jobId}/finish`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const finishJobHistory = async (jobId, data) => {
  return authenticatedAPI
    .patch(`api/internal/companies/jobs/history/${jobId}/finish`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const scheduleInterviewCompany = async (jobId, applicationId, data) => {
  return authenticatedAPI
    .post(
      `api/internal/companies/jobs/${jobId}/applications/${applicationId}/interview`,
      data
    )
    .then((res) => res.data)
    .catch((error) => error);
};
const getScheduleInterview = async (jobId, applicationId, interviewId) => {
  try {
    const response = await authenticatedAPI.get(
      `api/internal/companies/jobs/${jobId}/applications/${applicationId}/interview/${interviewId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCancellationCompanyInterview = async (
  jobId,
  applicationId,
  interviewId
) => {
  try {
    const response = await authenticatedAPI.get(
      `api/internal/companies/jobs/${jobId}/applications/${applicationId}/canceled-interview/${interviewId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateScheduleInterview = async (
  jobId,
  applicationId,
  interviewId,
  data
) => {
  try {
    const response = await authenticatedAPI.put(
      `api/internal/companies/jobs/${jobId}/applications/${applicationId}/interview/${interviewId}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getUniversities = async (query = '') => {
  const url = `api/internal/companies/jobs/universities${
    query ? `?${query}` : ''
  }`;
  return authenticatedAPI
    .get(url)
    .then((res) => res)
    .catch((error) => error);
};

const getCoursesByUniversity = async (universities) => {
  const params = new URLSearchParams();
  universities.forEach((u) => params.append('universities', u));
  try {
    const response = await authenticatedAPI.get(
      `/api/internal/companies/jobs/courses?${params.toString()}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const verifyDiversityData = async (universities) => {
  if (!Array.isArray(universities) || universities.length < 1) return true;
  const params = new URLSearchParams();
  universities.forEach((u) => params.append('universities', u.value));
  try {
    const response = await authenticatedAPI.get(
      `/api/internal/companies/jobs/universities/verify-diversity-data?${params.toString()}`
    );
    const hasParams = response.data
      .map((i) => i.student_diversity_data)
      .includes(false);
    return !hasParams;
  } catch (error) {
    return true;
  }
};

export {
  getCourses,
  createJobOffer,
  getBackOfficeProfessions,
  getInformationProfessions,
  getTechnicalSkills,
  getBehaviorAttributes,
  getCities,
  createProfession,
  getJobDetails,
  getUfsByNumber,
  editJobOffer,
  getJobsCount,
  getJobsOpeningsCompany,
  getJobsPartners,
  getHistoryInstitution,
  getHistoryPartners,
  getCandidates,
  unPublishJob,
  getJobPartnersDetails,
  getOptionsFilterCompany,
  filterCandidates,
  getCompanyCurriculumDetail,
  getJobsHistoryFilters,
  getFinalCandidates,
  finishJob,
  finishJobHistory,
  scheduleInterviewCompany,
  getCandidatesHistory,
  getCurriculumDetailHistoryPartner,
  getOptionsFilterHistoryCompany,
  filterCandidatesHistory,
  getScheduleInterview,
  updateScheduleInterview,
  updateFeedBack,
  getUniversities,
  getCancellationCompanyInterview,
  getPublishedJobsFilters,
  getCoursesByUniversity,
  verifyDiversityData,
};
