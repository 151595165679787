import { JPGIcon, PDFIcon, PNGIcon } from 'assets/ComponentIcons/evidencesIcon';
import { RiLink } from 'react-icons/ri';

export const UploadType = {
  LINK: 'link',
  DOCUMENT: 'file',
  IMAGE: 'image',
};

export const typeSuffixIcons = {
  '.pdf': <PDFIcon size={40} />,
  '.png': <PNGIcon size={40} />,
  '.jpg': <JPGIcon size={40} />,
  '.jpeg': <JPGIcon size={40} />,
  '.url': <RiLink color={'#009291'} size={40} />,
};

export const typeIcons = {
  pdf: <PDFIcon size={40} />,
  jpg: <JPGIcon size={40} />,
  jpeg: <JPGIcon size={40} />,
  png: <PNGIcon size={40} />,
  url: <RiLink color={'#009291'} size={40} />,
};
