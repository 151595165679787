import styled from 'styled-components';

export const ButtonStyled = styled.button`
  width: ${({ width }) => (width ? width : '120px')};
  height: ${({ height }) => (height ? height : '34px')};
  border: ${({ border, color }) => (border ? `1px solid ${color}` : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : '#000')};
  background-color: ${({ background }) => (background ? background : '#FFF')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  cursor: pointer;
  a, a:visited, a:hover, a:active {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 7px 0px 0px;
    color: #ffffff;
  }
`;
