import React, { useContext, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import {
  Overlay,
  ContentWrapper,
  Header,
  CloseButton,
  Content,
  MainContent,
  TitleSection,
} from './styled';
import { getReports } from 'views/StaffPages/ExtensionProject/services';
import { EnrolledStudentsList } from '../EnrolledStudentsList';
import { ExtensionProjectsContext } from 'views/StaffPages/ExtensionProject/context/ExtensionProjectsContext';
import { PaginationContainer } from '../../styled';
import Pagination from 'components/CustomPagination';
import { ContentFilters } from '../../../ModalFinishProject/Steps/StepTwo/styled';
import { GenericInputSearch } from 'components/GenericInputSearch';
import ButtonWithMenu from 'components/ModalShowEvidence/ButtonWithMenu';
import { useDebounce } from 'hooks/useDebounce';

export default function EvidenceFinishedModal({ onClose, id }) {
  const [limitPerPage, setLimitPerPage] = useState(20);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [studentCount, setStudentCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState('name');
  const [students, setStudents] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const { currentPagePagination, setCurrentPagePagination } = useContext(
    ExtensionProjectsContext
  );
  const debouncedSearch = useDebounce(searchValue, 500);

  async function getStudents(page = 1) {
    setRequestInProgress(true);
    const offset = (page - 1) * limitPerPage;

    const data = await getReports(
      id,
      limitPerPage,
      offset,
      ordering,
      searchValue
    );
    const { results, count } = data;
    setStudentCount(count);
    setStudents(results);
    setRequestInProgress(false);
  }

  async function changePage(page) {
    setStudents([]);
    setCurrentPagePagination(() => page);
    await getStudents(page);
  }

  const menusButtons = [
    {
      name: 'Avaliação pendente',
      onChange: () => setOrdering('pending'),
    },
    {
      name: 'Estudantes aprovados',
      onChange: () => setOrdering('approved'),
    },
    {
      name: 'Estudantes reprovados',
      onChange: () => setOrdering('disapproved'),
    },
    {
      name: 'Nome (A-Z)',
      onChange: () => setOrdering('student__name'),
    },
    {
      name: 'Nome (Z-A)',
      onChange: () => setOrdering('-student__name'),
    },
  ];

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (debouncedSearch !== null) {
      getStudents({
        limitPerPage,
        offset: (page - 1) * limitPerPage,
        ordering,
        searchValue: debouncedSearch,
      });
    }
  }, [debouncedSearch, ordering, page, limitPerPage]);

  return (
    <Overlay>
      <ContentWrapper>
        <Header>
          <CloseButton onClick={onClose}>
            <IoClose size={22} color="#606062" />
          </CloseButton>
        </Header>
        <Content>
          <MainContent>
            <TitleSection>
              <h1>Avaliações</h1>
              <p>
                Confira abaixo as avaliações realizadas durante o projeto de
                extensão.
              </p>
            </TitleSection>
            <ContentFilters>
              <GenericInputSearch
                placeholder="Buscar por nome ou código"
                value={searchValue}
                onChange={handleSearchChange}
              />
              <ButtonWithMenu
                buttonHeight="50px"
                buttonWidth="125px"
                insideMenus={menusButtons}
                menuLabel="Ordenar por"
                className="custom-width"
                MinWidth={'6rem'}
                transparentButton={true}
              />
            </ContentFilters>
            <EnrolledStudentsList
              ordering={ordering}
              status={true}
              limitPerPage={limitPerPage}
              page={page}
              getStudents={getStudents}
              students={students}
              requestInProgress={requestInProgress}
              showStatus={false}
              finalization={true}
              search={search}
            />

            <PaginationContainer>
              <Pagination
                className="pagination-bar"
                currentPage={currentPagePagination}
                totalCount={studentCount}
                onPageChange={changePage}
                limit={limitPerPage}
                setLimit={setLimitPerPage}
                setCurrentPage={setCurrentPagePagination}
                showLimit={false}
              />
            </PaginationContainer>
          </MainContent>
        </Content>
      </ContentWrapper>
    </Overlay>
  );
}
